import { Component, Input, Output, EventEmitter, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { UserInfoModalService } from '../../util/modal/user-info.modal';
import { SharedApplicationService } from '../../service/shared.service';

@Component({
    selector: 'order-tile',
    templateUrl: './order-tile.component.html',
    styleUrls: ['./order-tile.component.css']
})

export class OrderTileComponent implements OnInit {
    @Input() orderDetails;
    @Input() disableCheckBoxForMissingReadyToPrintImages: boolean = false;
    @Input() isInventoryMang:boolean = false;
    @Input() isCSCLabel:boolean = false;
    @Input() rejectCodes;
    // @Output() orderDetailsChange = new EventEmitter();
    @Output() tileLabelClicked = new EventEmitter();
    @Output() selectClicked =  new EventEmitter();
    @Input() canRejectOrder: boolean = false;
    @Output() holdClicked = new EventEmitter();
    reasonCode: string;
    shipToCountry;
    constructor( 
        @Inject(DOCUMENT) private document: Document, 
        private userModal: UserInfoModalService,
        public sharedAppService: SharedApplicationService ) {}

    ngOnInit() {
        this.reasonCode = this.orderDetails.reason_code === "" ? null : this.orderDetails.reason_code;
        this.shipToCountry = this.orderDetails.shipTo_cust_country;
        
    }

    imageClick() {
        this.tileLabelClicked.emit(this.orderDetails);
        window.scroll(0,0);
    }

    labelHold () {
        let postParams = {
            rejectionCode : this.reasonCode,
            rejectionDesc : this.rejectCodes.reason_description[this.rejectCodes.reason_code.indexOf(this.reasonCode)],
            rejectionComment : ""
        }
        let action = 'OnHold';
        if( this.canRejectOrder ) {
            action = 'Rejected';
        }
        this.sharedAppService.httpPostService(`/api/orderstatus/${this.orderDetails.order_id}/${action}`, postParams)
        .then(
            data => {
                this.holdClicked.emit();
                let msg = `Order has been successfully ${action}.`;
                if(action == "OnHold") {
                    msg = `Order has been placed on hold.`;
                }
                this.userModal.openModal({status: 'success', title: action, message: msg});
            },
        // Error
            err => {
                console.log(err);
                this.userModal.openModal({status: 'error', title: 'Action Failed', message: `${action} of order failed. ${err.message}`}); 
            }
        )
    }
}