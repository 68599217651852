<div class="user-search-container container">
  <div class="row">
    <h2 class="title">
          Edit Group
          <button class="pull-right search-view-toggle" [class.searchCollapsed]="!searchViewToggle" (click)="searchViewToggle = !searchViewToggle;"></button>
        </h2>
  </div>

  <div class="row search-fields-container" *ngIf="searchViewToggle">
    <form [formGroup]="profileForm" (ngSubmit)="updateGroup(profileForm.value)" novalidate autocomplete="off">
      <div class="sub-search-container row">
        <h3>Group Details</h3>
        <div class="sub-search-wrapper">

          <div class="col-md-4" [ngClass]="{'has-error': profileForm.get('group_name').invalid && profileForm.get('group_name').dirty}">
            <label for="group_name">
              <span>Group Name</span>
            </label>
            <input type="text" class="user-input-text" formControlName="group_name" id="group_name" placeholder="Group Name">
            <div class="text-danger" *ngIf="profileForm.get('group_name').dirty && profileForm.get('group_name').invalid">
              <span class="col-sm-10" style="padding:4px 0 0" *ngIf="profileForm.get('group_name').errors">
                    Please enter group name
                </span>
            </div>
          </div>

          <div class="col-md-4" [ngClass]="{'has-error': profileForm.get('group_description').invalid && profileForm.get('group_description').dirty}">
            <label for="group_description">Group Description</label>
              <input type="text" class="user-input-text" formControlName="group_description" id="group_description" placeholder="Group Description">
                <div class="text-danger" *ngIf="profileForm.get('group_description').dirty && profileForm.get('group_description').invalid">
                  <span class="col-sm-10" style="padding:4px 0 0" *ngIf="profileForm.get('group_description').errors">
                      Enter Group Description
                  </span>
                </div>
          </div>

          <div class="col-md-4" id="accessListCheckbox"><span id="checkboxList3">Access List:</span>
            <form id="checkboxList2" formGroupName="access_list">
              <div *ngFor="let access of accesses; let i = index;">
                <input type="checkbox" [formControlName]="access.name" checked="false" [value]="access.name" id="checkbox{{i}}">{{ access.name }}
              </div>
            </form>
          </div>

          <div class="text-center">
            <button type="submit" [disabled]="profileForm.invalid" class="ycc-button button-search">Update</button>
            <button (click)="cancelGroupWasClicked()" class="ycc-button button-clear">Cancel</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
