<div class="order-grid-containter">    
    <!-- <ngx-datatable
        class="material"
        [rows]="dataList"
        [columnMode]="'flex'"
        [columns]="columns"
        [headerHeight]="50"
        [selected]="selectedRows"
        [selectCheck]="checkSelectable"
        [selectionType]="'checkbox'"
        [externalSorting]="true"
        [displayCheck]="displayCheck"
        [loadingIndicator]="loading"
        (sort)="onSort($event)"
        [sorts]="defaultSort"
        [rowHeight]="50"
        [scrollbarV]="true"
        (select)='onSelect($event)'
        (activate)='onUserEvent($event)'>
    </ngx-datatable> -->
    <ngx-datatable
    class="material"
    [rows]="dataList"
    [columnMode]="'flex'"
    [columns]="columns"
    [headerHeight]="50"
    [selected]="selectedRows"
    [selectionType]="'checkbox'"
    [externalSorting]="true"
    [displayCheck]="displayCheck"
    (sort)="onSort($event)"
    [sorts]="defaultSort"
    [rowHeight]="50"
    [scrollbarV]="true"
    (select)='onSelect($event)'
    (activate)='onUserEvent($event)'>
</ngx-datatable>
</div>