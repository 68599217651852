<div class="row">
  <div class="col-md-12">
    <br>
    <form [formGroup]="myForm" (ngSubmit)="Getdetails(myForm)">
      <div class="col-md-3 formgroup datewrapper">
        <label class="pull-left">From Date:</label>
        <!-- <owl-date-time [autoClose]="true" [dateFormat]="'MM/DD/YYYY HH:mm'" [placeHolder]="'MM/DD/YYYY HH:mm'" formControlName="FromDate"
          [ngModel]="crsfromDate" name="crsfromDate" (ngModelChange)="dateSelectedcrs($event, 'from')">
        </owl-date-time> -->
        <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeHolder="'MM/DD/YYYY HH:mm'"
          formControlName="FromDate" [ngModel]="crsfromDate" name="crsfromDate"
          (ngModelChange)="dateSelectedcrs($event, 'from')">
        <owl-date-time #dt1></owl-date-time>
      </div>
      <div class="col-md-3 formgroup datewrapper">
        <label class="pull-left">To Date</label>
        <!-- <owl-date-time [max]="maxDate" [placeHolder]="'MM/DD/YYYY HH:mm'" [autoClose]="true" [dateFormat]="'MM/DD/YYYY HH:mm'" formControlName="ToDate"
          [ngModel]="crstoDate" name="crstoDate" (ngModelChange)="dateSelectedcrs($event, 'to')">
        </owl-date-time> -->
        <input [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" placeHolder="'MM/DD/YYYY HH:mm'" formControlName="ToDate"
          [ngModel]="crstoDate" name="crstoDate" (ngModelChange)="dateSelectedcrs($event, 'to')">
        <owl-date-time #dt2></owl-date-time>
        <span class="date-error" *ngIf="fromToDateMismatch">'From Date' should be earlier than 'To date'</span>
      </div>

      <div class="col-md-3">
        <div class="formgroup">
          <label>Product Form</label>
          <select class="user-input-select" name="commodity" formControlName="commodity">
                <option *ngFor="let status of commodityGroup" [value]="status"
                  >{{status}}</option>
                  <option value='All' selected>All</option>
                  
            </select>
        </div>

      </div>
      <div class="col-md-2 formgroup">
        <button type="submit">Go</button> &nbsp;
        <button type="reset" (click)="resetform()">Clear</button>
      </div>
    </form>
  </div>
  <div class="clearfix"></div>
  <div class="col-md-12">
    <div style="padding-top: 45px;">
      <div *ngIf="showtable">
        <button type="button" class="pull-right exportcsv" (click)="exportcsv(OrdersArray)">Export Excel</button>
        <div>
          <ngx-datatable class="material" [columnMode]="'flex'" [columns]="columns" [headerHeight]="50" [selectionType]="'button'"
            [loadingIndicator]="loading" [rowHeight]="50" [scrollbarV]="true" [rows]="rows" [columns]="columns">
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>