import { Injectable } from '@angular/core';
// import { Http, Response, Headers, RequestOptions} from '@angular/http';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
// import 'rxjs/add/operator/do';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/observable/throw';
// import { throwError } from 'rxjs';

@Injectable()
export class UserService {

  public jwtToken: string;

  constructor(private http: HttpClient) {
    const theUser: any = JSON.parse(localStorage.getItem('currentUser'));
    if (theUser) {
      this.jwtToken = theUser.token;
    }
  }

  register(oUser) {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    // const options = new RequestOptions({headers: headers});
    const options = {
      headers: new HttpHeaders().append('key', 'value')
    }

    return this.http.post('/register', JSON.stringify(oUser), options).pipe(
      map((response: HttpResponse<any>) => response),
      catchError(this.handleError));
  }

  getUser(userid) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    // const options = new RequestOptions({ headers: headers });
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }

    return this.http.get(`/api/user/${userid}`, options).pipe(
      map((response: HttpResponse<any>) => response),
      catchError(this.handleError));
  }
  getUsers() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    // const options = new RequestOptions({ headers: headers });
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`),
    };

    return this.http.get(`/api/user`, options).pipe(
      map((response: HttpResponse<any>) => response),
      catchError(this.handleError));

  }
  updateUser(userid, oUser) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    // const options = new RequestOptions({ headers: headers });
    // oUser.userId=userid
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }

    return this.http.put(`/api/user/${userid}`, JSON.stringify(oUser), options)
      .pipe(map((response: HttpResponse<any>) => response),
        catchError(this.handleError));
  }

  updatePassword(userid, oUser) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    oUser.userId = userid
    // const options = new RequestOptions({ headers: headers });
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }
    return this.http.put(`/api/password/${userid}`, JSON.stringify(oUser), options).pipe(
      map((response: HttpResponse<any>) => response),
      catchError(this.handleError));
  }

  updateLostPassword(userid, oUser) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    oUser.userId = userid
    // const options = new RequestOptions({ headers: headers });
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }
    return this.http.put(`/api/lostpassword/${userid}`, JSON.stringify(oUser), options)
      .pipe(map((response: HttpResponse<any>) => response),
        catchError(this.handleError));
  }

  private handleError(error: Response) {
    console.error(error);
    return throwError(error["error"] || 'Server error');
  }


  deluserDetails(userid) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    // const options = new RequestOptions({ headers: headers });
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }

    return this.http.delete(`/api/user/${userid}`, options)
      .pipe(map((response: HttpResponse<any>) => response),
        catchError(this.handleError));
  }

  transmitData(userid) {
    //user = user;

    console.log(userid);
    return userid;
  }

  transmitData2(groupid) {
    //user = user;

    console.log(groupid);
    return groupid;
  }
  transmitData3(accessid) {
    //user = user;

    console.log(accessid);
    return accessid;
  }

  // Group Management

  registerGroup(oGroup) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    // const options = new RequestOptions({headers: headers});
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }

    return this.http.post('/api/group', JSON.stringify(oGroup), options)
      .pipe(map((response: HttpResponse<any>) => response),
        catchError(this.handleError));
  }

  getGroups() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    // const options = new RequestOptions({headers: headers});
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`),
    }

    return this.http.get(`/api/group`, options)
      .pipe(map((response: HttpResponse<any>) => response),
        catchError(this.handleError));

  }

  delGroup(groupid) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    // const options = new RequestOptions({ headers: headers });
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }

    return this.http.delete(`/api/group/${groupid}`, options)
      .pipe(map((response: HttpResponse<any>) => response),
        catchError(this.handleError));

  }

  updateGroup(groupid, oGroup) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    // const options = new RequestOptions({ headers: headers });
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }

    return this.http.put(`/api/group/${groupid}`, JSON.stringify(oGroup), options)
      .pipe(map((response: HttpResponse<any>) => response),
        catchError(this.handleError));
  }
  getGroup(groupid) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    // const options = new RequestOptions({ headers: headers });
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }

    return this.http.get(`/api/group/${groupid}`, options)
      .pipe(map((response: HttpResponse<any>) => response),
        catchError(this.handleError));
  }

  // Access Management

  registerAccess(oAccess) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    // const options = new RequestOptions({headers: headers});
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }

    return this.http.post('/api/access', JSON.stringify(oAccess), options)
      .pipe(map((response: HttpResponse<any>) => response),
        catchError(this.handleError));
  }

  getAccesses() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    // const options = new RequestOptions({headers: headers});
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }

    return this.http.get(`/api/access`, options)
      .pipe(map((response: HttpResponse<any>) => response),
        catchError(this.handleError));

  }

  delAccess(accessid) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    // const options = new RequestOptions({ headers: headers });
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }

    return this.http.delete(`/api/access/${accessid}`, options)
      .pipe(map((response: HttpResponse<any>) => response),
        catchError(this.handleError));

  }

  updateAccess(accessid, oAccess) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    // const options = new RequestOptions({ headers: headers });
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }

    return this.http.put(`/api/access/${accessid}`, JSON.stringify(oAccess), options)
      .pipe(map((response: HttpResponse<any>) => response),
        catchError(this.handleError));
  }
  getAccess(accessid) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    // const options = new RequestOptions({ headers: headers });
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }

    return this.http.get(`/api/access/${accessid}`, options)
      .pipe(map((response: HttpResponse<any>) => response),
        catchError(this.handleError));
  }

}
