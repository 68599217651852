<div class="controlPanel">
    <order-search *ngIf="isSearchAndDetailToggle" [(searchQuery)]="searchparams" [dropDownList]="dropDownDataList" (clearClick)="resetSearch();" (searchClick)="searchOrders()"></order-search>
    <quick-filter *ngIf="isSearchAndDetailToggle" [quickFilters]="quickFilters" (quickFiltersChange)="filterChanged($event)"></quick-filter>
    <pickticket-detail-view *ngIf="!isSearchAndDetailToggle" [rejectCodes]="dropDownDataList.datalist.rejected" [detailViewData]="detailViewData" [isInventoryMang]="true" (closeDetails)="closeDetailView($event)"></pickticket-detail-view>
</div>

<div class="resultPanel container nopadding">
    <div *ngIf="searchResults" id="top-pagination-list">
        <paginator [isLoading]="isLoading" [searchResults]="searchResults" [pageNumCache]="searchparams.pageno" [(pageNum)]="searchparams.pageno" [(pageSize)]="searchparams.pagesize" (paginationChange)="searchOnPaginationchange($event);" (viewChange)="isListViewToggle = $event;"
            [isInventoryMangage]="true">
            <div searchdisplay>
                <div *ngIf="searchOrderDetailsResults.length; else No_Results">
                    <list-view *ngIf="isListViewToggle" [(dataList)]="searchOrderDetailsResults" (recordClicked)="showDetailsView($event);" [searchQuery]="searchparams" (searchQueryChange)="callSort($event);"></list-view>
                    <grid-view *ngIf="!isListViewToggle" [(dataList)]="searchOrderDetailsResults" (recordClicked)="showDetailsView($event);" [isInventoryMang]="true" [rejectCodes]="dropDownDataList.datalist.rejected"></grid-view>
                </div>
                <ng-template #No_Results>
                    <div class="text-center">No results to display</div>
                </ng-template>
            </div>
            <div actionbuttons>
                <button type="button" class="approve-button pull-right" (click)="generateBatch();" [disabled]="!numOfSelectedItem()">
                    Generate Batch ({{numOfSelectedItem()}})
                </button>
                <button type="button" class="approve-button pull-right" (click)="previewSummaryClickHanlder();" [disabled]="!numOfSelectedItem()">
                    Preview Summary ({{numOfSelectedItem()}})
                </button>
            </div>
        </paginator>
    </div>
</div>
