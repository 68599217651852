import { Component, OnInit, Injectable, Inject, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders,  HttpParams } from "@angular/common/http";
// import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/toPromise';

import { SearchQuery } from '../../shared/model/searchQuery.model';
import { SearchResults } from '../../shared/model/searchResults.model';
import { OrderDetails } from "../../shared/model/orderDetails.model";
import { SearchDataList } from '../../shared/model/searchDataList.model';

import { SharedApplicationService } from '../../shared/service/shared.service';

import { NgForm, FormGroup, Validators, FormControl } from '@angular/forms';
// import { DateAdapter, NativeDateAdapter } from '@angular/material';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StatuspupupComponent } from "../../components/statuspupup/statuspupup.component"
import * as moment from 'moment';
@Component({
    selector: 'csc-labelordermanagement',
    templateUrl: './csc-labelordermanagement.component.html',
    styleUrls: ['./csc-labelordermanagement.component.css'],
    animations: [
        trigger('shrinkInOut', [
            transition(':leave', [
                style({ height: "*" }),
                animate(350, style({ height: 0 }))
            ]),
            transition(':enter', [
                style({ height: 0 }),
                animate(350, style({ height: "*" }))
            ])
        ])
    ]
})
export class CscLabelordermanagementComponent implements OnInit {

    constructor(private http: HttpClient, private Http: HttpClient,
        private sharedAppService: SharedApplicationService, public dialog: MatDialog) { }

    reportForm = new FormGroup({
        fromdate: new FormControl(),
        todate: new FormControl()

    });
    orderForm = new FormGroup({
        ordernumber: new FormControl(),
        sourceorder: new FormControl()

    });


    private FromDate: string;
    private ToDate: string;
    private OrdersArray: any;
    private showErrors: boolean;
    _onload() {
        this.isLoading = true;
        let params = new HttpParams();
        var fromdatenow = new Date()
        fromdatenow.setHours(0)
        fromdatenow.setMinutes(0)
        fromdatenow.setSeconds(0)
        fromdatenow.setMilliseconds(0)

        var todatenow = new Date()
        todatenow.setHours(23)
        todatenow.setMinutes(59)
        todatenow.setSeconds(0)
        todatenow.setMilliseconds(0)

        this.ToDate = todatenow.toString()
        this.FromDate = fromdatenow.toString()

        this.FromDate = moment.parseZone(this.FromDate).format("YYYY-MM-DDTHH:mm:ss")
        this.ToDate = moment.parseZone(this.ToDate).format("YYYY-MM-DDTHH:mm:ss")
        this.reportForm.patchValue({
            fromdate: new Date(fromdatenow.toString()),
            todate: new Date(todatenow.toString())
        })
        this.showErrors = false
        this.http.get('/api/orders/reportdates?fromdate=' +
            this.FromDate + "&todate=" + this.ToDate, { params })
            .subscribe(
            _data => {
                // this.searchResults = _data;
                this.isLoading = false;
                this.isSearchGraphToggle = true;
                this.LoadGraph(_data)
            },
            err => {
                console.log(err);
            }
            )
    }
    fromDate
    toDate
    dateSelectedsales(e, field) {
        if (field === "from") {
            this.fromDate = e;
        } else {
            this.
                toDate = e;
        }
        if (typeof (this.fromDate) == "string" && this.fromDate != "")
            this.fromDate = new Date(this.fromDate)
        this.fromToDateMismatch = !this.checkDateValidity(this.fromDate, this.toDate);
    }

    fromDateInput = (e, field) => {
        // let $thisDate = e.value.toJSON().split("-")
        // var dates = e.targetElement.value.split("/");
        //  this.FromDate = dates[2] + "-" + dates[0] + "-" + dates[1]
        this.FromDate = e.value
    }
    toDateInput = (e, field) => {
        // let $thisDate = e.value.toJSON().split("-")
        // var dates = e.targetElement.value.split("/");
        // this.ToDate = dates[2] + "-" + dates[0] + "-" + dates[1]
        this.ToDate = e.value
        this.fromToDateMismatch = !this.checkDateValidity(this.FromDate, this.ToDate);
    }
    fromToDateMismatch: boolean = false
    checkDateValidity(from, to): boolean {
        if (from && to && from > to) {
            return false;
        }
        //returning true by default
        return true;
    }
    isLoading: boolean = false;
    getchartbardata() {
        this.isLoading = true;
        let params = new HttpParams();
        if (this.FromDate == undefined && this.ToDate == undefined) {
            this.showErrors = true
            return false
        }
        var fromdatenow = new Date(this.fromDate)
        fromdatenow.setHours(0)
        fromdatenow.setMinutes(0)
        fromdatenow.setSeconds(0)
        fromdatenow.setMilliseconds(0)

        var todatenow = new Date(this.toDate)
        todatenow.setHours(23)
        todatenow.setMinutes(59)
        todatenow.setSeconds(0)
        todatenow.setMilliseconds(0)

        this.ToDate = todatenow.toString()
        this.FromDate = fromdatenow.toString()

        // this.ToDate=new Date().toString()
        // this.FromDate=new Date().toString()
        this.FromDate = moment.parseZone(this.FromDate).format("YYYY-MM-DDTHH:mm:ss")
        this.ToDate = moment.parseZone(this.ToDate).format("YYYY-MM-DDTHH:mm:ss")
        this.showErrors = false
        this.http.get('/api/orders/reportdates?fromdate=' +
            this.FromDate + "&todate=" + this.ToDate, { params })
            .subscribe(
            _data => {
                // this.searchResults = _data;
                this.isLoading = false;
                this.isSearchGraphToggle = true;
                this.LoadGraph(_data)
            },
            err => {
                console.log(err);
            }
            )
    }

    //new variables
    public JsonData: any;
    public Jlatters: any = [];

    Newcount: number = 0;
    Pendingcount: number = 0;
    Approvedcount: number = 0;
    OnHoldcount: number = 0;
    Rejectedcount: number = 0;
    ReadytoPrintcount: number = 0;
    Printedcount: number = 0;
    QAVerifiedcount: number = 0;
    QAReviewcount: number = 0;
    Cancelledcount: number = 0;
    Shippedcount: number = 0;
    Returnedcount: number = 0;
    isSearchGraphToggle: boolean = false;

    //private stages: any = [];
    //private Ordernumber: string;
    //private updatedate: string;
    //private OrderName: string;
    //private orderStatusinfo: string;
    private estimated_delivery_date: string;
    private showInfoOrder: boolean;
    public showGraph: boolean = false;
    private noOrderfound: boolean = false;

    private showPending: boolean = true;
    private showOnhold: boolean = true;
    private showCancelled: boolean = true;
    private showApproved: boolean = true;
    private showRejected: boolean = true;
    private showReadyToPrint: boolean = true;
    //private OrderId: string;
    //private sourceorderid: string;

    showGraphFun(value) {
        this.showGraph = value;
        this.isSearchGraphToggle = value;
    }

    private IsProved: boolean = false;
    private thislength: any;
    private ShowStatusBtn = true;
    private SourceShowStatusBtn = false;
    orderonfocusMethod(form) {
        this.ShowStatusBtn = true;
        this.SourceShowStatusBtn = false;
        let ordernumberId = form.ordernumber;
        if (ordernumberId == undefined)
            ordernumberId = "";
        this.orderForm.setValue({
            ordernumber: ordernumberId,
            sourceorder: ""
        });

    }
    selectedrecodData: any;
    onUserEvent(e) {
        if (e.type == "click" && e.column.prop == "order_id") {
            console.log(e.row)
            this.detailViewData = e.row;
            //this.showpopuporder(e.row)
            //this.isSearchAndDetailToggle = false;
            this.selectedrecodData = e.row;

            let dialogRef = this.dialog.open(DialogPreviewSummarypopup, {
               // width: '100%',
                height: '600px',
                panelClass: "orderdetailDialog",
                data: { "recorddata": e.row, "dropDownDataList": this.dropDownDataList, "StatusDtata": this.ShowStatus(e.row["order_id"]) }
            });
            dialogRef.afterClosed().subscribe(result => {
                console.log(`Dialog closed`);
                this.searchOrders();
            });

        }
    }
    ShowStatus(orderid) {
        if (!orderid) {
            alert("Please enter order id");
            return false
        }
        return this.http.get<SearchResults>('/api/orderstatus/' + orderid)
           

    }
    // public updatedGride(date: any): void {        
    //     alert(date);
    //     this.searchOrders();
    // }
    sourceonfocusMethod(form) {
        this.SourceShowStatusBtn = true;
        this.ShowStatusBtn = false;
        let sourceorderId = form.sourceorder;
        if (sourceorderId == undefined)
            sourceorderId = "";
        this.orderForm.setValue({
            ordernumber: "",
            sourceorder: sourceorderId
        });

    }


    public SourceOrderstable = false;

    public SourceOrders: any = [];
    columns: any[] = [
        { name: 'Order Id', flexGrow: 1, prop: 'order_id', },
        { name: 'FirstName', flexGrow: 1, prop: 'shipTo_cust_firstName', },
        { name: 'LastName', flexGrow: 1, prop: 'shipTo_cust_lastName' },
        { name: 'OrderStatus', flexGrow: 1, prop: 'order_status' }

    ];

    ClearSourceOrder(orderid) {
        this.SourceOrderstable = false;
        this.orderForm.setValue({
            ordernumber: null,
            sourceorder: null
        });
        this.showInfoOrder = false;
        this.ShowOrderDetails = false;
        this.noOrderfound = false

    }



    private selected = [];
    public hidesourceorder = false;

    closeUpdateorder() {
        this.ShowOrderDetails = false;
        this.hidesourceorder = false;
        this.isSearchAndDetailToggle = true;

    }


    private ShowOrderDetails: boolean = false;

    LoadGraph(data) {
        // data = data["orders"]
        let MyArray = [];
        let Newcount = 0;
        let Pendingcount = 0;
        let Approvedcount = 0;
        let OnHoldcount = 0
        let Rejectedcount = 0
        let ReadytoPrintcount = 0
        let Printedcount = 0
        let QAVerifiedcount = 0
        let QAReviewcount = 0
        let Cancelledcount = 0
        let Shippedcount = 0
        let Returnedcount = 0;
        //data = data[0];
        if (data) {
            data.map(function (val) {
                // console.log(val)
                if (val["_id"] == "New")
                    Newcount = val["count"]
                else if (val["_id"] == "Pending")
                    Pendingcount = val["count"]
                else if (val["_id"] == "Approved")
                    Approvedcount = val["count"]
                else if (val["_id"] == "OnHold")
                    OnHoldcount = val["count"]
                else if (val["_id"] == "Cancelled")
                    Cancelledcount = val["count"]
                else if (val["_id"] == "Rejected")
                    Rejectedcount = val["count"]
                else if (val["_id"] == "ReadyToPrint")
                   ReadytoPrintcount = val["count"]
                else if (val["_id"] == "Printed")
                    Printedcount = val["count"]
                else if (val["_id"] == "QA Verified")
                    QAVerifiedcount = val["count"]
                else if (val["_id"] == "QA Review")
                    QAReviewcount = val["count"]
                else if (val["_id"] == "Shipped")
                    Shippedcount = val["count"]
		else if (val["_id"] == "Returned")
                    Returnedcount = val["count"]
            })
        }
        if (Newcount == 0)
            Newcount = null
        if (Pendingcount == 0)
            Pendingcount = null
        if (Approvedcount == 0)
            Approvedcount = null
        if (OnHoldcount == 0)
            OnHoldcount = null
        if (Rejectedcount == 0)
            Rejectedcount = null
        if (ReadytoPrintcount == 0)
            ReadytoPrintcount = null
        if (Printedcount == 0)
            Printedcount = null
        if (QAVerifiedcount == 0)
            QAVerifiedcount = null
        if (QAReviewcount == 0)
            QAReviewcount = null
        if (Cancelledcount == 0)
            Cancelledcount = null
        if (Shippedcount == 0)
            Shippedcount = null
        if (Returnedcount == 0)
            Returnedcount = null

        this.JsonData = [
            { letter: "New", frequency: Newcount },
            { letter: "Pending", frequency: Pendingcount },
            { letter: "Approved", frequency: Approvedcount },
            { letter: "OnHold", frequency: OnHoldcount },
            { letter: "Rejected", frequency: Rejectedcount },
            { letter: "Ready to Print", frequency: ReadytoPrintcount },
            { letter: "Printed", frequency: Printedcount },
            { letter: "QA Verified", frequency: QAVerifiedcount },
            { letter: "QA Review", frequency: QAReviewcount },
            { letter: "Cancelled", frequency: Cancelledcount },
            { letter: "Shipped", frequency: Shippedcount },
            { letter: "Returned", frequency: Returnedcount }
        ]
        // console.log(this.JsonData)
        this.barChartData = [
            {
                data: [Newcount, Pendingcount, Approvedcount, OnHoldcount,
                    Rejectedcount, ReadytoPrintcount, Printedcount, QAVerifiedcount, QAReviewcount,
                    Cancelledcount, Shippedcount, Returnedcount]
            }
        ]
        this.isLoading = false;
    }


    ngOnInit() {
        this._onload() //RAcheck
        this.searchparams.pageno = 1; //default page number        
        this.searchparams.pagesize = 50;
        this.searchparams.ordersource = this.cscApprovalConstants.source;
        this.searchparams.orderstatus = this.cscApprovalConstants.status;
        this.searchparams.sortby = this.cscApprovalConstants.sortby;
        this.searchparams.sortorder = this.cscApprovalConstants.sortorder;
        let url = `/api/searchDataList`;
        this.sharedAppService.httpGetService(url)
            .then(
            (data: SearchDataList) => {
                this.dropDownDataList = data;
                let tempCountryList = [];
                for (let key in data.datalist.country[0]) {
                    tempCountryList.push({ id: key, name: data.datalist.country[0][key] });
                }
                this.dropDownDataList.datalist.new_country = tempCountryList;
                this.dropDownDataList.datalist.new_sku = data.datalist.sku.map((str) => {
                    return { id: str, name: str }
                })
                this.dropDownDataList.datalist.new_sku_description = data.datalist.sku_description.map((str) => {
                    return { id: str, name: str }
                })
                this.dropDownDataList.datalist.order_status = this.removeDupsvalue(this.dropDownDataList.datalist.order_status)// ["OnHold", "Pending", "Approved", "Rejected"]
            },
            (err) => console.log(err)
            )
    }
    removeDupsvalue(order_status_data) {
        let data: any = [];
        for (let i = 0; i < order_status_data.length; i++) {
            if (data.indexOf(order_status_data[i]) == -1)
                data.push(order_status_data[i])
        }
        return data;
    }
    // order search

    searchparams = new SearchQuery();
    searchResults: SearchResults;
    searchOrderDetailsResults: OrderDetails[];
    dropDownDataList: SearchDataList;
    isSearchAndDetailToggle: boolean = true;
    isListViewToggle: boolean = false;
    detailViewData: OrderDetails;
    selectedOrders: number;
    pageno: number = 1;


    cscApprovalConstants = {
        source: "UK E-COM",
        status: "",
        sortby: "order_date",
        sortorder: "desc"
    }

    quickFilters = [
        {
            dispName: "Today",
            key: "Today",
            checked: false
        },
        {
            dispName: "Single Orders",
            key: "Single",
            checked: false
        },
        {
            dispName: "Multi Item",
            key: "MultiItem",
            checked: false
        },
        {
            dispName: "Multi Quantity",
            key: "MultiQty",
            checked: false
        },
        {
            dispName: "All",
            key: "OnHold",
            checked: false
        },
        {
            dispName: "Priority Delivery",
            key: "Priority",
            checked: false
        },
        {
            dispName: "72 Hrs +",
            key: "72Hrs",
            checked: false
        }
    ];

    fetchOrders() {
        this.isLoading = true;
        let params = {};
        for (let key of Object.keys(this.searchparams)) {
            if (key == "pagesize" || ((this.searchparams[key] instanceof Array) && this.searchparams[key].length) ||
                (!(this.searchparams[key] instanceof Array) && this.searchparams[key])) {
                params[key] = this.searchparams[key];
            }
        }
        // get the order details on search
        this.sharedAppService.httpGetService('/api/orders/Shippingstatus', params)
            .then(
            // Success
            (data: SearchResults) => {
                data.orders.map((value) => { value.isSelected = true; return value; });
                this.searchResults = data;
                this.searchOrderDetailsResults = data.orders;
                this.isLoading = false;
                if (!this.searchOrderDetailsResults.length) {
                    this.isLoading = true;
                    this.searchparams.pageno = undefined;
                }
            },
            // Error
            err => {
                console.log(err);
                this.isLoading = true;
            }
            )
    }

    searchOrders() {
        this.searchparams.pageno = 1;
        this.pageno = this.searchparams.pageno;
        this.quickFilters = this.quickFilters.map((filter) => { filter.checked = false; return filter });
        this.searchparams.filter = [];
        this.fetchOrders();
    }



    //quick filter
    filterChanged(quickFilters) {
        this.quickFilters = quickFilters;
        this.searchparams.pageno = 1;
        this.pageno = this.searchparams.pageno;
        this.searchparams.filter = quickFilters
            .filter((filterItem) => { return filterItem.checked })
            .map((filteredItem) => { return filteredItem.key });
        this.fetchOrders();
    }

    searchOnPaginationchange(e) {
        this.fetchOrders();
    }

    // details view    
    closeDetailView(e) {
        this.isSearchAndDetailToggle = true;
        if (e) {
            this.fetchOrders();
        }
    }

    showDetailsView(e) {
        this.detailViewData = e;
        this.isSearchAndDetailToggle = false;
    }

    numOfSelectedItem() {
        return (this.searchOrderDetailsResults.filter((obj) => { return obj.isSelected })).length;
    }

    approveSelected() {
        let selectedOrderIds = this.searchOrderDetailsResults
            .filter((obj) => { return obj.isSelected })
            .map((obj) => { return obj.order_id });

        let postParams = {
            order_status: {
                order_id: selectedOrderIds,
                order_status: "Approved",
                entry_date_time: new Date(),
                user: localStorage.getItem('Username')
            }
        }

    }

    callSort(e) {
        this.searchparams = e;
        this.searchparams.pageno = 1;
        this.pageno = this.searchparams.pageno;
        this.fetchOrders();
    }

    resetSearch() {
        this.searchResults = null;
        this.searchOrderDetailsResults = null;
        this.searchparams.filter = [];
    }


    //New Graph[]

    public barChartOptions: any = {
        scaleShowVerticalLines: false,
        responsive: true,
        scaleShowValues: false,
        scaleValuePaddingX: 10,
        scaleValuePaddingY: 10,
        scales: {
            xAxes: [{
                gridLines: {
                    display: false
                }
            }],
            yAxes: [{
                gridLines: {
                    display: false
                }
            }]
        },
        tooltips: { enabled: false },
        animation: {
            onComplete: function () {
                var chartInstance = this.chart,
                    ctx = chartInstance.ctx;
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';
                this.data.datasets.forEach(function (dataset, i) {
                    var meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {
                        var data = dataset.data[index];
                        ctx.fillText(data, bar._model.x, bar._model.y - 5);
                    });
                });
            }
        }
    };
    public barChartLabels: string[] = ['New', 'Pending', 'Approved', 'On-Hold', 'Rejected', 'Ready to Print', 'Printed', 'QA Verified', 'QA Review', 'Cancelled', 'Shipped', 'Returned'];
    public barChartType: string = 'bar';
    public barChartLegend: boolean = false;

    public barChartData: any[] = [
        { data: [] }
    ]

    // events[]
    public chartClicked(e: any): void {
        console.log(e);

    }

    public chartHovered(e: any): void {
        console.log(e);
    }


}

@Component({
    selector: 'dialog-Preview-summarypopup',
    templateUrl: './ShowPopUp.html',
    styleUrls: ['./showpupup.css'],
})
export class DialogPreviewSummarypopup {
    // @Input() recordData: any;
    @Output() onUpdated: EventEmitter<any> = new EventEmitter<any>();
    searchparams = new SearchQuery();
    searchResults: SearchResults;
    searchOrderDetailsResults: OrderDetails[];
    dropDownDataList: SearchDataList;
    isSearchAndDetailToggle: boolean = true;
    isListViewToggle: boolean = false;
    detailViewData: OrderDetails;
    selectedOrders: number;
    pageno: number = 1;
    cscApprovalConstants = {
        source: "UK E-COM",
        status: "",
        sortby: "order_date",
        sortorder: "desc"
    }

    quickFilters = [
        {
            dispName: "Today",
            key: "Today",
            checked: false
        },
        {
            dispName: "Single Orders",
            key: "Single",
            checked: false
        },
        {
            dispName: "Multi Item",
            key: "MultiItem",
            checked: false
        },
        {
            dispName: "Multi Quantity",
            key: "MultiQty",
            checked: false
        },
        {
            dispName: "All",
            key: "OnHold",
            checked: false
        },
        {
            dispName: "Priority Delivery",
            key: "Priority",
            checked: false
        },
        {
            dispName: "72 Hrs +",
            key: "72Hrs",
            checked: false
        }
    ];
    StatusUpdateForm = new FormGroup({
        OrderId: new FormControl(),
        order_status: new FormControl({ value: '', disabled: true }),
        postcode: new FormControl(),
        country: new FormControl({ value: '', disabled: true }),
        state: new FormControl(),
        county: new FormControl(),
        town: new FormControl(),
        address_line_3: new FormControl(),
        address_line_2: new FormControl(),
        address_line_1: new FormControl(),
        company: new FormControl(),
        lastName: new FormControl(),
        firstName: new FormControl(),
        delivery_method: new FormControl({ value: '', disabled: true }),
        shippeddate: new FormControl({ value: '', disabled: true }),
        shippedquantity: new FormControl(),
        lineitemquantity: new FormControl(),
        shipmentgroupid: new FormControl({ value: '', disabled: true }),
        shippingshipmentgroupid: new FormControl({ value: '', disabled: true }),
        tracking_url: new FormControl(['', Validators.required]),
        tracking_number: new FormControl(),
		shipped_service_type: new FormControl(),
        barcode_image: new FormControl(),
        print_ready_image: new FormControl(),
        preview_img: new FormControl(),
        label_img: new FormControl(),
        print_img: new FormControl(),
        thumbnail_img: new FormControl(),
        order_synch: new FormControl({ value: '', disabled: true })


    });

    public showModelBox: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<DialogPreviewSummarypopup>,
        @Inject(MAT_DIALOG_DATA) public recordData: any, private sharedAppService: SharedApplicationService, private http: HttpClient) {

        //  console.log("testing data");
        //         console.log(this.recordData)        

        this.orderStatusData(this.recordData.recorddata, this.recordData.dropDownDataList,this.recordData.StatusDtata)
    }


    previewSummaryGridColumns: any[] = [
        { name: 'SKU', prop: 'sku', flexGrow: 3 },
        { name: 'Description Form', prop: 'sku_desc', flexGrow: 8 },
        { name: 'Qty', prop: 'quantity', flexGrow: 2 }
    ];
    public synch: any[] = ["N", "Y","Q","E"]
	public shipped_service_type: any[] = ["Next Day Delivery", "Two Day Delivery", "Saturday Delivery", "International Delivery", "Sunday Delivery"]
    public service_type_selected = "Next Day Delivery";
    private rejectedDetails: any = []
    public synchselect = 'N';
    public reason_comment: string
    public reason_description: string
    public reason_code: string
    public reject_synch: string

    closePreviewSummaryPopup(): void {
        this.dialogRef.close();
    }

    rejectedDetailsView(data) {
        for (let i = 0; i < data.length; i++) {
            if (data[i]["order_status"] == "Rejected" || data[i]["order_status"] == "On Hold") {
                data[i]["reason_comment"] = data[i].info.rejected.reason_comment;
                this.reason_comment = data[i]["reason_comment"];
                data[i]["reason_description"] = data[i].info.rejected.reason_description;
                this.reason_description = data[i]["reason_description"];
                data[i]["reason_code"] = data[i].info.rejected.reason_code;
                this.reason_code = data[i]["reason_code"];
            }
             if (data[i]["order_status"] == "Rejected" || data[i]["order_status"] == "On Hold" || data[i]["order_status"] == "IN ATG Q" || data[i]["order_status"] == "Data Error") {
                data[i]["reject_synch"] = data[i].info.rejected.synced;
                this.reject_synch = data[i]["reject_synch"]
            }
        }
        console.log(this.reason_description,"data in rejected")
        return data;
    }

    orderStatusData(recorddata,datalist,respo) {
        return new Promise((resolve)=>{
        respo.subscribe(
            data => {
                if (data["data"].length != 0) {
                    //console.log(data)
                    data = data["data"][0];
                    this.Ordernumber = data["order_id"];
                    this.updatedate = data["order_status"][0]["entry_date_time"];
                    this.OrderStatusDetails = this.orderstatusview(data["order_status"])
                    this.orderStatusinfo = data["order_status"][data["order_status"].length - 1]["order_status"];
                    this.OrderId = data["order_id"]                    
                    this.rejectedDetails = this.rejectedDetailsView(data["order_status"])
                    
                    this.showpopuporder(recorddata, datalist);
                    resolve();          
                }
                else {
                    // this.noOrderfound = true;                    
                }
            },
            err => {
                console.log(err);
                // this.isLoading = true;
            }
        )
        })
    }
    public Src_barcode_image: "";
    public Src_print_ready_image: "";
    public Src_preview_img: "";
    public Src_label_img: "";
    public Src_print_img: "";
    public Src_thumbnail_img: ""
    public web_order_id = "";
    public total_line_items = "";
    updateStatus(params, From): any {
        //return false
        let response;
        let userName = JSON.parse(localStorage.getItem('currentUser'))
        userName = userName.user.username

        //const headers = new Headers({ 'Content-Type': 'application/json' });
        const headers = new HttpHeaders().set('Content-Type', 'application/json')
                     .set('user-name', localStorage.getItem('Username'));
        params["shipped_date"] = this.shippeddate;
        params["user"] = userName;
        params["ismultiorders"] = this.ismultiorder
        params["shipmentgroupid"] = From.controls.shipmentgroupid.value
        params["lineitemquantity"] = From.controls.lineitemquantity.value;
        params["order_synch"] = From.controls.order_synch.value;
        params["shipped_date"] = From.controls.shippeddate.value;
        params["tracking_number"] = From.controls.tracking_number.value;
        params["tracking_url"] = From.controls.tracking_url.value;
		params["shipped_service_type"] = params.shipped_service_type;
        params["order_status"] = From.controls.order_status.value;
        params["delivery_method"] = From.controls.delivery_method.value;

        // const options = new RequestOptions({ headers: headers });
        const options = {
            headers: headers.append('key', 'value'),
            params: new HttpParams().append('key', 'value')
          }
        if (!this.checkIsmodify(params, this.recordData.recorddata)) {
            alert("No modifications are detected!")
            return false
        }


        return this.http.post("/api/orders/updatestatus", params, options).toPromise()
            .then(
            response => {
                //console.log(response)
                // var data = response["_body"]
                // data = JSON.parse(data)
                if (response["success"]) {
                    alert(response["message"])
                    this.StatusUpdateForm.patchValue({
                        OrderId: "",
                        order_status: "",
                        postcode: "",
                        country: "",
                        state: "",
                        county: "",
                        town: "",
                        address_line_3: "",
                        address_line_2: "",
                        address_line_1: "",
                        company: "",
                        lastName: "",
                        firstName: "",
                        delivery_method: "",
                        shippeddate: this.parseDate(""),
                        tracking_url: "",
                        shipmentgroupid: "",
                        shippingshipmentgroupid: "",
                        shippedquantity: "",
                        lineitemquantity: "",
                        tracking_number: "",
                        barcode_image: "",
                        print_ready_image: "",
                        preview_img: "",
                        label_img: "",
                        print_img: "",
                        thumbnail_img: "",
                            order_synch:"",
							shipped_service_type:""

                    });
                    // this.ShowOrderDetails = false;
                    this.isSearchAndDetailToggle = true
                    this.onUpdated.emit("hello");
                    this.showModelBox = false;
                    this.dialogRef.close();
                }
                else {
                    alert("Server error")
                }
            }

            ).catch();

    }
    ShippedDate;
    shippeddate
    //validation
    lineitemquantity_invalid = false
    shippedquantity_invalid = false
    tracking_number_invalid = false
    delivery_method_invalid = false
    shippeddate_invalid = false
    tracking_url_invalid = false
	shipped_service_type_invalid = false

    //end validation

    public editmode: boolean = true;
    public _shippedquantity;
    kshuffleEditmode = true
    updatekshuffleStatus(params, From): any {
        var fromvalid = true;
        var invalids = []
        if (params.order_status == "Shipped") {
            if (params.lineitemquantity == "") {
                fromvalid = false
                this.lineitemquantity_invalid = true;
            }
            else {
                this.lineitemquantity_invalid = false;
            }
            if (params.shippedquantity == "") {
                fromvalid = false
                this.shippedquantity_invalid = true;
            }
            else {
                this.shippedquantity_invalid = false;
            }

            if (params.shippeddate == "" || params.shippeddate == undefined) {
                fromvalid = false
                this.shippeddate_invalid = true
            }
            else {
                this.shippeddate_invalid = false;
            }

            if (params.tracking_number == "" || params.tracking_number == undefined) {
                fromvalid = false
                this.tracking_number_invalid = true
            }
            else {
                this.tracking_number_invalid = false;
            }

            if (params.tracking_url == "" || params.tracking_url == undefined) {
                // invalids.push("Tracking URL")
                fromvalid = false
                this.tracking_url_invalid = true;
            }
            else {
                this.tracking_url_invalid = false;
            }

            if (params.delivery_method == "" || params.delivery_method == undefined) {
                fromvalid = false
                this.delivery_method_invalid = true
            }
            else {
                this.delivery_method_invalid = false;
            }
			if(params.shipped_service_type == "" || params.shipped_service_type == undefined) {
                fromvalid = false;
                this.shipped_service_type_invalid = false;
            }
            else{
                this.shipped_service_type_invalid = false;
            }
        }
        if (!fromvalid) {          
            return false
        }
        let response;
        let userName = JSON.parse(localStorage.getItem('currentUser'))
        userName = userName.user.username

        //const headers = new Headers({ 'Content-Type': 'application/json' });
        
        const headers = new HttpHeaders().set('Content-Type', 'application/json')
                     .set('user-name', localStorage.getItem('Username'));
        params["shipped_date"] = params.shippeddate;
        params["user"] = userName;
        params["country"] = From.controls.country.value;
        params["total_line_items"]=this.total_line_items;
		params["shipped_service_type"] = params.shipped_service_type;
        params["quantity"]=  this.total_line_items;
        params["ismultiorders"] = this.ismultiorder
        // const options = new RequestOptions({ headers: headers });
        const options = {
            headers: headers.append('key', 'value'),
            params: new HttpParams().append('key', 'value')
          }
        if (!this.checkIsmodify(params, this.recordData.recorddata)) {
            alert("No modifications are detected!")
            return false
        }
        if (params.order_status == "Rejected") {
            params["reason_comment"] = this.reason_comment;
            params["reason_description"] = this.reason_description;
            params["reason_code"] = this.reason_code;
            return this.http.post("/api/orders/kshuffleupdatestatus", params, options).toPromise()
                .then(
                    response => {
                        console.log(response["_body"], "response from if statement")
                        // var data = response["_body"]
                        // data = JSON.parse(data)
                        if (response["success"]) {
                            alert(response["message"])
                            this.StatusUpdateForm.patchValue({
                                OrderId: "",
                                order_status: "",
                                postcode: "",
                                country: "",
                                state: "",
                                county: "",
                                town: "",
                                address_line_3: "",
                                address_line_2: "",
                                address_line_1: "",
                                company: "",
                                lastName: "",
                                email: "",
                                phone: "",
                                city: "",
                                firstName: "",
                                delivery_method: "",
                                shippeddate: this.parseDate(""),
                                tracking_url: "",
                                shipmentgroupid: "",
                                shippingshipmentgroupid: "",
                                shippedquantity: "",
                                lineitemquantity: "",
                                tracking_number: "",
                                barcode_image: "",
                                print_ready_image: "",
                                preview_img: "",
                                label_img: "",
                                print_img: "",
                                thumbnail_img: "",
                                order_synch: "",
                                ship_via: "",
                                reprint_url: "",
                                invoice_url: "",
                                airwayBill_url: "",
                                amount: "",
                                deliveryDate: "",
                                reason_code: "",
                                reason_description: "",
                                reason_comment: "",
                            })
                            this.isSearchAndDetailToggle = true
                            this.showModelBox = false;
                            this.dialogRef.close();
                        }
                        else {
                            alert("server error");
                        }
                    }
                )
        }else{
        return this.http.post("/api/orders/kshuffleupdatestatus", params, options).toPromise()
            .then(
            response => {
                //console.log(response)
                // var data = response["_body"]
                // data = JSON.parse(data)
                if (response["success"]) {
                    alert(response["message"])
                    this.StatusUpdateForm.patchValue({
                        OrderId: "",
                        order_status: "",
                        postcode: "",
                        country: "",
                        state: "",
                        county: "",
                        town: "",
                        address_line_3: "",
                        address_line_2: "",
                        address_line_1: "",
                        company: "",
                        lastName: "",
                        firstName: "",
                        delivery_method: "",
                        shippeddate: this.parseDate(""),
                        tracking_url: "",
                        shipmentgroupid: "",
                        shippingshipmentgroupid: "",
                        shippedquantity: "",
                        lineitemquantity: "",
                        tracking_number: "",
                        barcode_image: "",
                        print_ready_image: "",
                        preview_img: "",
                        label_img: "",
                        print_img: "",
                        thumbnail_img: "",
                        order_synch: "",
						shipped_service_type:"",
                    });
                    // this.ShowOrderDetails = false;
                    this.isSearchAndDetailToggle = true
                    this.showModelBox = false;
                    this.dialogRef.close();
                }
                else {
                    alert("Server error")
                }
            }

            ).catch();
        }
    }

    kshuffleditmodeIstrue(event) {
        this.kshuffleEditmode = false;
        event.controls.order_status.enable("true")
        event.controls.order_synch.enable("true")
        event.controls.delivery_method.enable("true")
        event.controls.shipmentgroupid.enable("true")
		event.controls.shipped_service_type.enable("true")
        event.controls.shippeddate.enable("true")
        if (event.controls.order_status.value == "Shipped" && !this.kshuffleEditmode) {
            this.Isnotshippined = true;
            this.multiorderchecked = true;
            this.ismultiorder = true
        }
        else {
            this.Isnotshippined = false;
            this.multiorderchecked = false;
            this.ismultiorder = false
        }
    }
    editmodeIstrue(event) {
        this.editmode = false;
        event.controls.shippingshipmentgroupid.enable("true")
        event.controls.country.enable("true")
        this.multiorderchecked = true;
        this.ismultiorder = true
        // this.StatusUpdateForm.enable()
    }
    statuschangevalue(event) {
        if (event.controls.order_status.value == "Shipped" && !this.kshuffleEditmode) {
            this.Isnotshippined = true
            this.multiorderchecked = true;
            this.ismultiorder = true
        }
        else {
            this.Isnotshippined = false
            this.multiorderchecked = false;
            this.ismultiorder = false
            event.controls.shippedquantity.setValue(this._shippedquantity)

        }

    }
    public Isnotshippined: boolean = false;
    private statusDropDown: any = [];
    public countryid = "";
    public _delivery_method
    order_statusVal
    showtabactive = true

    showtabs(value) {
        this.showtabactive = value
    }
    showpopuporder(data, dropDownDataListData) {
        dropDownDataListData = dropDownDataListData.datalist
        this.searchparams.ordersource = this.cscApprovalConstants.source;
        this.searchparams.orderstatus = this.cscApprovalConstants.status;
        this.searchparams.sortby = this.cscApprovalConstants.sortby;
        this.searchparams.sortorder = this.cscApprovalConstants.sortorder;
        this.dropDownDataList = this.recordData.dropDownDataList;

        //console.log(dropDownDataListData)
        let tempCountryList = [];
        for (let key in dropDownDataListData.country[0]) {
            tempCountryList.push({ id: key, name: dropDownDataListData.country[0][key] });
        }
        this.dropDownDataList.datalist.new_country = tempCountryList;
        this.dropDownDataList.datalist.new_sku = dropDownDataListData.sku.map((str) => {
            return { id: str, name: str }
        })
        this.dropDownDataList.datalist.new_sku_description = dropDownDataListData.sku_description.map((str) => {
            return { id: str, name: str }
        })
        this.dropDownDataList.datalist.delivery_method = dropDownDataListData.delivery_method
        this.statusDropDown = this.removeRejected(this.dropDownDataList.datalist.order_status, data["order_status"])

        this.showModelBox = true;

        let recepient = data;
        let tracking_url = "", tracking_number = "", tracking_urls = "", tracking_numbers = "";
        if (recepient != "" && recepient != undefined) {
            if (recepient["postcode"] == undefined)
                recepient["postcode"] = "";
            if (recepient["country"] == undefined)
                recepient["country"] = ""
            if (recepient["state"] == undefined)
                recepient["state"] = ""
            if (recepient["county"] == undefined)
                recepient["county"] = ""
            if (recepient["town"] == undefined)
                recepient["town"] = ""
            if (recepient["address_line_3"] == undefined)
                recepient["address_line_3"] = ""
            if (recepient["address_line_2"] == undefined)
                recepient["address_line_2"] = ""
            if (recepient["address_line_1"] == undefined)
                recepient["address_line_1"] = ""
            if (recepient["company"] == undefined)
                recepient["company"] = ""
            if (recepient["lastName"] == undefined)
                recepient["lastName"] = ""
            if (recepient["firstName"] == undefined)
                recepient["firstName"] = ""
            if (recepient["delivery_method"] == undefined)
                recepient["delivery_method"] = ""
            if (recepient["shipTo_Shipping_date"] == undefined)
                recepient["shipTo_Shipping_date"] = ""
            if (recepient["shipped_quantity"] == undefined)
                recepient["shipped_quantity"] = ""
            if (recepient["line_item_quantity"] == undefined)
                recepient["line_item_quantity"] = ""
            if (recepient["barcode_img"] == undefined)
                recepient["barcode_img"] = ""
            if (recepient["print_ready_image"] == undefined)
                recepient["print_ready_image"] = ""
            if (recepient["preview_img"] == undefined)
                recepient["preview_img"] = ""
            if (recepient["label_img"] == undefined)
                recepient["label_img"] = ""
            if (recepient["print_img"] == undefined)
                recepient["print_img"] = ""
            if (recepient["thumbnail_img"] == undefined)
                recepient["thumbnail_img"] = ""
            if (recepient["shipped_line_item_quantity"] == undefined)
                recepient["shipped_line_item_quantity"] = ""
            if (recepient["shipment_group_id"] == undefined)
                recepient["shipment_group_id"] = ""
            if (recepient["shipTo_shipped_synced"] && (recepient["order_status"] == "Shipped")) {
                    this.synchselect = recepient["shipTo_shipped_synced"];
                }
            else if ((recepient["order_status"] == "Rejected")|| recepient["order_status"] == "IN ATG Q" || recepient["order_status"] == "Data Error") {
                    this.synchselect = this.reject_synch;
                    recepient["shipTo_shipped_synced"] = this.synchselect;
                }
            else {
                    this.synchselect = 'N';
                }

            if (recepient.shipTo_tracking.length == 0 || recepient.shipTo_tracking == undefined) {
                tracking_url = ""
                tracking_number = ""
            }
            else {
                 if (recepient.shipTo_tracking.length > 0) {
                    for (var i = 0; i < recepient.shipTo_tracking.length; i++) {
                        if (i == 0) {
                            tracking_number += recepient["shipTo_tracking"][i]["tracking_number"];
                            tracking_url += recepient["shipTo_tracking"][i]["tracking_url"];
                        }
                        else {
                            tracking_url += " and " + recepient["shipTo_tracking"][i]["tracking_url"];
                            tracking_number += " and " + recepient["shipTo_tracking"][i]["tracking_number"];
                        }

                    }
                }
                else {
                    tracking_url = recepient["shipTo_tracking"][0]["tracking_url"];
                    tracking_number = recepient["shipTo_tracking"][0]["tracking_number"];
                }
            }
			if (recepient["shipped_service_type"] == undefined)
                recepient["shipped_service_type"] = ""
            // if (tracking_url == undefined)
            //     tracking_url = ""
            // if (tracking_number == undefined)
            //     tracking_number = ""

            // if(recepient["order_status"]=="Shipped"&& this.editmode){
            //     this.Isnotshippined=true;
            // }
            // else{
            //     this.Isnotshippined=false;
            // }
            // if (recepient["tracking_url"] == undefined)
            //     recepient["tracking_url"] = ""
            // if (recepient["tracking_number"] == undefined)
            //     recepient["tracking_number"] = ""
            this.countryid = recepient["shipTo_cust_country"]
            this.StatusUpdateForm.patchValue({
                OrderId: recepient["order_id"],
                order_status: recepient["order_status"],//data[["order_status"]
                postcode: recepient["shipTo_cust_postcode"],
                country: recepient["shipTo_cust_country"],
                state: recepient["shipTo_cust_state"],
                county: recepient["shipTo_cust_county"],
                town: recepient["shipTo_cust_town"],
                address_line_1: recepient["shipTo_cust_address_line_1"],
                address_line_2: recepient["shipTo_cust_address_line_2"],
                address_line_3: recepient["shipTo_cust_address_line_3"],
                company: recepient["shipTo_cust_company"],
                lastName: recepient["shipTo_cust_lastName"],
                firstName: recepient["shipTo_cust_firstName"],
                delivery_method: recepient["shipping_method"],
                shippeddate: this.parseDate(recepient["shipTo_Shipping_date"]),//(new Date(e.setMinutes(e.getMinutes() - e.getTimezoneOffset()))).toJSON(),
                shippedquantity: recepient["shipped_quantity"],
                lineitemquantity: recepient["shipped_line_item_quantity"],
                tracking_url: tracking_url,///recepient["shipTo_tracking"][0]["tracking_url"],
                tracking_number: tracking_number,
                barcode_image: recepient["barcode_img"],
                print_ready_image: recepient["print_ready_image"],
                preview_img: recepient["preview_img"],
                label_img: recepient["label_img"],
                print_img: recepient["print_img"],
                thumbnail_img: recepient["thumbnail_img"],
                order_synch: recepient["shipTo_shipped_synced"],
                shipmentgroupid: recepient["shipment_group_id"],
				shipped_service_type: recepient["shipped_service_type"]
                /// shippingshipmentgroupid:recepient["shipment_group_id"]

            });
            this.order_statusVal= recepient["order_status"]
            //this.quantityVal=recepient["quantity"]
            this.Src_barcode_image = recepient["barcode_img"]
            this.Src_print_ready_image = recepient["print_ready_image"]
            this.Src_preview_img = recepient["preview_img"]
            this.Src_label_img = recepient["label_img"]
            this.Src_print_img = recepient["print_img"]
            this.Src_thumbnail_img = recepient["thumbnail_img"]
            this.shippeddate = recepient["shipTo_Shipping_date"];
            this.web_order_id = recepient["source_order_id"];
            this.total_line_items = recepient["quantity"];
            
			if(recepient["shipped_service_type"]){
                this.service_type_selected = recepient["shipped_service_type"];
            }
            else{
                this.service_type_selected = "Next Day Delivey";
            }
            this._shippedquantity = recepient["shipped_quantity"];
            this._delivery_method = recepient["shipping_method"]
            //this.ShowStatus(recepient["order_id"])
            // this.editmode = true
            //this.ShowOrderDetails = true;
            //this.noOrderfound = false;

        }
        else {
            // this.noOrderfound = true;
            // this.ShowOrderDetails = false;
        }
    }
    parseDate(dateString: string): Date {
        if (dateString) {
            return new Date(dateString);
        } else {
            return null;
        }
    }

    toDateInput = (e, field) => {
        let $thisDate = e.value.toJSON().split("-")
        var dates = e.targetElement.value.split("/");
        this.shippeddate = dates[2] + "-" + dates[0] + "-" + dates[1]
    }
    //  dateSelected(e, field) {
    //      this.shipped_date = (new Date(e.setMinutes(e.getMinutes() - e.getTimezoneOffset()))).toJSON();
    //     // this.StatusUpdateForm.setValue({ 
    //     //     shipped_date :(new Date(e.setMinutes(e.getMinutes() - e.getTimezoneOffset()))).toJSON()
    //     // });
    //     //alert(this.shippeddate)
    // }
    //orderInfo
    private OrderStatusDetails: any = [];
    private stages: any = [];
    private Ordernumber: string;
    private updatedate: string;
    private OrderName: string;
    private orderStatusinfo: string;
    private OrderId: string;
    private sourceorderid: string;
    ismultiorder: boolean = true


    orderstatusview(data) {
        let temp = []
        for (let i = 0; i < data.length; i++) {
            data[i]["date"] = data[i].entry_date_time.split("T")[0]
            data[i]["time"] = data[i].entry_date_time.split("T")[1].split(".")[0]
        }
        return data;

    }
    removeRejected(data, CurrentStatus) {
        let temp = []
        if (CurrentStatus == "Rejected") {
            temp.push("Rejected")
        }
        else {
            for (let i = 0; i < data.length; i++) {
                if (data[i] != "Rejected") {
                    temp.push(data[i])
                }
            }
        }
        return temp;
    }
    checkIsmodify(newValues, oldValues) {
        let tracking_url = "", tracking_number = "";
        if (oldValues.shipTo_tracking.length == 0) {
            tracking_url = ""
            tracking_number = ""
        }
        else {
            tracking_url = oldValues["shipTo_tracking"][0]["tracking_url"];
            tracking_number = oldValues["shipTo_tracking"][0]["tracking_number"];
        }
        if (newValues.address_line_1 != oldValues.shipTo_cust_address_line_1)
            return true
        if (newValues.address_line_2 != oldValues.shipTo_cust_address_line_2)
            return true
        if (newValues.address_line_3 != oldValues.shipTo_cust_address_line_3)
            return true
        if (newValues.firstName != oldValues.shipTo_cust_firstName)
            return true
        if (newValues.lastName != oldValues.shipTo_cust_lastName)
            return true
        if (newValues.order_status != oldValues.order_status)
            return true
        if (newValues.postcode != oldValues.shipTo_cust_postcode)
            return true
        if (newValues.company != oldValues.shipTo_cust_company)
            return true
        if (newValues.country != oldValues.shipTo_cust_country)
            return true
        if (newValues.county != oldValues.shipTo_cust_county)
            return true
        if (newValues.town != oldValues.shipTo_cust_town)
            return true
        if (newValues.delivery_method != oldValues.shipping_method)
            return true
        if (newValues.barcode_image != oldValues.barcode_img)
            return true
        if (newValues.label_img != oldValues.label_img)
            return true
        if (newValues.preview_img != oldValues.preview_img)
            return true
        if (newValues.print_img != oldValues.print_img)
            return true
        if (newValues.print_ready_image != oldValues.print_ready_image)
            return true
        if (newValues.thumbnail_img != oldValues.thumbnail_img)
            return true
        if (newValues.tracking_number != tracking_number)
            return true
        if (newValues.tracking_url != tracking_url)
            return true
        if (newValues.shipped_date != oldValues.shipTo_Shipping_date)
            return true
        if (newValues.shippedquantity != oldValues.shipped_quantity)
            return true
        if (newValues.lineitemquantity != oldValues.quantity)
            return true
		 if(newValues.shipped_service_type != oldValues.shipped_service_type)
            return true

        //  if(newValues.shippeddate!=oldValues.shipTo_Shipping_date)
        //     return true
        if (newValues.state != oldValues.shipTo_cust_state)
            return true
    }
    multiorderchecked = false
    multiorders(value, formvalues, from) {
        if (value.target.checked) {
            if (from == "frmorderflow") {
                if (formvalues.controls.order_status.value == "Shipped") {
                    this.ismultiorder = true
                }
                else {
                    alert("Order Status must be shipped, to upadte multi Orders")
                    value.target.checked = false
                }
            } else {
                this.ismultiorder = true
            }
        }
        else
            this.ismultiorder = false
    }

}