import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from '../../../common/toastr.service';
import { UserService } from '../../../user/user.service';
import { AuthService } from '../../../user/auth.service';
import { IUser } from '../../../user/user';
import { IGroup } from '../../../user/group';
import { IAccess } from '../../../user/access';
// import { FindGroupComponent } from '../find-group/find-group.component';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/do';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/observable/throw';

@Component({
  selector: 'app-edit-access',
  templateUrl: './edit-access.component.html',
  styleUrls: ['./edit-access.component.css']
})
export class EditAccessComponent implements OnInit {

  @Input() accessid: string;
  @Output() cancelAccessClicked = new EventEmitter();

  ngOnChanges(change) {
    this.getAccessEdit();
  }

  profileForm: FormGroup

  access: IAccess
  data: any;

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private userService: UserService,
              // private FindUserComponent: FindUserComponent,
              private router: Router,
              private toastr: ToastrService) {
  }

  name = new FormControl('', [Validators.required]);
  description = new FormControl('', [Validators.required]);
  type = new FormControl('', [Validators.required]);

  searchViewToggle: boolean = true;

  ngOnInit(): void {
    this.profileForm = this.fb.group({
      name: this.name,
      description: this.description,
      type: this.type
    });
  }

  populateAccess(): void{
    this.access = this.userService.transmitData3(this.data);
    // this.userService.transmitDat a(user).then(user => this.user = user)

    console.log(this.access)
  }

populateForm(data): void {
    this.profileForm.patchValue({
      name: data.name,
      description: data.description,
      type: data.type
    });
  }

  updateAccess(accessid): void {
    // console.log(formdata)
    if (this.profileForm.dirty && this.profileForm.valid) {
      this.userService.updateAccess(this.accessid, this.profileForm.value) //this.accessid
        .subscribe(data => {
          if (data["success"] === false) {
            if (data["errcode"]){
              // this.authService.logout();
              this.router.navigate(['accessmanagement']);
            }
            // this.toastr.error(data.message);
          } else {
            // this.toastr.success(data.message);
            location.reload();
            // const theUser: any = JSON.parse(localStorage.getItem('currentUser')); //'currentUser' must be something else
            // theUser.user.firstname = this.profileForm.value.firstname;
            // localStorage.setItem('currentUser', JSON.stringify(theUser));   //same as above
          }
        });
    }
  }

  getAccessEdit(): void {
    this.userService.getAccess(this.accessid).subscribe(data => {

      if (data["success"] === false) {
        if (data["errcode"]) {
          //this.authService.logout();
          this.router.navigate(['/accessmanagement']);
        }
        // this.toastr.error(data.message);
      } else {
        this.access = data["data"];
        this.populateForm(this.access[0]);

      }

    });
    // console.log(this.access[0]);

  }

  cancelAccessWasClicked(){
    this.cancelAccessClicked.emit();
  }

}
