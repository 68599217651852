import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from '../../common/toastr.service';
import { UserService } from '../../user/user.service';
import { AuthService } from '../../user/auth.service';
import { WarningComponent } from '../../components/password-expiring-warning/warning.component';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { TagService } from '../../user/tag.service';

function comparePassword(c: AbstractControl): { [key: string]: boolean } | null {
    const passwordControl = c.get('password');
    const confirmControl = c.get('retypepass');

    if (passwordControl.pristine || confirmControl.pristine) {
        return null;
    }

    if (passwordControl.value === confirmControl.value) {
        return null;
    }
    return { 'mismatchedPassword': true };
}

@Component({
    providers: [WarningComponent],
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
    tag = {
        version: '',
        env: '',
        region: ''
    };
    passwordForm: FormGroup;
    userObj: any;
    user: any;
    userid: string;
    roles: string;
    constructor(private fb: FormBuilder,
        private authService: AuthService,
        private userService: UserService,
        private router: Router,
        private toastr: ToastrService,
        private warningComponent: WarningComponent,
        private activatedRoute: ActivatedRoute,
        public tagService: TagService,
        private http: HttpClient) {
    }
    roles_lists = ["Administrator Accounts", "Privileged Accounts", "Non-Privileged Accounts", "Service Accounts"];
    password = new FormControl('', [Validators.required,
    Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9_]).{16,20}$')]);
    retypepass = new FormControl('', [Validators.required]);

    ngOnInit() {
        this.userObj = this.authService.currentUser;
        window.scrollTo(0, 0);
        this.activatedRoute.queryParams.subscribe(params => {
            this.roles = decodeURI(params.role);
            this.userid = params.id;
        })
        if (this.roles === 'Administrator Accounts') {
            this.password.setValidators(
                [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9_]).{16,20}$')]);

        } else if (this.roles === 'Privileged Accounts') {
            this.password.setValidators(
                [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9_]).{10,20}$')]);

        } else if (this.roles === 'Non-Privileged Accounts') {
            this.password.setValidators(
                [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9_]).{8,20}$')]);
        } else if (this.roles === 'Service Accounts') {
            this.password.setValidators(
                [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9_]).{18,20}$')]);
        }
        this.passwordForm = this.fb.group({
            passwordGroup: this.fb.group({
                password: this.password,
                retypepass: this.retypepass,
            }, { validator: comparePassword })
        });
        this.tagService.getTag().subscribe(tag =>
            this.tag = tag["data"]
        );
    }

    updatePassword(formdata: any): void {
        if (this.passwordForm.dirty && this.passwordForm.valid) {
            const theForm = this.passwordForm.value;
            const headers = new Headers({
                'Content-Type': 'application/json'
            });
            formdata["password"] = theForm.passwordGroup.password;
            // const options = new RequestOptions({ headers: headers });
            const options = {
                headers: new HttpHeaders().append('key', 'value'),
            }
            this.http.put(`/api/reset/${this.userid}`, formdata, options).toPromise()
                .then(
                    response => {
                        // var data = response["_body"]
                        // data = JSON.parse(data)
                        if (response["success"]) {
                            alert(response["message"])
                            this.router.navigate(['/login']);
                        }
                        else {
                            alert(response["message"])
                        }
                    }
                ).catch();

        }

    }

    populateForm(data): void {
        this.passwordForm.patchValue({
            roles: data.roles
        });
    }


    getUserEdit(userid): void {
        this.userService.getUser(this.userObj.userid).subscribe(data => {

            if (data["success"] === false) {
                if (data["errcode"]) {
                    // this.authService.logout();
                    this.router.navigate(['/usermanagement']);
                }
                // this.toastr.error(data.message);
            } else {
                this.user = data["data"];
                this.populateForm(this.user[0]);

            }

        });
        // console.log(this.username);

    }

}
