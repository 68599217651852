import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedApplicationService } from '../../shared/service/shared.service';

import { SearchQuery } from '../../shared/model/searchQuery.model';
import { SearchResults } from '../../shared/model/searchResults.model';
import { OrderDetails } from "../../shared/model/orderDetails.model";
import { SearchDataList } from '../../shared/model/searchDataList.model';
import { PreviewSummaryDetails } from '../../shared/model/previewSummaryDetails.model';
import { UserInfoModalService } from '../../shared/util/modal/user-info.modal';

@Component({
    selector: 'Inventory-Management',
    templateUrl: './inventoryManagement.component.html',
    styleUrls: ['./inventoryManagement.component.css']
})
export class InventoryManagementPageComponent implements OnInit {

    isPreviewSummaryPopup: boolean = false;
    constructor(public dialog: MatDialog,
        private userModal: UserInfoModalService, private sharedAppService: SharedApplicationService) { }

    ngOnInit() {
        this.searchparams.pageno = 1; //default page number        
        this.searchparams.pagesize = 0;
        this.searchparams.ordersource = this.cscApprovalConstants.source;
        this.searchparams.orderstatus = this.cscApprovalConstants.status;
        this.searchparams.sortby = this.cscApprovalConstants.sortby;
        this.searchparams.sortorder = this.cscApprovalConstants.sortorder;
        let url = `/api/searchDataList`;
        this.sharedAppService.httpGetService(url)
            .then(
            (data: SearchDataList) => {
                this.dropDownDataList = data;
                let tempCountryList = [];
                for (let key in data.datalist.country[0]) {
                    tempCountryList.push({ id: key, name: data.datalist.country[0][key] });
                }
                this.dropDownDataList.datalist.new_country = tempCountryList;
                this.dropDownDataList.datalist.new_sku = data.datalist.sku.map((str) => {
                    return { id: str, name: str }
                })
                this.dropDownDataList.datalist.new_sku_description = data.datalist.sku_description.map((str) => {
                    return { id: str, name: str }
                })
                this.dropDownDataList.datalist.order_status = ["Approved"]
            },
            (err) => console.log(err)
            )
    }

    // order search
    isLoading: boolean = true;
    searchparams = new SearchQuery();
    searchResults: SearchResults;
    searchOrderDetailsResults: OrderDetails[];
    dropDownDataList: SearchDataList;
    isSearchAndDetailToggle: boolean = true;
    isListViewToggle: boolean = true;
    detailViewData: OrderDetails;
    selectedOrders: number;
    pageno: number = 1;
    cscApprovalConstants = {
        source: "UK E-COM",
        status: "Approved",
        sortby: "order_date",
        sortorder: "desc"
    }

    quickFilters = [
        {
            dispName: "Today",
            key: "Today",
            checked: false
        },
        {
            dispName: "Single Orders",
            key: "Single",
            checked: false
        },
        {
            dispName: "Multi Item",
            key: "MultiItem",
            checked: false
        },
        {
            dispName: "Multi Quantity",
            key: "MultiQty",
            checked: false
        },
        {
            dispName: "All",
            key: "Approved",
            checked: false
        },
        {
            dispName: "Priority Delivery",
            key: "Priority",
            checked: false
        },
        {
            dispName: "72 Hrs +",
            key: "72Hrs",
            checked: false
        }
    ];

    fetchOrders() {
        this.isLoading = true;
        this.searchparams.pageno = this.searchparams.pageno || 1;
        let params = {};
        for (let key of Object.keys(this.searchparams)) {
            if (key == "pagesize" || ((this.searchparams[key] instanceof Array) && this.searchparams[key].length) ||
                (!(this.searchparams[key] instanceof Array) && this.searchparams[key])) {
                params[key] = this.searchparams[key];
            }
        }
        // get the order details on search
        this.sharedAppService.httpGetService('/api/orders', params)
            .then(
            // Success
            (data: SearchResults) => {
                data.orders.map((value) => { value.isSelected = true; return value; });
                this.searchResults = data;
                this.searchOrderDetailsResults = data.orders;
                this.isLoading = false;
                if (!this.searchOrderDetailsResults.length) {
                    this.isLoading = true;
                    this.searchparams.pageno = undefined;
                }
            },
            // Error
            err => {
                console.log(err);
                this.isLoading = true;
            }
            )
    }

    searchOrders() {
        this.searchparams.pageno = 1;
        this.pageno = this.searchparams.pageno;
        this.quickFilters = this.quickFilters.map((filter) => { filter.checked = false; return filter });
        this.searchparams.filter = [];
        this.fetchOrders();
    }

    resetSearch() {
        this.searchResults = null;
        this.searchOrderDetailsResults = null;
        this.searchparams.pageno = 1;
        this.pageno = this.searchparams.pageno;
        this.quickFilters = this.quickFilters.map((filter) => { filter.checked = false; return filter });
        this.searchparams.filter = [];
        setTimeout(() => {
            this.searchparams.ordersource = this.cscApprovalConstants.source;
            this.searchparams.orderstatus = this.cscApprovalConstants.status;
        })
    }

    //quick filter
    filterChanged(quickFilters) {
        this.quickFilters = quickFilters;
        this.searchparams.pageno = 1;
        this.pageno = this.searchparams.pageno;
        this.searchparams.filter = quickFilters
            .filter((filterItem) => { return filterItem.checked })
            .map((filteredItem) => { return filteredItem.key });
        this.fetchOrders();
    }

    searchOnPaginationchange(e) {
        this.fetchOrders();
    }

    // details view    
    closeDetailView(e) {
        this.isSearchAndDetailToggle = true;
        if (e) {
            this.fetchOrders();
        }
    }

    showDetailsView(e) {
        this.detailViewData = e;
        this.isSearchAndDetailToggle = false;
    }

    numOfSelectedItem() {
        return (this.searchOrderDetailsResults.filter((obj) => { return obj.isSelected })).length;
    }

    getSelectedOrderIds() {
        return this.searchOrderDetailsResults
            .filter((obj) => { return obj.isSelected })
            .map((obj) => { return obj.order_id });
    }

    generateBatch() {
        let postParams = {
            orders: this.getSelectedOrderIds()
        }
        this.sharedAppService.httpPostService(`/api/inventory/generateBatch`, postParams)
            .then(
            // Success
            data => {
                console.log(data);
                this.userModal.openModal({ status: 'success', title: 'Batch Generated', message: 'Successfully Batch has been generated for selected orders.' });
                this.fetchOrders();
            },
            // Error
            err => {
                console.log(err);
                this.userModal.openModal({ status: 'error', title: 'Action Failed', message: `Batch generation failed of orders failed. Due to ${err.message}` });
            }
            )
    }

    previewSummaryClickHanlder(){
        let postParams = {
            orders : this.getSelectedOrderIds()
        }
        this.sharedAppService.httpPostService(`/api/inventory/getPreviewSummary`,postParams)
        .then(
            // Success
            (result: PreviewSummaryDetails[]) => {
                let dialogRef = this.dialog.open(DialogPreviewSummary, {
                    width: '800px',
                    data: result
                });
            }, //close the detail view
            // Error
            err => { console.log(err) }
            );
    }

    callSort(e) {
        this.searchparams = e;
        this.searchparams.pageno = 1;
        this.pageno = this.searchparams.pageno;
        this.fetchOrders();
    }
}


@Component({
    selector: 'dialog-Preview-summary',
    template: `<div md-dialog-content>
    <div popup-title class="row popup-Header-container">
        <div class="col-md-11 print-ticket-header">
            <h1>Preview Summary</h1>
            <button (click)="downloadPreviewSummary(data)">Export to CSV</button>
        </div>
        <div class="col-md-1 back-to-page clearfix">
            <button class="pull-right back-to-page-button" (click)="closePreviewSummaryPopup()"><img src="assets/images/close-button.png" alt="close button"></button>
        </div>
    </div>
    <div popup-content class="row popup-list-container">
        <ngx-datatable class="material" [rows]="data" [columnMode]="'flex'" [columns]="previewSummaryGridColumns" [headerHeight]="50" [scrollbarV]="true">
        </ngx-datatable>
    </div>
</div>`,
    styles: ['.back-to-page-button img { width: 41px;  height: 41px;}.popup-Header-container {  padding: 10px 15px 10px 20px;} .popup-list-container { padding: 10px 20px 10px 20px;} .ngx-datatable { height: 400px;} :host /deep/ .ngx-datatable .datatable-row-wrapper:nth-child(even) { background: #efefef;}']
})
export class DialogPreviewSummary {

    constructor(
        public dialogRef: MatDialogRef<DialogPreviewSummary>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    previewSummaryGridColumns: any[] = [
        { name: 'SKU', prop: 'sku', flexGrow: 3 },
        { name: 'Description Form', prop: 'sku_desc', flexGrow: 8 },
        { name: 'Qty', prop: 'quantity', flexGrow: 2 },
        { name: 'Allocated', prop: 'quantity_allocated', flexGrow: 3 }
    ];

    closePreviewSummaryPopup(): void {
        this.dialogRef.close();
    }

    downloadPreviewSummary(previewData): void {
        let today = new Date();
        var summary = 'SKU,Description Form,Qty,Allocated\n';
        //window.alert('hello'+data[0].sku_desc);
        previewData.forEach(function (row) {
           // summary += row.sku + ',' + row.sku_desc + ',' + row.quantity + ',' + row.quantity_allocated;
            summary += row.sku + ',' + row.sku_desc.replace(/[^a-zA-Z\s]/gi, '')  + ',' + row.quantity + ',' + row.quantity_allocated;
            summary += "\n";
        });

        // window.alert('hello'+csv);
        console.log(summary);
        var csvElement = document.createElement('a');
        csvElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(summary);
        csvElement.target = '_blank';
        csvElement.download = today + '.csv';
        csvElement.click();
    }
}