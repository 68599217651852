import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchSharedModule } from '../../shared/module/searchshared.module';
import { LabelPrintPageComponent } from './labelPrint.component';
import { OnHoldDialogComponent } from './on-hold-dialog/on-hold-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@NgModule({
    imports: [
        CommonModule,
        SearchSharedModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule
    ],
    declarations: [
        LabelPrintPageComponent,
        OnHoldDialogComponent,
    ],
    exports: [LabelPrintPageComponent]
})

export class LabelPrintModule { }