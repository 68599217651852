export class SearchQuery {
    //--- order details
    orderid: string;
    sourceorderid: string;
    quantity: string;    
    ordersource: string;
    sku: string;
    orderstatus: string;
    ordersize: string;
    skudesc: string[];
    fromdate: string;
    todate: string;

    //--- product and shipping details
    fragrance: string;
    shippingmethod: string;
    shiptostate: string;
    productform: string;
    destination:string;
    age:string;

    //--- customer details
    custfname: string;
    custlname: string;
    custnumber: string;
    custemail: string;
    custaddressline1: string;
    custaddressline2: string;
    custaddressline3: string;
    custtown: string;
    custpostalcode: string;
    custcounty: string;

    //--- Quick filter
    filter: string[];
    
    //--- page info 
    pageno: number;
    pagesize: number;

    //--- sorting    
    sortby: string;
    sortorder: string;
}