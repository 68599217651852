import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ToastrService } from '../../../common/toastr.service';
import { UserService } from '../../../user/user.service';
import { AuthService } from '../../../user/auth.service';
import { IUser } from '../../../user/user';
import { IGroup } from '../../../user/group';
// import { FindGroupComponent } from '../find-group/find-group.component';
import { Observable } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
// import 'rxjs/add/operator/do';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/observable/throw';

@Component({
  selector: 'app-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.css']
})
export class EditGroupComponent implements OnInit {

  @Input() groupid: string;

  @Output() cancelGroupClicked = new EventEmitter();
  @Output() updateGroupsubmit = new EventEmitter();

  ngOnChanges(change) {
    this.getGroupEdit();
  }

  profileForm: FormGroup
  userObj: any;
  group: IGroup;
  dataAccess: any;
  dataGroup: any;
  accesses: any;


  constructor(private fb: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private toastr: ToastrService,
  ) {
  }

  group_name = new FormControl('', [Validators.required]);
  group_description = new FormControl('', [Validators.required]);
  access_list = new FormGroup({});

  searchViewToggle: boolean = true;


  ngOnInit(): void {
    window.scrollTo(0, 0);

    let tmp = this.fb.group({
    })

    this.userService.getAccesses().subscribe(dataAccess => {
      if (dataAccess["success"] === false) {
        if (dataAccess["errcode"]) {
          this.router.navigate(['/usermanagement']);
        }
        console.log("a;sldjf;alsdkfjoutter")
        // this.toastr.error(data.message);
      } else {
        this.accesses = dataAccess["data"];
        // console.log("after setting this.accesses---------" + this.accesses)
        this.accesses.forEach(access => {
          // console.log(access)
          tmp.addControl(access.name, new FormControl(false));
        });
      }
    });

    this.userObj = this.authService.currentUser;

    this.profileForm = this.fb.group({
      group_name: this.group_name,
      group_description: this.group_description,
      access_list: tmp
    });

  }


  populateGroup(): void {
    this.group = this.userService.transmitData2(this.dataGroup);
  }

  populateForm(): void {  //dataGroup
    var tmp = this.fb.group({});
    this.userService.getAccesses().subscribe(dataAccess => {
      if (dataAccess["success"] === false) {
        if (dataAccess["errcode"]) {
          // this.router.navigate(['/usermanagement']);
        }
        // this.toastr.error(data.message);
      } else {
        this.accesses = dataAccess["data"];
        this.accesses.forEach(access => {
          // console.log(access)
          tmp.addControl(access.name, new FormControl(false));
        });
        for (let av of this.group[0].access_list) {
          tmp.controls[av].setValue(true);
        };
        this.profileForm = this.fb.group({
          group_name: this.group[0].group_name,
          group_description: this.group[0].group_description,
          access_list: tmp  //try tyings on ng oninit
        });
        // console.log(tmp.controls)
      }
    });
  }

  updateGroup(groupid): void {
    let accessArray = [];

    for (var i = 0; i < this.accesses.length; i++) {
      let accessess = [<HTMLInputElement>document.getElementById("checkbox" + i)]
      if (accessess[0].checked) {
        accessArray.push(accessess[0].value)
        // console.log(accessArray)

      }
    }

    const theForm = this.profileForm.value;
    theForm.access_list = accessArray;
    // console.log(theForm)

    this.userService.updateGroup(this.groupid, theForm) //this.userid
      .subscribe(dataGroup => {
        if (dataGroup["success"] === false) {
          if (dataGroup["errcode"]) {
            this.authService.logout();
            this.router.navigate(['groupmanagement']);
            console.log('hello')
          }
          // this.toastr.error(data.message);
        } else {
          this.updateGroupsubmit.emit();
          alert("Group has been updated")
        }
      });
  }

  getGroupEdit(): void {
    this.userService.getGroup(this.groupid).subscribe(dataGroup => {
      if (dataGroup["success"] === false) {
        if (dataGroup["errcode"]) {
          this.router.navigate(['/groupmanagement']);
        }
        // this.toastr.error(data.message);
      } else {
        this.group = dataGroup["data"];
        // console.log("j------------------  " + this.group[0].access_list)
        this.populateForm(); //this.group[0]
      }
    });
  }
  cancelGroupWasClicked() {
    this.cancelGroupClicked.emit();
  }
}
