import { Component, OnInit, Injectable, Inject, Input, Output, EventEmitter } from '@angular/core';
// import { DateAdapter, NativeDateAdapter } from '@angular/material';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { DataService } from "../../shared/service/data.service"
@Component({
    selector: 'configuration',
    templateUrl: './configuration.component.html',
    styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {
    myForm: FormGroup;
    post: any;
    loading: any;
    fromtime: string;
    totime: string;
    constructor(public dialog: MatDialog,
        private fb: FormBuilder,
        private dataservice: DataService) {

    }


    ngOnInit() {
        this.myForm = new FormGroup({
            'FromDate': new FormControl(),
            'ToDate': new FormControl(),
            'shiftname': new FormControl()
        });
        this._onload()
    }
    _onload() {
        this.dataservice.GetPostMethod("configuration/getshifts", "")
            .subscribe(Response => {
                this.rows = Response["message"]
            })
    }
    fromDate: string;
    toDate: string;
    shiftId: any;
    _shiftname: any;
    fromToDateMismatch: boolean;
    updateBtn: boolean = false;
    shiftid: any;
    Clear() {
        this.fromDate = ""
        this.toDate = ""
        this._shiftname = ""
        this.updateBtn = false;
        this.shiftid = "";
    }
    dateSelected(e, field) {
        if (field === "from") {
            this.fromDate = e;
        } else {
            this.toDate = e;
        }
        this.fromToDateMismatch = !this.checkDateValidity(this.fromDate, this.toDate);
    }
    checkDateValidity(from, to): boolean {
        if (from && to && from > to) {
            return false;
        }
        //returning true by default
        return true;
    }
    public SourceOrders: any = [];
    rows = []
    columns = [
        { prop: 'shift_name', name: 'Shift Name' },
        { prop: 'shiftFromTime', name: 'From Time' },
        { prop: 'shiftToTime', name: 'To Time' }
    ];
    public updateData: any;
    onUserEvent(e) {
        //&& e.column.prop == "shift_name"
        if (e.cellIndex == 4 && e.type == "click") {
            console.log(e.row)
            this.deleteshift(e.row)
        }
        else if (e.type == "click") {
            console.log(e.row)
            this.updateData = e.row;
            this.fromDate = moment(e.row.fromtime, "HH:mm a").toString()
            this.toDate = moment(e.row.totime, "HH:mm a").toString()
            // this.fromDate = moment.parseZone(e.row.fromtime).format("YYYY-MM-DDTHH:mm:ss")
            // this.toDate = moment.parseZone(e.row.totime).format("YYYY-MM-DDTHH:mm:ss")
            this._shiftname = e.row.shift_name;
            this.updateBtn = true;
            this.shiftid = e.row._id;

        }
    }
    Update(form) {
        if (this.toDate == null || this.toDate == "" || this.fromDate == null || this.fromDate == "" || form == "") {
            alert("Please fill")
            return false
        }
        // console.log(moment(this.fromDate).format("hh:mm:ss a"))
        // console.log(moment(this.toDate).format("hh:mm:ss a"))
        var frmTime = moment(this.fromDate).format("hh:mm:ss a")
        var toTime = moment(this.toDate).format("hh:mm:ss a")
        var obj = {
            "fromtime": frmTime,
            "totime": toTime,
            "shift_name": form
        }
        if (!this.checkupdate(this.updateData, obj)) {
            alert("No Changes are detected")
            this.Clear()
            return false
        }
        this.dataservice.GetPostMethod("configuration/updateshifts?shift_name=" + form + "&fromtime=" +
            frmTime + "&totime=" + toTime + "&shiftId=" + this.shiftid, "&user=" + localStorage.getItem('Username') + "")
            .subscribe(Response => {
                alert(Response["message"])
                this._onload()
            })
        this.Clear()
    }
    shiftname = new FormControl('', [
        Validators.required
    ]);
    getfromtime = new FormControl('', [
        Validators.required
    ]);
    gettotime = new FormControl('', [
        Validators.required
    ]);
    orderError: string;
    addshift(form) {
        if (!this.fromDate || !this.toDate) {
            //show error
            this.orderError = "All fields are mandatory."
            return false;
        } else {
            //remove error
            this.orderError = "";
        }
        // this.fromDate = moment.parseZone(this.fromDate).format("YYYY-MM-DDTHH:mm:ss")
        // this.toDate = moment.parseZone(this.toDate).format("YYYY-MM-DDTHH:mm:ss")
        var frmTime = moment(this.fromDate).format("hh:mm:ss a")
        var toTime = moment(this.toDate).format("hh:mm:ss a")
        var pamrams = {
            "shift_name": form,
            "fromtime": frmTime,
            "totime": toTime
        }
        //return false

        this.dataservice.GetPostMethod("configuration?shift_name=" + form + "&fromtime=" +
            frmTime + "&totime=" + toTime, "&user=" + localStorage.getItem('Username') + "")
            .subscribe(Response => {
                if (Response["success"] == "true") {
                    alert(Response["message"])
                    this.fromDate = ''
                    this.toDate = ''
                }
                else {
                    alert(Response["message"])
                }
            })


        this.dataservice.GetPostMethod("configuration/getshifts", "")
            .subscribe(Response => {
                this.rows = Response["message"]
            })
    }


    deleteshift(row) {
        console.log(row)
        var _id = row._id;
        this.dataservice.GetPostMethod("configuration/deleteshift?shiftid=" + _id + "&user=" + localStorage.getItem('Username') + "", "")
            .subscribe(Response => {
                const newData = Response["data"];
                alert(newData.n + " Recorded is deleted")
                this._onload()
            })
    }
    checkupdate(oldArr, newArr) {
        if (oldArr["fromtime"] != newArr["fromtime"]) {
            return true
        }
        else if (oldArr["totime"] != newArr["totime"]) {
            return true
        }
        else if (oldArr["shift_name"] != newArr["shift_name"]) {
            return true
        }
        else {
            return false
        }
    }
}