import { Injectable } from '@angular/core';
// import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
// import 'rxjs/add/operator/do';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/observable/throw';

@Injectable()
export class DatalistService {

  public jwtToken: string; // unused

  constructor(private http: HttpClient) {
    const theUser: any = JSON.parse(localStorage.getItem('currentUser'));
    if (theUser) {
      this.jwtToken = theUser.token;
    }
  }

  private handleError(error: Response) {
    console.error(error);
    return throwError(error["error"] || 'Server error');
  }
  getPostalCode() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }

    return this.http.get(`/api/utility/getPostalCode`, options)
      .pipe(
      map((response: HttpResponse<any>) => response),
      catchError(this.handleError));;
  }
  getNetworkCode() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }

    return this.http.get(`/api/utility/getNetworkCode`, options)
      .pipe(
      map((response: HttpResponse<any>) => response),
      catchError(this.handleError));;
  }
  getNextPostalCode() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }

    return this.http.get(`/api/utility/getNextPostalCode`, options)
      .pipe(
      map((response: HttpResponse<any>) => response),
      catchError(this.handleError));;

  }
  getPRArtDimension() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }
    return this.http.get(`/api/utility/getPRArtDimension`, options)
      .pipe(
      map((response: HttpResponse<any>) => response),
      catchError(this.handleError));;

  }
  addpostalCode(opostalCode) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }
    console.log(opostalCode, "postal code in add")
    return this.http.post(`/api/utility/addpostalCode`, JSON.stringify(opostalCode), options)
      .pipe(
      map((response: HttpResponse<any>) => response),
      catchError(this.handleError));;

  }
  addnetworkCode(opostalCode) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }
    console.log(opostalCode, "postal code in add")
    return this.http.post(`/api/utility/addnetworkCode`, JSON.stringify(opostalCode), options)
      .pipe(
      map((response: HttpResponse<any>) => response),
      catchError(this.handleError));;

  }
  addNextPostalCode(opostalCode) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `${this.jwtToken}`);
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }
    console.log(opostalCode, "postal code in add")
    return this.http.post(`/api/utility/addNextPostalCode`, JSON.stringify(opostalCode), options)
      .pipe(
      map((response: HttpResponse<any>) => response),
      catchError(this.handleError));;

  }

  removepostalCode(postalCode) {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    const options = {
      headers: new HttpHeaders().append('key', 'value')
    }

    return this.http.post(`/api/utility/removepostalCode`, JSON.stringify({ "postalCode": postalCode }), options)
      .pipe(
      map((response: HttpResponse<any>) => response),
      catchError(this.handleError));
  }
  removeNextPostalCode(postalCode) {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    const options = {
      headers: new HttpHeaders().append('key', 'value')
    }

    return this.http.post(`/api/utility/removeNextPostalCode`, JSON.stringify({ "postalCode": postalCode }), options)
      .pipe(
      map((response: HttpResponse<any>) => response),
      catchError(this.handleError));
  }
  removeArtCode(code, art_code) {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    const options = {
      headers: new HttpHeaders().append('key', 'value')
    }

    return this.http.post(`/api/removeArtCode`, JSON.stringify({ "code": code, "art_code": art_code }), options)
      .pipe(
      map((response: HttpResponse<any>) => response),
      catchError(this.handleError));
  }
  updatepostalCode(postalCode, newpostalCode) {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', `${this.jwtToken}`);
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }
    console.log(postalCode)
    console.log(postalCode)
    return this.http.post(`/api/utility/updatepostalCode`, JSON.stringify({ "postalCode": postalCode, "newpostalCode": newpostalCode }), options)
      .pipe(
      map((response: HttpResponse<any>) => response),
      catchError(this.handleError));
  }

  updateNextPostalCode(postalCode, newpostalCode) {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', `${this.jwtToken}`);
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }
    console.log(postalCode)
    console.log(postalCode)
    return this.http.post(`/api/utility/updateNextPostalCode`, JSON.stringify({ "postalCode": postalCode, "newpostalCode": newpostalCode }), options)
      .pipe(
      map((response: HttpResponse<any>) => response),
      catchError(this.handleError));
  }
  updateNetworkCode(id, shippingType, serviceType, editField) {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', `${this.jwtToken}`);
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }
    console.log(id, shippingType, serviceType, editField)
    return this.http.post(`/api/utility/updateNetworkCode`, JSON.stringify({ "id": id, "shippingType": shippingType, "serviceType": serviceType, "editField": editField }), options)
      .pipe(
      map((response: HttpResponse<any>) => response),
      catchError(this.handleError));
  }
  updatePRArtDimension(code, art_code, formdata) {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', `${this.jwtToken}`);
    const options = {
      headers: new HttpHeaders().append('Authorization', `${this.jwtToken}`)
    }

    return this.http.post(`/api/utility/updatePRArtDimension`, JSON.stringify({ "code": code, "art_code": art_code, "newcode": formdata }), options)
      .pipe(
      map((response: HttpResponse<any>) => response),
      catchError(this.handleError));
  }
}
