<div class="user-search-container">
  <div class="row">
    <h2 class="title">
      Add Postal Code
      <button class="pull-right search-view-toggle" [class.searchCollapsed]="!searchViewToggle"
        (click)="searchViewToggle = !searchViewToggle;"></button>
    </h2>
  </div>

  <div class="row search-fields-container" *ngIf="searchViewToggle">
    <form [formGroup]="registerForm" (ngSubmit)="addButton(registerForm.value)" novalidate autocomplete="off">
      <div class="sub-search-container row">
        <h3>Postal Code Details</h3>
        <div class="sub-search-wrapper">

          <div class="col-md-4">
            <label for="add_postalCode">Add Postal Code</label>
            <input type="text" class="user-input-text" [formControl]="add_postalCode" placeholder="Postal Code">
          </div>

          <br>

          <div class="col-md-10 text-center">
            <!-- <button type="submit" [disabled]="registerForm.invalid" class="ycc-button button-search">Submit</button> -->
            <button type="submit" [disabled]="registerForm.invalid" class="ycc-button button-search"
              (click)="onTwoClick()">Two Day Delivery</button>
            <button type="submit" [disabled]="registerForm.invalid" class="ycc-button button-search"
              (click)="onNextClick()">Next Day Delivery</button>
            <button type="reset" class="ycc-button button-clear">Reset</button>
          </div>

        </div>
      </div>
    </form>
    <div>
      <div class=" sub-search-wrapper col-md-11">
        <h3>Postal Code</h3>
      </div>

      <table class="postTable col-md-12" id="myTable">
        <thead>
          <tr>
            <th class="postTableCss">Two Day Delivery Codes</th>
            <th class="postTableCss">Next Day Delivery Codes</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let postalCode of newArray" id='searchContainer'>
            <td class="a">
              <form [formGroup]="editForm" (ngSubmit)="updateButton(postalCode.postalCodes, editForm.value, $event)"
                novalidate autocomplete="off">
                <span [hidden]="postalCode.postalCodeEdit"
                  (dblclick)="doubleClickpostalCode(postalCode)">{{ postalCode.postalCodes }}</span>
                <input ID="formInput" [hidden]="!postalCode.postalCodeEdit" [formControl]="newpostalCode" autofocus />
                <input [hidden]="!postalCode.postalCodeEdit" id="submit-1" (click)="doneEditpostalCode(postalCode)"
                  type="submit" value="Edit">
                <input [hidden]="!postalCode.postalCodeEdit" id="submit-2" (click)="doneRemovepostalCode(postalCode)"
                  type="submit" value="Remove">
                <button type="button" [hidden]="!postalCode.postalCodeEdit"
                  (click)="doneEditpostalCode(postalCode)">Cancel</button>
              </form>
            </td>
            <!-- <br> -->
            <td class="b">
              <span [hidden]="postalCode.NextPostalCodeEdit"
                (dblclick)="doubleClickNextPostalCode(postalCode)">{{ postalCode.nextPostalCodes }}</span>
              <form [formGroup]="editFormNextpostalCode"
                (ngSubmit)="updateButtonNextPostalCode(postalCode.nextPostalCodes, editFormNextpostalCode.value)"
                novalidate autocomplete="off">
                <input ID="formInputNextPostalCode" [hidden]="!postalCode.NextPostalCodeEdit"
                  [formControl]="newNextPostalCode" autofocus />
                <input [hidden]="!postalCode.NextPostalCodeEdit" (click)="doneEditNextPostalCode(postalCode)"
                  type="submit" value="Edit">
                <input [hidden]="!postalCode.NextPostalCodeEdit" id="submit-2"
                  (click)="doneRemoveNextpostalCode(postalCode)" type="submit" value="Remove">
                <button type="button" [hidden]="!postalCode.NextPostalCodeEdit"
                  (click)="doneEditNextPostalCode(postalCode)">Cancel</button>
              </form>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  <div class="row">
    <h2 class="title">
      Edit Network Code
      <button class="pull-right search-view-toggle" [class.searchCollapsed]="!searchViewToggle2"
        (click)="searchViewToggle2 = !searchViewToggle2;"></button>
    </h2>
  </div>
  <div class="row search-fields-container" *ngIf="searchViewToggle2">
    <table border=1>
      <thead>
        <tr>
          <th class="postTableCss">Shipping Types</th>
          <th class="postTableCss">Service Types</th>
          <th class="postTableCss">Network Code</th>
        </tr>
      </thead>
      <ng-container *ngFor="let groupRowData of datalistArray;">
        <ng-container *ngFor="let requestDetailData of groupRowData.serviceTypes; let $index = index">
          <tr>
            <td *ngIf="$index===0;" [attr.rowspan]="groupRowData.serviceTypes.length">{{ groupRowData.GroupId }}</td>
            <td>{{requestDetailData.Name}}</td>
            <td>
              <span (keyup)="changeValue($index, groupRowData.GroupId,requestDetailData.Name,'name', $event)"
                (blur)="updateList($index,groupRowData.GroupId,requestDetailData.Name, 'name', $event)"
                contenteditable="true">{{requestDetailData.Id}}</span>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </table>
  </div>
  <div class="row">
    <h2 class="title">
      Edit Art Board Dimensions
      <button class="pull-right search-view-toggle" [class.searchCollapsed]="!searchViewToggle3"
        (click)="searchViewToggle3 = !searchViewToggle3;"></button>
    </h2>
  </div>
  <div class="row search-fields-container" *ngIf="searchViewToggle3">    
    <div>
      <div class=" sub-search-wrapper col-md-11">
        <h3>Art Board Image Dimensions</h3>
        <p class="note">Please Note:</p>
  <p> a[0] - Large_jar<br />
    a[1] - Small_jar<br /></p>
      </div>
      <table class="postTable col-md-12" id="myTable">
        <thead>
          <tr>
            <th class="postTableCss">Dimension Name</th>
            <th class="postTableCss">Code</th>           
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of newArtArray" id='searchContainer'>
            <td>{{ item.art_code }}</td>
            <td>
              <span [hidden]="item.art_code_edit" (dblclick)="doubleClickArtcode(item)">{{ item.code }}</span>
              <form [formGroup]="editArtForm" (ngSubmit)="updateArtDimensionButton(item.code, item.art_code, editArtForm.value)" novalidate
                autocomplete="off">
                <input ID="formInput" [hidden]="!item.art_code_edit" [formControl]="newArtcode" autofocus />
                <input [hidden]="!item.art_code_edit" (click)="doneEditArtcode(item)" type="submit" value="Edit">
                <button type="button" [hidden]="!item.art_code_edit" (click)="doneEditArtcode(item)">Cancel</button>
              </form>
            </td>      
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>