<div class="user-search-container container">
  <div class="row">
    <h2 class="title">
          Edit User
          <button class="pull-right search-view-toggle" [class.searchCollapsed]="!searchViewToggle" (click)="searchViewToggle = !searchViewToggle;"></button>
        </h2>
  </div>

  <div class="row search-fields-container" *ngIf="searchViewToggle">
    <form [formGroup]="profileForm" (ngSubmit)="updateUser(profileForm.value)" novalidate autocomplete="off">
      <div class="sub-search-container row">
        <h3>User Details</h3>
        <div class="sub-search-wrapper">

          <div class="col-md-4" [ngClass]="{'has-error': profileForm.get('firstname').invalid && profileForm.get('firstname').dirty}">
            <label for="fname">
              <span>First Name</span>
            </label>
            <input type="text" class="user-input-text" [formControl]="firstname" id="fname" placeholder="First Name">
            <div class="text-danger" *ngIf="profileForm.get('firstname').dirty && profileForm.get('firstname').invalid">
              <span class="col-sm-10" style="padding:4px 0 0" *ngIf="profileForm.get('firstname').errors">
                    Please enter your first name
                </span>
            </div>
          </div>


          <div class="col-md-4" [ngClass]="{'has-error': profileForm.get('lastname').invalid && profileForm.get('lastname').dirty}">
            <label for="lname">Last Name</label>
            <input type="text" class="user-input-text" [formControl]="lastname" id="lname" placeholder="Last Name">
            <div class="text-danger" *ngIf="profileForm.get('lastname').dirty && profileForm.get('lastname').invalid">
              <span class="col-sm-10" style="padding:4px 0 0" *ngIf="profileForm.get('lastname').errors">
                    Please enter your last name
                </span>
            </div>
          </div>


          <div class="col-md-4" [ngClass]="{'has-error': profileForm.get('email').invalid && profileForm.get('email').dirty}">
            <label for="email">Email</label>
            <input type="text" class="user-input-text" [formControl]="email" id="email" placeholder="Email">
            <div class="text-danger" *ngIf="profileForm.get('email').dirty && profileForm.get('email').invalid">
              <span class="col-sm-10" style="padding:4px 0 0" *ngIf="profileForm.get('email').invalid">
                    Please enter a valid email
                </span>
            </div>
          </div>

          <div class="col-md-4" [ngClass]="{'has-error': profileForm.get('username').invalid && profileForm.get('username').dirty}">
            <label for="username">Username </label>
            <input type="text" class="user-input-text" [formControl]="username" id="username" placeholder="Username">
            <div class="text-danger" *ngIf="profileForm.get('username').dirty && profileForm.get('username').invalid">
              <span class="col-sm-10" style="padding:4px 0 0" *ngIf="profileForm.get('username').invalid">
                    Please enter username
                </span>
              <span class="col-sm-10" style="padding:4px 0 0" *ngIf="profileForm.get('username').invalid">
                    Username must be longer than 3 characters.
                </span>
              <span class="col-sm-10" style="padding:4px 0 0" *ngIf="profileForm.get('username').invalid">
                    Username cannot be longer than 16 characters.
                </span>
            </div>
          </div>

          <div class="col-md-4">
            <label for="roles">Roles</label>
            <select class="user-input-select"  name="roles" [formControl]="roles"  id="group_name" (onselect)="passsword($event, roles_list)" id="myOptions">
              <option value="" disabled>Select one--</option>
                  <option *ngFor="let roles_list of roles_lists; let i = index;" id="roles{{i}}" >{{roles_list}}</option>
              </select>
          </div>

          <div class="col-md-4" hidden>
            <label for="password_minimum_age">password_minimum_age</label>
            <select class="user-input-select"  name="password_minimum_age" [formControl]="password_minimum_age"  id="group_name" (onselect)="passsword($event, roles_list)" id="myOptions">
              <option value="1">1 Day</option>
              </select>
          </div>

          <div class="col-md-4" hidden>
            <label for="password_maximum_age">password_maximum_age</label>
            <select class="user-input-select"  name="password_maximum_age" [formControl]="password_maximum_age"  id="group_name" (onselect)="passsword($event, roles_list)" id="myOptions">
              <option value="30">30 Days</option>
              <option value="90">90 Days</option>
              <option value="365">365 Days</option>
              </select>
          </div>


          <div class="col-md-4">
            <label for="group">Group</label>
            <select class="user-input-select"  name="group_name" [formControl]="group_name" [(ngModel)]="term5" id="group_name">
                  <option *ngFor="let group of groups" [value]="group.group_name">{{group.group_name}}</option>
              </select>
          </div>

<br>
<br><br><br><br>

  <div class="text-center">
    <button type="submit" [disabled]="profileForm.invalid" class="ycc-button button-search">Update</button>
    <button (click)="cancelWasClicked()" class="ycc-button button-clear">Cancel</button>
  </div>

        </div>
      </div>


    </form>
  </div>
</div>
