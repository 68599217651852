<div class="col-md-12">
  <div class="row" style="margin-top:70px; padding-bottom:10px;">
    <div class="col-md-12">
      <div class="container">
        <h2 class="title">Shift Config</h2>
        <div class="col-md-12">
          <form [formGroup]="myForm" (ngSubmit)="addshift(shiftname.value)">
            <div class="col-md-8">
              <div class="col-md-6 formgroup">
                <div class="date-picker-container">
                  <div class="formgroup">
                    <label>Set Time From: </label>
                    <div class="search-date-picker">
                      <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeHolder="'HH:mm:ss'"
                        [formControl]="getfromtime" (ngModelChange)="dateSelected($event, 'from')" name="fromtime"
                        [ngModel]="fromDate">
                     <owl-date-time [pickerType]="'timer'" #dt1></owl-date-time>
                      <!-- <owl-date-time [placeHolder]="'HH:mm:ss'" [autoClose]="true" 
                      [dateFormat]="'HH:mm:ss'" [type]="'timer'"
                       [formControl]="getfromtime"
                        (ngModelChange)="dateSelected($event, 'from')"
                         name="fromtime"
                         [ngModel]="fromDate"
                         >
                      </owl-date-time> -->
                    </div>
                  </div>
                </div>
               
              </div>
              <div class="col-md-6 formgroup">
                <div class="date-picker-container">
                  <div class="formgroup">
                    <label>Set Time To: </label>
                    <div class="search-date-picker">
                      <input [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" placeHolder="'HH:mm:ss'"
                       [formControl]="gettotime" (ngModelChange)="dateSelected($event, 'to')" name="toDate"
                       [ngModel]="toDate">
                      <owl-date-time [pickerType]="'timer'" #dt2></owl-date-time>
                      <!-- <owl-date-time [max]="maxDate" [type]="'timer'" 
                      [placeHolder]="'HH:mm:ss'" [autoClose]="true"
                       [dateFormat]="'HH:mm:ss'" 
                       [formControl]="gettotime"
                        (ngModelChange)="dateSelected($event, 'to')"
                         name="toDate" 
                        [ngModel]="toDate">
                      </owl-date-time> -->
                    </div>
                  </div>
                </div>
               
                <span class="date-error" *ngIf="orderError">'From Time' should be earlier than 'To Time'</span>
                <span class="date-error" *ngIf="fromToDateMismatch">'From Time' should be earlier than 'To Time'</span>
                <!-- <div class="alert" *ngIf="shiftform.controls['totime'].invalid && shiftform.controls['totime'].touched">Please Enter To time</div> -->
              </div>
              <div class="col-md-6">
                <div class="formgroup">
                  <label>Shift Name</label>
                   <input type="text" class="formcontrol" name="shiftname" id="shiftname" [formControl]="shiftname"
                  [ngModel]="_shiftname" />
                </div>
                
              </div>
              <div class="col-md-6 btnwrapper text-center">
                <input type="button" *ngIf="!updateBtn"  value="Reset" (click)="Clear()" class="reset btn btn-primary" />
                <input type="submit" *ngIf="!updateBtn" value="Submit" class="btn btn-defult"
                 [disabled]="getfromtime.invalid||gettotime.invalid||shiftname.invalid"/>                
                 <input type="button" *ngIf="updateBtn" value="Cancel" (click)="Clear()" 
                 class="reset btn btn-primary" />
                 <input type="button" value="Update"  class="btn btn-defult" (click)="Update(shiftname.value)"
                 *ngIf="updateBtn">
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-8 col-md-offset-2">
          <div class="tableData">
            
            <ngx-datatable (activate)='onUserEvent($event)'
             class="material" [rows]="rows" [columnMode]="'flex'" [columns]="columns"
              [headerHeight]="50" [selectionType]="'button'" [loadingIndicator]="loading" [rowHeight]="50" [scrollbarV]="true">

              <ngx-datatable-column [flexGrow]="14" prop="shift_name" name="Shift Name"></ngx-datatable-column>
              <ngx-datatable-column  [flexGrow]="10" prop="shiftFromTime" name="From Time"></ngx-datatable-column>
              <ngx-datatable-column  [flexGrow]="10" prop="shiftToTime" name="To Time"></ngx-datatable-column>
              <ngx-datatable-column [flexGrow]="10" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false">
                <ng-template ngx-datatable-header-template let-value="shift_name" let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                  <label>Edit</label>
                </ng-template>
                <ng-template prop="editshift" ngx-datatable-cell-template let-value="shift_name" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                  <!-- <input type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)"/> -->
                  
                   <input type="button" value="Edit" />
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [flexGrow]="1" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false">
                <ng-template ngx-datatable-header-template let-value="shift_name" let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                  <label>Delete</label>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="shift_name" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                  <input type="button" value="Delete"/>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
