import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { SharedApplicationService } from '../../shared/service/shared.service';

import { SearchQuery } from '../../shared/model/searchQuery.model';
import { SearchResults } from '../../shared/model/searchResults.model';
import { OrderDetails } from "../../shared/model/orderDetails.model";
import { SearchDataList } from '../../shared/model/searchDataList.model';

import { UserInfoModalService } from '../../shared/util/modal/user-info.modal';

@Component({
    selector: 'csc-label-approval',
    templateUrl: './cscLabelApproval.component.html',
    styleUrls: ['./cscLabelApproval.component.css']
})
export class CSCLabelApprovalPageComponent implements OnInit {

    constructor(private userModal: UserInfoModalService,
        private sharedAppService: SharedApplicationService) { }

    ngOnInit() {
        this.searchparams.pageno = 1; //default page number        
        this.searchparams.pagesize = 50;
        this.searchparams.ordersource = this.cscApprovalConstants.source;
        this.searchparams.orderstatus = this.cscApprovalConstants.status;
        this.searchparams.sortby = this.cscApprovalConstants.sortby;
        this.searchparams.sortorder = this.cscApprovalConstants.sortorder;
        let url = `/api/searchDataList`;
        this.sharedAppService.httpGetService(url)
            .then(
                (data: SearchDataList) => {
                    this.dropDownDataList = data;
                    let tempCountryList = [];
                    for (let key in data.datalist.country[0]) {
                        tempCountryList.push({ id: key, name: data.datalist.country[0][key] });
                    }
                    this.dropDownDataList.datalist.new_country = tempCountryList;
                    this.dropDownDataList.datalist.new_sku = data.datalist.sku.map((str) => {
                        return { id: str, name: str }
                    })
                    this.dropDownDataList.datalist.new_sku_description = data.datalist.sku_description.map((str) => {
                        return { id: str, name: str }
                    })
                    this.dropDownDataList.datalist.order_status = ["Pending"]

                },
                (err) => console.log(err)
            )
    }

    // order search
    isLoading: boolean = true;
    searchparams = new SearchQuery();
    searchResults: SearchResults;
    searchOrderDetailsResults: OrderDetails[];
    dropDownDataList: SearchDataList;
    isSearchAndDetailToggle: boolean = true;
    isListViewToggle: boolean = false;
    detailViewData: OrderDetails;
    selectedOrders: number;
    pageno: number = 1;
    ukGibraltarOrderInfo = [];

    cscApprovalConstants = {
        source: "UK E-COM",
        status: "Pending",
        sortby: "order_date",
        sortorder: "desc"
    }

    quickFilters = [
        {
            dispName: "Today",
            key: "Today",
            checked: false
        },
        {
            dispName: "Single Orders",
            key: "Single",
            checked: false
        },
        {
            dispName: "Multi Item",
            key: "MultiItem",
            checked: false
        },
        {
            dispName: "Multi Quantity",
            key: "MultiQty",
            checked: false
        },
        {
            dispName: "All",
            key: "Pending",
            checked: false
        },
        {
            dispName: "Priority Delivery",
            key: "Priority",
            checked: false
        },
        {
            dispName: "72 Hrs +",
            key: "72Hrs",
            checked: false
        }
    ];

    fetchOrders() {
        let params = {};
        this.isLoading = true;
        this.searchparams.pageno = this.searchparams.pageno || 1;

        for (let key of Object.keys(this.searchparams)) {
            if (key == "pagesize" || ((this.searchparams[key] instanceof Array) && this.searchparams[key].length) ||
                (!(this.searchparams[key] instanceof Array) && this.searchparams[key])) {
                params[key] = this.searchparams[key];
            }
        }
        // get the order details on search
        this.sharedAppService.httpGetService('/api/orders', params)
            .then(
                // Success
                (data: SearchResults) => {
                    data.orders.map((value) => {
                        value.isSelected = true;
                        //value.order_date = moment(value.order_date).format("MM/DD/YYYY HH:mm");
                        return value;
                    });
                    this.searchResults = data;
                    this.searchOrderDetailsResults = data.orders;
                    this.isLoading = false;
                    if (!this.searchOrderDetailsResults.length) {
                        this.isLoading = true;
                        this.searchparams.pageno = undefined;
                    }
                    this.sharedAppService.getUKGibraltarOrderFromList(data.orders).then((paramUKGibraltarOrderInfo) => {
                        this.ukGibraltarOrderInfo = paramUKGibraltarOrderInfo;
                    });
                },
                // Error
                err => {
                    console.log(err);
                    this.isLoading = true;
                }
            );
    }

    searchOrders() {
        this.searchparams.pageno = 1;
        this.pageno = this.searchparams.pageno;
        this.quickFilters = this.quickFilters.map((filter) => { filter.checked = false; return filter });
        this.searchparams.filter = [];
        this.fetchOrders();
    }

    resetSearch() {
        this.searchResults = null;
        this.searchOrderDetailsResults = null;
        this.searchparams.pageno = 1;
        this.pageno = this.searchparams.pageno;
        this.quickFilters = this.quickFilters.map((filter) => { filter.checked = false; return filter });
        this.searchparams.filter = [];
        setTimeout(() => {
            this.searchparams.ordersource = this.cscApprovalConstants.source;
            this.searchparams.orderstatus = this.cscApprovalConstants.status;
        });
        this.ukGibraltarOrderInfo = [];
    }

    //quick filter
    filterChanged(quickFilters) {
        this.quickFilters = quickFilters;
        this.searchparams.pageno = 1;
        this.pageno = this.searchparams.pageno;
        this.searchparams.filter = quickFilters
            .filter((filterItem) => { return filterItem.checked })
            .map((filteredItem) => { return filteredItem.key });
        this.fetchOrders();
    }

    searchOnPaginationchange(e) {
        this.fetchOrders();
    }

    // details view    
    closeDetailView(e) {
        this.isSearchAndDetailToggle = true;
        if (e) {
            this.fetchOrders();
        }
    }

    showDetailsView(e) {
        this.detailViewData = e;
        this.isSearchAndDetailToggle = false;
    }

    numOfSelectedItem() {
        return (this.searchOrderDetailsResults.filter((obj) => { return obj.isSelected })).length;
    }

    approveSelected() {
        let selectedOrderIds = this.searchOrderDetailsResults
            .filter((obj) => { return obj.isSelected })
            .map((obj) => { return obj.order_id });

        let postParams = {
            order_status: {
                order_id: selectedOrderIds,
                order_status: "Approved",
                entry_date_time: new Date(),
                user: localStorage.getItem('Username')
            }
        }
        this.sharedAppService.httpPostService(`/api/orch/updatePersOrds`, postParams)
            .then(
                // Success
                data => {
                    this.userModal.openModal({ status: 'success', title: 'Approved', message: 'Selected orders successfully approved.' });
                    this.fetchOrders();
                },
                // Error
                err => {
                    console.log(err);
                    this.userModal.openModal({ status: 'error', title: 'Action Failed', message: `Approval of orders failed. ${err.message}` });
                }
            )
    }

    callSort(e) {
        this.searchparams = e;
        this.searchparams.pageno = 1;
        this.pageno = this.searchparams.pageno;
        this.fetchOrders();
    }
}