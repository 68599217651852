import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { FilterPipe } from '../../../temp/adimn/filter.pipe';
import { Filter2Pipe } from '../../../temp/adimn/filter2.pipe';
import { Filter3Pipe } from '../../../temp/adimn/filter3.pipe';
import { AuthGuard } from '../../user/auth.guard';
import { AuthService } from '../../user/auth.service';
import { UserService } from '../../user/user.service';
import { UserModule } from '../../user/user/user.module';
import { AddAccessComponent } from '../access-management-component/add-access/add-access.component';
import { EditAccessComponent } from '../access-management-component/edit-access/edit-access.component';
import { FindAccessComponent } from '../access-management-component/find-access/find-access.component';
import { AddGroupComponent } from '../group-management-component/add-group/add-group.component';
import { EditGroupComponent } from '../group-management-component/edit-group/edit-group.component';
import { FindGroupComponent } from '../group-management-component/find-group/find-group.component';
import { EditUserComponent } from '../user-management-components/edit-user/edit-user.component';
import { FindUserComponent } from '../user-management-components/find-user/find-user.component';
import { ListViewComponent } from '../user-management-components/list-view/list-view.component';
import { RegisterComponent } from '../user-management-components/register/register.component';
import { AccessManagementCoreComponent } from './access-management-core/access-management-core.component';
import { GroupManagementCoreComponent } from './group-management-core/group-management-core.component';
import { UserManagementCoreComponent } from './user-management-core/user-management-core.component';
import { UpdateLostPasswordComponent } from '../user-management-components/update-lost-password/update-lost-password.component';

//import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';

// import { ToastrService } from '../../common/toastr.service';
@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    UserModule,
    NgxDatatableModule

  ],
  declarations: [
    FindUserComponent,
    EditUserComponent,
    RegisterComponent,
    UserManagementCoreComponent,
    GroupManagementCoreComponent,
    AccessManagementCoreComponent,
    FilterPipe,
    Filter2Pipe,
    Filter3Pipe,
    FindGroupComponent,
    EditGroupComponent,
    AddGroupComponent,
    ListViewComponent,
    AddAccessComponent,
    EditAccessComponent,
    FindAccessComponent,
    UpdateLostPasswordComponent
  ],

  providers: [
    // ToastrService,
    AuthService,
    AuthGuard,
    UserService,
    FilterPipe, 
    Filter2Pipe, 
    Filter3Pipe
  ],
  exports: [FilterPipe, Filter2Pipe, Filter3Pipe]
})
export class UserManagementCoreModule { }
