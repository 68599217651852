import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-access-management-core',
  templateUrl: './access-management-core.component.html',
  styleUrls: ['./access-management-core.component.css']
})
export class AccessManagementCoreComponent implements OnInit {

  currentAccess = "";

  constructor() { }

  ngOnInit() {
  }

  buttonAccessWasClicked: boolean = true;
  accessTabOpen: boolean = true;
  onAccessEdit: boolean = false;

  editAccessButtonClicked(clicked:boolean) {
    this.onAccessEdit = true;
  }
  cancelAccessButtonClicked(clicked:boolean) {
    this.onAccessEdit = false;
  }

  setAccessButtonClicked(clicked: boolean) {
      this.buttonAccessWasClicked = clicked;
      console.log('hello')
  }
  //
  tabAccessWasOpen(clicked: boolean){
      this.accessTabOpen = clicked;
      console.log('bye')
  }
    editAndy(e){
      this.currentAccess = e;
      console.log(this.currentAccess)
    }

}
