import { Component, Input, OnInit } from '@angular/core';
import { SharedApplicationService } from '../../service/shared.service';
import { languages } from "../../model/language.model";

@Component({
  selector: 'packaging-slip-print-view-component',
  templateUrl: './packaging-slip-print-view.component.html',
  styleUrls: ['./packaging-slip-print-view.component.css']
})
export class PackagingSlipPrintViewComponent {

  @Input() inputDetails: any;
   lan = new languages();

  pickPrintDetails: any;

  timer = 2500;
  countryCode;
  date;
  page;
  Refno;
  orderNo;
  custserv;
  shipmentType;
  billTO;
  deliverTO;
  deliverVIA;
  deliverVIAData;
  QtyOrdered;
  QtyShipped;
  itemShipped;
  itemDescription;
  imageID;
  returnExchange;
  rep1;
  rep2;
  rep3;
  rep4;
  refund;
  ref1;
  returnItems;
  itemNo;
  reasonCode;
  QTY;
  link;
  thank;
  share;
  
  constructor(private sharedService: SharedApplicationService) {
  }

  ngAfterContentInit() {
    this.pickPrintDetails = this.updateImageId(this.inputDetails);
    this.shipmentType = this.pickPrintDetails.radioButton;
    setTimeout(() => {
        window.print();
        this.sharedService.loader.next(false);
    }, this.timer);
}

  updateImageId(pickPrintDetails: any): any {
    if (pickPrintDetails && pickPrintDetails.order_line_items) {
      let imageId: number = 1;
      let lastIndex: number = 0;
      let sku: string = "";
      let skuDesc: string = "";
      /*
    for (var index = 0; index < pickPrintDetails.order_line_items.length; index++) {
      if (index !== 0
        && pickPrintDetails.order_line_items[index - 1].sku !== pickPrintDetails.order_line_items[index].sku) {
        imageId++;
      }
      else
        {
        imageId=1;
        }
      pickPrintDetails.order_line_items[index].imageId = imageId;
    }
    */
      for (var index = 0; index < pickPrintDetails.order_line_items.length; index++) {
        imageId = 1;
        sku = pickPrintDetails.order_line_items[index].sku;
        for (var i = 0; i < index; i++) {
          if (pickPrintDetails.order_line_items[i].sku === sku) {
            imageId++;
          }
        }
        pickPrintDetails.order_line_items[index].imageId = imageId;

        //Getting translated sku description
        var translatedSkuDes = pickPrintDetails.order_line_items[index].translatedSkuDescription;
        console.log("Sku: ",translatedSkuDes);
        if(translatedSkuDes){
          pickPrintDetails.order_line_items[index].sku_desc = translatedSkuDes;
        }
      }
    }

    var despatchLang = pickPrintDetails.order_details.despatch_note_language, 
        shipCountry = pickPrintDetails.order_details.shipTo_cust_country, 
        countryParam;
   
      if(despatchLang && despatchLang.length > 0){
      countryParam = despatchLang;
  } else {
      countryParam = shipCountry;
  }
  console.log("Localisation: ",countryParam);
    if(countryParam == "FR"){
      this.date = this.lan.french.date;
      this.Refno = this.lan.french.Refno;
      this.orderNo = this.lan.french.orderNo;
      this.custserv = this.lan.french.custserv;
      this.billTO = this.lan.french.billTO;
      this.deliverTO = this.lan.french.deliverTO;
      this.deliverVIA = this.lan.french.deliverVIA;
      this.itemNo  = this.lan.french.itemNo;
      this.itemDescription = this.lan.french.itemDescription;
      this.imageID = this.lan.french.imageID;
      this.returnExchange = this.lan.french.returnExchange;
      this.refund = this.lan.french.refund;
      this.returnItems = this.lan.french.returnItems;
      this.reasonCode = this.lan.french.reasonCode;
      this.QTY = this.lan.french.QTY;
      this.deliverVIAData = this.lan.french.deliverVIAData;
      this.rep1 = this.lan.french.rep1;
      this.rep2 = this.lan.french.rep2;
      this.rep3 = this.lan.french.rep3;
      this.rep4 = this.lan.french.rep4;
      this.ref1 = this.lan.french.ref1;
      this.link = this.lan.french.link;
      this.thank = this.lan.french.thank;
      this.share = this.lan.french.share;
      this.QtyOrdered = this.lan.french.QtyOrdered;
      this.QtyShipped = this.lan.french.QtyShipped;
      this.itemShipped = this.lan.french.itemShipped;
      this.page = this.lan.french.page;
    }
    else if(countryParam == "IT"){
      this.date = this.lan.italy.date;
      this.Refno = this.lan.italy.Refno;
      this.orderNo = this.lan.italy.orderNo;
      this.custserv = this.lan.italy.custserv;
      this.billTO = this.lan.italy.billTO;
      this.deliverTO = this.lan.italy.deliverTO;
      this.deliverVIA = this.lan.italy.deliverVIA;
      this.itemNo  = this.lan.italy.itemNo;
      this.itemDescription = this.lan.italy.itemDescription;
      this.imageID = this.lan.italy.imageID;
      this.returnExchange = this.lan.italy.returnExchange;
      this.refund = this.lan.italy.refund;
      this.returnItems = this.lan.italy.returnItems;
      this.reasonCode = this.lan.italy.reasonCode;
      this.QTY = this.lan.italy.QTY;
      this.deliverVIAData = this.lan.italy.deliverVIAData;
      this.rep1 = this.lan.italy.rep1;
      this.rep2 = this.lan.italy.rep2;
      this.rep3 = this.lan.italy.rep3;
      this.rep4 = this.lan.italy.rep4;
      this.ref1 = this.lan.italy.ref1;
      this.link = this.lan.italy.link;
      this.thank = this.lan.italy.thank;
      this.share = this.lan.italy.share;
      this.QtyOrdered = this.lan.italy.QtyOrdered;
      this.QtyShipped = this.lan.italy.QtyShipped;
      this.itemShipped = this.lan.italy.itemShipped;
      this.page = this.lan.italy.page;
    }
    else if(countryParam == "DE"){
      this.date = this.lan.german.date;
      this.Refno = this.lan.german.Refno;
      this.orderNo = this.lan.german.orderNo;
      this.custserv = this.lan.german.custserv;
      this.billTO = this.lan.german.billTO;
      this.deliverTO = this.lan.german.deliverTO;
      this.deliverVIA = this.lan.german.deliverVIA;
      this.itemNo  = this.lan.german.itemNo;
      this.itemDescription = this.lan.german.itemDescription;
      this.imageID = this.lan.german.imageID;
      this.returnExchange = this.lan.german.returnExchange;
      this.refund = this.lan.german.refund;
      this.returnItems = this.lan.german.returnItems;
      this.reasonCode = this.lan.german.reasonCode;
      this.QTY = this.lan.german.QTY;
      this.deliverVIAData = this.lan.german.deliverVIAData;
      this.rep1 = this.lan.german.rep1;
      this.rep2 = this.lan.german.rep2;
      this.rep3 = this.lan.german.rep3;
      this.rep4 = this.lan.german.rep4;
      this.ref1 = this.lan.german.ref1;
      this.link = this.lan.german.link;
      this.thank = this.lan.german.thank;
      this.share = this.lan.german.share;
      this.QtyOrdered = this.lan.german.QtyOrdered;
      this.QtyShipped = this.lan.german.QtyShipped;
      this.itemShipped = this.lan.german.itemShipped;
      this.page = this.lan.german.page;
    }
    else{
      this.date = this.lan.english.date;
      this.Refno = this.lan.english.Refno;
      this.orderNo = this.lan.english.orderNo;
      this.custserv = this.lan.english.custserv;
      this.billTO = this.lan.english.billTO;
      this.deliverTO = this.lan.english.deliverTO;
      this.deliverVIA = this.lan.english.deliverVIA;
      this.itemNo  = this.lan.english.itemNo;
      this.itemDescription = this.lan.english.itemDescription;
      this.imageID = this.lan.english.imageID;
      this.returnExchange = this.lan.english.returnExchange;
      this.refund = this.lan.english.refund;
      this.returnItems = this.lan.english.returnItems;
      this.reasonCode = this.lan.english.reasonCode;
      this.QTY = this.lan.english.QTY;
      this.deliverVIAData = this.lan.english.deliverVIAData;
      this.rep1 = this.lan.english.rep1;
      this.rep2 = this.lan.english.rep2;
      this.rep3 = this.lan.english.rep3;
      this.rep4 = this.lan.english.rep4;
      this.ref1 = this.lan.english.ref1;
      this.link = this.lan.english.link;
      this.thank = this.lan.english.thank;
      this.share = this.lan.english.share;
      this.QtyOrdered = this.lan.english.QtyOrdered;
      this.QtyShipped = this.lan.english.QtyShipped;
      this.itemShipped = this.lan.english.itemShipped;
      this.page = this.lan.english.page;
    }
    this.countryCode = pickPrintDetails.order_details.cust_country;
    return pickPrintDetails;
  }

  getToday(): string {
    let todayString: string = '',
      today = new Date();

    todayString += today.getDate();
    todayString += '/' + (today.getMonth() + 1);
    todayString += '/' + today.getFullYear();

    return todayString;
  }
}





