import { Component, OnInit } from '@angular/core';

import { SearchQuery } from '../../shared/model/searchQuery.model';
import { SearchResults } from '../../shared/model/searchResults.model';
import { OrderDetails } from "../../shared/model/orderDetails.model";
import { SearchDataList } from '../../shared/model/searchDataList.model';
import { SharedApplicationService } from '../../shared/service/shared.service';
import { PrintReadyImgPrintViewComponent } from '../../shared/components/print-view/printReadyImg-print-view.component';
import { UserInfoModalService } from '../../shared/util/modal/user-info.modal';
// import { QzTrayService } from '../../shared/service/qz.service';
import { MatDialog } from '@angular/material/dialog';
import { OnHoldDialogComponent } from '../labelPrint/on-hold-dialog/on-hold-dialog.component'

@Component({
    selector: 'Label-Print',
    templateUrl: './labelPrint.component.html',
    styleUrls: ['./labelPrint.component.css']
})
export class LabelPrintPageComponent implements OnInit {

    constructor(private sharedService: SharedApplicationService/*, private qzService: QzTrayService*/,
        private userModal: UserInfoModalService, public dialog: MatDialog) { }

    ngOnInit() {
        /* if (qz && !qz.websocket.isActive()) {
            this.qzService.init({}).subscribe(qzActivated => {
                if (!qzActivated) {
                    this.userModal.openModal({ status: 'error', title: 'QZ Tray Alert', message: `QZ Tray is not running` })
                        .afterClosed().subscribe(result => {
                            // setTimeout(() => {
                            this.qzService.init({ retries: 1, delay: 0.1 }).subscribe(qzActivatedOnRetry => {
                                // if (qzActivatedOnRetry) {
                                //     this.userModal.openModal({
                                //         status: 'success', title: 'QZ Tray Alert',
                                //         message: `QZ Tray is now running successfully`
                                //     });
                                // }
                            });
                            // }, 1000);
                        });
                }
            });
        } */


        this.searchparams.pageno = 1; //default page number        
        this.searchparams.pagesize = 0;
        this.searchparams.ordersource = this.cscApprovalConstants.source;
        this.searchparams.orderstatus = this.cscApprovalConstants.status;
        this.searchparams.sortby = this.cscApprovalConstants.sortby;
        this.searchparams.sortorder = this.cscApprovalConstants.sortorder;
        let url = `/api/searchDataList`;
        this.sharedService.httpGetService(url)
            .then(
                (data: SearchDataList) => {
                    this.dropDownDataList = data;
                    let tempCountryList = [];
                    for (let key in data.datalist.country[0]) {
                        tempCountryList.push({ id: key, name: data.datalist.country[0][key] });
                    }
                    this.dropDownDataList.datalist.new_country = tempCountryList;
                    this.dropDownDataList.datalist.new_sku = data.datalist.sku.map((str) => {
                        return { id: str, name: str }
                    })
                    this.dropDownDataList.datalist.new_sku_description = data.datalist.sku_description.map((str) => {
                        return { id: str, name: str }
                    })
                    this.dropDownDataList.datalist.order_status = ["ReadyToPrint", "Printed", "QA Review"]
                },
                (err) => console.log(err)
            )
    }

    // order search

    searchparams = new SearchQuery();
    searchResults: SearchResults;
    searchOrderDetailsResults: OrderDetails[];
    dropDownDataList: SearchDataList;
    isSearchAndDetailToggle: boolean = true;
    isListViewToggle: boolean = true;
    detailViewData: OrderDetails;
    selectedOrders: number;
    pageno: number = 1;
    maxPrintSize: number = 100;
    isPrintLimitError: boolean = false;

    isLoading: boolean = true;
    cscApprovalConstants = {
        source: "UK E-COM",
        status: "ReadyToPrint",
        sortby: "order_date",
        sortorder: "desc"
    }

    quickFilters = [
        {
            dispName: "Today",
            key: "Today",
            checked: false
        },
        {
            dispName: "Single Orders",
            key: "Single",
            checked: false
        },
        {
            dispName: "Multi Item",
            key: "MultiItem",
            checked: false
        },
        {
            dispName: "Multi Quantity",
            key: "MultiQty",
            checked: false
        },
        {
            dispName: "All",
            key: "ReadyToPrint",
            checked: false
        },
        {
            dispName: "Priority Delivery",
            key: "Priority",
            checked: false
        },
        {
            dispName: "72 Hrs +",
            key: "72Hrs",
            checked: false
        },
        {
            dispName: "Missing Barcode",
            key: "missingbarcode",
            checked: false
        }
    ];

    orderSource: boolean = false;

    fetchOrders() {
        this.isLoading = true;
        let params = {};
        if (this.searchparams.ordersource == "ALL") {
            this.orderSource = true;
        }
        for (let key of Object.keys(this.searchparams)) {
            if (key == "pagesize" || ((this.searchparams[key] instanceof Array) && this.searchparams[key].length) ||
                (!(this.searchparams[key] instanceof Array) && this.searchparams[key])) {
                params[key] = this.searchparams[key];
            }
        }
        // get the order details on search        
        this.sharedService.httpGetService('/api/orders', params)
            .then(
                // Success
                (data: SearchResults) => {
                    data.orders.map((value) => { value.isSelected = (value.print_ready_image !== "") ? true : false; return value; });
                    this.searchResults = data;
                    this.searchOrderDetailsResults = data.orders;
                    this.isLoading = false;
                    if (!this.searchOrderDetailsResults.length) {
                        this.isLoading = true;
                        this.searchparams.pageno = undefined;
                    }
                    this.sharedService.getMissingBarcodeOrderList(data.orders).then((data) => {
                        this.missingBarcodeOrderInfo = data;
                        console.log("missing barcode orders", this.missingBarcodeOrderInfo)
                    }); 
                },
                // Error
                err => {
                    console.log(err);
                    this.isLoading = true;
                }
            )
    }
    missingBarcodeOrderInfo;
    disablePrint: boolean = false;
    searchOrders() {
        this.searchparams.pageno = 1;
        this.pageno = this.searchparams.pageno;
        this.quickFilters = this.quickFilters.map((filter) => { filter.checked = false; return filter });
        this.searchparams.filter = [];
        this.disablePrint = false;        
        this.fetchOrders();
    }

    resetSearch() {
        this.searchResults = null;
        this.searchOrderDetailsResults = null;
        this.searchparams.pageno = 1;
        this.pageno = this.searchparams.pageno;
        this.quickFilters = this.quickFilters.map((filter) => { filter.checked = false; return filter });
        this.searchparams.filter = [];
        setTimeout(() => {
            this.searchparams.ordersource = this.cscApprovalConstants.source;
            this.searchparams.orderstatus = this.cscApprovalConstants.status;
        })
        this.disablePrint = false;
    }

    //quick filter
    filterChanged(quickFilters) {
        this.quickFilters = quickFilters;
        this.searchparams.pageno = 1;
        this.pageno = this.searchparams.pageno;
        this.searchparams.filter = quickFilters
            .filter((filterItem) => { return filterItem.checked })
            .map((filteredItem) => { return filteredItem.key });
        if(this.searchparams.filter[0] == "missingbarcode"){
            this.disablePrint = true;
        }
        else{
            this.disablePrint = false;
        }
        this.fetchOrders();
    }

    searchOnPaginationchange(e) {
        this.fetchOrders();
    }

    // details view    
    closeDetailView(e) {
        this.isSearchAndDetailToggle = true;
        if (e) {
            this.fetchOrders();
        }
    }

    showDetailsView(e) {
        this.detailViewData = e;
        this.isSearchAndDetailToggle = false;
    }
    numOfSelectedItem() {
        return (this.searchOrderDetailsResults.filter((obj) => { return obj.isSelected })).length;
    }

    callSort(e) {
        this.searchparams = e;
        this.searchparams.pageno = 1;
        this.pageno = this.searchparams.pageno;
        this.fetchOrders();
    }

    async UpdateStatusAndPrintPRImage() {
        await this.bulkRegenarate(true);
        let sumQty = 0,
            count = 0,
            tempArrOrderIds = this.searchOrderDetailsResults
                .filter((obj) => { return obj.isSelected })
                .map((obj) => { sumQty += Number(obj.quantity); return obj.order_id; });
                
        let checkbarcode = this.searchOrderDetailsResults
            .filter((obj) => { return obj.isSelected })
            .map((obj) => { return obj.barcode_img; });
        console.log(checkbarcode, "barcode result");
        for (let i = 0; i < checkbarcode.length; i++) {
            if (checkbarcode[i] == null || checkbarcode[i] == "") {
                count++;
                console.log(checkbarcode, "barcode result1");
            }
        }
        if (count > 0) {
            alert("Some of the order does not have barcode. Before Printing Please click on Missing Barcode and Regenerate");
            count = 0;
        }
        else {
            this.sharedService.sendPrint(PrintReadyImgPrintViewComponent, `/api/print/getPRImageDetailsWithoutSequence?itemseq=null&orderids=${tempArrOrderIds}`)
                .then((res) => {
                    this.fetchOrders();
                });
        }

        // }
    }

    /* directPrintPRImage(){
        let popupWindow;
        let dataList =  this.searchOrderDetailsResults
        .filter( (obj) => { return obj.isSelected })
        .map( (obj) => { return { order_id:obj.order_id, image: obj.print_ready_image , quantity: obj.quantity, seq:obj.sku} });

       if(dataList.length == 1)
        {
            //this.popup.onbeforeunload = this.closePrint;
            //this.popup.onafterprint = this.closePrint;
            //this.popup.focus(); // Required for IE
            //this.popup.print();

            var oHiddFrame = document.createElement("iframe");
            oHiddFrame.id = 'printSection';
            oHiddFrame.onload = function (){
                setTimeout(function (){
                    var img = document.getElementById('printSection');
                        img.focus();
                        window.print();
                    },2000);
        };
            oHiddFrame.style.visibility = "hidden";
            oHiddFrame.style.position = "fixed";
            oHiddFrame.style.right = "0";
            oHiddFrame.style.bottom = "0";
            oHiddFrame.src = dataList[0].image;
            document.body.appendChild(oHiddFrame);
        }
    } */

    async downloadPRImage() {
        await this.bulkRegenarate(true);
        let dataList = this.searchOrderDetailsResults
            .filter((obj) => { return obj.isSelected })
            .map((obj) => {
                return setTimeout(() => {
                    if (obj.print_ready_image != "") {
                        window.open(obj.print_ready_image);
                    }
                    return { order_id: obj.order_id, image: obj.print_ready_image, quantity: obj.quantity, seq: 0 }
                }, 1000);
            });
    }
    
    make_api_call(api, params) {
        return this.sharedService.httpPostService(api, params);
    }    
    postParams: any;    
    async bulkRegenarate(isAuto?) {
        let tempArrOrderIds = this.searchOrderDetailsResults
            .filter((obj) => { return obj.isSelected })
            .map((obj) => { return obj.order_id; });        
            let result;        
            for (let i = 0; i < tempArrOrderIds.length; i++) {
            this.postParams = {
                "order_id": tempArrOrderIds[i]
            };
            result = await this.make_api_call(`/api/image`, this.postParams);
        }
        console.log(result, "result")
        if (result != null || result != "") {
            if(!isAuto){
            this.userModal.openModal({ status: 'success', title: 'Action Success', message: `Print ready image has been re-generated.` });
            }
            this.fetchOrders();
        }
        else {
            if(!isAuto){
                this.userModal.openModal({ status: 'error', title: 'Action Failed', message: `Re-generate Print ready image failed.` });
            }
        }
    }


    onHoldPrint() {
        let onHoldList = this.searchOrderDetailsResults.filter((obj) => {
            if (obj.cust_country)
                return obj.isSelected
        })
        if (this.orderSource) {
            this.userModal.openModal({
                status: 'error', title: 'Action Failed',
                message: `Order Source selected is "All". Kindly select single Locale in order to On Hold Bulk Orders`
            });
            this.orderSource = false;
        }
        else {
            const dialog = this.dialog.open(OnHoldDialogComponent, {
                width: '40vw',
                data: {
                    //data: 'This text is passed into the dialog!',
                    order_id: onHoldList,
                },

            }).afterClosed().subscribe(result => {
                this.fetchOrders();
            });
        }
    }

    checkPrintLimitExceeded(e) {
        if (e <= this.maxPrintSize) {
            this.isPrintLimitError = false;
            return true;
        } else {
            this.isPrintLimitError = true;
            return false;
        }
    }

    imgRegenerated(e) {
        this.fetchOrders();
    }
}