import { Component, OnInit } from '@angular/core';

import { NgForm, FormGroup, FormControl } from '@angular/forms';
// import { DateAdapter, NativeDateAdapter} from '@angular/material';
import {trigger, state, style, animate, transition} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {

  // constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }
public SourceOrders: any = [];
   rows = [
    { name: 'Austin', gender: 'Male', company: 'Swimlane' },
    { name: 'Dany', gender: 'Male', company: 'KFC' },
    { name: 'Molly', gender: 'Female', company: 'Burger King' },
  ];
  columns = [
    { prop: 'name' },
    { name: 'Gender' },
    { name: 'Company' }
  ];
    //   onUserEvent(e) {
    //     if (e.type == "click" && e.column.prop == "order_id") {
    //         console.log(e.row)
           
            
    //         let dialogRef = this.dialog.open(DialogPreviewSummarypopup, {
    //             width: '1000px',
    //             height: '500px',
    //             data: {"recorddata":e.row}
    //         });
    //         dialogRef.afterClosed().subscribe(result => {
    //             console.log(`Dialog closed`);
                
    //             });
            
    //     }
    // }
}
  // @Component({
  //   selector: 'dialog-Preview-summarypopup',
  //   templateUrl: './ShowPopUp.html',
  //   styleUrls: ['./showpupup.css'],
  // })
  //  export class DialogPreviewSummarypopup {

  //  }
