<div class=" pagecontainer">
  <img src="assets/images/logo.png" alt="Mountain View">
  <p class="text-center brand">
    <small>Orchestrator Edition {{tag.version}}</small>
  </p>
  <!-- sample -->

  <form [formGroup]="loginForm" class="form-signin" autocomplete="off" novalidate autocomplete="off" *ngIf="!forgotpasswordShow">

    <label for="inputUser" class="sr-only">Username</label>
    <input type="text" id="inputUser" class="form-control" [formControl]="username" placeholder="Username" autofocus>
    <div id="logInBox" class="text-danger" *ngIf="loginForm.get('username').dirty && loginForm.get('username').invalid">
      Please enter username
    </div>
    <br>
    <label for="inputPassword" class="sr-only">Password</label>
    <input type="password" id="inputPassword" class="form-control" [formControl]="password" placeholder="Password">
    <div id="logInBox2" class="text-danger" *ngIf="loginForm.get('password').dirty && loginForm.get('password').invalid">
      Please enter password
    </div>
    <br>
    <label for="inputNewPassword" class="sr-only"> New Password</label>
    <input type="password" id="inputNewPassword" *ngIf="passwordExpired==true" class="form-control" [formControl]="newpassword" placeholder="New Password">
    <div class="text-danger" *ngIf="loginForm.get('newpassword').dirty && loginForm.get('newpassword').errors">
      <span class="col-sm-10" style="padding:4px 0 0" *ngIf="loginForm.get('newpassword').errors">
              Password must contain one Capital letter, number & special characters
          </span>
    </div>
      <br>
      <div  *ngIf="passwordExpired==true">
        <button type="submit" style="margin-right: 16px" class="btn btn-default pull-right leftpadding" (click)="LoginPassword(loginForm.value)">Save</button>
        <button class="btn btn-default pull-right leftpadding" style="margin-right: 16px" (click)="cancelWasClicked()">Cancel</button>
      </div>
    <br><br>
    <button id="logInButton" (click)="loginUser(loginForm.value)" class="btn btn-lg btn-primary btn-block" type="submit"
      [disabled]="(loginForm.invalid && desablebtn) || passwordExpired==true">Sign in</button>
    <br>
    <div class="row">
      <div class="col-md-6 col-sm-6 col-xs-6">
        <button (click)="forgotpassword()" type="button" class="forgotpassword">Forgot Password</button>

      </div>
      <div class="col-md-6 col-sm-6 col-xs-6">
        <input #saveUserNameCheckBox id="saveUserNameCheckBox" type="checkbox" [checked]="Rememberme" (change)="onSaveUsernameChanged(saveUserNameCheckBox.checked)" />
        <label for="saveUserNameCheckBox">Remember me</label>
      </div>

      <!--<div class="col-md-6 col-sm-6 col-xs-6" style="text-align: right"><a [routerLink]="['/register']">Register</a></div>-->
    </div>
  </form>
  <form [formGroup]="forgotForm" *ngIf="forgotpasswordShow" class="form-signin" novalidate autocomplete="off">

    <label for="inputUser" class="sr-only">Username</label>
    <input type="email" class="form-control" [formControl]="useremail" placeholder="Email id" autofocus>
    <div class="text-danger" *ngIf="forgotForm.get('useremail').dirty && forgotForm.get('useremail').invalid">
      Please enter Email id
    </div>
    <br><br>
    <input type="button" id="logInButton" (click)="ResetPassword(forgotForm.value)" class="btn btn-lg btn-primary btn-block"
      [disabled]="ResetButton" value="Send password">
    <br>
    <div class="row">
      <div class="col-md-6 col-sm-6 col-xs-6">
        <button (click)="forgotpasswordShow=false" class="forgotpassword">Sign in</button>
      </div>
    </div>
  </form>
</div>