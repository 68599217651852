import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import * as moment from 'moment';
import { OrderDetails } from "../../model/orderDetails.model";
import { SharedApplicationService } from '../../service/shared.service';
import { PrintReadyImgPrintViewComponent } from '../print-view/printReadyImg-print-view.component';
import { UserInfoModalService } from '../../util/modal/user-info.modal';

@Component({
    selector: 'pickticket-detail-view',
    templateUrl: './pickticket-detail-view.component.html',
    styleUrls: ['./pickticket-detail-view.component.css']
})
export class PickTicketDetailViewComponent implements OnInit {
    @Input() detailViewData: OrderDetails;
    @Input() rejectCodes;
    @Input() isInventoryMang: boolean = false;
    @Output() closeDetails = new EventEmitter();
    @Output() imgRegenerated = new EventEmitter();

    constructor(public sharedService: SharedApplicationService, private userModal: UserInfoModalService, ) { }

    labelImageurl: string;
    pritnStartSequence;
    maxPrintSize: number = 100;
    isPrintLimitError: boolean = false;
    rejectionProps = {
        rejectCode: "",
        rejectReason: "",
        rejectComment: ""
    };

    ngOnInit() {
        this.labelImageurl = "assets/images/label_image.jpg";
        this.detailViewData.order_date = moment(this.detailViewData.order_date).format("MM/DD/YYYY HH:mm");
        this.detailViewData.reason_code = this.detailViewData.reason_code === "" ? null : this.detailViewData.reason_code;
        this.pritnStartSequence = 1;
    }

    closeSection() {
        this.closeDetails.emit();
    }

    async toDownload() {
        if (this.detailViewData.print_ready_image != "") {
            await this.toRegeneratePRImage(true);
            window.open(this.detailViewData.print_ready_image);
        }
    }

    toRegeneratePRImage(isAuto) {
        let postParams = {
            "order_id": this.detailViewData.order_id
        };

        return this.sharedService.httpPostService(`/api/image`, postParams)
            .then(
            // Success
            (data:any) => {
                for(let i = 0; i < data.length; i++) {
                    if( /_PR_/.test( data[i]) ) {
                        this.detailViewData.print_ready_image = data[i];
                    } else if ( /_BR_/.test( data[i]) ) {
                        this.detailViewData.barcode_img = data[i];                        
                    }
                }
                this.imgRegenerated.emit();
                if(!isAuto){
                    this.userModal.openModal({ status: 'success', title: 'Action Success', message: `Print ready image has been re-generated.` });
                }
            },
            // Error
            err => {
                console.log(err);
                if(!isAuto){
                    this.userModal.openModal({ status: 'error', title: 'Action Failed', message: `Re-generate Print ready image failed.` });
                }
            });
    }

   async toPrint() {
        //Number(this.detailViewData.quantity) <= this.maxPrintSize &&
        if (this.pritnStartSequence > 0 && this.pritnStartSequence <= Number(this.detailViewData.quantity)) {
            await this.toRegeneratePRImage(true);
            this.isPrintLimitError = false;

            this.sharedService.sendPrint(PrintReadyImgPrintViewComponent, `/api/print/getPRImageDetailsWithSequence?itemseq=${this.pritnStartSequence}&orderids=${this.detailViewData.order_id}`);
            //this.sharedService.sendPrint([{ order_id:this.detailViewData.order_id, image: this.detailViewData.print_ready_image , quantity: this.detailViewData.quantity, seq:this.pritnStartSequence}]);
        } else {
            this.isPrintLimitError = true;
        }
    }
}