<div class="container">
    <div class="row">
        <div class="col-md-6 order-number-container">
            <h3 class="order-heading">
                Order
            </h3>
            <div>
                <form>
                    <div class="order-id-container">
                        <label class="d-block" for="orderId">Order ID</label>
                        <!-- <input type="text" id="orderId" name="orderId" [(ngModel)]="orderId" (keyup.enter)="getOrderDetails();" #orderIdRef class="order-id-input" autocomplete=off autofocus> -->
                        <input type="text" id="orderId" name="orderId" [(ngModel)]="orderId" (paste)="setFocusToOrderIdField();" (click)="setFocusToOrderIdField();" (focusin)="setFocusToOrderIdField();" (keyup.enter)="getOrderDetails();" #orderIdRef class="order-id-input" autocomplete=off
                            autofocus>
                    </div>
                    <div class="red" *ngIf="qaOrderErr">
                        {{ qaOrderErr }}
                    </div>
                    <div class="edit-quantity-container">
                        <label class="d-block" for="quantity">Edit Item Quantity</label>
                        <input type="text" id="quantity" name="userEnteredQty" [(ngModel)]="userEnteredQty" class="edit-quantity" [disabled]="disableAllActionButtons">
                        <span> of </span>
                        <input type="text" disabled class="total-quantity" [value]="detailViewData?.quantity">
                        <button type="button" (click)="confirmQty()" [disabled]="disableAllActionButtons" class="approve-button confirm-btn">Confirm</button>
                        <button type="button" (click)="rejectQty()" [disabled]="disableAllActionButtons" class="approve-button failed-btn">Reject</button>
                        <p class="red hide" [class.show]="isInvalidQty">Enter valid quantity</p>
                        <p class="red hide" [class.show]="isQtyExceeds">Quantity Exceeds Remaining</p>
                        <p class="red hide" [class.show]="isFullFilled">Item already fullfilled</p>
                    </div>
                </form>

                <div class="text-left action-buttons-container">
                    <button type="button" class="approve-button custom-approve-button pull-left mar_right" (click)="printPackagingSlip()" [disabled]="disableResetButtons">
                                Print Packaging Slip
                    </button>
                    <button type="button" class="approve-button custom-approve-button " (click)="openFailedModal()" [disabled]="disableResetButtons">Reset Line</button>
                    <button type="button" class="approve-button custom-approve-button" (click)="verifyOrder()" [disabled]="disableAllActionButtons">Submit Line</button>
                    <p *ngIf="isOrderInComplete" class="red text-right">Order is not complete. All items in order has to be confirmed or zeroed.</p>
                </div>
            </div>
        </div>
        <div class="col-md-6 order-status-container">
            <h3 class="status-heading">Status</h3>
            <div class="border-bottom clearfix">
                <div class="col-md-6">
                    <label>Found</label>
                    <span class="count-status-display">
                        <span class="large-font"> {{getTotalConfirmed()}} </span>
                    <span> of </span>
                    <span class="large-font"> {{searchResults.length}} </span>
                    </span>
                </div>
                <div class="col-md-6">
                    <label>Remaining:</label>
                    <span class="large-font count-status-display">
                        {{searchResults.length - getTotalConfirmed()}}
                    </span>
                </div>
            </div>
            <div class="clearfix border-bottom col-md-12 order-status-indicator-container">
                <label class="pull-left order-status-label">Order Status</label>
                <span>
                    <span class="order-status-indicator pull-left" [ngClass]="getOrderstatusLegend()"></span>
                <span class="order-status-indicator-hint pull-left">{{orderstatuses[getOrderstatusLegend()]}}</span>
                </span>
            </div>
            <div class="clearfix legend-container">
                <label class="d-block legend-title">Legend</label>
                <div class="col-md-3">
                    <span class="order-status-legend pull-left multi-order-complete"></span>
                    <span class="pull-left order-status-legend-hint">Multi Order (Complete)</span>
                </div>
                <div class="col-md-3">
                    <span class="order-status-legend pull-left single-order"></span>
                    <span class="pull-left order-status-legend-hint">Single Order</span>
                </div>
                <div class="col-md-3">
                    <span class="order-status-legend pull-left multi-order-wait"></span>
                    <span class="pull-left order-status-legend-hint">Multi Order (Wait)</span>
                </div>
                <div class="col-md-3">
                    <span class="order-status-legend pull-left cancelled"></span>
                    <span class="pull-left order-status-legend-hint">Cancelled / Rejected</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <qa-detail-view #qaDetailViewRef [detailViewData]="detailViewData" (skuScanned)="skuScanComplete($event)"></qa-detail-view>
    </div>
    <div>
        <div class="orders-grid-containter">
            <ngx-datatable class="material" [rows]="searchResults" [columnMode]="'flex'" [rowClass]="getRowClass" [headerHeight]="50" [loadingIndicator]="loading" [rowHeight]="50" [selected]="selectedGridRow" [selectCheck]="false" [selectionType]="'checkbox'" [scrollbarV]="true">
                <ngx-datatable-column [flexGrow]="1" [sortable]="false" [draggable]="false" [resizeable]="false">
                    <!-- <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                    </ng-template>  -->
                    <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                        <input type="checkbox" [checked]="isSelected" disabled/>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Order Number" prop="order_id" [flexGrow]="4"></ngx-datatable-column>
                <ngx-datatable-column name="Order Status" prop="order_status" [flexGrow]="4"></ngx-datatable-column>
                <ngx-datatable-column name="Multi Order Qty" prop="quantity" [flexGrow]="4"></ngx-datatable-column>
                <ngx-datatable-column name="Quantity Confirmed" prop="quantity_confirmed" [flexGrow]="4"></ngx-datatable-column>
                <ngx-datatable-column name="Zeroed" prop="quantity_rejected" [flexGrow]="4"></ngx-datatable-column>
            </ngx-datatable>
        </div>
    </div>
    <!--<div class="text-right action-buttons-container">
        <button type="button" class="approve-button pull-right" (click)="printPackagingSlip()" [disabled]="disableResetButtons">
                    Print Packaging Slip
        </button>
        <button type="button" class="approve-button" (click)="openFailedModal()" [disabled]="disableResetButtons">Reset Line</button>
        <button type="button" class="approve-button" (click)="verifyOrder()" [disabled]="disableAllActionButtons">Submit Line</button>
        <p *ngIf="isOrderInComplete" class="red text-right">Order is not complete. All items in order has to be confirmed or zeroed.</p>
    </div>-->
    <span class="fa fa-facebook-square" style="visibility:hidden"></span>
</div>