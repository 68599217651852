<div class="container detail-view-container">
    <div class="row">
        <div class="col-md-11 print-ticket-header">
            <h1>Pick Ticket Info</h1>
        </div>
        <div class="col-md-1 back-to-search clearfix">
            <button class="pull-right back-to-search-button" (click)="closeSection()"><img src="assets/images/close-button.png" alt="close button"><span>Back to Search</span></button>
        </div>
    </div>

    <div class="row">
        <div class="col-md-5 label-img-container">
            <img [src]="detailViewData.print_img" *ngIf="isInventoryMang" class="detailViewLabelImage" alt="Label image">
            <img [src]="detailViewData.print_img" *ngIf="!isInventoryMang" class="detailViewLabelImage" alt="Label image">
        </div>

        <div class="col-md-6 label-img-container">
            <img [src]="detailViewData.barcode_img" class="barcodeLabelImage" alt="Barcode image">
        </div>
        <div class="col-md-4 customer-details-container">

            <form #detailViewform="ngForm">
                <div class="customer-input-container">
                    <label>Order #</label>
                    <input class="pull-right detail-view-inputs" type="text" name="firstName" [value]="detailViewData.order_id" disabled>
                </div>
                <div class="customer-input-container">
                    <label>SKU</label>
                    <input class="pull-right detail-view-inputs" type="text" name="surname" [value]="detailViewData.sku" disabled>
                </div>
                <div class="customer-input-container">
                    <label>Product Form</label>
                    <input class="pull-right detail-view-inputs" type="text" name="email" [value]="detailViewData.product_form" disabled>
                </div>
                <div class="customer-input-container">
                    <label>Delivery Method</label>
                    <input class="pull-right detail-view-inputs" type="text" name="status" [value]="detailViewData.shipping_method" disabled>
                </div>
                <div class="customer-input-container">
                    <label>Item Quanity</label>
                    <input class="pull-right detail-view-inputs" type="text" name="quantity" [value]="detailViewData.quantity" disabled>
                </div>
                <div class="customer-input-container">
                    <label>Gift Box Sku</label>
                    <input class="pull-right detail-view-inputs" type="text" name="gift-box-sku" [value]="detailViewData.gift_box_sku_id" disabled>
                </div>
            </form>
        </div>
        <div class="col-md-3 reject-reason-container">
            <div class="customer-input-container">
                <label>Sequence</label>
                <input class="pull-right detail-view-inputs" type="text" name="text1" [(ngModel)]="pritnStartSequence">
            </div>
            <div class="customer-input-container">
                <label>Description</label>
                <input class="pull-right detail-view-inputs" type="text" name="tetx2" [value]="detailViewData.sku_desc" disabled>
            </div>
            <div class="customer-input-container">
                <label>Order Date</label>
                <input class="pull-right detail-view-inputs" type="text" name="text1" value="{{detailViewData.order_date}}" disabled>
            </div>
            <div class="customer-input-container">
                <label>Fragrance</label>
                <input class="pull-right detail-view-inputs" type="text" name="tetx2" [value]="detailViewData.product_fragrance" disabled>
            </div>
            <div class="customer-input-container">
                <label>Gift Box <br/>Description</label>
                <input class="pull-right detail-view-inputs" type="text" name="gift-box-description" [value]="detailViewData.gift_box_sku_description" disabled>
            </div>
        </div>
    </div>
    <div class="col-md-offset-5 text-center action-buttons-container">
        <div>
            <button *ngIf="!isInventoryMang" [disabled]="sharedService.checkValidImageURL(detailViewData.print_img)==false" (click)="toRegeneratePRImage(false)">Re-generate Image</button>
            <button *ngIf="!isInventoryMang" (click)="toDownload()">Download</button>
            <button *ngIf="!isInventoryMang" (click)="toPrint()">Print</button>
        </div>
        <div *ngIf="sharedService.checkValidImageURL(detailViewData.print_img)==false" class="red">
            Re-generate Image button disabled because of the incomplete information from fluid
        </div>        
        <div *ngIf="isPrintLimitError" class="red">
            Sequence Number should not be higher than ordered quantity.
        </div>
        <!-- and maximum number of prints allowed is {{maxPrintSize}}.-->
    </div>
</div>