import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'quick-filter',
    templateUrl: 'quick-filter.component.html',
    styles: [`
        .quick-filter{padding: 10px 0;    margin-top: 10px;  margin-bottom: 10px; border-bottom: 1px solid #ccc;}
        .quick-filter h2 {     font-size: 17px;    float: left;    margin: 12px 10px 10px 0;}
        .quick-filter label {font-weight: normal;    padding: 10px 20px;    /*border: 1px solid #666;*/
                cursor: pointer; border-radius: 30px; margin: 5px;
                webkit-box-shadow: inset 0 0 0 1px #666, 0 5px 15px #666;
                -moz-box-shadow: inset 0 0 0 1px #666,0 5px 15px #666;
                box-shadow: inset 0 0 0 1px #666, 0 2px 10px #666;
        }
        .quick-filter label.checked {
            webkit-box-shadow: inset 0 0 0 1px #666, inset 0 5px 30px #666;
            -moz-box-shadow: inset 0 0 0 1px #666,inset 0 5px 30px #666;
            box-shadow: inset 0 0 0 1px #666, inset 0 1px 10px #666;
        }
        .quick-filter label.input-checked { background: #efefef;}
    `]
})
export class QuickFilterComponent {
    @Input() quickFilters;
    @Output() quickFiltersChange = new EventEmitter();
    

    //filter check/uncheck 
    filterStateChanged () {
        this.quickFiltersChange.emit(this.quickFilters);
    }
}