import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormControl, FormArray } from '@angular/forms';
import * as moment from 'moment';
import { DataService } from "../../shared/service/data.service"
@Component({
  selector: 'app-userproductivity',
  templateUrl: './userproductivity.component.html',
  styleUrls: ['./userproductivity.component.css']
})
export class UserproductivityComponent implements OnInit {

  constructor(private dataservice: DataService) {

  }
  public myForm: FormGroup;

  ngOnInit() {
    this.myForm = new FormGroup({
      'FromDate': new FormControl(),
      'ToDate': new FormControl(),
      'order_status': new FormControl()
    });
    this._onload()
    
  }
  _onload() {
    this.myForm.setValue({
      order_status: "All",
      FromDate:'',
      ToDate:''

    })
   var fromdatenow=new Date()     
    fromdatenow.setHours(0)
    fromdatenow.setMinutes(0)
    fromdatenow.setSeconds(0)
    fromdatenow.setMilliseconds(0)

    var todatenow=new Date()     
    todatenow.setHours(23)
    todatenow.setMinutes(59)
    todatenow.setSeconds(0)
    todatenow.setMilliseconds(0)
  this.toDate=todatenow.toString()
    this.fromDate=fromdatenow.toString()
    this.dataservice.GetPostMethod("productivity?FromDate=" +
        this.fromDate + "&ToDate=" + this.toDate + "&Orderstatus=All" , "&user=" + localStorage.getItem('Username') + "")
        .subscribe(Response => {
          if (Response["success"] == "true") {
            this.OrdersArray = Response["message"];
            this.rows = Response["message"]
            this.showtable = true
            //this.filterData(Response.message)

          }
          else {
            alert(Response["message"])
            this.fromDate = ''
            this.toDate = ''
          }
        })

    
  }


  public fromDate: any;
  public toDate: any;
  public OrdersArray: any;
  public showtable = false;
  public Orderstatus: any;



  public OrderstatusGroup: any = ['All','New', 'Pending', 'Approved', 'On-Hold', 'Rejected',
    'Ready to Print', 'Printed', 'QA Verified', 'QA Review',
    'Cancelled', 'Shipped', 'Returned']
  rows = [];
  columns = [
    { name: 'Order Status', flexGrow: 1, prop: 'Status' },
    { name: 'User', flexGrow: 1, prop: 'user' },
    { name: 'Orders Quantity', flexGrow: 1, prop: 'count' }
  ];
  Getdetails(myForm: any) {
    console.log('Registration successful.');
    //console.log(myForm);
    var frmdate = ""
    this.fromDate = moment.parseZone(myForm.controls.FromDate.value).format("YYYY-MM-DDTHH:mm:ss")
    this.toDate = moment.parseZone(myForm.controls.ToDate.value).format("YYYY-MM-DDTHH:mm:ss")
    this.Orderstatus = myForm.controls.order_status.value;
    if(this.fromDate=="Invalid date"||this.toDate=="Invalid date"){
      alert("Please fill")
      return false
    }
    //productivity/order_status
    
      this.dataservice.GetPostMethod("productivity?FromDate=" +
        this.fromDate + "&ToDate=" + this.toDate + "&Orderstatus=" + this.Orderstatus, "&user=" + localStorage.getItem('Username') + "")
        .subscribe(Response => {
          if (Response["success"] == "true") {
            this.OrdersArray = Response["message"];
            this.rows = Response["message"]
            this.showtable = true
            //this.filterData(Response.message)

          }
          else {
            alert(Response["message"])
            this.fromDate = ''
            this.toDate = ''
          }
        })
    
   

  }
  fromToDateMismatch:boolean=false
  checkDateValidity(from, to): boolean {
      if (from && to && from > to) {
          return false;
      }
      //returning true by default
      return true;
  }

dateSelecteduserproduction(e, field) {
  setTimeout(() => {
      if(field === "from") {
          this.fromDate = e;
      } else {
          this.toDate = e;
      }
  if(typeof(this.fromDate)=="string"&&this.fromDate!="")
          this.fromDate=new Date(this.fromDate)
      this.fromToDateMismatch = !this.checkDateValidity(this.fromDate, this.toDate);
    })
  }
  resetform(){
    this.showtable=false;
  }
  exportcsv(JsonData): any {
    let today = new Date();
    var summary = 'Order Status, User, Orders Quantity\n';
    JsonData.forEach(function (row) {
      summary += row.Status + ',' + row.user + ',' + row.count + ',';
      summary += "\n";
    });

    //console.log(summary);
    var csvElement = document.createElement('a');
    csvElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(summary);
    csvElement.target = '_blank';
    csvElement.download = 'Userprod_Report_' + today + '.csv';
    csvElement.click();
  }

}
