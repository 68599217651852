import * as Raven from 'raven-js';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { MdDatepickerModule, MdNativeDateModule } from '@angular/material';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatDialogModule} from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoadingModule } from 'ngx-loading';
import { OwlDateTimeModule, OwlNativeDateTimeModule, } from 'ng-pick-datetime';
import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting

// ----- Onshore Imports
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './user/auth.guard';
import { HeaderComponent } from './components/header/header.component';
import { UserModule } from './user/user/user.module';
import { ToastrService } from './common/toastr.service';
import { LoginComponent } from './home/login/login.component';
import { AboutComponent } from './home/about/about.component';
import { ApproverComponent } from './components/approver/approver.component';
import { ManagerComponent } from './components/manager/manager.component';
import { InventoryComponent } from './components/inventory/inventory.component';
import { PrintComponent } from './components/print/print.component';
import { QaComponent } from './components/qa/qa.component';
import { ShipmentComponent } from './components/shipment/shipment.component';
import { OrdermanagementComponent } from './components/ordermanagement/ordermanagement.component';
import { CscLabelordermanagementModule } from './core/csc-labelordermanagement/csc-labelordermanagement.module';
import { CscReportsModule } from './core/csc-reports/csc-reports.module';
import { UsermanagementComponent } from './components/usermanagement/usermanagement.component';
import { GroupmanagementComponent } from './components/groupmanagement/groupmanagement.component';
import { ReportingComponent } from './components/reporting/reporting.component';
import { ConfigurationModule } from './core/configuration/configuration.module';
// new
import 'rxjs/Rx';
import { FindUserComponent } from './admin/user-management-components/find-user/find-user.component';
import { EditUserComponent } from './admin/user-management-components/edit-user/edit-user.component';
import { UserManagementCoreComponent } from './admin/management-core/user-management-core/user-management-core.component';
import { RegisterComponent } from './admin/user-management-components/register/register.component';
import { UserManagementCoreModule } from './admin/management-core/management-core.module';
import { GroupManagementCoreComponent } from './admin/management-core/group-management-core/group-management-core.component';
import { AccessManagementCoreComponent } from './admin/management-core/access-management-core/access-management-core.component';
import { intersection } from 'lodash';
// import { SearchModule } from './search/search.module'
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
//Graphs
//import { BarchatComponent } from './shared/charts/barchat/barchat.component';
import { StackbarComponent } from './shared/charts/stackbar/stackbar.component';
import { ChartsModule } from 'ng2-charts';

import { AuthService } from './user/auth.service';
import { UserService } from './user/user.service';
import { TagService } from './user/tag.service';

// ----- Offshore Imports
import { CSCLabelApprovalModule } from './core/cscLabelApproval/cscLabelApproval.module';
import { CSCLabelManagerModule } from './core/cscLabelManager/cscLabelManager.module';
import { InventoryManagementModule } from './core/inventoryManagement/inventoryManagement.module';
import { LabelPrintModule } from './core/labelPrint/labelPrint.module';
import { ShippingStatusModule } from './core/shippingstatus/shippingstatus.module';
import { QualityAssuranceModule } from './core/qualityAssurance/qa.module';
import { DynamicPrintWrapperComponent } from './shared/components/dynamic-wrapper-component/dynamic-printwrapper-component';
// import {DynamicWrapperComponent} from './shared/components/dynamic-wrapper-component/dynamic-wrapper-component';
import { PrintReadyImgPrintViewComponent } from './shared/components/print-view/printReadyImg-print-view.component';
import { SharedApplicationService } from './shared/service/shared.service';
import { PackagingSlipPrintViewComponent } from './shared/components/print-view/packaging-slip-print-view.component';
// import { PerfectScrollbarModule, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
 
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
 suppressScrollX: true
};
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SnapShotComponent } from './core/snap-shot/snap-shot.component';
import { UserproductivityComponent } from './core/userproductivity/userproductivity.component';
import { ShiftproductionComponent } from './core/shiftproduction/shiftproduction.component';
import {ResetPasswordComponent} from './core/reset-password/reset-password.component';
import { ProcessingreportComponent } from './core/processingreport/processingreport.component';
import { CrsReportComponent } from './core/crs-report/crs-report.component';
import { NotSyncedShipComponent } from './core/not-synced-ship/not-synced-ship.component';
import { WarningComponent } from './components/password-expiring-warning/warning.component';
import { ErrorPasswordComponent } from './core/error-password/error-password.component';
import { PostalcodeComponent } from './components/postalcode/postalcode.component';
// import { QzTrayService } from './shared/service/qz.service';
import {PostalCodeStatusModule} from './core/postal-code-status/postal-code-status.module';
import {DatalistService} from './user/datalist.service';
import { DataextractComponent } from './core/dataextract/dataextract.component';
// const PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//   suppressScrollX: true
// };

Raven
  .config('https://96b1846ce3f247d08a570ab7aa988fb7@sentry.io/220923')
  .install();
// Raven
//   .config(process.env.SENTRY_ANGULAR_URL)
//   .install();


@NgModule({
  declarations: [
    AppComponent,
    //BarchatComponent,
    StackbarComponent,
    LoginComponent,
    AboutComponent,
    HeaderComponent,
	WarningComponent,
    ApproverComponent,
    ManagerComponent,
    InventoryComponent,
    PrintComponent,
    QaComponent,
    ShipmentComponent,
    OrdermanagementComponent,
    UsermanagementComponent,
    GroupmanagementComponent,
    ReportingComponent,
    DynamicPrintWrapperComponent,
    PrintReadyImgPrintViewComponent,
    PackagingSlipPrintViewComponent,
    ConfigurationComponent,
    SnapShotComponent,
    UserproductivityComponent,
    ShiftproductionComponent,
    ResetPasswordComponent,
    ProcessingreportComponent,
    CrsReportComponent,
    NotSyncedShipComponent,    
    ErrorPasswordComponent,PostalcodeComponent, DataextractComponent,




  ],
  imports: [
    LoadingModule,
    BrowserModule,
    FormsModule,
    ChartsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatGridListModule,
    NgxDatatableModule,
    // DateTimePickerModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    // SearchModule,
    ReactiveFormsModule,
    HttpClientModule,
    UserModule,
    UserManagementCoreModule,
    CSCLabelApprovalModule,
    CscLabelordermanagementModule,
    ConfigurationModule,
    CscReportsModule,
    CSCLabelManagerModule,
    InventoryManagementModule,
    LabelPrintModule,
    ShippingStatusModule,
    QualityAssuranceModule,PostalCodeStatusModule,
    // PerfectScrollbarModule.forRoot(PERFECT_SCROLLBAR_CONFIG),
    PerfectScrollbarModule,
    
    NgIdleKeepaliveModule.forRoot(),
    RouterModule.forRoot([
      { path: 'login', component: LoginComponent },
      // { path: 'register', component:RegisterComponent},
      { path: 'report', component: AboutComponent, canActivate: [AuthGuard] },
      { path: 'about', component: AboutComponent },
      {path: 'reset-password', component : ResetPasswordComponent},
      { path: 'error-password', component: ErrorPasswordComponent },
      { path: 'approver', component: ApproverComponent, canActivate: [AuthGuard] },
      { path: 'inventory', component: InventoryComponent, canActivate: [AuthGuard] },
      { path: 'manager', component: ManagerComponent, canActivate: [AuthGuard] },
      { path: 'print', component: PrintComponent, canActivate: [AuthGuard] },
      { path: 'qa', component: QaComponent, canActivate: [AuthGuard] },
      { path: 'ordermanagement', component: OrdermanagementComponent, canActivate: [AuthGuard] },
      { path: 'shipment', component: ShipmentComponent, canActivate: [AuthGuard] },
      { path: 'usermanagement', component: UserManagementCoreComponent, canActivate: [AuthGuard] },
      { path: 'groupmanagement', component: GroupManagementCoreComponent, canActivate: [AuthGuard] },
      { path: 'accessmanagement', component: AccessManagementCoreComponent, canActivate: [AuthGuard] },
      { path: 'reporting', component: ReportingComponent, canActivate: [AuthGuard],
          children: [
          { path: '', redirectTo: 'reporting', pathMatch: 'full' },
          { path: 'snapshot', component: SnapShotComponent },
          { path: 'shiftproduction', component: ShiftproductionComponent },
          { path: 'userproductivity', component: UserproductivityComponent },
          {path: 'processingreport', component: ProcessingreportComponent},
          {path: 'crsreport', component: CrsReportComponent},
          {path: 'notsyncedship', component: NotSyncedShipComponent},
          {path:'dataextract', component: DataextractComponent}          
        ]
      },
      { path: 'config', component: ConfigurationComponent, canActivate: [AuthGuard] },
      { path: 'postalcode', component: PostalcodeComponent , canActivate: [AuthGuard] },
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: '**', redirectTo: 'login', pathMatch: 'full' },
    ])
  ],

  providers: [
    ToastrService,
    AuthService,
    AuthGuard,
    UserService,
    TagService,
    SharedApplicationService,
    DatalistService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  entryComponents: [PrintReadyImgPrintViewComponent, PackagingSlipPrintViewComponent],
  bootstrap: [AppComponent, DynamicPrintWrapperComponent]
})
export class AppModule { }
