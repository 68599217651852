<!-- <div class="row">
              <div class="col-md-12">
                <div class="col-md-10 col-md-offset-1">
                    <form [formGroup]="reportForm" (ngSubmit)="registerUser()">
                        <md-form-field class="example-full-width">
                            <input mdInput [min]="minDate" (dateInput)="fromDateInput($event, 'from')" formControlName="fromdate" [max]="maxDate"  [mdDatepicker]="fromdate" placeholder="Choose a date From Date">
                            <md-datepicker-toggle mdSuffix [for]="fromdate"></md-datepicker-toggle>
                            <md-datepicker #fromdate></md-datepicker>
                        </md-form-field>
                        <md-form-field class="example-full-width">
                            <input mdInput [min]="minDate" (dateInput)="toDateInput($event, 'from')" formControlName="todate"  [max]="maxDate" [mdDatepicker]="todate" placeholder="Choose a date To Date">
                            <md-datepicker-toggle mdSuffix [for]="todate"></md-datepicker-toggle>
                            <md-datepicker #todate></md-datepicker>
                        </md-form-field>
                        <button type="submit" class="button" >Show Details</button>                      
                    </form>
              <table class="table table-striped orderlist" *ngIf="OrdersArray">
                <tr>
                  <th>Sku </th>
                  <th>Sku Description </th>
                  <th>Quantity </th>
                  <th>Price </th>
                  <th>Currency </th>                  
                </tr>
                <tr  *ngFor="let record of OrdersArray">                    
                      <td>{{record.sku}}</td>
                      <td>{{record.sku_description}}</td>                    
                      <td>{{record.skuordercount}}</td>
                      <td>{{record.line_item_price}}</td>
                      <td>{{record.currency}}</td>
                </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><strong>Total Pounds: {{OrdersArray.TotalPrice.Pound}}</strong></td>
                        <td><strong>Total Euros: {{OrdersArray.TotalPrice.Euro}}</strong></td>
                    </tr>
              </table>
            </div>
          </div>
          </div> -->


<app-csc-reports>
</app-csc-reports>