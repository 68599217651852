import { Injectable } from '@angular/core';
// import { Http, Response, Headers, RequestOptions} from '@angular/http';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Router} from '@angular/router';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { throwError } from  'rxjs';
// import 'rxjs/add/operator/do';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/observable/throw';
// import 'rxjs/add/operator/toPromise';


import { IUser } from './user';

@Injectable()
export class AuthService {

  public currentUser: IUser;

 public Screenacess:any;

  constructor(private http: HttpClient,private router: Router) {}

  isLoggedIn(): boolean {
    try {
      const theUser: any = JSON.parse(localStorage.getItem('currentUser'));
      if (theUser) {
        this.currentUser = theUser.user;
      }
    } catch (e) {
      return false;
    }

    return !!this.currentUser;
  }

  login(oUser) {
    const headers = new Headers({ 'Content-Type': 'application/json'});
    // const options = new RequestOptions({headers: headers});
    const options = {
      headers: new HttpHeaders().append('key', 'value')
    }

    return this.http.post('/api/login', JSON.stringify(oUser), options).pipe(
      tap((response: HttpResponse<any>) => {
        if (response["success"]) {
          this.currentUser = <IUser>response["message"];
          const userObj: any = {};
          userObj.user = response["message"];
          userObj.token = response["token"];

            localStorage.setItem("Username",oUser.username)
            localStorage.setItem("userpwd",oUser.password)
            localStorage.setItem("group_name",oUser.group_name)

          localStorage.setItem('currentUser', JSON.stringify(userObj));
           

        }
        //response.json();
      }),
      catchError(this.handleError));
  }
  isScreenacess():any{
    const AScreenacess: any = {
          isManger:false,
          isApproval:false,
          isReporting:false,
          isQuality:false,
          isInventory:false,
          isPrint:false,
          isShipping:false,
          isOrderManagement:false
    };
    return AScreenacess;  
  }
  

  logout(): void {
    this.currentUser = null;
    localStorage.removeItem('currentUser');
    this.router.navigate(['/login']);
  }

  private handleError(error: Response) {
    console.error(error);
    return throwError(error["error"] || 'Server error');
  }
}