<div class="user-search-container container">
  <div class="row">
    <h2 class="title">
      Update Lost Password
      <button class="pull-right search-view-toggle" [class.searchCollapsed]="!searchViewToggle" (click)="searchViewToggle = !searchViewToggle;"></button>
    </h2>
  </div>

  <div class="row search-fields-container" *ngIf="searchViewToggle">
    <form [formGroup]="registerForm" (ngSubmit)="updateLostPassword(registerForm.value)" novalidate autocomplete="off">
      <div class="sub-search-container row">
        <h3>User Details</h3>
        <div class="sub-search-wrapper">

          <div class="col-md-4" formGroupName="passwordGroup" [ngClass]="{'has-error': registerForm.get('passwordGroup').errors }">
            <div [ngClass]="{'has-error': registerForm.get('passwordGroup.password').invalid && registerForm.get('passwordGroup.password').dirty}">
              <label for="password">Password </label>
              <input type="password" class="user-input-text" [formControl]="password" id="password" placeholder="Password">
              <div class="text-danger" *ngIf="registerForm.get('passwordGroup.password').dirty && registerForm.get('passwordGroup.password').errors">
                <span class="col-sm-10" style="padding:4px 0 0" *ngIf='registerForm.get("passwordGroup.password").errors'>
                        Please enter password
          </span>
                <span class="col-sm-10" style="padding:4px 0 0" *ngIf="registerForm.get('passwordGroup.password').errors">
                        Password must contain one letter, number & special characters
                    </span>
              </div>


            </div>
            <br>



            <div [ngClass]="{'has-error': registerForm.get('passwordGroup.retypepass').invalid && registerForm.get('passwordGroup.retypepass').dirty}">
              <label for="retypepass">Retype</label>
              <input type="password" class="user-input-text" [formControl]="retypepass" id="retypepass" placeholder="Retype Password">
              <div class="text-danger" *ngIf="(registerForm.get('passwordGroup.retypepass').touched || registerForm.get('passwordGroup.retypepass').dirty) && (registerForm.get('passwordGroup.retypepass').errors || registerForm.get('passwordGroup').errors)">
                <span class="col-sm-10" style="padding:4px 0 0" *ngIf='registerForm.get("passwordGroup.retypepass").errors'>
                        Please confirm your password
                    </span>
                <span class="col-sm-10" style="padding:4px 0 0" *ngIf="registerForm.get('passwordGroup').errors">
                        Password do not match
                    </span>
              </div>
            </div>

          </div>

          <div class="col-md-4" hidden>
            <label for="roles">Roles</label>
            <select class="user-input-select"  name="roles" [formControl]="roles"  id="group_name" (onselect)="passsword($event, roles_list)" id="myOptions">
              <option value="" disabled>Select one--</option>
              <option *ngFor="let roles_list of roles_lists; let i = index;" id="roles{{i}}" >{{roles_list}}</option>
            </select>
          </div>




          <br>
          <br><br><br><br>

          <div class="text-center">
            <button type="submit" [disabled]="registerForm.invalid" class="ycc-button button-search">Update</button>
            <button (click)="cancelWasClicked()" class="ycc-button button-clear">Cancel</button>
          </div>

        </div>
      </div>


    </form>
  </div>
</div>
