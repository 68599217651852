<div>
  <ngx-datatable
    class="material"
    [rows]="rows"
    [columnMode]="'standard'"
    [columns]="columns"
    [headerHeight]="50"
    [selected]="selectedRows"
    [selectionType]="'SelectionType.checkbox'"
    [rowHeight]="50"
    [scrollbarV]="true">
  </ngx-datatable>
</div>
