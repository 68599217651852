<div class="printSection" id="printSection" #printSection>
    <div id="body">
        <div class="row">
            <section class="col-md-11 nPr nPl">
                <div *ngIf="!pickPrintDetails; else printContainer">Loading... </div>
                <ng-template #printContainer>
                    <div class="container">
                        <div class="row">
                            &nbsp;
                        </div>
                        <div class="row">
                            &nbsp;
                        </div>
                        <div class="row">
                            &nbsp;
                        </div>
                        <div class="row">
                            &nbsp;
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-sm-6 col-xs-6">
                                <img src="assets/images/logo.png" class="img-responsive" style=" height: 100px;"><br/>
                                <div class="text-center">
                                    <p>Cabot Park, Poplar Way East, Avonmouth, Bristol, BS11 0YH. </p>
                                    <p>{{custserv}}: +44 (0) 117 316 1260 | <a href="#">consumeronline@yankeecandle.co.uk</a></p>
                                </div>
                                <div class="text-center social">
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <p><span class="fa fa-twitter"></span> @yankeecandleeu</p>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <p><span class="fa fa-facebook-square"></span> TheYankeeCandleCompanyUK</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-6 ">
                                <div class="row">
                                    <div class="col-md-4 col-sm-4 col-xs-6">
                                        <p>{{page}}</p>
                                        <p>1</p>
                                    </div>
                                    <div class="col-md-8 col-sm-8 col-xs-6">
                                        <p>{{date}}</p>
                                        <p>{{getToday()}}</p>
                                    </div>
                                </div>
                                <table class="table billing text-center mtop">
                                    <thead>
                                        <tr>
                                            <th class="text-center width50 ">{{Refno}}</th>
                                            <th class="text-center ">{{orderNo}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{pickPrintDetails.order_details.shipment_group_id}}</td>
                                            <td>{{pickPrintDetails.order_details.order_id}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row" id="bill-delivery">
                            <div class="mtop billdetails" style="width:210px;float:left;margin-left:15px;">
                                <table class="table billing" id="bill-to">
                                    <thead>
                                        <tr>
                                            <th class="billhead">{{billTO}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p>{{pickPrintDetails.order_details.cust_firstName}} &nbsp;&nbsp;&nbsp;&nbsp;{{pickPrintDetails.order_details.cust_lastName}} 
                                                 </p>
                                                <p>{{pickPrintDetails.order_details.cust_address_line_1}} {{pickPrintDetails.order_details.cust_address_line_2}} {{pickPrintDetails.order_details.cust_address_line_3}}
                                                </p>
                                                <p>{{pickPrintDetails.order_details.cust_town}} {{pickPrintDetails.order_details.cust_county}}</p>
                                                <p>{{pickPrintDetails.order_details.cust_country}} {{pickPrintDetails.order_details.cust_postcode}}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="mtop shipdetails" style="width:210px;float:left;margin-left:10px;">
                                <table class="table billing" id="delivery-to">
                                    <thead>
                                        <tr>
                                            <th class="billhead">{{deliverTO}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p>{{pickPrintDetails.order_details.shipTo_cust_firstName}} &nbsp;&nbsp;&nbsp;&nbsp;{{pickPrintDetails.order_details.shipTo_cust_lastName}} 
                                                 </p>   
                                                <p>{{pickPrintDetails.order_details.shipTo_cust_address_line_1}} {{pickPrintDetails.order_details.shipTo_cust_address_line_2}} {{pickPrintDetails.order_details.shipTo_cust_address_line_3}}
                                                </p>
                                                <p>{{pickPrintDetails.order_details.shipTo_cust_town}} {{pickPrintDetails.order_details.shipTo_cust_county}}</p>
                                                <p>{{pickPrintDetails.order_details.shipTo_cust_country}} {{pickPrintDetails.order_details.shipTo_cust_postcode}}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="mtop" style="width:210px;float:left;margin-left:10px;">
                                <table class="table billing text-center mbtm0">
                                    <thead>
                                        <tr>
                                            <th class="billhead">{{deliverVIA}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td height="97" *ngIf="shipmentType==='UPS'"><br/>UPS</td>
                                            <td height="97" *ngIf="shipmentType==='DPD Local'"><br/>{{deliverVIAData}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <table class="table billing text-center ">
                                    <thead>
                                        <tr>
                                            <th class="text-center ">{{QtyOrdered}}</th>
                                            <th class="text-center ">{{QtyShipped}}</th>
                                            <th class="text-center ">{{itemShipped}}</th>
                                            <th class="text-center ">{{itemDescription}}</th>
                                            <th class="text-center ">{{imageID}}</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let orderItem of pickPrintDetails.order_line_items">
                                        <tr>
                                            <td width="100">{{orderItem.quantity}}</td>                                            
                                            <td width="100">{{orderItem.order_status === 'Shipped' ? orderItem.shipped_quantity : orderItem.quantity_confirmed}}</td>
                                            <td width="180">{{orderItem.sku}}</td>
                                            <td>{{orderItem.sku_desc}}</td>
                                            <td width="100">{{orderItem.imageId}}</td>
                                        </tr>
                                        <tr *ngIf = 'orderItem.gift_box_sku_id!= "" && orderItem.gift_box_sku_id!= null'>
                                            <td width="100">{{orderItem.quantity}}</td>  
                                            <td width="100">{{orderItem.order_status === 'Shipped' ? orderItem.shipped_quantity : orderItem.quantity_confirmed}}</td>                                           
                                            <td width="180">{{orderItem.gift_box_sku_id}}</td>
                                            <td>{{orderItem.gift_box_sku_description}}</td>
                                            <td width="100">{{orderItem.imageId}}</td>
                                        </tr> 


                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row col-md-12">
                            <div class="col-md-9 col-sm-9 col-xs-12">
                                <h3>{{returnExchange}}</h3>
                                <p>{{rep1}} </p>
                                <p>{{rep2}}</p>
                                <p>{{rep3}} </p>
                                <p>{{rep4}} </p>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-12">
                                <h3>{{refund}}</h3>
                                <p>{{ref1}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12 mtop">
                                <table class="table billing text-center ">
                                    <tr>
                                        <td colspan="4">{{returnItems}}</td>
                                    </tr>
                                    <tr>
                                        <th class="text-center " width="150">{{itemNo}}</th>
                                        <th class="text-center">{{itemDescription}}</th>
                                        <th class="text-center " width="150">{{QTY}}.</th>
                                        <th class="text-center " width="150">{{reasonCode}}</th>
                                    </tr>
                                    <tbody>
                                        <tr style="border: 1px solid ">
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr style="border: 1px solid ">
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12 text-center">{{thank}}</div>
                        </div>
                        <div class="row">
                            <div class="text-center social">
                                <p>{{share}}</p>
                                <div class="col-md-3 col-sm-6 col-xs-12">
                                     <p><span class="fa fa-facebook-square"></span> TheYankeeCandleCompanyUK</p>
                                </div>
                                <div class="col-md-3 col-sm-6 col-xs-12">
                                   <p><span class="fa fa-twitter"></span> @yankeecandleeu</p>
                                </div>
                                <div class="col-md-3 col-sm-6 col-xs-12">
                                    <p><span class="fa fa-instagram"></span> @yankeecandleeu</p>
                                </div>
                                <div class="col-md-3 col-sm-6 col-xs-12">
                                    <p><span class="fa fa-pinterest"></span> TheYankeeCandleCompanyUK</p>
                                </div>
                            </div>
                        </div>
                        <div class="row text-center">
                            <p>{{link}}</p>
                        </div>
                    </div>
                    <!--container -->
                </ng-template>
            </section>
        </div>
    </div>
</div>