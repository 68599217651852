import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormControl, FormArray } from '@angular/forms';
import * as moment from 'moment';
import { DataService } from "../../shared/service/data.service";
//import { SharedApplicationService } from "../../shared/service/shared.service"
import { SharedApplicationService } from '../../shared/service/shared.service'
import { SearchDataList } from '../../shared/model/searchDataList.model';

@Component({
  selector: 'app-not-synced-ship',
  templateUrl: './not-synced-ship.component.html',
  styleUrls: ['./not-synced-ship.component.css']
})
export class NotSyncedShipComponent implements OnInit {

  constructor(private dataservice: DataService,public sharedApplicationService:SharedApplicationService) {
    
      }
      public myForm: FormGroup;
    
      ngOnInit() {
        this.myForm = new FormGroup({
          'FromDate': new FormControl(),
          'ToDate': new FormControl(),
          'commodity': new FormControl()
        });
        this._onload()
      }
      
      crstoDate
      crsfromDate
      dropDownDataList: SearchDataList;
      //region: string;
      commodityGroup:any;
      
      _onload() {
        let url = `/api/searchDataList`;
        //this.region = this.sharedApplicationService.getRegion();
        this.sharedApplicationService.httpGetService(url)
          .then(
          (data: SearchDataList) => {
                this.dropDownDataList = data;
                let tempCountryList = [];
                for (let key in data.datalist.country[0]) {
                    tempCountryList.push({ id: key, name: data.datalist.country[0][key] });
                }
                this.dropDownDataList.datalist.new_country = tempCountryList;
                this.dropDownDataList.datalist.new_sku = data.datalist.sku.map((str) => {
                    return { id: str, name: str }
                })
                this.dropDownDataList.datalist.new_sku_description = data.datalist.sku_description.map((str) => {
                    return { id: str, name: str }
                })
                this.commodityGroup=this.dropDownDataList.datalist.product_form;
                this.dropDownDataList.datalist.order_status = this.dropDownDataList.datalist.order_status// ["OnHold", "Pending", "Approved", "Rejected"]
            },
            (err) => console.log(err)
            )
        var fromdatenow = new Date()
        fromdatenow.setHours(0)
        fromdatenow.setMinutes(0)
        fromdatenow.setSeconds(0)
        fromdatenow.setMilliseconds(0)
    
        var todatenow = new Date()
        todatenow.setHours(23)
        todatenow.setMinutes(59)
        todatenow.setSeconds(0)
        todatenow.setMilliseconds(0)
        this.crstoDate = todatenow.toString()
        this.crsfromDate = fromdatenow.toString()
    
    
        this.myForm.setValue({
          commodity: "All",
          FromDate: '',
          ToDate: ''
    
        })
        this.getcrsreportdata(this.crsfromDate, this.crstoDate,'All');
      }
      fromToDateMismatch: boolean = false;
      checkDateValidity(from, to): boolean {
        if (from && to && from > to) {
            return false;
        }
        //returning true by default
        return true;
    }
    
    dateSelectedcrs(e, field) {
        if(field === "from") {
            this.fromDate = e;
        } else {
            this.toDate = e;
        }
        if(typeof(this.fromDate)=="string"&&this.fromDate!="")
          this.fromDate=new Date(this.fromDate)
        else if(typeof(this.toDate)=="string"&&this.toDate!="")
          this.toDate=new Date(this.toDate)
        this.fromToDateMismatch = !this.checkDateValidity(this.fromDate, this.toDate);
    }
    getcrsreportdata(fromdate, todate,commodity) {
      fromdate = moment.parseZone(fromdate).format("YYYY-MM-DDTHH:mm:ss")
      todate = moment.parseZone(todate).format("YYYY-MM-DDTHH:mm:ss")
      
        this.dataservice.GetPostMethod("notsyncedship?startDate=" + fromdate +
         "&endDate=" + todate + "&commodity="+commodity+"&user=" + localStorage.getItem('Username') + "", "")
          .subscribe(Response => {
            if (Response["success"] == "true") {
              this.rows=Response["message"]
              this.showtable=true
              this.OrdersArray=Response["message"]
            }
            else {
              
            }
          })
      }
    
    
      public fromDate: any;
      public toDate: any;
      public OrdersArray: any;
      public showtable = false;
      public commodity: any;
      rows = [];
      columns = [
        { name: 'Web Order ID', flexGrow: 2, prop: 'web_order_id' },
        { name: 'Order ID', flexGrow: 2, prop: 'order_id' },
        { name: 'Ordered Date', flexGrow: 2, prop: 'order_submit_date' },
        { name: 'Sku', flexGrow: 2, prop: 'sku' },                    
        { name: 'Shipped Date', flexGrow: 2, prop: 'shipped_date' },
        { name: 'Order Status', flexGrow: 2, prop: 'order_status' },
        { name: 'Synced', flexGrow: 1, prop: 'Sync' },
        { name: 'Total Quantity', flexGrow: 2.5, prop: 'line_item_quantity' },
      ];
    
      Getdetails(myForm: any) {        
        var frmdate = ""
        this.fromDate = moment.parseZone(myForm.controls.FromDate.value).format("YYYY-MM-DDTHH:mm:ss")
        this.toDate = moment.parseZone(myForm.controls.ToDate.value).format("YYYY-MM-DDTHH:mm:ss")
        this.commodity = myForm.controls.commodity.value;
        if (this.fromDate == "Invalid date" || this.toDate == "Invalid date") {
          alert("Please fill")
          return false
        }
        this.getcrsreportdata(this.fromDate, this.toDate,this.commodity)
    
      }
      resetform() {
        this.showtable = false;
      }
      exportcsv(JsonData): any {
        let today = new Date();
        var summary = 'Web Order ID, Order ID, Ordered Date,Sku ,Shipped Date, Order Status, Synced, Total Quantity\n';
        JsonData.forEach(function (row) {
          summary += row.web_order_id + ',' + row.order_id + ','+ row.order_submit_date + ',' + row.sku + ','+ row.shipped_date + ','
          + row.order_status + ','+ row.Sync + ','+ row.line_item_quantity + ','; //+ row.order_submit_date + ','+ row.embellishment + ','+ row.finishing_touch + ',';
          summary += "\n";
        });
        var csvElement = document.createElement('a');
        csvElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(summary);
        csvElement.target = '_blank';
        csvElement.download = 'NotSync_Report_' + today + '.csv';
        csvElement.click();
      }

    }
