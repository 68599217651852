export class languages{

    english = {
        date  : "Date",
        page : "Page",
        Refno  : "Reference Number",
        orderNo  : "Order Number",
        custserv  : "Customer Service:",
        billTO  : "BILL TO",
        deliverTO  : "DELIVER TO",
        deliverVIA  : "DELIVER VIA",
        deliverVIAData  : "DPD Local – Home Delivery",
        QtyOrdered  : "Qty Ordered",
        QtyShipped  : "Qty Shipped",
        itemShipped  : "Item Shipped",
        itemDescription  : "Item Description",
        imageID  : "ImageID",
        returnExchange  : "Return & Exchanges",
        rep1  : "1. Enter the items you are returning in the chart below.",
        rep2  : "2. Provide a Reason Code for each item returned: 01 Wrong Item 02 Damaged or Defective 03 Poor Quality.",
        rep3  : "3. Provide the phone number(in case there is a question): __________",
        rep4  : "4. Return this completed form with the item using this return address: Yankee Candle Personalised Candle Returns, Cabot Park, Poplar Way East, Avonmouth, Bristol, BS11 0YH",
        refund  : "Refunds",
        ref1  : "Refund will be issued by your original payment method within 14 days of us receiving the goods.",
        returnItems  : "Items being returned",
        itemNo  : "Item Number",
        reasonCode  : "Reason Code",
        QTY  : "Qty.",
        link  : "www.yankeecandle.co.uk/personaliseCandle",
        thank  : "Thank you for shopping with us!",
        share  : "Share us On:"
        
    }
    
    italy = {
        date  : "Data",
        page : "Pagina",
        Refno  : "Numero di riferimento",
        orderNo  : "Numero d'ordine",
        custserv  : "Servizio clienti",
        billTO  : "INDIRIZZO DI FATTURAZIONE",
        deliverTO  : "INDIRIZZO DI SPEDIZIONE",
        deliverVIA  : "CONSEGNA CON",
        deliverVIAData  : "Spedizione DPD Express Internazionale ",
        QtyOrdered  : "Qtà ordinata",
        QtyShipped  : "Qtà spedita",
        itemShipped  : "Articolo spedito",
        itemDescription  : "Descrizione articolo",
        imageID  : "Immagine di indentificazione ",
        returnExchange  : "Resi e Sostituzioni ",
        rep1  : "1. Inserisci gli articoli che stai restituendo nella tabella sottostante.",
        rep2  : "2. Fornisci una motivazione per ogni articolo restituito: 01 Articolo errato 02 Articolo Danneggiato o difettoso 03 Scarsa qualità.",
        rep3  : "3. Fornisci un numero di telefono (nel caso di necessità di ulteriori informazioni): __________",
        rep4  : "4. Restituisci questo modulocon il tuo articolo, al seguente indirizzo : Yankee Candle Personalised Candle Returns, Cabot Park, Poplar Way East, Avonmouth, Bristol, BS11 0YH",
        refund  : "Rimborsi",
        ref1  : "Il rimborso verrà emesso attraverso il metodo di pagamento originale, entro 14 giorni dalla ricezione dei vostri articoli ",
        returnItems  : "Articoli resi",
        itemNo  : "Numero dell'articolo ",
        reasonCode  : "Motivazione",
        QTY  : "Qtà",
        link  : "www.yankeecandle.it/personaliseCandle",
        thank  : "Grazie per il tuo acquisto!",
        share  : "Condividi su:"
        
    }

    german = {
        date  : "Datum",
        page : "Seite",
        Refno  : "Referenznummer",
        orderNo  : "Bestellnummer",
        custserv  : "Kundenservice:",
        billTO  : "RECHNUNG AN",
        deliverTO  : "VERSAND AN",
        deliverVIA  : "VERSAND ÜBER",
        deliverVIAData  : "DPD International – Hauszustellung",
        QtyOrdered  : "Bestellte Menge",
        QtyShipped  : "Versendete Menge",
        itemShipped  : "Versendeter Artikel",
        itemDescription  : "Artikelbeschreibung",
        imageID  : "Bildnr.",
        returnExchange  : "Rückgabe und Umtausch",
        rep1  : "1. Tragen Sie bitte die Artikel, die Sie zurücksenden, in die Liste unten ein.",
        rep2  : "2. Geben Sie einen Begründungscode für jeden zurückgesendeten Artikel ein: 01 Falscher Artikel 02 Beschädigt oder fehlerhaft 03 Schlechte Qualität.",
        rep3  : "3. Geben Sie eine Telefonnummer (für Rückfragen) an: __________",
        rep4  : "4. Senden Sie dieses ausgefüllte Formular zusammen mit dem Artikel an folgende Adresse zurück: Yankee Candle Personalised Candle Returns, Cabot Park, Poplar Way East, Avonmouth, Bristol, BS11 0YH",
        refund  : "Rückerstattung",
        ref1  : "Die Rückerstattung erfolgt über Ihre ursprüngliche Zahlungsmethode innerhalb von 14 Tagen, nachdem wir die Ware erhalten haben",
        returnItems  : "Zurückgesendete Artikel",
        itemNo  : "Artikelnummer",
        reasonCode  : "Begründungscode",
        QTY  : "Menge",
        link  : "www.yankeecandle.de/personaliseCandle",
        thank  : "Vielen Dank für Ihren Einkauf!",
        share  : "Teilen Sie uns auf:"

    }
 
    french = {
        date  : "Date",
        page : "Page",
        Refno  : "Numéro de référence",
        orderNo  : "Numéro de commande",
        custserv  : "Service clients :",
        billTO  : "ADRESSE DE FACTURATION",
        deliverTO  : "ADRESSE DE LIVRAISON",
        deliverVIA  : "LIVRAISON PAR",
        deliverVIAData  : "DPD international - Livraison à domicile",
        QtyOrdered  : "Qté commandée",
        QtyShipped  : "Qté envoyée",
        itemShipped  : "Article envoyé",
        itemDescription  : "Description de l'article",
        imageID  : "Nom de l'image",
        returnExchange  : "Retours & échanges",
        rep1  : "1. Entrez les articles que vous retournez dans le tableau ci-dessous.",
        rep2  : "2. Entrez le code correspondant au motif du retour, pour chaque article retourné : 01 Mauvais article reçu 02 Article endommagé ou défectueux 03 Problème de qualité.",
        rep3  : "3. Veuillez indiquer un numéro de téléphone auquel nous pouvons vous joindre en cas de question __________",
        rep4  : "4. Retournez l'article ainsi que ce formulaire complété, à l'adresse suivante : Yankee Candle Personalised Candle Returns, Cabot Park, Poplar Way East, Avonmouth, Bristol, BS11 0YH",
        refund  : "Remboursements",
        ref1  : "Le remboursement sera effectué sur le moyen de paiement utilisé lors de la commande et dans les 14 jours suivant la réception de votre retour.",
        returnItems  : "Articles retournés",
        itemNo  : "Référence de l'article",
        reasonCode  : "Code raison",
        QTY  : "Qté",
        link  : "www.yankeecandlefrance.com/personaliseCandle",
        thank  : "Merci d'avoir commandé sur notre site !",
        share  : "Partagez sur :"

    }
    
}