<div class="row">
  <div class="col-md-11">
    <br>
    <form [formGroup]="myForm" (ngSubmit)="Getdetails(myForm)">
      <div class="col-md-3 formgroup datewrapper">
        <label class="pull-left">From Date:</label>
        <!-- <owl-date-time [autoClose]="true" [dateFormat]="'DD/MM/YYYY HH:mm'" [placeHolder]="'DD/MM/YYYY HH:mm'" formControlName="FromDate"
          [ngModel]="fromDate" [inputId]="'FromDate'" (ngModelChange)="dateSelectedprocessing($event, 'from')">
        </owl-date-time> -->
        <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeHolder="'MM/DD/YYYY HH:mm'"
          formControlName="FromDate" [ngModel]="fromDate" name="FromDate"
          (ngModelChange)="dateSelectedprocessing($event, 'from')">
        <owl-date-time #dt1></owl-date-time>
      </div>
      <div class="col-md-3 formgroup datewrapper">
        <label class="pull-left">To Date</label>
        <!-- <owl-date-time [max]="maxDate" [placeHolder]="'DD/MM/YYYY HH:mm'" [autoClose]="true" [dateFormat]="'DD/MM/YYYY HH:mm'" formControlName="ToDate"
          [ngModel]="toDate" name="ToDate" (ngModelChange)="dateSelectedprocessing($event, 'to')">
        </owl-date-time> -->
        <input [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" placeHolder="'MM/DD/YYYY HH:mm'" formControlName="ToDate"
          formControlName="ToDate" [ngModel]="toDate" name="ToDate"
          (ngModelChange)="dateSelectedprocessing($event, 'to')">
        <owl-date-time #dt2></owl-date-time>
        <span class="date-error" *ngIf="fromToDateMismatch">'From Date' should be earlier than 'To date'</span>
      </div>
      <div class="col-md-3 order-status">
        <div class="formgroup">
          <label>Order Status</label>
          <select class="user-input-select" name="order_status" formControlName="order_status">
            <option *ngFor="let status of OrderstatusGroup" [value]="status" [selected]="status ==order_status">
              {{status}}</option>
          </select>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="col-sm-2 country">
        <div class="formgroup">
          <label>Country</label>
          <select class="user-input-select" name="country" formControlName="country">
            <option *ngFor="let country of countries" [value]="country" [selected]="country ==country">
              {{country}}</option>
          </select>
        </div>
      </div>      
      <div class="col-md-3">
        <div class="formgroup">
          <label>Age Group</label>
          <select class="user-input-select" name="agegroup" formControlName="agegroup">
            <option selected="selected" value="All">All</option>
            <option *ngFor="let age of ageGroup" [value]="age.value">{{age.text}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-2 formgroup submit">
        <button type="submit">Go</button> &nbsp;
        <button type="reset" (click)="resetform()">Clear</button>
      </div>
    </form>
  </div>
  <div class="clearfix"></div>
  <div class="col-md-12">
    <div style="padding-top: 10px;">
      <div *ngIf="isLoading else loaded" class="loading">

      </div>
      <ng-template #loaded>
        <div *ngIf="showtable">
          <button type="button" class="pull-right exportcsv" (click)="exportcsv(OrdersArray)">Export Excel</button>
          <div class="totalcount">
            <div><strong>Total Amount: {{summaryInfo.TotalAmountCount}}</strong></div>
            <div><strong>Total Quantity: {{summaryInfo.TotalQuntityCount}}</strong></div>
            <div><strong>Total Orders: {{summaryInfo.TotalOrders}}</strong></div>
            <ngx-datatable class="material" [columnMode]="'flex'" [columns]="columns" [headerHeight]="50"
              [selectionType]="'button'" [loadingIndicator]="loading" [rowHeight]="50" [scrollbarV]="true" [rows]="rows"
              [columns]="columns">
            </ngx-datatable>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>