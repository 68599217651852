<br><br><br>

<div class="buttonContainer container">
<button class="addUserButton"  *ngIf="accessTabOpen && !onAccessEdit" (click)="setAccessButtonClicked(false); tabAccessWasOpen(false)">+Add Access</button>
<button class="addUserButton" *ngIf="!accessTabOpen && !onAccessEdit" (click)="setAccessButtonClicked(true); tabAccessWasOpen(true)">+Find Access</button>
</div>

<app-add-access *ngIf="!buttonAccessWasClicked && !onAccessEdit"></app-add-access>
<app-edit-access  *ngIf="onAccessEdit" [accessid]="currentAccess" (cancelAccessClicked)="cancelAccessButtonClicked(false)"></app-edit-access>
<app-find-access *ngIf="buttonAccessWasClicked && !onAccessEdit" (onAccessEdit)="editAndy($event)" (editAccessClicked)="editAccessButtonClicked(true)"></app-find-access>
