import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
// import { Http, Response, Headers, RequestOptions } from '@angular/http';
// import { MatDialogModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../user/auth.service';
import { ToastrService } from '../../common/toastr.service';
import { TagService } from '../../user/tag.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './error-password.component.html',
    styleUrls: ['./error-password.component.css']
})
export class ErrorPasswordComponent implements OnInit {
    tag = {
        version: '',
        env: '',
        region: ''
    };
    constructor(public tagService: TagService) { }
    ngOnInit() {
        this.tagService.getTag().subscribe(tag =>
            this.tag = tag["data"]
        );
    }

}