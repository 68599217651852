import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter3'
})
export class Filter3Pipe implements PipeTransform {

  transform(accesses: any, term31: string, term32: string, term33: string){

      if (accesses && accesses.length){
          return accesses.filter(access =>{
              if (term31 && access.name.toLowerCase().indexOf(term31.toLowerCase()) === -1){
                  return false;
              }
              if (term32 && access.description.toLowerCase().indexOf(term32.toLowerCase()) === -1){
                  return false;
              }
              if (term33 && access.type.toLowerCase().indexOf(term33.toLowerCase()) === -1){
                  return false;
              }
              return true;
         })
      }
      else{
          return accesses;
      }
  }

}
