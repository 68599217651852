<div style="margin-left: 30px">

    <div class="row" style="margin-top:70px; padding-bottom:10px;">
        <div class="col-md-11">
            <!-- <h3> Order Status Graph </h3> -->
            <div class="col-md-4 col-md-offset-8 text-right togglebtn">
                <button class="statusBtn" (click)="showGraphFun(false)"><img
                        src="../../../assets/images/order-status.png" alt="Order Status" /></button>
                <button class="statusBtn" (click)="showGraphFun(true)"><img src="../../../assets/images/bar-chart.png"
                        alt="bar chart" /></button>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="showGraph">
        <div class="col-md-11">
            <div class="top-header">Search</div>
            <h3 class="search-line">Show Order Details Graph</h3>
            <form [formGroup]="reportForm">
                <div class="col-md-3">
                    <label class="pull-left">From Date</label>
                    <!-- <owl-date-time [autoClose]="true" [dateFormat]="'DD/MM/YYYY HH:mm'" 
                    [placeHolder]="'DD/MM/YYYY HH:mm'"
                    formControlName="fromdate"
                     [ngModel]="FromDate"
                     name="FromDate"
                     (ngModelChange)="dateSelectedsales($event, 'from')"
                     >
                   </owl-date-time> -->
                    <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeHolder="'DD/MM/YYYY HH:mm'"
                        formControlName="fromdate" [ngModel]="FromDate" name="FromDate"
                        (ngModelChange)="dateSelectedsales($event, 'from')">
                    <owl-date-time #dt1></owl-date-time>
                    <!-- <md-form-field class="example-full-width">
                        <input mdInput [min]="minDate" (dateInput)="fromDateInput($event, 'from')"
                               formControlName="fromdate" [max]="maxDate" [mdDatepicker]="fromdate"
                               placeholder="Choose From Date" readonly>
                        <md-datepicker-toggle mdSuffix [for]="fromdate" ></md-datepicker-toggle>
                        <md-datepicker #fromdate></md-datepicker>
                    </md-form-field> -->
                </div>
                <div class="col-md-3">
                    <label class="pull-left">To Date</label>
                    <!-- <md-form-field class="example-full-width">
                        <input mdInput [min]="minDate" (dateInput)="toDateInput($event, 'from')"
                               formControlName="todate" [max]="maxDate" [mdDatepicker]="todate"
                               placeholder="Choose To Date" readonly>
                        <md-datepicker-toggle mdSuffix [for]="todate"></md-datepicker-toggle>
                        <md-datepicker #todate></md-datepicker>
                    </md-form-field> -->
                    <!-- <owl-date-time [autoClose]="true" [dateFormat]="'DD/MM/YYYY HH:mm'"
                        [placeHolder]="'DD/MM/YYYY HH:mm'" formControlName="todate" [ngModel]="ToDate" name="ToDate"
                        (ngModelChange)="dateSelectedsales($event, 'to')">
                    </owl-date-time> -->
                    <input [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" placeHolder="'DD/MM/YYYY HH:mm'"
                        formControlName="todate" [ngModel]="ToDate" name="ToDate"
                        (ngModelChange)="dateSelectedsales($event, 'to')">
                    <owl-date-time #dt2></owl-date-time>
                    <span class="date-error" *ngIf="fromToDateMismatch">'From Date' should be earlier than 'To
                        date'</span>
                </div>
                <div class="col-md-3">
                    <button type="button" (click)="getchartbardata()" class="button">Show Details</button>
                </div>
                <div class="col-md-3 totalcount" *ngIf="OrdersArray">
                    <!-- <p><strong>Total Pounds: <span>{{OrdersArray.TotalPrice.Pound}}</span></strong></p>
                    <p><strong>Total Euros: <span>{{OrdersArray.TotalPrice.Euro}}</span></strong></p> -->
                </div>
                <div *ngIf="showErrors" class="col-md-12 error">
                    Please select from and to dates
                </div>
            </form>
        </div>
        <div class="clearfix"></div>
        <div class="col-md-10 col-lg-offset-1">
            <div style="padding-top: 100px;">
                <div style="display: block" *ngIf="isSearchGraphToggle">
                    <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                        [legend]="barChartLegend" [chartType]="barChartType"></canvas>
                </div>

            </div>
        </div>
    </div>

    <div class="StatusWrapper">
        <div class="row" *ngIf="!showGraph">
            <div class="col-md-12">
                <div class="row">
                    <div>
                        <div class="controlPanel">
                            <order-search *ngIf="isSearchAndDetailToggle" [(searchQuery)]="searchparams"
                                [dropDownList]="dropDownDataList" (clearClick)="resetSearch();"
                                (searchClick)="searchOrders()"></order-search>
                            <quick-filter *ngIf="isSearchAndDetailToggle" [quickFilters]="quickFilters"
                                (quickFiltersChange)="filterChanged($event)"></quick-filter>

                        </div>

                        <div class="resultPanel container nopadding">

                            <div *ngIf="searchResults" id="top-pagination-list">
                                <paginator [isLoading]="isLoading" [searchResults]="searchResults"
                                    [pageNumCache]="searchparams.pageno" [(pageNum)]="searchparams.pageno"
                                    [(pageSize)]="searchparams.pagesize"
                                    (paginationChange)="searchOnPaginationchange($event);" [noGridView]="true"
                                    [noSelectText]="true">
                                    <div searchdisplay>
                                        <div class="order-grid-containter">

                                            <ngx-datatable (activate)='onUserEvent($event)' class="material"
                                                [rows]="searchResults.orders" [columnMode]="'flex'" [columns]="columns"
                                                [headerHeight]="50" [loadingIndicator]="loading" [rowHeight]="50"
                                                [scrollbarV]="true">
                                            </ngx-datatable>
                                        </div>
                                    </div>
                                </paginator>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="noOrderfound">
                        <p class="noOrder">No order found</p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>