import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormControl, FormArray } from '@angular/forms';
import * as moment from 'moment';
import { DataService } from "../../shared/service/data.service"

@Component({
  selector: 'app-shiftproduction',
  templateUrl: './shiftproduction.component.html',
  styleUrls: ['./shiftproduction.component.css']
})
export class ShiftproductionComponent implements OnInit {

  constructor(private dataservice: DataService) {

  }
  public myForm: FormGroup;

  ngOnInit() {
    this.myForm = new FormGroup({
      'FromDate': new FormControl(),
      'ToDate': new FormControl(),
      'order_status': new FormControl()
    });
    this._onload()
  }
  _onload() {
    var fromdatenow = new Date()
    fromdatenow.setHours(0)
    fromdatenow.setMinutes(0)
    fromdatenow.setSeconds(0)
    fromdatenow.setMilliseconds(0)

    var todatenow = new Date()
    todatenow.setHours(23)
    todatenow.setMinutes(59)
    todatenow.setSeconds(0)
    todatenow.setMilliseconds(0)
    this.toDate = todatenow
    this.fromDate = fromdatenow


    this.myForm.setValue({
      order_status: "All",
      FromDate: '',
      ToDate: ''

    })
    this.getshiftdata(this.fromDate, this.toDate, "All");
  }
  fromToDateMismatch: boolean = false;
  checkDateValidity(from, to): boolean {
    if (from && to && from > to) {
        return false;
    }
    //returning true by default
    return true;
}

dateSelectedshift(e, field) {
    if(field === "from") {
        this.fromDate = e;
    } else {
        this.toDate = e;
    }
    this.fromToDateMismatch = !this.checkDateValidity(this.fromDate, this.toDate);
}
  getshiftdata(fromdate, todate, order_status) {
    this.dataservice.GetPostMethod("shiftproduction/shift?user=" + localStorage.getItem('Username') + "", "")
      .subscribe(Response => {
        if (Response["success"] == "true") {
          this.getshiftorders(Response["message"], fromdate, todate, order_status)
        }
        else {

        }
      })
  }

  getshiftorders(data, fromdate, todate, orderstatus) {
    var _data = [];
    var shiftname = "";
    for (let i = 0; i < data.length; i++) {
      shiftname = data[i]["shift_name"]
      var shiftFrmtime = data[i]["from_time"];
      var shiftTotime = data[i]["to_time"];
      

      var _shiftTotime = moment.parseZone(todate).format("YYYY-MM-DDTHH:mm:ss")
       var _shiftFrmtime = moment.parseZone(fromdate).format("YYYY-MM-DDTHH:mm:ss")

      var _fromdate = moment.parseZone(fromdate).format("YYYY-MM-DDT")
      shiftFrmtime = moment(shiftFrmtime, "h:mm A").format("HH:mm")
      shiftFrmtime = moment(_fromdate + '' + shiftFrmtime);
     

      var _todate = moment.parseZone(todate).format("YYYY-MM-DDT")
      shiftTotime = moment(shiftTotime, "h:mm A").format("HH:mm")
      shiftTotime = moment(_todate + '' + shiftTotime);
      
      

      // var _datefrm = new Date(shiftFrmtime)
      // var _dateto = new Date(shiftTotime)

      var x=new Date(shiftFrmtime)
      var _datefrm =x.getHours()
      var shiftFrmtimemm=x.getMinutes()
      _datefrm=_datefrm*60+shiftFrmtimemm

      x=new Date(shiftTotime)
      var _dateto =x.getHours()
      var shiftTotimemm=x.getMinutes()
      _dateto=_dateto*60+shiftTotimemm
      // var temp;
      // if(shiftFrmtimemm>shiftTotimemm){
      //   temp=shiftTotimemm;
      //   shiftTotimemm=shiftFrmtimemm;
      //   shiftFrmtimemm=temp
      // }



      this.dataservice.GetPostMethod("shiftproduction?shiftname=" + shiftname + "&FromDate=" + _shiftFrmtime +
      "&ToDate=" + _shiftTotime + "&shiftFrmtime="+_datefrm+"&shiftTotime="+_dateto+"&shiftFrmtimemm="+shiftFrmtimemm+"&shiftTotimemm="+shiftTotimemm+"&Orderstatus=" + orderstatus + "&user=" + localStorage.getItem('Username') + "", "")
      .subscribe(Response => {
          if (Response["success"] == "true" && Response["message"].length > 0) {
            Response["message"].map(function (val, index) {
              _data.push(val)
            })
            this.OrdersArray = _data;
            this.showtable = true
            this.rows = _data
          }
          else {
            /* alert(Response["message"])
            this.fromDate = ''
            this.toDate = '' */
          }
        })
    }


  }

  public fromDate: any;
  public toDate: any;
  public OrdersArray: any;
  public showtable = false;
  public Orderstatus: any;
  public OrderstatusGroup: any = ['All', 'New', 'Pending', 'Approved', 'On-Hold', 'Rejected',
    'ReadyToPrint', 'Printed', 'QAVerified', 'QAReview',
    'Cancelled', 'Shipped', 'Returned']
  rows = [];
  columns = [
    { name: 'Shift Name', flexGrow: 1, prop: 'shiftname' },
    { name: 'Order Status', flexGrow: 1, prop: 'status' },
    { name: 'Orders Quantity', flexGrow: 1, prop: 'total' }
  ];

  Getdetails(myForm: any) {
    console.log('Registration successful.');
    //console.log(myForm);
    var frmdate = ""
    this.fromDate = moment.parseZone(myForm.controls.FromDate.value).format("YYYY-MM-DDTHH:mm:ss")
    this.toDate = moment.parseZone(myForm.controls.ToDate.value).format("YYYY-MM-DDTHH:mm:ss")
    this.Orderstatus = myForm.controls.order_status.value;
    if (this.fromDate == "Invalid date" || this.toDate == "Invalid date") {
      alert("Please fill")
      return false
    }
    this.getshiftdata(this.fromDate, this.toDate, this.Orderstatus)



  }
  resetform() {
    this.showtable = false;
  }
  exportcsv(JsonData): any {
    let today = new Date();
    var summary = 'Shift Name, Order Status , Orders Quantity \n';
    JsonData.forEach(function (row) {
      summary += row.shiftname + ',' + row.status + ',' + row.total + ',';
      summary += "\n";
    });
    var csvElement = document.createElement('a');
    csvElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(summary);
    csvElement.target = '_blank';
    csvElement.download = 'Shiftprod_Report_' + today + '.csv';
    csvElement.click();
  }

}
