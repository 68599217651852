<div class="controlPanel">
    <order-search *ngIf="isSearchAndDetailToggle" [(searchQuery)]="searchparams" [dropDownList]="dropDownDataList" (clearClick)="resetSearch();" (searchClick)="searchOrders()"></order-search>
    <quick-filter *ngIf="isSearchAndDetailToggle" [quickFilters]="quickFilters" (quickFiltersChange)="filterChanged($event)"></quick-filter>
    <pickticket-detail-view *ngIf="!isSearchAndDetailToggle" [rejectCodes]="dropDownDataList.datalist.rejected" [detailViewData]="detailViewData" [isInventoryMang]="false" (closeDetails)="closeDetailView($event)" (imgRegenerated)="imgRegenerated($event)"></pickticket-detail-view>
    <div class = "missing-barcode" *ngIf = "(missingBarcodeOrderInfo?.length>0)">        
        <p>
            <strong>
                <span class="glyphicon glyphicon-info-sign" aria-hidden="true"></span> Missing Barcode Orders are found on the search result. </strong>
        </p>        
</div> 
</div>

<div class="resultPanel container nopadding">
    <div *ngIf="searchResults" id="top-pagination-list">
        <paginator [isLoading]="isLoading" [searchResults]="searchResults" [pageNumCache]="searchparams.pageno" [(pageNum)]="searchparams.pageno" [(pageSize)]="searchparams.pagesize" (paginationChange)="searchOnPaginationchange($event);" (viewChange)="isListViewToggle = $event;"
            [isInventoryMangage]="false" [isLabelPrint]="true">
            <div searchdisplay>
                <div *ngIf="searchOrderDetailsResults.length; else No_Results">
                    <list-view *ngIf="isListViewToggle" [(dataList)]="searchOrderDetailsResults" (itemsSelected)="updateNumOfSelectedItem($event)" (recordClicked)="showDetailsView($event);" [searchQuery]="searchparams" (searchQueryChange)="callSort($event);" [disableCheckBoxForMissingReadyToPrintImages]="true"></list-view>
                    <grid-view *ngIf="!isListViewToggle" [(dataList)]="searchOrderDetailsResults" (itemsSelected)="updateNumOfSelectedItem($event)" (recordClicked)="showDetailsView($event);" [rejectCodes]="dropDownDataList.datalist.rejected" [disableCheckBoxForMissingReadyToPrintImages]="true"></grid-view>
                </div>
                <ng-template #No_Results>
                    <div class="text-center">No results to display</div>
                </ng-template>
            </div>
            <div actionbuttons class="action-buttons">
                <button type="button" class="approve-button pull-right" [disabled]="!numOfSelectedItem() || disablePrint" (click)="UpdateStatusAndPrintPRImage()">
                    Print ({{numOfSelectedItem()}})
                </button>
                <button type="button" class="approve-button pull-right download-btn" [disabled]="!numOfSelectedItem()" (click)="downloadPRImage()">
                    Download ({{numOfSelectedItem()}})
                </button>
                <button type="button" class="approve-button pull-right onHold-btn" [disabled]="!numOfSelectedItem()" (click)="onHoldPrint()">
                    On Hold ({{numOfSelectedItem()}})
                </button>
                <button type="button" class="approve-button pull-right regenerate-btn" [disabled]="!numOfSelectedItem()" (click)="bulkRegenarate(false)">
                    Re-generate ({{numOfSelectedItem()}})
                </button>
                <button type="button" class="approve-button pull-right generate-btn" disabled>
                    Generate EPS
                </button>
            </div>
        </paginator>
        <div *ngIf="isPrintLimitError" class="pull-right red">
            Maximum number of prints allowed is {{maxPrintSize}}
        </div>
        <div class = "barcode-error" *ngIf = "(missingBarcodeOrderInfo?.length>0)">        
            <p>
                <strong> Missing Barcode Orders are found on the search result. </strong>
            </p>        
        </div>
    </div>
</div>