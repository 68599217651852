<div class="detail-view-container">
    <div class="row">
        <div class="col-md-4 label-img-container">
            <img [src]="detailViewData && detailViewData?.preview_img" class="detailViewLabelImage" [class.active]="detailViewData && detailViewData?.preview_img" alt="Label image">
        </div>
        <div class="col-md-6 label-img-container">
            <img [src]="detailViewData && detailViewData?.barcode_img" class="barcodeLabelImage" alt="Barcode image">
        </div>
        <div class="col-md-4 customer-details-container">
            <div class="customer-input-container">
                <label>Order #</label>
                <input class="pull-right detail-view-inputs" type="text" name="order_id" [value]="detailViewData?.order_id" disabled>
            </div>
            <div class="customer-input-container">
                <label>Product Form</label>
                <input class="pull-right detail-view-inputs" type="text" name="product_form" [value]="detailViewData?.product_form" [title]="detailViewData?.product_form" disabled>
            </div>
            <div class="customer-input-container">
                <label>Description</label>
                <input class="pull-right detail-view-inputs" type="text" name="sku_desc" [value]="detailViewData?.sku_desc" [title]="detailViewData?.sku_desc" disabled>
            </div>
            <div class="customer-input-container">
                <label>SKU</label>
                <input class="pull-right detail-view-inputs" type="text" name="sku" [ngClass]="{'bg-green': skuMatch, 'bg-red': skuMismatch}" [value]="detailViewData?.sku" disabled>
            </div>
            <div class="customer-input-container">
                <label>Product Scan SKU</label>
                <input class="pull-right detail-view-inputs" id="productScanSKURef" #productScanSKURef type="text" name="scanedSKU" [ngClass]="{'bg-green': skuMatch, 'bg-red': skuMismatch}" [(ngModel)]="scanedSKU" (keyup.enter)="checkSKUMatch();" [disabled]="detailViewData && !detailViewData.sku">
            </div>
            <div class="customer-input-container">
                <label>Gift Box SKU</label>
                <input class="pull-right detail-view-inputs"  type="text" name="giftBoxSku" [value]="detailViewData?.gift_box_sku_id"  disabled>
            </div>
        </div>
        <div class="col-md-4 reject-reason-container">
            <div class="customer-input-container">
                <label>Order Date</label>
                <input class="pull-right detail-view-inputs" type="text" name="order_date" [value]="detailViewData?.order_date" disabled>
            </div>
            <div class="customer-input-container">
                <label>Sequence</label>
                <input class="pull-right detail-view-inputs" type="text" name="sequence" [value]="detailViewData?.order_id ? (detailViewData?.quantity_confirmed ? detailViewData?.quantity_confirmed : 1) : ''" disabled>
            </div>
            <div class="customer-input-container">
                <label>Item Quanity</label>
                <input class="pull-right detail-view-inputs" type="text" name="quantity" [value]="detailViewData?.quantity" disabled>
            </div>
            <div class="customer-input-container">
                <label>Fragrance</label>
                <input class="pull-right detail-view-inputs" type="text" name="product_fragrance" [value]="detailViewData?.product_fragrance" disabled>
            </div>
            <div class="customer-input-container">
                <label>Delivery Method</label>
                <input class="pull-right detail-view-inputs" type="text" name="shipping_method" [value]="detailViewData?.shipping_method" disabled>
            </div>
            <div class="customer-input-container">
                <label>Gift Box Description</label>
                <input class="pull-right detail-view-inputs" type="text" name="gift_box_description" [value]="detailViewData?.gift_box_sku_description" disabled>
            </div>
        </div>
    </div>
</div>