
<br><br><br>

<div class="buttonContainer container">
  <button class="addUserButton"  *ngIf="tabOpen && !onEdit && !onPass" (click)="setButtonClicked(false); tabWasOpen(false)">+ Add User</button>
  <button class="addUserButton" *ngIf="!tabOpen && !onEdit && !onPass" (click)="setButtonClicked(true); tabWasOpen(true)">+ Find User</button>
</div>


<app-register *ngIf="!buttonWasClicked && !onEdit && !onPass" (registersubmit)="submitButtonClicked(true); tabWasOpen(true)"></app-register>
<app-edit-user *ngIf="onEdit" [userid]="currentUser" (cancelClicked)="cancelButtonClicked(false)" (updateUsersubmit)="cancelButtonClicked(false)"></app-edit-user>
<app-find-user *ngIf="buttonWasClicked && !onEdit && !onPass" (searchClick)="searchUsers()" (onUserEdit)="editJune($event)" (onUserPass)="editTim($event)" (editClicked)="editButtonClicked(true)" (passClicked)="passButtonClicked(true)"></app-find-user>
<!-- <app-list-view><app-list-view> -->
<app-update-lost-password *ngIf="onPass" [userid]="currentUser" (cancelPassClicked)="cancelPassButton(false)" (resetPasswordSubmit)="cancelPassButton(false)"></app-update-lost-password>
