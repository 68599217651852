import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'grid-view' ,
    template: `
        <div *ngIf="dataList && dataList.length" class="container grid-view-container">
            <div>
                <label>
                    <input type="checkBox" name="selectAll" [(ngModel)]="selectAll" (change)="selectionChanged()">
                    <span>Select All</span>
                </label>
            </div>
            <div class="gird-view-subContainer">
                <mat-grid-list  cols="3" rowHeight="520px">
                    <mat-grid-tile  *ngFor="let data of dataList">
                        <order-tile [orderDetails]="data" [canRejectOrder]="canRejectOrder" [rejectCodes]="rejectCodes" (holdClicked)="holdClicked.emit()" [isInventoryMang]="isInventoryMang" [isCSCLabel]="isCSCLabel" (selectClicked)="checkSelectAll()" (tileLabelClicked)="recordClicked.emit($event)" [disableCheckBoxForMissingReadyToPrintImages]="disableCheckBoxForMissingReadyToPrintImages"></order-tile>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
        </div>
    `,
    styles: ['.grid-view-container { border: 1px solid #666; height: 580px; } .gird-view-subContainer {height: 550px; overflow-y: auto;}']
})

export class GridViewComponent {
    @Input() dataList;
    @Input() isInventoryMang:boolean = false;
    @Input() isCSCLabel:boolean = false;
    @Input() rejectCodes;
    @Input() canRejectOrder: boolean = false;
    @Output() recordClicked = new EventEmitter();
    @Output() itemsSelected = new EventEmitter();
    @Output() dataListChange = new EventEmitter();
    selectAll: boolean = true;
    @Input() disableCheckBoxForMissingReadyToPrintImages:boolean = false;
    @Output() holdClicked = new EventEmitter();
    selectionChanged() {
        if(this.selectAll) {
            this.dataList.map( (order)=> { 
                order.isSelected = (this.disableCheckBoxForMissingReadyToPrintImages==true && order.print_ready_image == "") ? false : true; return order
            });
        } else {
            this.dataList.map( (order)=> { order.isSelected = false; return order});
        }
    }
    
    checkSelectAll(){
        setTimeout(
            ()=>{
            let selectedItems = this.dataList.filter( (order)=> { return order.isSelected});
            this.selectAll = selectedItems.length == this.dataList.length;
        })
    }
}