import {Component, Input, OnInit, Output} from '@angular/core';
import { UserService } from '../../user/user.service';

@Component({
    selector: 'warning-component',
    templateUrl: './warning.component.html',
    styleUrls: ['./warning.component.css']
  })
  export class WarningComponent implements OnInit {
    public showWarning : string = 'hideWarning';
    public warningComponentClass;
    @Input() userid : string;
    @Output() public alert: any;
    constructor(private userService: UserService) {
        this.alert = {
            status: false,
            title: 'Password expiring',
            noOfDaysLeftToExpire: 0
        };
    }

    ngOnInit() : void {
        this.userService.getUser(this.userid).subscribe(data => {
            if (data["success"] === false) {
                console.log(data["message"]);
            } else {
                let newData = data["data"]
                let passwordLastUpdated = new Date(newData[0].password_created);
                let today = new Date();
                let daysLeftToExpire = Math.ceil(Math.abs(passwordLastUpdated.getTime() + (newData[0].password_maximum_age * 1000*3600*24) - today.getTime())/(1000 * 3600 * 24));
                if (daysLeftToExpire <= 10) {
                    this.showWarning = "showWarning";
                    this.alert.noOfDaysLeftToExpire = daysLeftToExpire;
                } else {
                    this.showWarning = "hideWarning";
                    if (document.getElementsByClassName('container-warning-alert')[0]) {
                        this.warningComponentClass = document.getElementsByClassName('container-warning-alert')[0];
                        this.warningComponentClass.parentNode.removeChild(this.warningComponentClass);
                    }
                }
            }
        });
    }
  }