import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormControl, FormArray } from '@angular/forms';
import * as moment from 'moment';
import { DataService } from "../../shared/service/data.service"
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-dataextract',
  templateUrl: './dataextract.component.html',
  styleUrls: ['./dataextract.component.css']
})
export class DataextractComponent implements OnInit {


  constructor(private dataservice: DataService) {

  }
  public myForm: FormGroup;

  ngOnInit() {
    this.myForm = new FormGroup({
      'FromDate': new FormControl(),
      'ToDate': new FormControl(),
      'order_status': new FormControl(),
      'country': new FormControl(),
    });
    this._onload()
  }
  _onload() {
    var fromdatenow = new Date()
    fromdatenow.setHours(0)
    fromdatenow.setMinutes(0)
    fromdatenow.setSeconds(0)
    fromdatenow.setMilliseconds(0)

    var todatenow = new Date()
    todatenow.setHours(23)
    todatenow.setMinutes(59)
    todatenow.setSeconds(0)
    todatenow.setMilliseconds(0)
    this.toDate = todatenow.toString()
    this.fromDate = fromdatenow.toString()


    this.myForm.setValue({
      order_status: "All",
      FromDate: '',
      ToDate: '',
      country: "All"
    })
    this.getreportdata(this.fromDate, this.toDate, "All", "All");
  }

  public OrderstatusGroup: any = ['All', 'New', 'Pending', 'Approved', 'OnHold',
    'Ready to Print', 'Printed', 'QA Verified', 'QA Review', 'Returned']

  public countries: any = ['UK E-COM', 'FR E-COM', 'DE E-COM', 'IT E-COM','IE E-COM', 'All']

  fromToDateMismatch: boolean = false;
  checkDateValidity(from, to): boolean {
    if (from && to && from > to) {
      return false;
    }
    //returning true by default
    return true;
  }

  dateSelected(e, field) {
    setTimeout(() => {
      if (field === "from") {
        this.fromDate = e;
      } else {
        this.toDate = e;
      }
      if (typeof (this.fromDate) == "string" && this.fromDate != "")
        this.fromDate = new Date(this.fromDate)
      this.fromToDateMismatch = !this.checkDateValidity(this.fromDate, this.toDate);
    })
  }

  getreportdata(fromdate, todate, Orderstatus, country) {
    fromdate = moment.parseZone(fromdate).format("YYYY-MM-DDTHH:mm:ss")
    todate = moment.parseZone(todate).format("YYYY-MM-DDTHH:mm:ss")
    this.dataservice.GetPostMethod("dataextract/getreport?startDate=" + fromdate + "&endDate=" + todate + "&Orderstatus=" + Orderstatus + "&user=" + localStorage.getItem('Username') + "&ordersource=" + country + "", "")
      .subscribe(Response => {
        if (Response["success"] == "true") {
          let newResponse = Response["message"];
          this.rows = newResponse[0]["orders"]
          this.showtable = true
          this.OrdersArray = newResponse[0]["orders"]
          this.summaryInfo = newResponse[0]["summary"];
          this.isLoading = false;
        }
        else {

        }
      })
  }

  isLoading: boolean = true;
  public fromDate: any;
  public toDate: any;
  public OrdersArray: any;
  public showtable = false;
  public Orderstatus: any;
  country: any;

  summaryInfo;
  rows = [];
  columns = [
    { name: 'Order ID', flexGrow: 2, prop: 'Order_id' },
    { name: 'ATG Web Order', flexGrow: 2, prop: 'Source_order_id' },
    { name: 'First Name', flexGrow: 2, prop: 'first_name' },
    { name: 'Last Name', flexGrow: 2, prop: 'last_name' },
    { name: 'Address Line 1', flexGrow: 2, prop: 'address_line_1' },
    { name: 'Address Line 2', flexGrow: 2, prop: 'address_line_2' },
    { name: 'Address Line 3', flexGrow: 2, prop: 'address_line_3' },
    { name: 'County', flexGrow: 2, prop: 'county' },
    { name: 'State', flexGrow: 2, prop: 'state' },
    { name: 'Country', flexGrow: 2, prop: 'country' },
    { name: 'Town', flexGrow: 2, prop: 'town' },
    { name: 'Post Code', flexGrow: 2, prop: 'post_code' },
    { name: 'Telephone #', flexGrow: 2, prop: 'telephone' },
    { name: 'Email', flexGrow: 2, prop: 'email' },
    { name: 'Sku', flexGrow: 2, prop: 'Sku' },
    { name: 'Order Status', flexGrow: 2, prop: 'order_status' },
    { name: 'Price', flexGrow: 1, prop: 'Price' },
    { name: 'Currency', flexGrow: 1, prop: 'Currency' },
    { name: 'To be Packed Units', flexGrow: 2, prop: 'pack' },

  ];

  Getdetails(myForm: any) {
    console.log('Registration successful.');
    //console.log(myForm);
    this.isLoading = true;
    var frmdate = ""
    this.fromDate = moment.parseZone(myForm.controls.FromDate.value).format("YYYY-MM-DDTHH:mm:ss")
    this.toDate = moment.parseZone(myForm.controls.ToDate.value).format("YYYY-MM-DDTHH:mm:ss")
    this.Orderstatus = myForm.controls.order_status.value;
    //this.Orderstatus = myForm.controls.order_status.value;
    this.country = myForm.controls.country.value;
    if (this.fromDate == "Invalid date" || this.toDate == "Invalid date") {
      alert("Please fill")
      return false
    }
    this.getreportdata(this.fromDate, this.toDate, this.Orderstatus, this.country)



  }
  resetform() {
    this.showtable = false;
  }
  /*exportcsv(JsonData): any {
    let today = new Date();
    var summary = 'Order ID, ATG Web Order, First Name, Last Name, Address Line 1, Address Line 2, Address Line 3, County, State, Country, Town , Post Code, Email, SKU, Order Status, Telephone, Price, Currency, To be Packed Units\n'
    JsonData.forEach(function (row) {
      let AddressLine1;      
      if (row.address_line_1.includes("\n")) {
        AddressLine1 = row.address_line_1.replace(/\n/g, " ")
      }
      else if (row.address_line_1.includes(",")) {
        AddressLine1 = '\"' + row.address_line_1 + '\"'
      } else
        AddressLine1 = row.address_line_1;
      let AddressLine2;
      if (row.address_line_2 == "" || row.address_line_2 == null) {
        AddressLine2 = ""
      } else if (row.address_line_2.includes("\n")) {
        AddressLine2 = row.address_line_2.replace(/\n/g, " ")
      }
      else if (row.address_line_2.includes(",")) {
        AddressLine2 = '\"' + row.address_line_2 + '\"'
      } else
        AddressLine2 = row.address_line_2;
      let AddressLine3;
      if (row.address_line_3 == "" || row.address_line_3 == null) {
        AddressLine3 = ""
      } else if (row.address_line_3.includes("\n")) {
        AddressLine3 = row.address_line_3.replace(/\n/g, " ")
      } else if (row.address_line_3.includes(",")) {
        AddressLine3 = '\"' + row.address_line_3 + '\"'
      } else
        AddressLine3 = row.address_line_3
      let county, state, country, town; 
      if (row.county == "" || row.county == null) {
        county = ""
      } else if (row.county.includes("\n")) {
        county = row.county.replace(/\n/g, " ")
      }
      else if (row.county.includes(",")) {
        county = '\"' + row.county + '\"'
      } else
        county = row.county;
      if (row.state == "" || row.state == null) {
        state = ""
      } else if (row.state.includes("\n")) {
        state = row.state.replace(/\n/g, " ")
      }
      else if (row.state.includes(",")) {
        state = '\"' + row.state + '\"'
      } else
        state = row.state;      
      if (row.country == "" || row.country == null) {
        country = ""
      } else if (row.country.includes("\n")) {
        country = row.country.replace(/\n/g, " ")
      }
      else if (row.country.includes(",")) {
        country = '\"' + row.country + '\"'
      } else
        country = row.country;      
      if (row.town == "" || row.town == null) {
        town = ""
      } else if (row.town.includes("\n")) {
        town = row.town.replace(/\n/g, " ")
      }
      else if (row.town.includes(",")) {
        town = '\"' + row.town + '\"'
      } else
        town = row.town;
      let postCode = (row.post_code == "" || row.post_code == null) ? "" : row.post_code   
      let telephone = JSON.stringify(row.telephone);
      let order_status = JSON.stringify(row.order_status);
      let Price = JSON.stringify(row.Price)
      let pack = JSON.stringify(row.pack);
      summary += row.Order_id + ',' + row.Source_order_id + ',' + row.first_name + ',' + row.last_name + ',' + AddressLine1 + ',' + AddressLine2 + ',' + AddressLine3 + ',' + county + ',' + state + ',' + country + ',' + town + ',' + postCode + ',' + row.email + ',' + row.Sku + ',' + order_status + ',' + telephone + ',' + Price + ',' + row.Currency + ',' + row.pack;
      summary += "\n";
    });
    var csvElement = document.createElement('a');
    csvElement.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(summary);
    csvElement.target = '_blank';
    csvElement.download = 'Data_Report_' + today + '.csv';
    csvElement.click();
  }*/
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  exportcsv(JsonData):any{
    let today = new Date();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(JsonData);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], {type: this.fileType});
    FileSaver.saveAs(data, "EUReport_"+today + this.fileExtension);
  }


}

