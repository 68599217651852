import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { MdDatepickerModule, MdInputModule, MdNativeDateModule, MdGridListModule} from '@angular/material';
import {  MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule} from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2CompleterModule } from "ng2-completer";

import { OrderSearchComponent } from '../../shared/components/order-search/order-search.component';
import { DetailViewComponent } from '../../shared/components/detail-view/detail-view.component';
import { PickTicketDetailViewComponent } from '../../shared/components/pickticket-detail-view/pickticket-detail-view.component';
import { QADetailViewComponent } from '../../shared/components/qa-detail-view/qa-detail-view.component';
import { QuickFilterComponent } from '../../shared/components/quick-filter/quick-filter.component';
import { DataTablecomponent } from '../../shared/components/list-view/data-table.component';
import { GridViewComponent } from '../../shared/components/grid-view/grid-view.component';
import { OrderTileComponent } from '../../shared/components/grid-view/order-tile.component';
import { PaginationComponent } from '../../shared/components/pagination/pagination.component';
import { UserInfoModalService, UserInfoModalComponent } from '../util/modal/user-info.modal';
import { BaseModal } from '../../shared/util/modal/base.modal';


//Pipes
import { CstToUtcPipe } from '../pipe/cst-to-utc.pipe';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        MatDatepickerModule,
        MatInputModule,
        MatNativeDateModule,
        MultiselectDropdownModule,
        MatGridListModule,
        OwlDateTimeModule, 
        OwlNativeDateTimeModule,
        NgxDatatableModule,
        Ng2CompleterModule
    ],
    declarations: [
        OrderSearchComponent,
        DetailViewComponent,
        QuickFilterComponent,
        OrderTileComponent,
        DataTablecomponent,
        GridViewComponent,
        PaginationComponent,
        PickTicketDetailViewComponent,
        QADetailViewComponent,
        UserInfoModalComponent,
        CstToUtcPipe,
        BaseModal
    ],
    providers: [
        UserInfoModalService
    ],
    entryComponents: [
        UserInfoModalComponent
    ],
    exports: [
        NgxDatatableModule,
        OrderSearchComponent,
        DetailViewComponent,
        QuickFilterComponent,
        OrderTileComponent,
        DataTablecomponent,
        GridViewComponent,
        PaginationComponent,
        PickTicketDetailViewComponent,
        QADetailViewComponent,
        UserInfoModalComponent,
        CstToUtcPipe,
        BaseModal
    ]
})

export class SearchSharedModule{}