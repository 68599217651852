import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostaCodeStatusComponent } from './posta-code-status.component';
import { SearchSharedModule } from '../../shared/module/searchshared.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    SearchSharedModule
  ],
  declarations: [PostaCodeStatusComponent],
  exports:[PostaCodeStatusComponent]
})
export class PostalCodeStatusModule { }
