import { Component, ViewChild,ViewContainerRef } from '@angular/core';
import { SharedApplicationService } from '../../service/shared.service';

@Component({
    selector: 'dynamic-printwrapper-component',
    template: `<div #dynamicPrintWrapperContainer></div>`
})
export class DynamicPrintWrapperComponent { 
    @ViewChild('dynamicPrintWrapperContainer', { read: ViewContainerRef }) printWrapperViewContainerRef;

    constructor(private sharedService:SharedApplicationService) { }

    ngAfterViewInit(){
        this.sharedService.setPrintWrapperViewContainerRef(this.printWrapperViewContainerRef);
    }
}