import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams  } from "@angular/common/http";
import { NgForm, FormGroup, FormControl } from '@angular/forms';
// import { DateAdapter, NativeDateAdapter} from '@angular/material';
import {trigger,state, style, animate, transition} from '@angular/animations';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.css'],
  animations: [
    trigger('shrinkInOut', [
        transition(':leave', [
            style({ height: "*" }),
            animate(350, style({ height: 0 }))
        ]),
        transition(':enter', [
            style({ height: 0 }),
            animate(350, style({ height: "*" }))
        ])
    ])
]
})
export class ReportingComponent implements OnInit {
  constructor(private http: HttpClient) {
  }
  


ngOnInit() {
  }

}
