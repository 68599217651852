import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormArray, FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from '../../../common/toastr.service';
import { UserService } from '../../../user/user.service';
declare const jQuery:  any;

function comparePassword(c: AbstractControl): {[key: string]: boolean} | null {
  const passwordControl = c.get('password');
  const confirmControl = c.get('retypepass');

  if (passwordControl.pristine || confirmControl.pristine) {
    return null;
  }

  if (passwordControl.value === confirmControl.value) {
    return null;
  }
  return { 'mismatchedPassword': true };
}

@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.css']
})
export class AddGroupComponent implements OnInit {
  @Output() addGroupsubmit = new EventEmitter();

  registerForm: FormGroup;
  accesses: any;


  constructor(private fb: FormBuilder,
              private userService: UserService,
              private router: Router,
              private toastr: ToastrService) {
  }

  group_name = new FormControl('', [Validators.required]);
  group_description = new FormControl('', [Validators.required]);
  access_list = new FormControl('', [Validators.required]);


  searchViewToggle: boolean = true;

  ngOnInit(): void {
    window.scrollTo(0, 0);
    
    this.registerForm = this.fb.group({
      group_name: this.group_name,
      group_description: this.group_description,
      access_list: this.access_list
    });
    this.userService.getAccesses().subscribe(data => {
      if (data["success"] === false) {
        if (data["errcode"]) {
          //this.authService.logout();
          this.router.navigate(['/usermanagement']);
        }
       // this.toastr.error(data.message);
      } else {
        this.accesses = data["data"];
        //this.populateForm(this.user);
        // console.log(data);
      }
    });

  }



  registerGroup(formdata: any): void {
let access_list = [];

console.log(this.access_list)
for (var i = 0; i < this.accesses.length; i++){
  if (this.accesses[i].checked){
    access_list.push(this.accesses[i].name)
  }
} console.log(access_list)


    if (this.registerForm.dirty && this.registerForm.valid) {

      const theForm = this.registerForm.value;
      theForm.access_list = access_list;
      console.log(theForm)

      this.userService.registerGroup(theForm)
        .subscribe(data => {
          if (data["success"] === false) {
            // this.toastr.error(data.message);
          } else {
            console.log(data);
            alert('Group has been Created')
            this.addGroupsubmit.emit();

          }
          this.registerForm.reset();
        });
    }
  }


}
