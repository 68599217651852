<div class="user-search-container container">
  <div class="row">
    <h2 class="title">
          Edit Access
          <button class="pull-right search-view-toggle" [class.searchCollapsed]="!searchViewToggle" (click)="searchViewToggle = !searchViewToggle;"></button>
        </h2>
  </div>

  <div class="row search-fields-container" *ngIf="searchViewToggle">
    <form [formGroup]="profileForm" (ngSubmit)="updateAccess(profileForm.value)" novalidate autocomplete="off">
      <div class="sub-search-container row">
        <h3>Access Details</h3>
        <div class="sub-search-wrapper">
          <div class="col-md-4" [ngClass]="{'has-error': profileForm.get('name').invalid && profileForm.get('name').dirty}">
            <label for="name">
              <span>Access name</span>
            </label>
            <input type="text" class="user-input-text" [formControl]="name" id="name" placeholder="Group Name">
            <div class="text-danger" *ngIf="profileForm.get('name').dirty && profileForm.get('name').errors">
              <span class="col-sm-10" style="padding:4px 0 0" *ngIf="profileForm.get('name').errors">
                    Please enter a access name
                </span>
            </div>
          </div>


          <div class="col-md-4" [ngClass]="{'has-error': profileForm.get('description').invalid && profileForm.get('description').dirty}">
            <label for="lname">Access Description</label>
            <input type="text" class="user-input-text" [formControl]="description" id="description" placeholder="Access Description">
            <div class="text-danger" *ngIf="profileForm.get('description').dirty && profileForm.get('description').errors">
              <span class="col-sm-10" style="padding:4px 0 0" *ngIf="profileForm.get('description').errors">
                    Please enter an Access Description
                </span>
            </div>
          </div>

          <div class="col-md-4">
            <label for="type">Type</label>
            <select class="user-input-select" name="type" [formControl]="type" id="type" required>
                  <option value="Screen">Screen</option>
                  <option value="Service">Service</option>
              </select>
          </div>


<br>
<br>
<br>


  <div class="text-center">
    <button type="submit" [disabled]="profileForm.invalid" class="ycc-button button-search">Update</button>
    <button (click)="cancelAccessWasClicked()" class="ycc-button button-clear">Cancel</button>
  </div>

        </div>
      </div>
    </form>
  </div>
</div>
