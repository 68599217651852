  <div class="user-search-container container">
    <div class="row">
      <h2 class="title">
            Find Access
            <button class="pull-right search-view-toggle" [class.searchCollapsed]="!searchViewToggle" (click)="searchViewToggle = !searchViewToggle;"></button>
          </h2>
    </div>

    <div class="row search-fields-container" *ngIf="searchViewToggle">
      <form id="filter3" [formGroup]="registerForm" (ngSubmit)="registerAccess(registerForm.value)" novalidate autocomplete="off">
        <div class="sub-search-container row">
          <h3>Access Details</h3>
          <div class="sub-search-wrapper">
            <div class="col-md-4" [ngClass]="{'has-error': registerForm.get('name').invalid && registerForm.get('name').dirty}">
              <label for="name">
                <span>Access name</span>
              </label>
              <input type="text" class="user-input-text" [(ngModel)]="term31" [formControl]="name" id="name" placeholder="Access Name">
              <div class="text-danger" *ngIf="registerForm.get('name').dirty && registerForm.get('name').errors">
                <span class="col-sm-10" style="padding:4px 0 0" *ngIf="registerForm.get('name').errors">
                      Please enter a access name
                  </span>
              </div>
            </div>


            <div class="col-md-4" [ngClass]="{'has-error': registerForm.get('description').invalid && registerForm.get('description').dirty}">
              <label for="lname">Access Description</label>
              <input type="text" class="user-input-text" [(ngModel)]="term32" [formControl]="description" id="description" placeholder="Access Description">
              <div class="text-danger" *ngIf="registerForm.get('description').dirty && registerForm.get('description').errors">
                <span class="col-sm-10" style="padding:4px 0 0" *ngIf="registerForm.get('description').errors">
                      Please enter an Access Description
                  </span>
              </div>
            </div>

            <div class="col-md-4">
              <label for="type">Type</label>
              <select class="user-input-select" name="type" [(ngModel)]="term33" [formControl]="type" id="type" required>
                    <option value="">None</option>
                    <option value="Screen">Screen</option>
                    <option value="Service">Service</option>
                </select>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <br>
  <table class="postTable container">
    <thead>
      <tr>
        <th class="postTableCss">Access ID</th>
        <th class="postTableCss"></th>
        <th class="postTableCss">Access Name</th>
        <th class="postTableCss">Access Description</th>
        <th class="postTableCss"></th>
        <th class="postTableCss">Access Type</th>
        <th class="postTableCss"></th>
        <th class="postTableCss"></th>
        <th class="postTableCss"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let access of accesses | filter3: term31:term32:term33 " id='searchContainer'>
        <td>{{ access._id}}</td>
        <td>{{ access.name}}</td>
        <td></td>
        <td>{{ access.description}}</td>
        <td></td>
        <td>{{ access.type}}</td>
        <td></td>
        <td><a class="clickableCell" (click)="fireEditAccessEvent(this.access._id); editAccessWasClicked()">Edit</a></td>
        <td><a class="clickableCell" (click)="delAccess(access._id)">Delete</a></td>
      </tr>
    </tbody>
