import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from '../../../common/toastr.service';
import { UserService } from '../../../user/user.service';
declare const jQuery:  any;

function comparePassword(c: AbstractControl): {[key: string]: boolean} | null {
  const passwordControl = c.get('password');
  const confirmControl = c.get('retypepass');

  if (passwordControl.pristine || confirmControl.pristine) {
    return null;
  }

  if (passwordControl.value === confirmControl.value) {
    return null;
  }
  return { 'mismatchedPassword': true };
}

@Component({
  selector: 'app-add-access',
  templateUrl: './add-access.component.html',
  styleUrls: ['./add-access.component.css']
})
export class AddAccessComponent implements OnInit {

      registerForm: FormGroup;

  constructor(private fb: FormBuilder,
              private userService: UserService,
              private router: Router,
              private toastr: ToastrService) {
      }

      name = new FormControl('', [Validators.required]);
      description = new FormControl('', [Validators.required]);
      type = new FormControl('', [Validators.required]);

        searchViewToggle: boolean = true;

      ngOnInit(): void {
        this.registerForm = this.fb.group({
          name: this.name,
          description: this.description,
          type: this.type
        });
      }

      registerAccess(formdata: any): void {
        if (this.registerForm.dirty && this.registerForm.valid) {
          const theForm = this.registerForm.value;

          this.userService.registerAccess(theForm)
            .subscribe(data => {
              if (data["success"] === false) {
                // this.toastr.error(data.message);
              } else {
                // this.toastr.success(data.message);
                this.router.navigate(['/accessmanagement']);
                location.reload();
              }
              this.registerForm.reset();
            });
        }
      }
    }
