import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from '../../../common/toastr.service';
import { UserService } from '../../../user/user.service';
import { AuthService } from '../../../user/auth.service';
import { IUser } from '../../../user/user';
import { FilterPipe } from '../../../../temp/adimn/filter.pipe';
import { EditUserComponent } from '../edit-user/edit-user.component';

import { SearchQuery } from '../../model/searchQuery.model';

function comparePassword(c: AbstractControl): {[key: string]: boolean} | null {
  const passwordControl = c.get('password');
  const confirmControl = c.get('retypepass');

  if (passwordControl.pristine || confirmControl.pristine) {
    return null;
  }

  if (passwordControl.value === confirmControl.value) {
    return null;
  }
  return { 'mismatchedPassword': true };
}

@Component({
  selector: 'app-find-user',
  templateUrl: './find-user.component.html',
  styleUrls: ['./find-user.component.css'],

})
export class FindUserComponent implements OnInit {
@Output() onUserEdit = new EventEmitter();
@Output() editClicked = new EventEmitter();
@Output() onUserPass = new EventEmitter();
@Output() passClicked = new EventEmitter();
@Output() searchClick = new EventEmitter();
ngOnChanges(change) {
  this.userService.getUsers();
}
profileForm: FormGroup
registerForm: FormGroup;
userObj: any;
user: IUser;
users: any;
groups: any;
term1: FilterPipe;
term2: FilterPipe;
term3: FilterPipe;
term4: FilterPipe;
term5: FilterPipe;


editWasClicked(){
  this.editClicked.emit();
  window.scrollTo(0, 0);
}
passWasClicked(){
  this.passClicked.emit();
}
callSearch(){
  this.searchClick.emit();
}

fireEditEvent(userid, i: any){
  this.onUserEdit.emit(userid);
}
firePassEvent(userid, i: any){
  this.onUserPass.emit(userid);
}

constructor(private fb: FormBuilder,
            private userService: UserService,
            private authService: AuthService,
            private router: Router,
            private filterPipe: FilterPipe,
            private toastr: ToastrService) {
}

firstname = new FormControl('');
lastname = new FormControl('');
email = new FormControl('');
username = new FormControl('');
password = new FormControl('');
retypepass = new FormControl('');
group_name = new FormControl('');

searchViewToggle: boolean = true;

ngOnInit(): void {
  window.scrollTo(0, 0);
  
    this.userObj =  this.authService.currentUser;
  this.registerForm = this.fb.group({
    firstname: this.firstname,
    lastname: this.lastname,
    email: this.email,
    username: this.username,
    passwordGroup: this.fb.group({
      password: this.password,
      retypepass: this.retypepass,
      group_name: this.group_name,

    }, {validator: comparePassword})
  });

  this.userService.getUsers().subscribe(data => {
    if (data["success"] === false) {
      if (data["errcode"]) {
        //this.authService.logout();
        this.router.navigate(['/usermanagement']);
      }
     // this.toastr.error(data.message);
    } else {
      this.users = data["data"];
      //this.populateForm(this.user);
      console.log(this.users);
    }
  });
  this.userService.getGroups().subscribe(data => {
    if (data["success"] === false) {
      if (data["errcode"]) {
        //this.authService.logout();
        this.router.navigate(['/usermanagement']);
      }
     // this.toastr.error(data.message);
    } else {
      this.groups = data["data"];
      //this.populateForm(this.user);
      // console.log(data);
    }
  });
}

populateForm(e): void {
  this.profileForm.patchValue({
    firstname: e.firstname,
    lastname: e.lastname,
    email: e.email,
    username: e.username,
    group_name: e.group_name
  });
}

deleteUser(userid): void {
  if (confirm('Are you sure you want to delete this?' )){
    this.userService.deluserDetails(userid)
      .subscribe(data => {
        if (data["success"] === false) {
        //  this.toastr.error(data.message);
        } else {
         // this.toastr["success"](data.message);
         this.ngOnInit();
        }
      });
  }
}

getUserEdit(userid): void {
this.userService.getUser(userid).subscribe(data => {
  if (data["success"] === false) {
    if (data["errcode"]) {
      //this.authService.logout();
      this.router.navigate(['/usermanagement']);
    }
    this.toastr.error(data["message"]);
  } else {
    this.user = data["data"];
    //this.populateForm(this.user);
    console.log(this.user);
  }
});
}

sortTable(n) {
 var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
 table = document.getElementById("myTable");
 switching = true;
 //Set the sorting direction to ascending:
 dir = "asc";
 /*Make a loop that will continue until
 no switching has been done:*/
 while (switching) {
   //start by saying: no switching is done:
   switching = false;
   rows = table.getElementsByTagName("TR");
   /*Loop through all table rows (except the
   first, which contains table headers):*/
   for (i = 1; i < (rows.length - 1); i++) {
     //start by saying there should be no switching:
     shouldSwitch = false;
     /*Get the two elements you want to compare,
     one from current row and one from the next:*/
     x = rows[i].getElementsByTagName("TD")[n];
     y = rows[i + 1].getElementsByTagName("TD")[n];
     /*check if the two rows should switch place,
     based on the direction, asc or desc:*/
     if (dir == "asc") {
       if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
         //if so, mark as a switch and break the loop:
         shouldSwitch= true;
         break;
       }
     } else if (dir == "desc") {
       if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
         //if so, mark as a switch and break the loop:
         shouldSwitch= true;
         break;
       }
     }
   }
   if (shouldSwitch) {
     /*If a switch has been marked, make the switch
     and mark that a switch has been done:*/
     rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
     switching = true;
     //Each time a switch is done, increase this count by 1:
     switchcount ++;
   } else {
     /*If no switching has been done AND the direction is "asc",
     set the direction to "desc" and run the while loop again.*/
     if (switchcount == 0 && dir == "asc") {
       dir = "desc";
       switching = true;
     }
   }
 }
}
}
