import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SearchSharedModule } from '../../shared/module/searchshared.module';

import { CSCLabelApprovalPageComponent } from './cscLabelApproval.component';


@NgModule({
    imports: [
        SearchSharedModule,
        CommonModule
    ],
    declarations: [
        CSCLabelApprovalPageComponent,
    ],
    exports: [CSCLabelApprovalPageComponent]
})

export class CSCLabelApprovalModule{}