<div class="controlPanel">
    <order-search *ngIf="isSearchAndDetailToggle" [(searchQuery)]="searchparams" [dropDownList]="dropDownDataList"
        (clearClick)="resetSearch();" (searchClick)="searchOrders()"></order-search>
    <quick-filter *ngIf="isSearchAndDetailToggle" [quickFilters]="quickFilters"
        (quickFiltersChange)="filterChanged($event)"></quick-filter>
    <detail-view *ngIf="!isSearchAndDetailToggle" [disableApprove]=" (ukGibraltarOrderInfo.length == 0)"
        [rejectCodes]="dropDownDataList.datalist.rejected" [detailViewData]="detailViewData" [isCSCLabelManager]="false"
        (closeDetails)="closeDetailView($event)"></detail-view>
    <div class="col-md-10 col-md-offset-1 text-center" *ngIf="(ukGibraltarOrderInfo.length > 0)">
        <div class="alert alert-danger" role="alert">
            <p>
                <strong>
                    <span class="glyphicon glyphicon-info-sign" aria-hidden="true"></span> Gibraltar Orders [
                    {{ukGibraltarOrderInfo}} ] are found on the search result. </strong>
            </p>
        </div>
    </div>
</div>
<div class="resultPanel container nopadding">
    <div *ngIf="searchResults" id="top-pagination-list">
        <paginator [isLoading]="isLoading" [searchResults]="searchResults" [pageNumCache]="searchparams.pageno"
            [(pageNum)]="searchparams.pageno" [(pageSize)]="searchparams.pagesize"
            (paginationChange)="searchOnPaginationchange($event);" (viewChange)="isListViewToggle = $event;">
            <div searchdisplay>
                <div *ngIf="searchOrderDetailsResults.length; else No_Results">
                    <list-view *ngIf="isListViewToggle" [(dataList)]="searchOrderDetailsResults"
                        (recordClicked)="showDetailsView($event);" [searchQuery]="searchparams"
                        (searchQueryChange)="callSort($event);"></list-view>
                    <grid-view *ngIf="!isListViewToggle" [(dataList)]="searchOrderDetailsResults"
                        (recordClicked)="showDetailsView($event);" [isCSCLabel]="true" canHoldOrder="true"
                        [rejectCodes]="dropDownDataList.datalist.rejected"></grid-view>
                </div>
                <ng-template #No_Results>
                    <div class="text-center">No results to display</div>
                </ng-template>
            </div>
            <div actionbuttons>
                <button type="button" class="approve-button pull-right" (click)="approveSelected()"
                    [disabled]="((!numOfSelectedItem()) ||(ukGibraltarOrderInfo.length > 0))">
                    Approve ({{numOfSelectedItem()}})
                </button>
            </div>
        </paginator>
    </div>
</div>