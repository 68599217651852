import { Component, OnInit } from '@angular/core';
// import { Http, Response, Headers, RequestOptions} from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';

import {Router} from '@angular/router'
import { Location } from '@angular/common';

import { AuthService } from '../../user/auth.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
username:string="John smith"
private showlogout:boolean=false;

private isManger:boolean=false;
private isApproval:boolean=false;
private isReporting:boolean=false;
private isQuality:boolean=false;
private isInventory:boolean=false;
private isPrint:boolean=false;
private isShipping:boolean=false;
private isOrderManagement:boolean=false;
private isusermanagement:boolean=false;
private isgroupmanagement:boolean=false;
private isconfig:boolean=false;
private isutility:boolean=false;
private acess:string;
menuhide: any;
constructor(private _router:Router,public authService: AuthService,private http: HttpClient) { }

/*  private acess:string;
  urlpath: any;
    constructor(location: Location,private _router:Router,public authService: AuthService,private http: Http) { 
      _router.events.subscribe((val) => {
        if(location.path() != ''){
          this.urlpath=location.path()     
        } else {
          
        }
        if(this.urlpath=="/approver"){
          if(!this.authService.isScreenacess().isApproval)
            this._router.navigate(['/report']);
        }
  
        else if(this.urlpath=="/manager"){
          if(!this.authService.isScreenacess().isManger)
            this._router.navigate(['/report']);
        }
  
        else if(this.urlpath=="/inventory"){
          if(!this.authService.isScreenacess().isInventory)
            this._router.navigate(['/report']);
        }
  
        else if(this.urlpath=="/print"){
          if(!this.authService.isScreenacess().isPrint)
            this._router.navigate(['/report']);
        }
  
        else if(this.urlpath=="/qa"){
          if(!this.authService.isScreenacess().isQuality)
            this._router.navigate(['/report']);
        }
  
        else if(this.urlpath=="/shipment"){
          if(!this.authService.isScreenacess().isShipping)
            this._router.navigate(['/report']);
        }
  
        else if(this.urlpath=="/ordermanagement"){
          if(!this.authService.isScreenacess().isOrderManagement)
            this._router.navigate(['/report']);
        }
  
        else if(this.urlpath=="/reporting"){
          if(!this.authService.isScreenacess().isReporting)
            this._router.navigate(['/report']);
        }
  
        else if(this.urlpath=="/usermanagement"){
          if(!this.authService.isScreenacess().isusermanagement)
            this._router.navigate(['/report']);
        }
  
        else if(this.urlpath=="/groupmanagement"){
          if(!this.authService.isScreenacess().isgroupmanagement)
            this._router.navigate(['/report']);
        }
      });
    } */
  
  ngOnInit() {
    this.username=localStorage.getItem("Username")
    if(localStorage.getItem("currentUser")!=null){

      this.acess=JSON.parse(localStorage.getItem("currentUser")).user.screen_name;
      if(this.acess){
          this.getScreenAcess(this.acess)
  
  
      }      
      console.log(this.authService.isScreenacess().isApproval) 
      console.log(localStorage.getItem("currentUser"))
    }
  }
    getScreenAcess(params):any{
      let token=JSON.parse(localStorage.getItem("currentUser")).token;
    let acessparams={"groupname":params,"token":token}
    let response;
    const headers = new Headers({ 'Content-Type': 'application/json'});
    // const options = new RequestOptions({headers: headers});
    const options = {
      headers: new HttpHeaders().append('key', 'value')
    }
        return this.http.post("/api/getgroup", acessparams, options).toPromise()
	           .then(
                      response => {
                        // response=JSON.parse(response["_body"]);
                        console.log(response["access_list"]);
                        if(response["access_list"]){
                          response["access_list"].map(
                            (key)=>{ 
                                if(key=="CSC Label Approval"){
                                    this.isApproval=true
                                }
                                  if(key=="CSC Manager Label Approval"){
                                  this.isManger=true
                                }
                                if(key=="Reporting"){
                                  this.isReporting=true
                                }
                                if(key=="Inventory Management"){
                                  this.isInventory=true
                                }
                                if(key=="Quality Control"){
                                  this.isQuality=true
                                }
                                 if(key=="Label Print"){
                                  this.isPrint=true
                                }
                                if(key=="Shipping"){
                                  this.isShipping=true
                                }
                                 if(key=="Order Management"){
                                  this.isOrderManagement=true
                                }
                                if(key=="User Management"){
                                  this.isusermanagement=true
                                }
                                if(key=="Group Management"){
                                  this.isgroupmanagement=true
                                }
                                if(key=="Configuration"){
                                  this.isconfig=true
                                }
                                if(key="Postal Code"){
                                  this.isutility = true
                                }
                            }
                          
                          )
                        }         
                      }

                  ).catch();


    
  }
  showlogoutBtn(){    
    this.showlogout=true;
    
  }
  logout(){
this._router.navigateByUrl("/login");
  }

}
