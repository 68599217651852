import { Component, ViewChild, ElementRef, OnInit, Inject } from '@angular/core';
import { DatalistService } from '../../user/datalist.service';
import { FormArray, FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
// import { ERROR_LOGGER } from '@angular/core/src/errors';
@Component({
  selector: 'posta-code-status',
  templateUrl: './posta-code-status.component.html',
  styleUrls: ['./posta-code-status.component.css']
})
export class PostaCodeStatusComponent implements OnInit {

  constructor(private fb: FormBuilder, private datalistService: DatalistService) { }
  searchViewToggle: boolean = false;
  searchViewToggle2: boolean = false;
  searchViewToggle3: boolean = false;
  add_postalCode = new FormControl();
  add_network_code = new FormControl();
  postalCode = new FormControl();
  NextPostalCode = new FormControl();
  newpostalCode = new FormControl();
  newNextPostalCode = new FormControl();
  shippingTypeControl = new FormControl();
  serviceTypeControl = new FormControl();
  shippingTypes = ['EXPRESSPAK5', 'PARCEL'];
  serviceTypes = ['NDD', 'TDD', 'SUD', 'SDD'];
  testSubscription: Subscription;
  rows = [];
  newArray = [];
  datalistArray = [];
  newArtArray = [];
  postalCodes: any;
  nextPostalCodes: any;
  skus: any;
  keys: any;
  registerForm: FormGroup;
  registerNetworkForm: FormGroup;
  editForm: FormGroup;
  editFormNextpostalCode: FormGroup;
  nextClicked = false;
  editArtForm: FormGroup;
  remove = true;
  nextRemove = true;
  editField: string;
  code = new FormControl();
  art_code = new FormControl();
  newcode = new FormControl();
  newArtcode = new FormControl();
  ngOnInit() {
    this.registerForm = this.fb.group({
      add_postalCode: this.add_postalCode,
    });
    this.registerNetworkForm = this.fb.group({
      add_network_code: this.add_network_code,
      shippingType: this.shippingTypeControl,
      serviceType: this.serviceTypeControl
    });
    this.editForm = this.fb.group({
      postalCode: this.postalCode,
      newpostalCode: this.newpostalCode
    });
    this.editFormNextpostalCode = this.fb.group({
      NextPostalCode: this.NextPostalCode,
      newNextPostalCode: this.newNextPostalCode
    });
    this.editArtForm = this.fb.group({
      code: this.code,
      art_code: this.art_code,
      newArtcode: this.newArtcode
    });
    this.testSubscription = this.shippingTypeControl.valueChanges
      // .pipe(debounceTime(100))
      .subscribe(value => console.log(value));
    this.datalistService.getPostalCode().subscribe(data => {
      if (data["success"] === false) {
        if (data["errcode"]) {
          alert("Error while getting Postal Code");
        }
        // this.toastr.error(data["message"]);
      } else {
        console.log(data, "data in service");
        let newPost = data["data"]
        this.nextPostalCodes = newPost[0].datalist.next_postal_code;
        this.postalCodes = newPost[0].datalist.postal_code;
        this.newArray = [];
        var m = this.postalCodes;
        var n = this.nextPostalCodes;
        for (var _i = 0; _i < m.length; _i++) {
          var NextPostalCodeEdit;
          this.newArray.push({
            postalCodes: m[_i],
            nextPostalCodes: n[_i],
            postalCodeEdit: false,
            NextPostalCodeEdit: false
          });
          console.log(this.newArray)
        }
      }
    })
    this.datalistService.getNetworkCode().subscribe(data => {
      if (data["success"] === false) {
        if (data["errcode"]) {
          alert("Error while getting Netwrok Code")
        }
        // this.toastr.error(data["message"]);
      } else {
        let newArray = data["data"];
        this.datalistArray = newArray["0"].datalist.network_codes;
        console.log("this.datalist", this.datalistArray)
      }
    })
    this.datalistService.getPRArtDimension().subscribe(data => {
      if (data["success"] === false) {
        if (data["errcode"]) {
          alert("Error while getting Art Dimensions");
        }
      } else {
        console.log(data, "data inside pr service");
        // this.skus = Object.values(data.data[0].datalist.art_board[0]);
        let newKey = data["data"]
        this.keys = Object.keys(newKey[0].datalist.art_board[0]);
        this.skus = Object.keys(newKey[0].datalist.art_board[0]).map(key => newKey[0].datalist.art_board[0][key]);
        var k = this.keys;
        var s = this.skus;
        var i = k.length

        this.newArtArray = [];
        for (var _i = 0; _i < k.length; _i++) {
          this.newArtArray.push({
            art_code: k[_i],
            code: s[_i],
            art_code_edit: false
          });
        }
        console.log(this.newArtArray, "art array")
      }
    });
  }

  onSubmit(form: NgForm) {
    console.log('Your form data : ', form.value);
  }

  public onTwoClick(): void {
    this.nextClicked = false;
  }

  public onNextClick(): void {
    this.nextClicked = true;
  }

  addButton(formdata: any): void {
    if (!this.nextClicked) {
      if (this.registerForm.dirty && this.registerForm.valid) {
        this.datalistService.addpostalCode(formdata)
          .subscribe(data => {
            if (data["success"] === false) {
              // this.toastr.error(data["message"]);
            } else {
              console.log(data);
              alert(data["message"])
              this.ngOnInit();

            }
            this.registerForm.reset();
          });
      }
    }
    else {
      if (this.registerForm.dirty && this.registerForm.valid) {
        this.datalistService.addNextPostalCode(formdata)
          .subscribe(data => {
            if (data["success"] === false) {
              // this.toastr.error(data["message"]);
            } else {
              console.log(data);
              alert(data["message"])
              this.ngOnInit();
            }
            this.registerForm.reset();
          });
      }
    }
  }

  addNetworkCode(formdata: any): void {
    if (this.registerNetworkForm.dirty && this.registerNetworkForm.valid) {
      this.datalistService.addnetworkCode(formdata)
        .subscribe(data => {
          if (data["success"] === false) {
            // this.toastr.error(data["message"]);
          } else {
            console.log(data);
            alert(data["message"])
            this.ngOnInit();

          }
          this.registerNetworkForm.reset();
        });
    }
  }
  updateButton(postalCode, newpostalCode, $event: Event): void {
    var regex = new RegExp(/[a-z!@#$%^&*(),.?":{}|<>]/);
    if (!regex.test(newpostalCode.newpostalCode)) {
      if (this.remove == false) {
        console.log(this.remove, "remove inside if")
        this.datalistService.updatepostalCode(postalCode, newpostalCode.newpostalCode)
          .subscribe(data => {
            if (data["success"] === false) {
            } else {
              alert(data["message"])
              this.ngOnInit();
            }
            this.editForm.reset();
          });
      }
      else {
        console.log(this.remove, "remove inside else")
        this.datalistService.removepostalCode(postalCode)
          .subscribe(data => {
            if (data["success"] === false) {
            } else {
              alert(data["message"])
              this.ngOnInit();
            }
            this.editForm.reset();
          });
      }
    }
    else {
      alert("Invalid Input");
      this.ngOnInit();
    }


  }

  updateButtonNextPostalCode(NextPostalCode, newpostalCode): void {
    var regex = new RegExp(/[a-z!@#$%^&*(),.?":{}|<>]/);
    if (!regex.test(newpostalCode.newNextPostalCode)) {
      if (this.nextRemove == false) {
        this.datalistService.updateNextPostalCode(NextPostalCode, newpostalCode.newNextPostalCode)
          .subscribe(data => {
            if (data["success"] === false) {
            } else {
              alert(data["message"])
              this.ngOnInit();
            }
            this.editFormNextpostalCode.reset();
          });
      }
      else {
        this.datalistService.removeNextPostalCode(NextPostalCode)
          .subscribe(data => {
            if (data["success"] === false) {
            } else {
              alert(data["message"])
              this.ngOnInit();
            }
            this.editFormNextpostalCode.reset();
          });
      }
    }
    else {
      alert("Invalid Input");
      this.ngOnInit();
    }
  }

  updateArtDimensionButton(code, art_code, formdata): void {
    var regex;
    if (art_code === "THUMBNAIL_WIDTH" || art_code === "THUMBNAIL_HEIGHT" || art_code === "QRCODE_X" || art_code === "QRCODE_Y" ||
      art_code === "QRCODE_WIDTH" || art_code === "QRCODE_HEIGHT" || art_code === "BARCODE_WIDTH" || art_code === "BARCODE_HEIGHT" ||
      art_code === "PICKTKT_INFO_X" || art_code === "PICKTKT_INFO_Y_INCREMENT") {
      regex = new RegExp(/^[0-9]\d*(\.\d+)?$/);
    }
    else {
      regex = new RegExp(/^\[(\d+\.?\d*|\d*\.?\d+,\d+\.?\d*|\d*\.?\d+)\]$/);
    }
    if (regex.test(formdata.newArtcode)) {
      var newArtCode;
      if (art_code === "THUMBNAIL_WIDTH" || art_code === "THUMBNAIL_HEIGHT" || art_code === "QRCODE_X" || art_code === "QRCODE_Y" ||
      art_code === "QRCODE_WIDTH" || art_code === "QRCODE_HEIGHT" || art_code === "BARCODE_WIDTH" || art_code === "BARCODE_HEIGHT" ||
      art_code === "PICKTKT_INFO_X" || art_code === "PICKTKT_INFO_Y_INCREMENT") {
        newArtCode = Number.parseFloat(formdata.newArtcode)
    }
    else{
      newArtCode = formdata.newArtcode;
    }
      this.datalistService.updatePRArtDimension(code, art_code, newArtCode)
        .subscribe(data => {
          if (data["success"] === false) {
          } else {
            alert(data["message"])
            this.ngOnInit();
          }
          this.editArtForm.reset();
        });
    }
    else {
      alert("Invalid Input");
      this.ngOnInit();
    }
  }

  doubleClickpostalCode(editing): void {
    if (editing.postalCodeEdit === false) {
      editing.postalCodeEdit = true;
    } else if (editing.postalCodeEdit === true) {
      editing.postalCodeEdit = false;
    }
    this.editForm.get('newpostalCode').setValue(editing.postalCodes);
  }

  doubleClickNextPostalCode(editing): void {
    if (editing.NextPostalCodeEdit === false) {
      editing.NextPostalCodeEdit = true;
    } else if (editing.NextPostalCodeEdit === true) {
      editing.NextPostalCodeEdit = false;
    }
    this.editFormNextpostalCode.get('newNextPostalCode').setValue(editing.nextPostalCodes);
  }
  doubleClickArtcode(editing): void {
    if (editing.art_code_edit === false) {
      editing.art_code_edit = true;
    } else if (editing.art_code_edit === true) {
      editing.art_code_edit = false;
    }
    this.editArtForm.get('newArtcode').setValue(editing.code);

  }
  doneEditArtcode(editing): void {
    if (editing.art_code_edit === false) {
      editing.art_code_edit = true;
    } else if (editing.art_code_edit === true) {
      editing.art_code_edit = false;
    }
  }
  doneEditpostalCode(editing): void {
    this.remove = false;
    if (editing.postalCodeEdit === false) {
      editing.postalCodeEdit = true;
    } else if (editing.postalCodeEdit === true) {
      editing.postalCodeEdit = false;
    }
  }

  doneEditNextPostalCode(editing): void {
    this.nextRemove = false;
    if (editing.NextPostalCodeEdit === false) {
      editing.NextPostalCodeEdit = true;
    } else if (editing.NextPostalCodeEdit === true) {
      editing.NextPostalCodeEdit = false;
    }
  }

  doneRemovepostalCode(editing): void {
    this.remove = true;
  }
  doneRemoveNextpostalCode(editing): void {
    this.nextRemove = true;
  }
  doubleClickcode(editing): void {
    if (editing.art_code_edit === false) {
      editing.art_code_edit = true;
    } else if (editing.art_code_edit === true) {
      editing.art_code_edit = false;
    }
    this.editForm.get('newcode').setValue(editing.code);

  }
  doneEditcode(editing): void {
    if (editing.art_code_edit === false) {
      editing.art_code_edit = true;
    } else if (editing.art_code_edit === true) {
      editing.art_code_edit = false;
    }
  }
  updateList(id: number, group: string, name: string, property: string, event: any) {
    const editField = event.target.textContent;
    const shippingType = group;
    const serviceType = name;
    var regex = new RegExp(/^[ 0-9^]*$/);
    if (regex.test(editField)) {
      console.log("here")
      this.datalistService.updateNetworkCode(id, shippingType, serviceType, editField)
        .subscribe(data => {
          if (data["success"] === false) {
          } else {
            alert(data["message"])
            this.ngOnInit();
          }
          this.editFormNextpostalCode.reset();
        });
    }
    else {
      alert("Invalid Input");
      this.ngOnInit();
    }

  }
  changeValue(id: number, group: string, name: string, property: string, event: any) {
    this.editField = event.target.textContent;
  }

}
