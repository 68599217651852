import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';
import { QaDetails } from '../../model/qaDetails.model';
import { SharedApplicationService } from '../../service/shared.service';

@Component({
    selector: 'qa-detail-view',
    templateUrl: './qa-detail-view.component.html',
    styleUrls: ['./qa-detail-view.component.css']
})
export class QADetailViewComponent {
    @Input() detailViewData: QaDetails;
    @Output() skuScanned = new EventEmitter();

    @ViewChild('productScanSKURef')
    private productScanSKURef: ElementRef;

    scanedSKU: string = "";
    skuMatch: boolean = false;
    skuMismatch: boolean = false;

    constructor(private sharedAppService: SharedApplicationService) { }

    setFocusProductScanSKU() {
        setTimeout(() => {
            this.productScanSKURef.nativeElement.focus();
        }, 1000);
    }

    checkSKUMatch() {
        if (this.scanedSKU) {
            this.sharedAppService.httpGetService(`/api/orders/match/sku/${this.detailViewData.sku}/ucc/${this.scanedSKU}`)
                .then(
                (data: any) => {
                    if (data) {
                        this.productScanSKURef.nativeElement.title = "";
                        if (data.match) {
                            this.skuMatch = true;
                            this.skuMismatch = false;
                            this.skuScanned.emit(true);
                        } else {
                            this.skuMismatch = true;
                            this.skuMatch = false;
                            this.productScanSKURef.nativeElement.title = "Product Scan SKU is not matched with Ordered SKU.";
                        }
                        this.productScanSKURef.nativeElement.select();
                    }
                },
                err => console.log(err)
                );
        } else {
            this.skuMatch = false;
            this.skuMismatch = false;
        }
    }

    clearScannedSKU() {
        this.skuMatch = false;
        this.skuMismatch = false;
        this.scanedSKU = "";
        this.productScanSKURef.nativeElement.title = "";
    }
}