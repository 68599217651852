import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { NgForm, FormGroup, FormControl } from '@angular/forms';
// import { DateAdapter, NativeDateAdapter } from '@angular/material';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { SearchResults } from '../../shared/model/searchResults.model';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { SharedApplicationService } from '../../shared/service/shared.service'
import * as moment from 'moment';
@Component({
    selector: 'app-csc-reports',
    templateUrl: './csc-reports.component.html',
    styleUrls: ['./csc-reports.component.css'],
    animations: [
        trigger('shrinkInOut', [
            transition(':leave', [
                style({ height: "*" }),
                animate(350, style({ height: 0 }))
            ]),
            transition(':enter', [
                style({ height: 0 }),
                animate(350, style({ height: "*" }))
            ])
        ])
    ]
})
export class CscReportsComponent implements OnInit {
    activelink(menu) {
        if (menu == "salesreport") {
            this.router.navigate(['/reporting']);

            this.shapshotChild = false;
            this.userproductivity = false
            this.shiftproduction = false
            this.snapshot = false
            this.salesreport = true
            this.shippedreport = false;
            this.processingreport = false
            this.dataextract =false;
            this.crsreport = false
            this.notsyncedship = false
            this._loadsalesReport()
        }
        else if (menu == "shippedreport") {
            this.router.navigate(['/reporting']);
            this.shapshotChild = false;
            this.userproductivity = false
            this.shiftproduction = false
            this.snapshot = false
            this.salesreport = false
            this.shippedreport = true;
            this.crsreport = false
            this.processingreport = false
            this.dataextract = false
            this.notsyncedship = false
            this._loadshippedReport()
        }
        else {
            this.router.navigate(['/reporting/' + menu]);
        }
    }
    constructor(private http: HttpClient,
        private route: ActivatedRoute, private router: Router, public sharedService:SharedApplicationService) {
        route.url.subscribe(() => {
            if (route.snapshot.firstChild) {
                if (route.snapshot.firstChild.routeConfig.path == "snapshot"
                    || route.snapshot.firstChild.routeConfig.path == "shiftproduction"
                    || route.snapshot.firstChild.routeConfig.path == "userproductivity"
                    || route.snapshot.firstChild.routeConfig.path == "processingreport"
                    || route.snapshot.firstChild.routeConfig.path == "crsreport"
                    || route.snapshot.firstChild.routeConfig.path == "notsyncedship"
                    || route.snapshot.firstChild.routeConfig.path == "dataextract"
                ) {
                    this.shapshotChild = true;
                    if (route.snapshot.firstChild.routeConfig.path == "userproductivity") {
                        this.userproductivity = true
                        this.shiftproduction = false
                        this.snapshot = false
                        this.salesreport = false
                        this.shippedreport = false;
                        this.processingreport = false;
                        this.dataextract = false;
                        this.crsreport = false
                        this.notsyncedship = false
                        
                    }
                    else if (route.snapshot.firstChild.routeConfig.path == "shiftproduction") {
                        this.userproductivity = false
                        this.shiftproduction = true
                        this.snapshot = false
                        this.salesreport = false
                        this.shippedreport = false;
                        this.processingreport = false
                        this.dataextract = false;
                        this.crsreport = false
                        this.notsyncedship = false
                    }
                    else if (route.snapshot.firstChild.routeConfig.path == "snapshot") {
                        this.userproductivity = false
                        this.shiftproduction = false
                        this.snapshot = true
                        this.salesreport = false
                        this.shippedreport = false;
                        this.processingreport = false
                        this.dataextract = false;
                        this.crsreport = false
                        this.notsyncedship = false
                    }
                    else if (route.snapshot.firstChild.routeConfig.path == "processingreport") {
                        this.userproductivity = false
                        this.shiftproduction = false
                        this.snapshot = false
                        this.salesreport = false
                        this.shippedreport = false;
                        this.processingreport = true;
                        this.crsreport = false
                        this.crsreport = false
                        this.notsyncedship = false
                        
                    }
                    else if(route.snapshot.firstChild.routeConfig.path == "dataextract"){
                        this.userproductivity = false
                        this.shiftproduction = false
                        this.snapshot = false
                        this.salesreport = false
                        this.shippedreport = false;
                        this.processingreport = false;
                        this.dataextract = true
                        this.crsreport = false
                        this.crsreport = false
                        this.notsyncedship = false
                    }
                    else if (route.snapshot.firstChild.routeConfig.path == "crsreport") {
                        this.userproductivity = false
                        this.shiftproduction = false
                        this.snapshot = false
                        this.salesreport = false
                        this.shippedreport = false;
                        this.processingreport = false;
                        this.dataextract = false;
                        this.crsreport = true
                        this.notsyncedship = false
                    }
                    else if (route.snapshot.firstChild.routeConfig.path == "notsyncedship") {
                        this.userproductivity = false
                        this.shiftproduction = false
                        this.snapshot = false
                        this.salesreport = false
                        this.shippedreport = false;
                        this.processingreport = false;
                        this.dataextract = false
                        this.crsreport = false
                        this.notsyncedship = true
                    }
                    else {
                        this.userproductivity = false
                        this.shiftproduction = false
                        this.snapshot = false
                        this.salesreport = false
                        this.shippedreport = false;
                        this.processingreport = false
                        this.dataextract = false;
                        this.crsreport = false
                        this.notsyncedship = false
                    }

                }
            }

        });
    }

    userproductivity: boolean = false
    snapshot: boolean = false
    shiftproduction: boolean = false
    salesreport = false;
    shippedreport = false
    processingreport = false
    dataextract=false;
    crsreport = false
    notsyncedship = false
    ngOnInit() {
        if (this.route.snapshot["_routerState"].url == "/reporting") {
            this.salesreport = true
        }
        
        this._loadsalesReport()
        //this.salesReports(this.salesreportform)
        
    }
    shippedFromDate
    shippedToDate
    _loadshippedReport() {
        this.shippedFromDate = this.sharedService.gettodaydates()["Fromdate"]
        this.shippedToDate = this.sharedService.gettodaydates()["Todate"]
        this.shippedFromDate = moment.parseZone(this.shippedFromDate).format("YYYY-MM-DDTHH:mm:ss")
        this.shippedToDate = moment.parseZone(this.shippedToDate).format("YYYY-MM-DDTHH:mm:ss")
        this.http.get<SearchResults>('/api/orders/shippedorders?fromdate=' +
            this.shippedFromDate + "&todate=" + this.shippedToDate, {})
            .subscribe(
            // Success
            data => {
                this.OrdersArray = this.floatingvalues(data["orderdata"])
                this.SkuArray = this.floatingvalues(data["skudata"])
                this.summaryCount = this.summarycalc(this.OrdersArray)
                // console.log(data)
                // this.OrdersArray = this.removeDubs(data);
        },
        // Error
        err => {
            console.log(err);
        }
        )
    }
    
    sToDate
    sFromDate
    _loadsalesReport(){
        this.sFromDate=this.sharedService.gettodaydates()["Fromdate"]
        this.sToDate=this.sharedService.gettodaydates()["Todate"]
        this.sFromDate = moment.parseZone(this.sFromDate).format("YYYY-MM-DDTHH:mm:ss")
        this.sToDate = moment.parseZone(this.sToDate).format("YYYY-MM-DDTHH:mm:ss")
        this.http.get<SearchResults>('/api/orders/dates?fromdate=' +
        this.sFromDate + "&todate=" + this.sToDate, {  })
        .subscribe(
        // Success
        data => {
                this.OrdersArray = this.floatingvalues(data["orderdata"])
                this.SkuArray = this.floatingvalues(data["skudata"])
                this.summaryCount = this.summarycalc(this.OrdersArray)
                //console.log(data)
                //this.OrdersArray = this.removeDubs(data);
        },
        // Error
        err => {
            console.log(err);
        }
        )
    }
    public shapshotChild: boolean = false
    columns: any[] = [
        { name: 'SKU', prop: 'sku' },
        { name: 'SKU DESCRIPTION', prop: 'sku_description' },
        { name: 'QUANTITY', prop: 'skuordercount', sortable: false },
        { name: 'TOTAL PRICE', prop: 'line_item_price', sortable: false },
        { name: 'CURRENCY', prop: 'currency', sortable: false }

    ];

    public showShipping: boolean = false;
    reportForm = new FormGroup({
        FromDate: new FormControl(),
        ToDate: new FormControl()

    });
    salesreportform = new FormGroup({
        FromDate: new FormControl(),
        ToDate: new FormControl()

    });


    // private FromDate: string;
    // private ToDate: string;
    // private OrdersArray: any;
    // private showErrors: boolean;
    public FromDate: string;
    public ToDate: string;
    public OrdersArray: any;
    public showErrors: boolean;
    SkuArray;
    clearDates(from) {
        this.reportForm.reset();
        this.FromDate = "";
        this.ToDate = "";
        this.showorderview = false

    }
    fromDateInput = (e, field) => {
        let $thisDate = e.value.toJSON().split("/")
        // this.FromDate = $thisDate[1] + "/" + $thisDate[2].split("T")[0]+"/"+$thisDate[0]
        var dates = e.targetElement.value.split("/");
        this.FromDate = dates[2] + "/" + dates[0] + "/" + dates[1]
    }
    toDateInput = (e, field) => {
        let $thisDate = e.value.toJSON().split("/")
        //this.ToDate = $thisDate[1] + "/" + $thisDate[2].split("T")[0] + "/" + $thisDate[0]
        var dates = e.targetElement.value.split("/");
        this.ToDate = dates[2] + "/" + dates[0] + "/" + dates[1]
    }
    fromToDateMismatch:boolean=false
    checkDateValidity(from, to): boolean {
        if (from && to && from > to) {
            return false;
        }
        //returning true by default
        return true;
    }
    fromDate
    toDate
    fromToDateMismatchshipping=false
    fromToDateMismatchsales=false
    totalquantity=0
    totalorders=0
	dateSelectedshipping(e, field) {
        if(field === "from") {
            this.fromDate = e;
        } else {
            this.toDate = e;
        }
		if(typeof(this.fromDate)=="string"&&this.fromDate!="")
            this.fromDate=new Date(this.fromDate)
        this.fromToDateMismatchshipping = !this.checkDateValidity(this.fromDate, this.toDate);
    }
    dateSelectedsales(e, field) {
        if(field === "from") {
            this.fromDate = e;
        } else {
            this.toDate = e;
        }
		if(typeof(this.fromDate)=="string"&&this.fromDate!="")
            this.fromDate=new Date(this.fromDate)
        this.fromToDateMismatchsales = !this.checkDateValidity(this.fromDate, this.toDate);
    }
    showorderview = false
    summaryCount;
    changeview(value) {
        var summary;
        summary = this.summarycalc(this.OrdersArray)
        value.target.checked ? this.showorderview = true:this.showorderview = false
    }
    salesReports(form) {
        let params = new HttpParams();
        //params["fromdate"] = this.FromDate;
        //params["todate"] =  this.ToDate 

        if (form.value.FromDate == undefined || form.value.FromDate == "" && form.value.ToDate == undefined || form.value.ToDate == "") {
            this.showErrors = true
            return false
        }
        this.showErrors = false
        this.http.get<SearchResults>('/api/orders/dates?fromdate=' +
            form.value.FromDate + "&todate=" + form.value.ToDate, { params })
            .subscribe(
            // Success
            data => {
                //console.log(data)
                this.OrdersArray = this.floatingvalues(data["orderdata"])
                this.SkuArray = this.floatingvalues(data["skudata"])
                this.summaryCount = this.summarycalc(this.OrdersArray)

                //this.OrdersArray = this.removeDubs(data);
            },
            // Error
            err => {
                console.log(err);
            }
            )
    }
    removeDubs(array) {
        this.totalquantity=0;
        this.totalorders=0;
        var previous = [], totalPricePound = 0, totalPriceeuro = 0;
        for (let i = 0; i < array.length; i++) {
            this.totalorders+=1
            let IsExisting = false;
            // console.log(i)
            if (array[i].order.sku != undefined) {
               // console.log(array[i].order.line_item_quantity+"---sku----"+array[i].order.sku+"--price--"+array[i].order.line_item_price.toFixed(2))
                for (let j = 0; j < previous.length; j++) {
                   
                    if (previous[j].sku == array[i].order.sku && previous[j].currency == array[i].order.currency) {
                        previous[j].skuordercount = previous[j].skuordercount + array[i].order.line_item_quantity;
                        let totalprice = 0;
                        if (previous[j].line_item_price != undefined && previous[j].line_item_price != null)
                            totalprice = previous[j].line_item_price/1 + array[i].order.line_item_price/1
                        else
                            totalprice =  array[i].order.line_item_price.toFixed(2)
                        previous[j].line_item_price = parseFloat(totalprice.toString()).toFixed(2)
                        IsExisting = true;

                    }

                }

                if (previous.length == 0) {
                    array[i].order.skuordercount = array[i].order.line_item_quantity;
                    let obj = {
                        sku: array[i].order.sku,
                        skuordercount: array[i].order.line_item_quantity,
                        orderindex: i,
                        sku_description: array[i].order.sku_description,
                        line_item_price: array[i].order.line_item_price.toFixed(2),
                        currency: array[i].order.currency
                    }
                    previous.push(obj)

                }
                else if (!IsExisting) {
                    let obj = {
                        sku: array[i].order.sku,
                        skuordercount: array[i].order.line_item_quantity,
                        orderindex: i,
                        sku_description: array[i].order.sku_description,
                        line_item_price: array[i].order.line_item_price.toFixed(2),
                        currency: array[i].order.currency
                    }
                    previous.push(obj)


                }
                if (array[i].order.currency == "Pound" || "GBP") {
                    totalPricePound = totalPricePound + array[i].order.line_item_price.toFixed(2);
                }
                else if (array[i].order.currency == "euro") {
                    totalPriceeuro = totalPriceeuro + array[i].order.line_item_price.toFixed(2);
                }
		this.totalquantity=this.totalquantity+array[i].order.line_item_quantity;

            }

        }

        previous["TotalPrice"] = {
            Pound: totalPricePound.toFixed(2),
            Euro: totalPriceeuro.toFixed(2)
        }
        console.log(previous)
        return previous;
    }

    showshippingrecods(form) {
        let params = new HttpParams();
        params["fromdate"] = this.FromDate;
        params["todate"] = this.FromDate;
        this.FromDate = form.controls.FromDate.value
        this.ToDate = form.controls.ToDate.value
        if (this.FromDate == undefined || this.FromDate == "" && this.ToDate == undefined || this.ToDate == "") {
            this.showErrors = true
            return false
        }
        this.showErrors = false
        this.FromDate = moment.parseZone(this.FromDate).format("YYYY-MM-DDTHH:mm:ss")
        this.ToDate = moment.parseZone(this.ToDate).format("YYYY-MM-DDTHH:mm:ss")
        this.http.get<SearchResults>('/api/orders/shippedorders?fromdate=' +
            this.FromDate + "&todate=" + this.ToDate, { params })
            .subscribe(
            // Success
            data => {
                this.OrdersArray = this.floatingvalues(data["orderdata"])
                this.SkuArray = this.floatingvalues(data["skudata"])
                this.summaryCount = this.summarycalc(this.OrdersArray)
                
                //this.OrdersArray = this.removeDubs(data);
            },
            // Error
            err => {
                console.log(err);
            }
            )
    }

    exportcsv(jsonData, report): any {
        debugger
        let today = new Date();
        var summary;
        if (report == "sku") {
            summary = 'Sku, Sku Description, Quantity, Total Price, Currency\n';
            jsonData.forEach(function (row) {
                summary += row._id + ',' + row.sku_description.replace(/[^a-zA-Z\s]/gi, '')  + ',' + row.line_item_quantity + ',' + row.Total_Amount + ',' + row.Currency + ',';
                summary += "\n";
            });
        }
        else {
            summary = 'Order ID, Shipment Group ID, Quantity, Total Price, Currency\n';
            jsonData.forEach(function (row) {
                summary += row._id + ',' + row.shipment_group_id + ',' + row.line_item_quantity + ',' + row.Total_Amount + ',' + row.Currency + ',';
                summary += "\n";
            });
        }


        //console.log(summary);
        // var csvElement = document.createElement('a');
        // csvElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(summary);
        // csvElement.target = '_blank';
        // csvElement.download = 'Report_' + today + '.csv';
        // document.body.appendChild(csvElement); // Required for FF
        // csvElement.click();
       

        var encodedUri = encodeURI(summary);
        var link = document.createElement("a");
        link.setAttribute("href", 'data:text/csv;charset=utf-8,' + encodeURI(summary));
        link.setAttribute("download",'Report_' + today + '.csv');
        document.body.appendChild(link); // Required for FF
        
        link.click();
    }
    /* exportcsv(Json): any {
        let response;
        const headers = new Headers({ 'Content-Type': 'application/json' });
        const options = new RequestOptions({ headers: headers });
        return this.Http.post("/api/orders/exportCsv", Json, options).toPromise()
            .then(
            response => {
                console.log(response)
                this.downloadFile(response)
            }
            ).catch();
    }
    downloadFile(data) {
        let resData = data["_body"];
        resData = JSON.parse(resData)
        window.open('/api/orders/exportCsv/' + resData["path"], "_blank");
    } */
    floatingvalues(data){
        var newArr=[];
        data.map(function (val) {
            val.Total_Amount=val.Total_Amount.toFixed(2)
            newArr.push(val)
        })
        return newArr
    }
    summarycalc(data) {
        //Pounds = 0, 
        var Pounds = 0, Euro = 0, totalquantity = 0, totalorders = 0;
        if(data.length>0){

       
        data.map(function (val) {
            if (val.Currency == "EUR")
                Euro = Euro + parseFloat(val.Total_Amount)
            else if (val.Currency == "Pound"|| val.Currency == "GBP")
                Pounds = Pounds + parseFloat(val.Total_Amount)
            totalquantity = totalquantity + val.line_item_quantity
            totalorders = totalorders + 1
        })

        
    }
    else{
        Pounds=0
        Euro=0
        totalquantity=0
        totalorders=0
    }
    var summary = {
        "Pound": Pounds.toFixed(2),
        "Euro": Euro.toFixed(2),
        "totalquantity": totalquantity,
        "totalorders": totalorders
    }
        return summary
    }
}
