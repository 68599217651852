import { Pipe, PipeTransform } from '@angular/core';
import { intersection } from 'lodash';
import _ from "lodash";

@Pipe({
  name: 'filter2',
  pure: false
})
export class Filter2Pipe implements PipeTransform {

    transform(groups: any, term12: string, term22: string, accesses: Array<any>): any {
            if (groups && groups.length) {
              return groups.filter(group => {
                if (term12 && group.group_name.toLowerCase().indexOf(term12.toLowerCase()) === -1) {
                  return false;
                }
                if (term22 && group.group_description.toLowerCase().indexOf(term22.toLowerCase()) === -1) {
                  return false;
                }
                if (accesses) {
                  let checkedItems = [];
                  for (var i = 0; i < accesses.length; i++) {
                    if (accesses[i].checked) {
                      checkedItems.push(accesses[i].name)
                    }
                    var intersection = _.intersection(checkedItems, group.access_list)
                    // console.log(checkedItems) 
                  } if (checkedItems.length == 0) {
                    return true
                  }
                  if (intersection.length == 0) {
                    return false
                  }
                }
                return true
              })
            }
            else {
              return groups;
            }
          }
        
        }
        

//   transform(groups: any, term12: string, term22: string, term32: any){
//     // var intersection = []
//           // if (access_filter) {
//           //   intersection = access_filter.filter((n) => groups.access_list.includes(n));
//           // }

//       if (groups && groups.length){
//           return groups.filter(group =>{
//               if (term12 && group.group_name.toLowerCase().indexOf(term12.toLowerCase()) === -1){
//                   return false;
//               }
//               if (term22 && group.group_description.toLowerCase().indexOf(term22.toLowerCase()) === -1){
//                   return false;
//               }
//               if (filterScreens) {
//                 let checkedItems = [];
//                 for (var i = 0; i < filterScreens.length; i++) {
//                   if (filterScreens[i].checked) {
//                     checkedItems.push(filterScreens[i].value)
//                   }
//                   var intersection = _.intersection(checkedItems, group.access_list)
//                 } if (checkedItems.length == 0) {
//                   return true
//                 }
//                 if (intersection.length == 0) {
//                   return false
//                 }
//               }
      
//               return true;
//          })
//       }
//       else{
//           return groups;
//       }
//   }

// }
