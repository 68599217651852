import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SearchSharedModule } from '../../shared/module/searchshared.module';

import { InventoryManagementPageComponent, DialogPreviewSummary } from './inventoryManagement.component';

@NgModule({
    imports: [
        SearchSharedModule,
        CommonModule
    ],
    declarations: [
        InventoryManagementPageComponent,
        DialogPreviewSummary
    ],
    exports: [InventoryManagementPageComponent, DialogPreviewSummary],
    bootstrap: [ DialogPreviewSummary]
})

export class InventoryManagementModule{}