import { Component, ViewChild, ElementRef, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { DOCUMENT } from '@angular/common';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { ShippingOrderDetails } from '../../shared/model/shippingOrderDetails.model'
import { UserInfoModalService } from '../../shared/util/modal/user-info.modal';
import { SharedApplicationService } from '../../shared/service/shared.service';
import { PackagingSlipPrintViewComponent } from '../../shared/components/print-view/packaging-slip-print-view.component';
import { MatDialog } from '@angular/material/dialog';
import { ManualConsignmentComponent } from '../shippingstatus/manual-consignment/manual-consignment.component';

@Component({
    selector: 'shipping-status',
    templateUrl: './shippingstatus.component.html',
    styleUrls: ['./shippingstatus.component.css']
})
export class ShippingStatusComponent {

    constructor(@Inject(DOCUMENT) private document: Document, private http: HttpClient, private userModal: UserInfoModalService,
        private sharedAppService: SharedApplicationService, public dialog: MatDialog,) { }

    ngAfterViewInit() {
        this.orderNumEleRef.nativeElement.focus();
    }

    @ViewChild('orderNumRef')
    private orderNumEleRef: ElementRef;

    radioSelected: any = "UPS";
    isLoading: boolean = false;
    isOrderLoaded: boolean = false;
    orderError: string = "";
    isNumOfPackValid: boolean = false;
    orderNumber: string;
    orderLineItemsList = [];
    orderDetails: ShippingOrderDetails;
    disableActionItems: boolean = true;
    isStatusShipped: boolean = false;
    collectionDate;
    shippingTypes: any;
    warnCountryInfo: boolean = false;
    EUCountry: boolean = false;
    serviceInfoType: boolean = false;
    upsError;
    searchparams = {
        pageno: 1,
        pagesize: 0
    }

    searchResults = {
        totalOrderCount: 1,
        totalPageCount: 1,
        currentPageNo: 1,
        currentPageSize: 1,
        orders: []
    }

    columns = [
        { name: 'Order Id', flexGrow: 3, prop: 'order_id' },
        { name: 'Description', flexGrow: 7, prop: 'sku_desc' },
        { name: 'Qty Ordered', flexGrow: 2, prop: 'quantity' },
        { name: 'Qty Shipped', flexGrow: 2, prop: 'disp_ship_qty' },
        { name: 'Qty Outstanding', flexGrow: 2, prop: 'quantity_rejected' }
    ]
    shippingDetails = {
        shippingType: "Expresspak 5",
        shipmentType: "DPD Local",
        serviceType: "Next Day Delivery",
        customerServiceType: "Standard Delivery",
        invoice_number: "",
        numberOfPackages: 1,
        weight: "4.9",
        selectedShippingType: {
            networkCode: "",
            serviceType: "",
            type: ""
        }
    }

    UKShipmentType = [
        { name: 'UPS' },
        { name: 'DPD Local' }
    ]

    getOrderDetails() {
        if (!this.orderNumber) {
            return false;
        }
        this.orderError = "";
        this.warnCountryInfo = false;
        this.serviceInfoType = false;
        this.sharedAppService.httpGetService(`/api/orders/${this.orderNumber}/shipping`)
            .then(
                (data) => {
                    if (data && data["order_line_items"].length) {
                        this.orderDetails = data["order_details"];
                        this.orderLineItemsList = data['order_line_items'];
                        let orderCancelRejectList = [];
                        this.shippingTypes = data["dpd_details"].shippingTypes;
                        let shipCountry = data["dpd_details"].countryCode;
                        for (let i = 0; i < this.shippingTypes.length; i++) {
                            if (this.orderDetails.order_status == 'Shipped') {
                                if (this.shippingTypes[i].type == this.orderDetails.shipped_shipping_type) {
                                    this.shippingDetails.selectedShippingType = this.shippingTypes[i];
                                }
                            } else {
                                if (this.shippingTypes[i].type == 'Expresspak 5') {
                                    this.shippingDetails.selectedShippingType = this.shippingTypes[i];
                                    break;
                                } else if (this.shippingTypes[i].type == 'Parcel') {
                                    this.shippingDetails.selectedShippingType = this.shippingTypes[i];
                                }
                            }
                        }
                        if (shipCountry.indexOf("GB") == -1) {
                            this.shippingDetails.weight = "3";
                        }
                        else {
                            this.shippingDetails.weight = "4.9";
                        }
                        this.shippingDetails.invoice_number = this.orderDetails.invoice_number;
                        this.shippingDetails.numberOfPackages = 1;
                        this.shippingDetails.shippingType = this.shippingDetails.selectedShippingType.type;
                        this.shippingDetails.serviceType = this.shippingDetails.selectedShippingType.serviceType;
                        let qaVerifiedList = this.orderLineItemsList.filter((order, index) => {
                            //order quatity based on order status
                            if (order.order_status == "Shipped") {
                                this.orderLineItemsList[index].disp_ship_qty = order.shipped_quantity;
                                this.isStatusShipped = true;
                                this.orderNumEleRef.nativeElement.focus();
                            } else {
                                if (order.quantity_confirmed === null || order.quantity_confirmed === "") {
                                    this.orderLineItemsList[index].disp_ship_qty = order.shipped_quantity;
                                }
                                else {
                                    this.orderLineItemsList[index].disp_ship_qty = order.quantity_confirmed;
                                }
                                this.isStatusShipped = false;
                            }
                            //seperate cancelled and rejected orders
                            if (order.order_status == "Cancelled" || order.order_status == "Rejected") {
                                orderCancelRejectList.push(order);
                            }
                            return (order.order_status == "QA Verified" || order.order_status == "Shipped");
                        });
                        this.isOrderLoaded = true;
                        this.setPagination();
                        if (/^JE/.test(this.orderDetails.shipTo_cust_postcode) || /^GY/.test(this.orderDetails.shipTo_cust_postcode)) {
                            this.warnCountryInfo = true;
                        }
                        if (this.shippingDetails.serviceType == "International Delivery") {
                            this.serviceInfoType = true;

                        }
                        if (this.orderDetails.shipTo_cust_country == "GB") {
                            this.orderDetails.shipTo_cust_country = data["dpd_details"].countryCode;
                            
                            this.EUCountry = false;
                            if(this.orderDetails.shipTo_cust_postcode.toLowerCase().indexOf("bt") > -1){
                                this.EUCountry = true;
                            }
                        }
                        else {
                            console.log("fig countries")
                            this.EUCountry = true
                        }

                        if (orderCancelRejectList.length != this.orderLineItemsList.length) {
                            if (qaVerifiedList.length + orderCancelRejectList.length == this.orderLineItemsList.length) {
                                this.disableActionItems = false;
                            } else {
                                this.disableActionItems = true;
                                this.orderError = "Some/All the orderlines in the order are not QA Verified."
                            }
                        } else {
                            this.disableActionItems = true;
                            this.orderError = "All the orderlines in the order are Cancelled/Rejected."
                        }
                    } else {
                        this.isOrderLoaded = false;
                        this.orderDetails = new ShippingOrderDetails();
                        this.searchResults = {
                            totalOrderCount: 1,
                            totalPageCount: 1,
                            currentPageNo: 1,
                            currentPageSize: 1,
                            orders: []
                        }
                        this.orderError = "No data available for the entered order."
                    }
                },
                err => console.log(err)
            );
    }

    setPagination() {
        let startIndex: number;
        let pagesetSize: number = this.searchparams.pagesize ? Number(this.searchparams.pagesize) : this.orderLineItemsList.length;
        startIndex = this.searchparams.pageno ? (Number(this.searchparams.pageno) - 1) * pagesetSize : 0;
        this.searchResults.orders = this.orderLineItemsList.slice(startIndex, startIndex + pagesetSize);
        this.searchResults.totalOrderCount = this.orderLineItemsList.length;
        this.searchResults.totalPageCount = Math.ceil(this.orderLineItemsList.length / pagesetSize);
        this.searchResults.currentPageNo = Number(this.searchparams.pageno);
        this.searchResults.currentPageSize = this.searchResults.orders.length;
    }

    searchOnPaginationchange() {
        this.setPagination();
    }
    printPackagingSlip() {
        if (this.radioSelected !== "UPS") {
            if (this.warnCountryInfo && /^[a-zA-Z]{2}\/\d{4}\/\d{1}[a-zA-Z]{2}/.test(this.shippingDetails.invoice_number) === false) {
                // show error
                this.orderError = "Please enter valid Invoice number";
                window.scroll(0, 0);
                return false;
            }
        }

        this.sharedAppService.sendPrint(PackagingSlipPrintViewComponent, { order_details: this.orderDetails, order_line_items: this.getValidShipmentOrders(), radioButton: this.radioSelected });
    }

    numOfPackageChange(e) {
        if (/\D/g.test(e.target.value)) {
            // Filter non-digits from input value.
            e.target.value = e.target.value.replace(/\D/g, '');
        }
        this.shippingDetails.numberOfPackages = e.target.value;
    }

    shippingTypeChange() {
        this.shippingDetails.shippingType = this.shippingDetails.selectedShippingType.type;
        this.shippingDetails.serviceType = this.shippingDetails.selectedShippingType.serviceType;
        if (this.shippingDetails.shippingType.toLowerCase().indexOf('express') > -1) {
            this.shippingDetails.numberOfPackages = 1;
        }
    }

    orderIdFocused() {
        this.orderNumEleRef.nativeElement.select();
    }

    getValidShipmentOrders() {
        return this.orderLineItemsList.filter((order) => {
            return (order.order_status == "QA Verified" || order.order_status == "Shipped");
        })
    }
    onShipmentTypeChange(event) {
        this.radioSelected = event.target.value;

    }

    printShippingLabel() {
        let e = new Date();
        let order_lines = [];
        // let networkCode: string;
        //check madatory fields available 
        if (!parseInt(this.shippingDetails.numberOfPackages.toString()) || !this.collectionDate) {
            //show error
            this.orderError = "All fields are mandatory."
            window.scroll(0, 0);
            return false;
        } else if (this.radioSelected !== "UPS") {
            if (this.warnCountryInfo && /^[a-zA-Z]{2}\/\d{4}\/\d{1}[a-zA-Z]{2}/.test(this.shippingDetails.invoice_number) === false) {
                // show error
                this.orderError = "Please enter valid Invoice number";
                window.scroll(0, 0);
                return false;
            }
        }
        else {
            //remove error
            this.orderError = "";
        }
        order_lines = this.getValidShipmentOrders().map((obj) => {
            return { order_id: obj.order_id, line_item_quantity: obj.quantity, shipped_quantity: obj.disp_ship_qty }
        });
        // for(let i = 0; i < this.shippingTypes.length; i++) {
        //     if(this.shippingTypes[i].type == this.shippingDetails.shippingType) {
        //         networkCode = this.shippingTypes[i].networkCode;
        //     }
        // }
        let postParams = {
            order_id: this.orderDetails.order_id,
            source_order_id: this.orderDetails.source_order_id,
            shipment_group_id: this.orderDetails.shipment_group_id,
            currentDate: new Date().toJSON(),
            shippingDetails: {
                shippingType: this.radioSelected == "UPS" ? "UPSS" : this.shippingDetails.shippingType,
                shipmentType: this.radioSelected,
                serviceType: this.radioSelected == "UPS" ? "" : this.shippingDetails.serviceType,
                customerServiceType: this.radioSelected == "UPS" ? "" : this.shippingDetails.customerServiceType,
                invoiceNumber: this.shippingDetails.invoice_number,
                numberOfPackages: this.shippingDetails.numberOfPackages,
                weight: this.shippingDetails.weight,
                collectionDate: moment.parseZone(this.collectionDate).format("YYYY-MM-DDTHH:mm:ss"),
                networkCode: this.shippingDetails.selectedShippingType.networkCode
            },
            deliveryDetails: {
                "firstName": this.orderDetails.shipTo_cust_firstName,
                "lastName": this.orderDetails.shipTo_cust_lastName,
                "email": this.orderDetails.cust_email,
                "number": this.orderDetails.cust_phone,
                "address_line_1": this.orderDetails.shipTo_cust_address_line_1,
                "address_line_2": this.orderDetails.shipTo_cust_address_line_2,
                "address_line_3": this.orderDetails.shipTo_cust_address_line_3,
                "town": this.orderDetails.shipTo_cust_town,
                "county": this.orderDetails.shipTo_cust_county,
                "postcode": this.orderDetails.shipTo_cust_postcode,
                // "postcode": "B66 1BY",//TODO: remove this
                "country": this.orderDetails.shipTo_cust_country
            },
            order_lines: order_lines
        }
        this.sharedAppService.httpPostService(`/api/shipping/createShipment`, postParams)
            .then(
                // Success
                (data) => {
                    var shipCountryCode = postParams.deliveryDetails.country;
                    console.log("Country: ", shipCountryCode);
                    if (this.radioSelected === 'DPD Local') {
                        this.http.get(`/api/shipping/getShippingLabel/${data['shipmentId']}?country=${shipCountryCode}`, { responseType: 'text' })
                            .subscribe(
                                rawData => {
                                    var blob = new Blob([rawData], { type: "text/vnd.citizen-clp; charset=utf-8" });
                                    saveAs(blob, `${this.orderDetails.source_order_id}_${data['shipmentId']}_shippingLabel.prn`);
                                    this.getOrderDetails();
                                },
                                err => {
                                    console.log(err);
                                    this.userModal.openModal({ status: 'error', title: 'Action Failed', message: `Printing Shipping Label failed.` });
                                }
                            );
                    }
                    else {
                        if (data['ShipmentResults']['PackageResults'].length > 0) {
                            for (var i = 0; i < data['ShipmentResults']['PackageResults'].length; i++) {
                                var rawData = data['ShipmentResults']['PackageResults'][i]['ShippingLabel']['GraphicImage'];
                                const byteArray = new Uint8Array(atob(rawData).split('').map(char => char.charCodeAt(0)));
                                var blob = new Blob([byteArray], { type: "image/png" });
                                saveAs(blob, `${this.orderDetails.source_order_id}_shippingLabel.zpl`);
                                this.getOrderDetails();
                            }
                        }
                        else {
                            var rawData = data['ShipmentResults']['PackageResults']['ShippingLabel']['GraphicImage'];
                            const byteArray = new Uint8Array(atob(rawData).split('').map(char => char.charCodeAt(0)));
                            var blob = new Blob([byteArray], { type: "image/png" });
                            saveAs(blob, `${this.orderDetails.source_order_id}_shippingLabel.zpl`);
                            this.getOrderDetails();
                        }

                    }
                },
                // Error
                err => {
                    console.log(err);
                    var errormessage;
                    if (this.radioSelected === 'UPS') {
                        this.upsError = err.error.error.response.errors[0].message;
                        if ((this.orderDetails.shipTo_cust_address_line_1.length === 0) || (this.orderDetails.shipTo_cust_address_line_1.length > 35))
                            this.upsError = "Invalid / Missing address line1 in UPS Shipment. Please do Manual Shipment and update tracking number.";
                        if ((this.orderDetails.shipTo_cust_town.length === 0) || (this.orderDetails.shipTo_cust_town.length > 30))
                           this.upsError = "Invalid / Missing Town in UPS Shipment.Please do Manual Shipment and update tracking number.";
                        if ((this.orderDetails.cust_phone.length === 0) || (this.orderDetails.cust_phone.length > 15))
                           this.upsError = "Missing / Invalid Phone Number in UPS Shipment.Please do Manual Shipment and update tracking number.";
                        this.manualConsignment();

                        // this.userModal.openModal({ status: 'error', title: 'Action Failed', message: `Printing Shipping Label failed. ${upsError}` });
                    }
                    else if (this.radioSelected === "DPD Local") {
                        errormessage = err.error.error.message;
                        this.upsError = errormessage;
                        this.manualConsignment();
                        /* this.userModal.openModal({ status: 'error', title: 'Action Failed', message: `Printing Shipping Label failed. ${errormessage}` }); */
                    }

                }
            )
    }

    manualConsignment() {
        console.log("logged here")
        let totalQuantities = 0, orderLineItemlist = this.orderLineItemsList;
        let orderDetails = this.orderDetails, order_lines = [],
        upsError=this.upsError;
        order_lines = this.getValidShipmentOrders().map((obj) => {
            return { order_id: obj.order_id, line_item_quantity: obj.quantity, shipped_quantity: obj.quantity }
        });
        for (let i = 0; i < this.orderLineItemsList.length; i++) {
            totalQuantities += (this.orderLineItemsList[i].disp_ship_qty !== '' || this.orderLineItemsList[i].disp_ship_qty !== null) ?
                this.orderLineItemsList[i].disp_ship_qty : this.orderLineItemsList[i].quantity;
        }
        var num_of_pkg = 0, total_qty = 0;
        //for multi quantity and single orders
        if (order_lines.length == 1) {
            for (var i = 0; i < order_lines.length; i++) {
                var shipped_qty = order_lines[i].shipped_quantity
                for (var j = 0; j < shipped_qty; j++) {
                    if (j % 3 == 0) {
                        num_of_pkg++;
                    }
                }
            }
        }
        else {
            //for multi line orders
            for (var i = 0; i < order_lines.length; i++) {
                total_qty = total_qty + order_lines[i].shipped_quantity
            }
            console.log(total_qty, "total_qty")
            for (var k = 0; k < total_qty; k++) {
                if (k % 3 == 0) {
                    num_of_pkg++;
                }
            }
        }
        let collectionDate = moment.parseZone(this.collectionDate).format("YYYY-MM-DDTHH:mm:ss")
        console.log(order_lines, "order details")
        let radioSelected = this.radioSelected
        if (!this.collectionDate) {
            //show error
            this.orderError = "All fields are mandatory."
            window.scroll(0, 0);
            return false;
        }
        else {
            const dialog = this.dialog.open(ManualConsignmentComponent, {
                width: '800px',                
                data: { orderNumber: this.orderNumber, totalQuantities, collectionDate, orderLineItemlist, orderDetails, order_lines,upsError,num_of_pkg,radioSelected}
            }).afterClosed()
                .subscribe(result => {
                    this.getOrderDetails();
                })
        }
    }
}