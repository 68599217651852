export class SearchDataList {
    datalist: {
    source: string[];
    sku: string[];
    country: [ {
        [key: string]: string
    } ];
    order_status: string[],
    order_size: string[],
    sku_description: string[],
    product_fragrance: string[],
    delivery_method: string[],
    product_form: string[],
    destination: string[],
    new_sku_description: {id:string,name:string}[],
    new_sku: {id:string,name:string}[],
    new_country : {id:string,name:string}[],
    rejected: {
        reason_code: string[],
        reason_code_fr: string[],
        reason_code_it: string[],
        reason_code_de: string[],
        reason_description: string[]
    }
    }
}