<!-- Fixed navbar -->
<nav class="navbar navbar-default navbar-fixed-top" *ngIf="authService.isLoggedIn()">
    <div class="container">
        <div class="navbar-header" *ngIf="!authService.isLoggedIn()">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar"
                aria-expanded="false" aria-controls="navbar">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand" *ngIf="!authService.isLoggedIn()" routerLink="login">Orchestrator</a>
            <a class="navbar-brand" *ngIf="authService.isLoggedIn()" routerLink="report">Orchestrator</a>
        </div>
        <div id="navbar" class="collapse navbar-collapse">
            <ul class="nav navbar-nav">

            </ul>
            <ul class="nav navbar-nav navbar-right">
                <li *ngIf="!authService.isLoggedIn()">
                    <a routerLink="login" routerLinkActive="active">Log In</a>
                </li>
                <li *ngIf="authService.isLoggedIn()">
                    <ul class="menu">
                        <li>
                            Welcome {{ authService.currentUser.firstname }}
                            <ul>
                                <li routerLink="profile">My Profile</li>
                                <li routerLink="password">Change Password</li>
                                <li routerLink="logout">Logout</li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>
<div class="container-warning" *ngIf="authService.isLoggedIn()">
    <warning-component [userid]="authService.currentUser.userid"></warning-component>
</div>
<div class="container-fluid nPl ">
    <div class="row">
        <div class="col-md-1 nopadding sideMenu">
            <app-header *ngIf="authService.isLoggedIn()"></app-header>
        </div>
        <div class="col-md-11 nopadding">
            <router-outlet></router-outlet>
        </div>
    </div>

</div>

<footer class="footer">
    <div class="container text-center">
        <div *ngIf="tag.env === prod">
            <p *ngIf="!authService.isLoggedIn()" class="text-muted">Orchestrator {{tag.region}} {{tag.version}} </p>
            <p *ngIf="authService.isLoggedIn()" class="text-muted">Orchestrator {{tag.region}} {{tag.version}}
                {{idleState}}</p>
        </div>
        <div *ngIf="tag.env !== prod">
            <p *ngIf="!authService.isLoggedIn()" class="text-muted">Orchestrator {{tag.region}} {{tag.env}}
                {{tag.version}} </p>
            <p *ngIf="authService.isLoggedIn()" class="text-muted">Orchestrator {{tag.region}} {{tag.env}}
                {{tag.version}} {{idleState}}</p>
        </div>
    </div>
</footer>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }">
</ngx-loading>