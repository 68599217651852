<div class="user-search-container container">
  <div class="row">
    <h2 class="title">
          Add Group
          <button class="pull-right search-view-toggle" [class.searchCollapsed]="!searchViewToggle" (click)="searchViewToggle = !searchViewToggle;"></button>
        </h2>
  </div>

  <div class="row search-fields-container" *ngIf="searchViewToggle">
    <form [formGroup]="registerForm" (ngSubmit)="registerGroup(registerForm.value)" novalidate autocomplete="off">
      <div class="sub-search-container row">
        <h3>Group Details</h3>
        <div class="sub-search-wrapper">
          <div class="col-md-4" [ngClass]="{'has-error': registerForm.get('group_name').invalid && registerForm.get('group_name').dirty}">
            <label for="group_name">
              <span>Group name</span>
            </label>
            <input type="text" class="user-input-text" [formControl]="group_name" id="group_name" placeholder="Group Name">
            <div class="text-danger" *ngIf="registerForm.get('group_name').dirty && registerForm.get('group_name').errors">
              <span class="col-sm-10" style="padding:4px 0 0" *ngIf="registerForm.get('group_name').errors">
                    Please enter a group name
                </span>
            </div>
          </div>


          <div class="col-md-4" [ngClass]="{'has-error': registerForm.get('group_description').invalid && registerForm.get('group_description').dirty}">
            <label for="lname">Group Description</label>
            <input type="text" class="user-input-text" [formControl]="group_description" id="group_description" placeholder="Group Description">
            <div class="text-danger" *ngIf="registerForm.get('group_description').dirty && registerForm.get('group_description').errors">
              <span class="col-sm-10" style="padding:4px 0 0" *ngIf="registerForm.get('group_description').errors">
                    Please enter your last name
                </span>
            </div>
          </div>

          <label for="access_list" id="checkboxList" id="accessListCheckbox"><span id="checkboxList3">Access List:</span>
              <div id="checkboxList2" class="sub-search-wrapper">
                <form  *ngFor="let access of accesses">
              <input class="accessListCss" name="group.access_list"  type="checkbox" [(ngModel)]="access.checked" [value]="access.name" [formControl]="access_list" > {{ access.name }}
              </form>

            </div>

            </label>


  <div class="text-center">
    <button type="submit" [disabled]="registerForm.invalid" class="ycc-button button-search">Submit</button>
    <button type="reset" class="ycc-button button-clear">Reset</button>
  </div>

        </div>
      </div>
    </form>
  </div>
  <div class="required container">
    * Required
  </div>
</div>
