import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from '../../../common/toastr.service';
import { UserService } from '../../../user/user.service';
import { AuthService } from '../../../user/auth.service';
import { IUser } from '../../../user/user';
import { Filter3Pipe } from '../../../../temp/adimn/filter3.pipe';

function comparePassword(c: AbstractControl): {[key: string]: boolean} | null {
  const passwordControl = c.get('password');
  const confirmControl = c.get('retypepass');

  if (passwordControl.pristine || confirmControl.pristine) {
    return null;
  }

  if (passwordControl.value === confirmControl.value) {
    return null;
  }
  return { 'mismatchedPassword': true };
}

@Component({
  selector: 'app-find-access',
  templateUrl: './find-access.component.html',
  styleUrls: ['./find-access.component.css']
})
export class FindAccessComponent implements OnInit {
  @Output() onAccessEdit = new EventEmitter();
  @Output() editAccessClicked = new EventEmitter();

  accesses: any;

  registerForm: FormGroup;
  term31: Filter3Pipe;
  term32: Filter3Pipe;
  term33: Filter3Pipe;

  editAccessWasClicked(){
    this.editAccessClicked.emit();
  }

  fireEditAccessEvent(accessid, i:any){
    this.onAccessEdit.emit(accessid)
    // console.log(groupid)
  }

  constructor(private fb: FormBuilder,
              private userService: UserService,
              private authService: AuthService,
              private router: Router,
              private filterPipe: Filter3Pipe,
              private toastr: ToastrService) {
  }
  name = new FormControl('', [Validators.required]);
  description = new FormControl('', [Validators.required]);
  type = new FormControl('', [Validators.required]);

    searchViewToggle: boolean = true;

  ngOnInit() {
    this.registerForm = this.fb.group({
      name: this.name,
      description: this.description,
      type: this.type
    });

    this.userService.getAccesses().subscribe(data => {
      if (data["success"] === false) {
        if (data["errcode"]) {
          //this.authService.logout();
          this.router.navigate(['/usermanagement']);
        }
        // this.toastr.error(data.message);
      } else {
        this.accesses = data["data"];
        //this.populateForm(this.user);
        // console.log(data);
      }
    });
  }

  delAccess(accessid): void {
    if (confirm('Are you sure you want to delete this?' )){
      this.userService.delAccess(accessid)
        .subscribe(data => {
          if (data["success"] === false) {
            // this.toastr.error(data.message);
          } else {
            // this.toastr.success(data.message);
            this.router.navigate(['/accessmanagement']);
            console.log(accessid)
            location.reload();
          }
        });
    }
  }



}
