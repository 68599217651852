export class ShippingOrderDetails {
    "order_id": string = "";
    "invoice_number": string = "";
    "source_order_id": string = "";
    "order_status": string = "";
    "shipping_method": string = "";
    "shipment_group_id": string = "";
    "cust_firstName": string = "";
    "cust_lastName": string = "";
    "cust_email": string = "";
    "cust_phone": string = "";
    "cust_address_line_1": string = "";
    "cust_address_line_2": string = "";
    "cust_address_line_3": string = "";
    "cust_town": string = "";
    "cust_county": string = "";
    "cust_postcode": string = "";
    "cust_country": string = "";
    "shipTo_cust_firstName": string = "";
    "shipTo_cust_company": string = "";
    "shipTo_cust_lastName": string = "";
    "shipTo_cust_email": string = "";
    "shipTo_cust_phone": string = "";
    "shipTo_cust_address_line_1": string = "";
    "shipTo_cust_address_line_2": string = "";
    "shipTo_cust_address_line_3": string = "";
    "shipTo_cust_town": string = "";
    "shipTo_cust_county": string = "";
    "shipTo_cust_postcode": string = "";
    "shipTo_cust_country": string = "";
    "shipTo_Shipping_date": string = "";
    "shipTo_shipped_synced": string = "";
    "shipped_service_type": string = "";
    "shipped_shipping_type": string = "";
    "shipTo_tracking":{
    "tracking_number": { type: String},
    "tracking_url": { type: String}
                    };
}