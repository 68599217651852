import { Component, HostListener, OnInit } from '@angular/core';
import { ToastrService } from './common/toastr.service';
import { Router } from '@angular/router';
import { SharedApplicationService } from './shared/service/shared.service';
import { AuthService } from './user/auth.service';
import { TagService } from './user/tag.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  prod = 'production';
  tag = {
    version: '',
    env: '',
    region: ''
  }
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  minutes: any;
  public loading = false;
  @HostListener('window:unload', ['$event'])
  private onUnload(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
    localStorage.removeItem('currentUser');
  }
  constructor(public authService: AuthService,
    public tagService: TagService,
    public router: Router,
    public sharedService: SharedApplicationService,
    private idle: Idle,
    private keepalive: Keepalive) {
    sharedService.loader.subscribe((bool) => {
      setTimeout(() => {
        this.loading = !this.loading;
        // console.log(this.loading);
      });
    });
  }
  ngOnInit(): void {
    this.tagService.getTag().subscribe(tag =>
      this.tag = tag["data"]
    );

    this.idle.setIdle(30);
    this.idle.setTimeout(3600);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleEnd.subscribe(() => this.idleState = '');
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.authService.logout();
      this.router.navigate(['/login']);
      this.reset();
    });
    this.idle.onIdleStart.subscribe(() => this.idleState = '');
    this.idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'Timeout ' + Math.floor(countdown / 60) + ' mins ' + (countdown - Math.floor(countdown / 60) * 60) + ' secs');
    this.keepalive.interval(15)
    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
    this.reset();
  }
  reset() {
    this.idle.watch();
    this.idleState = '';
    this.timedOut = false;
  }
}

