import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormControl, FormArray } from '@angular/forms';
import * as moment from 'moment';
import { DataService } from "../../shared/service/data.service"
@Component({
  selector: 'app-processingreport',
  templateUrl: './processingreport.component.html',
  styleUrls: ['./processingreport.component.css']
})
export class ProcessingreportComponent implements OnInit {

  constructor(private dataservice: DataService) {

  }
  public myForm: FormGroup;

  ngOnInit() {
    this.myForm = new FormGroup({
      'FromDate': new FormControl(),
      'ToDate': new FormControl(),
      'order_status': new FormControl(),
      'agegroup': new FormControl(),
      'country': new FormControl(),
    });
    this._onload()
  }
  _onload() {
    var fromdatenow = new Date()
    fromdatenow.setHours(0)
    fromdatenow.setMinutes(0)
    fromdatenow.setSeconds(0)
    fromdatenow.setMilliseconds(0)

    var todatenow = new Date()
    todatenow.setHours(23)
    todatenow.setMinutes(59)
    todatenow.setSeconds(0)
    todatenow.setMilliseconds(0)
    this.toDate = todatenow.toString()
    this.fromDate = fromdatenow.toString()


    this.myForm.setValue({
      order_status: "All",
      FromDate: '',
      ToDate: '',
      agegroup: 'All',
      country: "All"
    })
    this.getprocessingreportdata(this.fromDate, this.toDate, "All", "All", "All");
  }
  public OrderstatusGroup: any = ['All', 'New', 'Pending', 'Approved', 'OnHold',
    'Ready to Print', 'Printed', 'QA Verified', 'QA Review', 'Returned']
  public ageGroup: any = [
    { value: 3, text: '>3' },
    { value: 5, text: '>5' },
    { value: 7, text: '>7' },
    { value: 10, text: '>10' }
  ]
  fromToDateMismatch: boolean = false;
  checkDateValidity(from, to): boolean {
    if (from && to && from > to) {
      return false;
    }
    //returning true by default
    return true;
  }

  dateSelectedprocessing(e, field) {
    setTimeout(() => {
    if (field === "from") {
      this.fromDate = e;
    } else {
      this.toDate = e;
    }
    if (typeof (this.fromDate) == "string" && this.fromDate != "")
      this.fromDate = new Date(this.fromDate)
    this.fromToDateMismatch = !this.checkDateValidity(this.fromDate, this.toDate);
  })
  }

  getprocessingreportdata(fromdate, todate, Orderstatus, age, country) {
    fromdate = moment.parseZone(fromdate).format("YYYY-MM-DDTHH:mm:ss")
    todate = moment.parseZone(todate).format("YYYY-MM-DDTHH:mm:ss")
    this.dataservice.GetPostMethod("processingorders/getreport?startDate=" + fromdate + "&endDate=" + todate + "&Orderstatus=" + Orderstatus + "&agegroup="+age+"&user=" + localStorage.getItem('Username') + "&ordersource=" + country + "", "")
      .subscribe(Response => {
        if (Response["success"] == "true") {
          let newResponse = Response["message"];
          this.rows = newResponse[0]["orders"]
          this.showtable = true
          this.OrdersArray = newResponse[0]["orders"]
          this.summaryInfo = newResponse[0]["summary"]
          this.isLoading = false;
        }
        else {

        }
      })
  }


  public fromDate: any;
  public toDate: any;
  public OrdersArray: any;
  public showtable = false;
  public Orderstatus: any;
  summaryInfo;
  rows = [];
  columns = [
    { name: 'Order ID', flexGrow: 2, prop: 'Order_id' },
    { name: 'Shipment Group ID', flexGrow: 2, prop: 'shipment_group_id' },
    { name: 'Sku', flexGrow: 2, prop: 'Sku' },
    { name: 'Order Submit date', flexGrow: 2, prop: 'submit_date' },
    { name: 'ATG Web Order', flexGrow: 2, prop: 'Source_order_id' },
    { name: 'Order Status', flexGrow: 2, prop: 'order_status' },
    { name: 'Last Updated Date', flexGrow: 2, prop: 'Order_Updated_Date' },    
    { name: 'Country', flexGrow: 2, prop: 'country' },
    { name: 'Amount', flexGrow: 1, prop: 'Total_Amount' },
    { name: 'Currency', flexGrow: 1, prop: 'Currency' },
    { name: 'Quantity', flexGrow: 1, prop: 'line_item_quantity' },
    { name: 'Delivery Method', flexGrow: 3, prop: 'Delivery_Method' },
    { name: 'Age(In Days)', flexGrow: 2, prop: 'Age' }
  ];

  public countries: any = ['UK E-COM', 'FR E-COM', 'DE E-COM', 'IT E-COM','IE E-COM', 'All']
  country: any;
  isLoading: boolean = true;

  Getdetails(myForm: any) {
    console.log('Registration successful.');
    //console.log(myForm);
    this.isLoading = true;
    var frmdate = ""
    this.fromDate = moment.parseZone(myForm.controls.FromDate.value).format("YYYY-MM-DDTHH:mm:ss")
    this.toDate = moment.parseZone(myForm.controls.ToDate.value).format("YYYY-MM-DDTHH:mm:ss")
    this.Orderstatus = myForm.controls.order_status.value;
    //this.Orderstatus = myForm.controls.order_status.value;
    this.country = myForm.controls.country.value;
    if (this.fromDate == "Invalid date" || this.toDate == "Invalid date") {
      alert("Please fill")
      return false
    }
    this.getprocessingreportdata(this.fromDate, this.toDate, this.Orderstatus, myForm.controls.agegroup.value,this.country)



  }
  resetform() {
    this.showtable = false;
  }
  exportcsv(JsonData): any {
    let today = new Date();
    var summary = 'Order ID, Shipment Group ID, Sku, Order Submit date , Country,ATG Web Order , Order Status, Order Updated Date, Total Amount,Currency, Total Quantity, Delivery Method, Age(In Days)\n';
    JsonData.forEach(function (row) {
      summary += row.Order_id + ',' + row.shipment_group_id + ',' + row.Sku + ',' + row.submit_date + ','+row.country+',' + row.Source_order_id + ',' + row.order_status + ',' + row.Order_Updated_Date + ',' + row.Total_Amount + ','
        + row.Currency + ',' + row.line_item_quantity + ',' + row.Delivery_Method + ',' + row.Age + ',';
      summary += "\n";
    });
    var csvElement = document.createElement('a');
    csvElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(summary);
    csvElement.target = '_blank';
    csvElement.download = 'Processing_Report_' + today + '.csv';
    csvElement.click();
  }

  filterageGroup(data, ageGroup) {
    if (ageGroup == "All") {
      return data
    }
    else {
      var newArr = []
      data.map(function (val) {
        if (val.Age) {
          var _orderage = val.Age.split(" ")[0]
          _orderage = parseInt(_orderage)
          ageGroup=parseInt(ageGroup)
          if (_orderage >= ageGroup)
            newArr.push(val)
        }

      })
      return newArr
    }
  }
}

