import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { OwlDateTimeModule, OwlNativeDateTimeModule, } from 'ng-pick-datetime';
import { SearchSharedModule } from '../../shared/module/searchshared.module';
import { ShippingStatusComponent } from './shippingstatus.component';
import { ManualConsignmentComponent } from './manual-consignment/manual-consignment.component';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        SearchSharedModule,
        ReactiveFormsModule
    ],
    declarations: [
        ShippingStatusComponent,
        ManualConsignmentComponent
    ],
    exports: [
        ShippingStatusComponent
    ]
})

export class ShippingStatusModule { }