<div class="container pagecontainer">
  <img src="assets/images/logo.png" alt="Mountain View">
  <p class="text-center brand">
    <small>Orchestrator Edition {{tag.version}}</small>
  </p>
  <div class="page-header">
    <h1>Change Password</h1>
  </div>
  <form [formGroup]="passwordForm" (ngSubmit)="updatePassword(passwordForm.value)" class="form-horizontal" novalidate
    autocomplete="off">

    <div formGroupName="passwordGroup" [ngClass]="{'has-error': passwordForm.get('passwordGroup').errors }">

      <div class="form-group"
        [ngClass]="{'has-error': passwordForm.get('passwordGroup.password').invalid && passwordForm.get('passwordGroup.password').dirty}">
        <label for="password" class="col-sm-2 control-label">New Password</label>
        <div class="input-group col-sm-10">
          <div class="input-group-addon"><i class="glyphicon glyphicon glyphicon-asterisk"></i></div>
          <input type="password" class="form-control" id="password" [formControl]="password" placeholder="New Pasword">
        </div>
        <div class="text-danger"
          *ngIf="passwordForm.get('passwordGroup.password').dirty && passwordForm.get('passwordGroup.password').errors">
          <span class="col-sm-2"></span>
          <span class="col-sm-10" style="padding:4px 0 0"
            *ngIf="passwordForm.get('passwordGroup.password').errors && roles == 'Administrator Accounts'">
            Password may not contain your name, be atleast 16~20 characters, with atleast 1 uppercase, lowercase,
            number, special character
          </span>
          <span class="col-sm-10" style="padding:4px 0 0"
            *ngIf="passwordForm.get('passwordGroup.password').errors && roles == 'Privileged Accounts'">
            Password may not contain your name, be atleast 10~20 characters, with atleast 1 uppercase, lowercase,
            number, special character
          </span>
          <span class="col-sm-10" style="padding:4px 0 0"
            *ngIf="passwordForm.get('passwordGroup.password').errors && roles == 'Non-Privileged Accounts'">
            Password may not contain your name, be atleast 8~20 characters, with atleast 1 uppercase, lowercase,
            number, special character
          </span>
          <span class="col-sm-10" style="padding:4px 0 0"
            *ngIf="passwordForm.get('passwordGroup.password').errors && roles == 'Service Accounts'">
            Password may not contain your name, be atleast 18~20 characters, with atleast 1 uppercase, lowercase,
            number, special character
          </span>
        </div>
      </div>
      <div class="form-group"
        [ngClass]="{'has-error': passwordForm.get('passwordGroup.retypepass').invalid && passwordForm.get('passwordGroup.retypepass').dirty}">
        <label for="retypepass" class="col-sm-2 control-label">Retype Password <sup>*</sup></label>
        <div class="input-group col-sm-10">
          <div class="input-group-addon"><i class="glyphicon glyphicon glyphicon-asterisk"></i></div>
          <input type="password" class="form-control" [formControl]="retypepass" id="retypepass"
            placeholder="Retype Password">
        </div>
        <div class="text-danger"
          *ngIf="(passwordForm.get('passwordGroup.retypepass').touched || passwordForm.get('passwordGroup.retypepass').dirty) && (passwordForm.get('passwordGroup.retypepass').errors || passwordForm.get('passwordGroup').errors)">
          <span class="col-sm-2"></span>
          <span class="col-sm-10" style="padding:4px 0 0" *ngIf="passwordForm.get('passwordGroup.retypepass').errors">
            Please confirm your password
          </span>
          <span class="col-sm-10" style="padding:4px 0 0" *ngIf="passwordForm.get('passwordGroup').errors">
            Password do not match
          </span>
        </div>
      </div>

    </div>


    <div class="form-group">
      <label class="col-sm-2 control-label"></label>
      <div class="col-sm-10">
        <button type="submit" [disabled]="passwordForm.invalid" class="btn btn-primary">Update</button>
        <button type="reset" class="btn btn-default">Cancel</button>
      </div>
    </div>
  </form>
</div>