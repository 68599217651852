<div class="col-md-10" style="margin-top:70px; padding-bottom:10px;">
  <div class="container">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <ul class="orderslist">
            <li [ngClass]="{'active': salesreport}">
              <a href="javascript:void(0)"
                (click)="activelink('salesreport');clearDates(reportForm);showShipping=false;OrdersArray=false;showErrors=false">Sales
                Report</a>
            </li>
            <li [ngClass]="{'active': shippedreport}">
              <a href="javascript:void(0)"
                (click)="activelink('shippedreport');clearDates(reportForm);showShipping=true;OrdersArray=false;showErrors=false">Shipped
                Report</a>
            </li>
            <li [ngClass]="{'active':snapshot}">
              <a href="javascript:void(0)" (click)="activelink('snapshot')">Snap Shot</a>
            </li>
            <li [ngClass]="{'active':shiftproduction}">
              <a href="javascript:void(0)" (click)="activelink('shiftproduction')">Shift Production</a>
            </li>
            <li [ngClass]="{'active':userproductivity}">
              <a href="javascript:void(0)" (click)="activelink('userproductivity')">User Productivity</a>
            </li>
            <li [ngClass]="{'active':processingreport}">
              <a href="javascript:void(0)" (click)="activelink('processingreport')">Processing Report</a>
            </li>
            <li [ngClass]="{'active':dataextract}">
              <a href="javascript:void(0)" (click)="activelink('dataextract')">EU Orders</a>
            </li>
            <li [ngClass]="{'active':crsreport}">
              <a href="javascript:void(0)" (click)="activelink('crsreport')">CRS Report</a>
            </li>
            <li [ngClass]="{'active':notsyncedship}">
              <a href="javascript:void(0)" (click)="activelink('notsyncedship')">Not Synced Ship Report</a>
            </li>

          </ul>
        </div>
      </div>
      <div class="row" *ngIf="!shapshotChild">
        <div class="col-md-12" *ngIf="!showShipping">
          <div class="col-md-10 col-md-offset-1">
            <h1>Sales Demand Report</h1>
            <form [formGroup]="salesreportform" (ngSubmit)="salesReports(salesreportform)">
              <div class="col-md-3 formgroup datewrapper">
                <label class="pull-left">From Date:</label>
                <!-- <owl-date-time [autoClose]="true" [dateFormat]="'DD/MM/YYYY HH:mm'" [placeHolder]="'DD/MM/YYYY HH:mm'" formControlName="FromDate"
                  [ngModel]="sFromDate" name="sFromDate" (ngModelChange)="dateSelectedsales($event, 'from')">
                </owl-date-time> -->
                <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" [placeHolder]="'DD/MM/YYYY HH:mm'"
                  formControlName="FromDate" [ngModel]="sFromDate" name="sFromDate"
                  (ngModelChange)="dateSelectedsales($event, 'from')">
                <owl-date-time #dt1></owl-date-time>
              </div>
              <div class="col-md-3 formgroup datewrapper">
                <label class="pull-left">To Date</label>
                <!-- <owl-date-time [max]="maxDate" [placeHolder]="'DD/MM/YYYY HH:mm'" [autoClose]="true"
                  [dateFormat]="'DD/MM/YYYY HH:mm'" formControlName="ToDate" [ngModel]="sToDate" name="sToDate"
                  (ngModelChange)="dateSelectedsales($event, 'to')">
                </owl-date-time> -->
                <input [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" [max]="maxDate" [placeHolder]="'DD/MM/YYYY HH:mm'"
                  [dateFormat]="'DD/MM/YYYY HH:mm'" formControlName="ToDate" [ngModel]="sToDate" name="sToDate"
                  (ngModelChange)="dateSelectedsales($event, 'to')">
                <owl-date-time #dt2></owl-date-time>
                <span class="date-error" *ngIf="fromToDateMismatchsales">'From Date' should be earlier than 'To
                  date'</span>
              </div>
              <div class="col-md-4">
                <button type="submit" class="button">Show Details</button>
              </div>
              <div class="col-md-3 totalcount" *ngIf="OrdersArray">
                <p><strong>Total in Pounds: <span>{{summaryCount.Pound}}</span></strong></p>
                <p><strong>Total in Euros: <span>{{summaryCount.Euro}}</span></strong></p>
                <p><strong>Total Units: <span>{{summaryCount.totalquantity}}</span></strong></p>
                <p><strong>Total Orders: <span> {{summaryCount.totalorders}}</span></strong></p>
              </div>
              <div *ngIf="showErrors" class="col-md-12 error">
                Please select from and to dates
              </div>
            </form>
          </div>
        </div>
        <div *ngIf="showShipping">
          <div class="col-md-12">
            <div class="col-md-10 col-md-offset-1">
              <h1>Shipped Demand Report</h1>
              <form [formGroup]="reportForm" (ngSubmit)="showshippingrecods(reportForm)">
                <div class="col-md-3 formgroup datewrapper">
                  <label class="pull-left">From Date:</label>
                  <!-- <owl-date-time [autoClose]="true" [dateFormat]="'DD/MM/YYYY HH:mm'" [placeHolder]="'DD/MM/YYYY HH:mm'"
                    formControlName="FromDate" [ngModel]="shippedFromDate" name="shippedFromDate"
                    (ngModelChange)="dateSelectedshipping($event, 'from')">
                  </owl-date-time> -->
                  <input [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3" [placeHolder]="'DD/MM/YYYY HH:mm'"
                    formControlName="FromDate" [ngModel]="shippedFromDate" name="shippedFromDate"
                    (ngModelChange)="dateSelectedshipping($event, 'from')">
                  <owl-date-time #dt3></owl-date-time>
                </div>
                <div class="col-md-3 formgroup datewrapper">
                  <label class="pull-left">To Date</label>
                  <!-- <owl-date-time [max]="maxDate" [placeHolder]="'DD/MM/YYYY HH:mm'" [autoClose]="true"
                    [dateFormat]="'DD/MM/YYYY HH:mm'" formControlName="ToDate" [ngModel]="shippedToDate"
                    name="shippedToDate" (ngModelChange)="dateSelectedshipping($event, 'to')">
                  </owl-date-time> -->
                  <input [owlDateTime]="dt4" [owlDateTimeTrigger]="dt4" [max]="maxDate"
                    [placeHolder]="'DD/MM/YYYY HH:mm'" [autoClose]="true" [dateFormat]="'DD/MM/YYYY HH:mm'"
                    formControlName="ToDate" [ngModel]="shippedToDate" name="shippedToDate"
                    (ngModelChange)="dateSelectedshipping($event, 'to')">
                  <owl-date-time #dt4></owl-date-time>
                  <span class="date-error" *ngIf="fromToDateMismatchshipping">'From Date' should be earlier than 'To
                    date'</span>
                </div>
                <div class="col-md-4">
                  <button type="submit" class="button">Show Details</button>
                </div>
                <div class="col-md-3 totalcount" *ngIf="OrdersArray">
                  <p><strong>Total Pounds: <span>{{summaryCount.Pound}}</span></strong></p>
                  <p><strong>Total Euros: <span>{{summaryCount.Euro}}</span></strong></p>
                  <p><strong>Total Units: <span>{{summaryCount.totalquantity}}</span></strong></p>
                  <p><strong>Total Orders: <span> {{summaryCount.totalorders}}</span></strong></p>
                </div>

                <div *ngIf="showErrors" class="col-md-12 error">
                  Please select from and to dates
                </div>
              </form>
            </div>
          </div>
        </div>


        <div class="col-md-10 col-md-offset-1">
          <p>
            <label for="changetoggle"><strong>Order Level Report </strong></label>
            <input type="checkbox" id="changetoggle" [checked]="showorderview" (change)="changeview($event)">
          </p>
          <button type="submit" class="button" *ngIf="OrdersArray&&!showorderview" (click)="exportcsv(SkuArray,'sku')"
            style="margin: 10px 0;">Export Report</button>
          <button type="submit" class="button" *ngIf="OrdersArray&&showorderview"
            (click)="exportcsv(OrdersArray,'orders')" style="margin: 10px 0;">Export Report</button>
          <div *ngIf="OrdersArray">
            <div *ngIf="!showorderview">
              <ngx-datatable class='material striped orderView' [rows]='SkuArray' [columnMode]="'force'"
                [headerHeight]="50" [footerHeight]="0" [rowHeight]="50" [scrollbarV]="true" [scrollbarH]="true">
                <ngx-datatable-column name="Sku" prop="_id" headerClass="skuclass" cellClass="sku">
                </ngx-datatable-column>
                <ngx-datatable-column name="Sku Description" headerClass="skuclass" [width]="315"
                  cellClass="sku_description" prop="sku_description"></ngx-datatable-column>
                <ngx-datatable-column name="Quantity" headerClass="skuclass" prop="line_item_quantity"
                  cellClass="line_item_quantity"></ngx-datatable-column>
                <ngx-datatable-column name="Total Price" headerClass="skuclass" prop="Total_Amount"
                  cellClass="Total_Amount"></ngx-datatable-column>
                <ngx-datatable-column name="Currency" headerClass="skuclass" prop="Currency" cellClass="currency">
                </ngx-datatable-column>
              </ngx-datatable>
            </div>
            <div *ngIf="showorderview">
              <ngx-datatable class='material striped orderView' [rows]='OrdersArray' [columnMode]="'force'"
                [headerHeight]="50" [footerHeight]="0" [rowHeight]="50" [scrollbarV]="true" [scrollbarH]="true">
                <ngx-datatable-column name="Order ID" prop="_id" headerClass="skuclass" cellClass="sku">
                </ngx-datatable-column>
                <ngx-datatable-column name="Shipemnt Group ID" headerClass="skuclass" cellClass="sku_description"
                  prop="shipment_group_id"></ngx-datatable-column>
                <ngx-datatable-column name="Quantity" headerClass="skuclass" prop="line_item_quantity"
                  cellClass="line_item_quantity"></ngx-datatable-column>
                <ngx-datatable-column name="Total Price" headerClass="skuclass" prop="Total_Amount"
                  cellClass="Total_Amount"></ngx-datatable-column>
                <ngx-datatable-column name="Currency" headerClass="skuclass" prop="Currency" cellClass="Currency">
                </ngx-datatable-column>
                <!-- <ngx-datatable-column name="Count" headerClass="skuclass" prop="count" cellClass="count"></ngx-datatable-column> -->
              </ngx-datatable>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="shapshotChild">
        <div class="col-md-12">
          <div>
            <div [ngClass]="'sub-child-container'">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>