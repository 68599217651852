import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from '../../common/toastr.service';
import { UserService } from '../../user/user.service';
import { AuthService } from '../../user/auth.service';
import { WarningComponent } from '../../components/password-expiring-warning/warning.component';

function comparePassword(c: AbstractControl): {[key: string]: boolean} | null {
  const passwordControl = c.get('password');
  const confirmControl = c.get('retypepass');

  if (passwordControl.pristine || confirmControl.pristine) {
    return null;
  }

  if (passwordControl.value === confirmControl.value) {
    return null;
  }
  return { 'mismatchedPassword': true };
}

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css'],
  providers:[WarningComponent]
})
export class PasswordComponent implements OnInit {

  passwordForm: FormGroup;
  userObj: any;
  user: any;

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private userService: UserService,
              private router: Router,
              private toastr: ToastrService,
              private warningComponent: WarningComponent) { 
  }
  roles_lists = ["Administrator Accounts", "Privileged Accounts", "Non-Privileged Accounts", "Service Accounts"];
  role: string;
  oldpassword = new FormControl('', [Validators.required]);
  password = new FormControl('', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9_]).{16,20}$')]);
  // password = new FormControl('', [Validators.required]);
  retypepass = new FormControl('', [Validators.required]);
  roles = new FormControl('');

  ngOnInit() {
    this.userObj =  this.authService.currentUser;
    this.passwordForm = this.fb.group({
      oldpassword: this.oldpassword,
      passwordGroup: this.fb.group({
        password: this.password,
        retypepass: this.retypepass,
      }, {validator: comparePassword}),
      roles: this.roles
    });


    this.userService.getUser(this.userObj.userid).subscribe(data => {
      const newData = data["data"]
      // console.log(newData[0].lockUntil)
      if (data["success"] === false) {
        if (data["errcode"]) {
          //this.authService.logout();
          this.router.navigate(['/usermanagement']);
        }
        // this.toastr.error(data["message"]);
      } else {
        this.user = data["data"];
        const newData = data["data"]
        this.role = newData[0].roles;
        if (newData[0].roles === 'Administrator Accounts') {

          this.password.setValidators([Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9_]).{16,20}$')]);

        } else if (newData[0].roles === 'Privileged Accounts') {

          this.password.setValidators([Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9_]).{10,20}$')]);

        } else if (newData[0].roles === 'Non-Privileged Accounts') {

          this.password.setValidators([Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9_]).{8,20}$')]);

        } else if (newData[0].roles === 'Service Accounts') {

          this.password.setValidators([Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9_]).{18,20}$')]);

        }
        //this.populateForm(this.user);
        // console.log(this.user);
      }
    });

    this.getUserEdit(this.userObj.userid)
  }

  updatePassword(formdata: any): void {
	  if (formdata.roles == null || formdata.roles == "") {
      this.userService.getUser(this.userObj.userid).subscribe(data => {
        if (data["success"] === false) {
          if (data["errcode"]) {
            // this.authService.logout();
            this.router.navigate(['/usermanagement']);
          }
          // this.toastr.error(data["message"]);
        } else {
          const newData = data["data"]
          formdata.roles = newData[0].roles;
    if (this.passwordForm.dirty && this.passwordForm.valid) {
      const theForm = this.passwordForm.value;
      const thePass = this.passwordForm.value.passwordGroup.password;
      theForm.password = thePass;
      console.log(theForm)
      delete theForm.passwordGroup;

      //this.userService.updatePassword(this.userObj.userid, theForm)
	  this.userService.updatePassword(this.userObj.userid, this.passwordForm.value)
        .subscribe(data => {
          if (data["success"] === false) {
            if (data["errcode"]) {
              this.authService.logout();
              this.router.navigate(['login']);
            }
            alert(data["message"])
            //this.toastr.error(data["message"]);
          } else {
            alert(data["message"]);
			 this.warningComponent.userid = this.userObj.userid;
            this.warningComponent.ngOnInit();
            // this.toastr["success"](data["message"]);
          }
          this.passwordForm.reset();
        });
    
	 }
        }
      });
      //console.log(formdata.roles, "roles in update");
    }
    else {
      if (this.passwordForm.dirty && this.passwordForm.valid) {
        const theForm = this.passwordForm.value;
        const thePass = this.passwordForm.value.passwordGroup.password;
        theForm.password = thePass;
        console.log(theForm)
        delete theForm.passwordGroup;

        this.userService.updatePassword(this.userObj.userid, this.passwordForm.value)
          .subscribe(data => {
            if (data["success"] === false) {
              if (data["errcode"]) {
                this.authService.logout();
                this.router.navigate(['login']);
              }
              alert(data["message"])
              //this.toastr.error(data["message"]);
            } else {
              alert(data["message"])
              this.warningComponent.userid = this.userObj.userid;
              this.warningComponent.ngOnInit();
              // this.toastr["success"](data["message"]);
            }
            this.passwordForm.reset();
          });
      }
  }
}

  populateForm(data): void {
    this.passwordForm.patchValue({
      roles: data.roles
    });
    // console.log(data.group_name)
  }


  getUserEdit(userid): void {
    this.userService.getUser(this.userObj.userid).subscribe(data => {

      if (data["success"] === false) {
        if (data["errcode"]) {
          //this.authService.logout();
          this.router.navigate(['/usermanagement']);
        }
        // this.toastr.error(data["message"]);
      } else {
        this.user = data["data"];
        this.populateForm(this.user[0]);

      }

    });
    // console.log(this.username);

  }

}
