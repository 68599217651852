import { Component, Input } from '@angular/core';

import { SharedApplicationService } from '../../service/shared.service';

@Component({
    selector: 'printer-comp',
    template: `<div class="printSection" id="printSection" #printSection>
  <section class="prImgSection" *ngFor="let inputDetail of inputDetails">
  <img *ngFor="let url of inputDetail.print_ready_image" src="{{url}}"></section></div>`
})
export class PrintReadyImgPrintViewComponent {
    @Input() inputDetails = [];

    timer = 2500;

    constructor(private sharedService: SharedApplicationService) {
    }

    ngAfterContentInit() {
        this.timer = this.timer + 1000 * this.inputDetails.length;
        setTimeout(() => {
            window.print();
            this.sharedService.loader.next(false);
        }, this.timer);
    }

    ngOnDestroy() {
        // console.log("on Destory");
    }
}