import { Component, Injectable, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Injectable()
export class UserInfoModalService {
    constructor(private dialog: MatDialog) {}
    openModal( data ) {
        let dialogRef = this.dialog.open(UserInfoModalComponent, 
            { width: '500px', data: data});
        return dialogRef;
    }
}


@Component({
    selector: 'user-info-modal',
    template: `
    <div>
        <div class="alert" role="alert" [ngClass]="{
            'alert-success': data.status=='success',
            'alert-danger': data.status=='error'
        }">
            <h4 class="alert-heading">{{data.title}}</h4>
            <p>{{data.message}}</p>
        </div>
        <div class="clearfix">
            <button type="button" (click)="dialogRef.close();" class="approve-button pull-right">close</button>
        </div>
    </div>
    `
})
export class UserInfoModalComponent {
    constructor(
        public dialogRef: MatDialogRef<UserInfoModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }
}