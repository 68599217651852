import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from "@angular/common/http";
import { SharedApplicationService } from "../../../shared/service/shared.service"
import { SearchDataList } from '../../../shared/model/searchDataList.model';
import { UserInfoModalService } from '../../../shared/util/modal/user-info.modal';
import { SearchQuery } from '../../../shared/model/searchQuery.model';
import { SearchResults } from '../../../shared/model/searchResults.model';
import { OrderDetails } from '../../../shared/model/orderDetails.model';

@Component({
  selector: 'app-on-hold-dialog',
  templateUrl: './on-hold-dialog.component.html',
  styleUrls: ['./on-hold-dialog.component.css']
})
export class OnHoldDialogComponent implements OnInit {
  @Output() closeDetails = new EventEmitter();
  public shipToCountry = "GB";

  constructor(private http: HttpClient, private userModal: UserInfoModalService,
    public dialogRef: MatDialogRef<OnHoldDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public popupData: any, public sharedAppService: SharedApplicationService) {
    var orderIdArray = [], reason_code_original = [], reason_desc_original = [], shipToCountryArr = []
    this.popupData.order_id.map(function (val) {
      orderIdArray.push(val.order_id);
      shipToCountryArr.push(val.shipTo_cust_country);
      reason_code_original.push(val.reason_code);
      reason_desc_original.push(val.reason_desc);
    });

    for (var i = 0; i < shipToCountryArr.length; i++) {
      if (shipToCountryArr[i] == "FR") {
        this.shipToCountry = "FR"
      }
      else if (shipToCountryArr[i] == "DE") {
        this.shipToCountry = "DE"
      }
      else if (shipToCountryArr[i] == "IT") {
        this.shipToCountry = "IT"
      }
      else {
        this.shipToCountry = "GB"
      }
    }
    console.log(this.shipToCountry, "country")
  }

  ngOnInit() {
    this.searchparams.pageno = 1; //default page number        
    this.searchparams.pagesize = 0;
    this.searchparams.ordersource = this.cscApprovalConstants.source;
    this.searchparams.orderstatus = this.cscApprovalConstants.status;
    this.searchparams.sortby = this.cscApprovalConstants.sortby;
    this.searchparams.sortorder = this.cscApprovalConstants.sortorder;
    let url = `/api/searchDataList`, shipCountry;
    shipCountry = this.shipToCountry;
    this.sharedAppService.httpGetService(url).then((data: SearchDataList) => {
      this.dropDownDataList = data;
      this.rejectList = this.dropDownDataList.datalist.rejected;
      this.rejectCodes = this.dropDownDataList.datalist.rejected.reason_code;
      this.rejectCodesFR = this.dropDownDataList.datalist.rejected.reason_code_fr;
      this.rejectCodesDE = this.dropDownDataList.datalist.rejected.reason_code_de;
      this.rejectCodesIT = this.dropDownDataList.datalist.rejected.reason_code_it;
      console.log(this.rejectCodes, "reject code")
    })
  }

  cscApprovalConstants = {
    source: "UK E-COM",
    status: "ReadyToPrint",
    sortby: "order_date",
    sortorder: "desc"
  }

  rejectList: any;
  dropDownDataList: SearchDataList;
  rejectCodes: any;
  rejectCodesFR: any;
  rejectCodesDE: any;
  rejectCodesIT: any;
  desc: String;
  reasonCode: String;
  errorMsg: String;

  rejectCodeChange(code) {

    let shippingCountry = this.shipToCountry;
    if (shippingCountry == "FR") {
      this.desc = this.rejectList.reason_description_fr[this.rejectList.reason_code_fr.indexOf(code)];
      this.reasonCode = code;
    }
    else if (shippingCountry == "IT") {
      this.desc = this.rejectList.reason_description_it[this.rejectList.reason_code_it.indexOf(code)];
      this.reasonCode = code;
    }
    else if (shippingCountry == "DE") {
      this.desc = this.rejectList.reason_description_de[this.rejectList.reason_code_de.indexOf(code)];
      this.reasonCode = code;
    }
    else {
      this.desc = this.rejectList.reason_description[this.rejectList.reason_code.indexOf(code)];
      this.reasonCode = code;
    }
  }

  holdOrder() {
    if(!this.desc || !this.reasonCode){
      this.errorMsg = "Please enter Reason code and Description";
      return false;
    }
    let status = "OnHold";
    let postParams: any = null;
    let orderIdArray = [];
    this.popupData.order_id.map(function (val) {
      orderIdArray.push(val.order_id);
    })
    postParams = {
      orderIdArray: orderIdArray,
      orderStatus: status,
      rejectionCode: this.reasonCode,
      rejectionDesc: this.desc
    }
    this.sharedAppService.httpPostService(`/api/orderstatus/bulkUpdateOnHoldOrders`, postParams)
      .then(
        // Success
        data => {
          this.userModal.openModal({ status: 'success', title: status, message: `Selected orders successfully ${status}.` });
          this.fetchOrders();
          this.closePopup();//close the detail view
        },
        // Error
        err => {
          console.log(err);

          this.userModal.openModal({ status: 'error', title: 'Action Failed', message: `${status} of order failed. ${err.message}` });
        }
      )

  }
  isLoading: boolean = true;
  searchparams = new SearchQuery();
  searchResults: SearchResults;
  searchOrderDetailsResults: OrderDetails[];
  fetchOrders() {
    this.isLoading = true;
    this.searchparams.pageno = this.searchparams.pageno || 1;
    let params = {};
    for (let key of Object.keys(this.searchparams)) {
      if (key == "pagesize" || ((this.searchparams[key] instanceof Array) && this.searchparams[key].length) ||
        (!(this.searchparams[key] instanceof Array) && this.searchparams[key])) {
        params[key] = this.searchparams[key];
      }
    }
    // get the order details on search
    this.sharedAppService.httpGetService('/api/orders', params)
      .then(
        // Success
        (data: SearchResults) => {
          data.orders.map((value) => { value.isSelected = true; return value; });
          this.searchResults = data;
          this.searchOrderDetailsResults = data.orders;
          this.isLoading = false;
          if (!this.searchOrderDetailsResults.length) {
            this.isLoading = true;
            this.searchparams.pageno = undefined;
          }
        },
        // Error
        err => {
          console.log(err);
          this.isLoading = true;
        }
      )
  }

  closePopup(): void {
    this.dialogRef.close();
    this.closeDetails.emit(true)
  }
}
