export class QaDetails {
    "order_id": string = "";
    "source_order_id": string = "";
    "order_date": string = "";
    "product_form": string = "";
    "sequence": number = null;
    "total_line_items": number = null;
    "sku_desc": string = "";
    "quantity": number = null;
    "sku": string = "";
    "product_fragrance": string = "";
    "shipping_method": string = "";
    "barcode_img": string = "";
    "preview_img": string = "";
    "order_status": string = "";
    "quantity_confirmed": string = "";
    "quantity_rejected": string = "";
    "qa_failed_reason": string = "";
    "gift_box_sku_id": string = "";
    "gift_box_sku_description":string = "";
}