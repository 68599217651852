export class OrderDetails {
    cust_email: string;
    cust_firstName: string;
    cust_lastName: string;
    cust_phone: string;
    label_text1: string;
    label_text2: string;
    order_date: string;
    order_id: string;
    order_size: string;
    order_status: string;
    label_img: string;
    print_img: string;
    preview_img: string;
    barcode_img: string;
    product_form: string;
    print_ready_image: string;
    product_fragrance: string;
    quantity: string;
    sku: string;
    reason_code: string;
    reason_desc: string;
    reason_comment: string;
    isSelected: boolean;
    disableCheckBoxForMissingReadyToPrintImages: boolean;
    cust_address_line_1: string;
    cust_address_line_2: string;
    cust_address_line_3: string;
    cust_town: string;
    cust_county: string;
    cust_postcode: string;
    cust_country: string;
    shipTo_cust_address_line_1: string;
    shipTo_cust_address_line_2: string;
    shipTo_cust_address_line_3: string;
    shipTo_cust_company: string;
    shipTo_cust_country: string;
    shipTo_cust_county: string;
    shipTo_cust_email: string;
    shipTo_cust_firstName: string;
    shipTo_cust_lastName: string;
    shipTo_cust_phone: string;
    shipTo_cust_postcode: string;
    shipTo_cust_state: string;
    shipTo_cust_town: string;
    source: string;
    gift_box_sku_description: string = "";
    sku_desc: string = "";
    gift_box_sku_id: string = "";
    shipping_method: string = "";
    source_order_id: string = "";
}