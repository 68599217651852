import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ComponentFactoryResolver, ComponentRef, Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
// import { Subject } from 'rxjs/Subject';
import * as moment from 'moment';

import { IBaseModel } from '../model/Ibase.model';
// import { QzTrayService } from './qz.service';

@Injectable()
export class SharedApplicationService {
    //----- Variable declaration
    public loader = new Subject<any>();
    private sharedSubject = new Subject<any>();
    private printSubject = new Subject<any>();
    private factoryResolver;
    private rootViewContainer;

    private cmpRef: ComponentRef<any>;
    private printWrapperViewContainerRef;

    constructor(private http: HttpClient, @Inject(ComponentFactoryResolver) factoryResolver/* , private qzService: QzTrayService */) {
        this.factoryResolver = factoryResolver;
    }

    //----- Shared Subject
    sendSharedSubject(paramDetails: any) {
        this.sharedSubject.next({ details: paramDetails });
    }

    clearSharedSubject() {
        this.sharedSubject.next();
    }

    getSharedSubject(): Observable<any> {
        return this.sharedSubject.asObservable();
    }

    //----- Print
    sendPrint(paramType: any, paramDetails: any) {
        const _self = this;
        let promise = new Promise((resolve, reject) => {
            if (typeof paramDetails == "string") {
                this.httpGetService(paramDetails)
                    .then(
                    (res) => {
                        this.loader.next(true);
                        /* if (paramType.name === 'PrintReadyImgPrintViewComponent' && qz && qz.websocket.isActive()) {
                            this.printPRImagesUsingQZTray(paramType, res).subscribe(result => {
                                // _self.loader.next(false);
                                resolve();
                            });
                        } else */ {
                            this.addPrintDynamicComponent(paramType, res)
                                .then(() => {
                                    //  _self.loader.next(false);
                                    resolve(res);
                                });
                        }
                    });
            }
            else {
                this.loader.next(true);
                this.addPrintDynamicComponent(paramType, paramDetails)
                    .then(() => {
                        resolve(paramDetails);
                    });
            }
        });
        return promise;
    }

    clearPrintSubject() {
        this.printSubject.next();
    }

    getPrint(): Observable<any> {
        return this.printSubject.asObservable();
    }
    //----- HTTP Service - GET Method
    httpGetService(apiURL: string, params?: any, options?: any) {
        let headers = new HttpHeaders().append('user-name', localStorage.getItem('Username'));
        this.loader.next(true);
        let httpParams = new HttpParams();
        for (let prop in params) {
            httpParams = httpParams.append(prop, params[prop])
        }
        let promise = new Promise((resolve, reject) => {
            this.http.get<IBaseModel>(apiURL, { headers: headers, params: httpParams })
                .toPromise()
                .then(
                (res) => { // Success                        
                    //console.log("API Success Response: ==> " + apiURL);
                    //console.log(" ==> " + JSON.stringify(res));
                    resolve(res.data);
                    this.loader.next(false);
                },
                err => { // Error
                    console.log("API Error Response: ==> " + apiURL);
                    console.log(" ==> " + err);
                    reject(err);
                    this.loader.next(false);
                }
                );
        });
        return promise;
    }

    //----- HTTP Service - POST Method
    httpPostService(apiURL: string, body: any, options?: any) {
        let headers = new HttpHeaders().append('user-name', localStorage.getItem('Username'));        
        this.loader.next(true);
        let promise = new Promise((resolve, reject) => {
            this.http.post<IBaseModel>(apiURL, body, { headers: headers })
                .toPromise()
                .then(
                (res) => { // Success                        
                    console.log("API Success Response: ==> " + apiURL);
                    console.log(" ==> " + JSON.stringify(res));
                    resolve(res.data);
                    this.loader.next(false);
                },
                err => { // Error
                    console.log("API Error Response: ==> " + apiURL);
                    console.log(" ==> " + err);
                    reject(err);
                    this.loader.next(false);
                }
                );
        });
        return promise;
    }

    //----- Add dynamic components
    addDynamicComponent(rootViewContainerRef: any, dynamicComponent: any) {
        const factory = this.factoryResolver.resolveComponentFactory(dynamicComponent);
        const component = factory.create(rootViewContainerRef.parentInjector);
        rootViewContainerRef.insert(component.hostView);
    }

    //----- Set Root View to Add dynamic components
    setPrintWrapperViewContainerRef(viewContainerRef: any) {
        this.printWrapperViewContainerRef = viewContainerRef;
    }

    addPrintDynamicComponent(dynamicPrintComponent: any, details: any) {
        let promise = new Promise((resolve, reject) => {
            this.removePrintDynamicComponent();

            const factory = this.factoryResolver.resolveComponentFactory(dynamicPrintComponent);
            this.cmpRef = this.printWrapperViewContainerRef.createComponent(factory);
            const contRef = this.cmpRef.instance;
            contRef.inputDetails = details;
            resolve(details);
        });
        return promise;
    }

    removePrintDynamicComponent() {
        if (this.cmpRef) {
            this.cmpRef.destroy();
        }
        this.cmpRef = null;
    }

    checkValidImageURL(url) {
       // return(url.match(/\.(jpeg|jpg|gif|png)$/) != null);
        return(url.match(/\.(jpeg|jpg|gif|png)/) != null);
    }

    // ----- validate UK "Gibraltar" or "GX11 1AA" order
    isUKGibraltarOrder(orderObj) {
        return ((orderObj.hasOwnProperty('cust_postcode') && orderObj.cust_postcode === 'GX11 1AA') || (orderObj.hasOwnProperty('cust_county') && orderObj.cust_county === 'Gibraltar') || (orderObj.hasOwnProperty('cust_town') && orderObj.cust_town === 'Gibraltar'));
    }

    // ----- validate UK "Gibraltar" or "GX11 1AA" list
    getUKGibraltarOrderFromList(orderObjlist) {
        let promise = new Promise<any[]>((resolve, reject) => {
            let orderIds = [];

            orderObjlist.map((data) => {
                if (this.isUKGibraltarOrder(data)) {
                    orderIds.push(data.order_id);
                }
            });
            resolve(orderIds);
        });
        return promise;
    }

     //Missing Barcode Order Validation
     missingBarcode(orderObj){
        if((orderObj.barcode_img == "") || (orderObj.barcode_img == null) || (orderObj.print_ready_image == "")||(orderObj.print_ready_image == null)){           
            return true;
        }
        else{         
            return false;
        }
    }

    getMissingBarcodeOrderList(orderobjList){
        let promise = new Promise<any[]>((resolve, reject)=>{
            let orderId = [];
            orderobjList.map((data)=>{
                if(this.missingBarcode(data)){
                    orderId.push(data.order_id)
                }
            })
            resolve(orderId);
        })
        return promise;
    }
  //Waiting on Consumer Order Validation
    waitingOnConsumer(orderObj){
        if((orderObj.reason_code == "Waiting On Consumer") ){           
            return true;
        }
        else{         
            return false;
        }
    }
​
    getWaitingOnConsumerOrderList(orderobjList){
        let promise = new Promise<any[]>((resolve, reject)=>{
            let orderId = [];
            orderobjList.map((data)=>{
                if(this.waitingOnConsumer(data)){
                    orderId.push(data.order_id)
                }
            })
            resolve(orderId);
        })
        return promise;
    }
    
    // ----- returns the current region
    getRegion() {
        //returns the current region
        return JSON.parse(localStorage.getItem("currentUser")).user.region;
    }
     gettodaydates=function(){
        let obj = {};
        let fromdatenow = new Date();
        fromdatenow.setHours(0);
        fromdatenow.setMinutes(0);
        fromdatenow.setSeconds(0);
        fromdatenow.setMilliseconds(0);

        let todatenow = new Date();
        todatenow.setHours(23);
        todatenow.setMinutes(59);
        todatenow.setSeconds(0);
        todatenow.setMilliseconds(0);
        obj["Fromdate"] = fromdatenow;
        obj["Todate"] = todatenow;
        return obj;

    }

	/* 
    getLargeJarConfig(): Observable<any> {
        const callback = new Subject<any>();
        const _self = this;
        this.qzService.getDefaultPrinter().then(function (printerName) {
            const pxlMargins = {
                top: 5,
                right: 0,
                bottom: 1,
                left: 1
            },
            customConfig = { units: 'mm', margins: pxlMargins };
            callback.next({'printerName': printerName, 'config': customConfig});
        }).catch(function() {
            _self.loader.next(false);
            callback.next(false);
        });
        return callback.asObservable();
    }

    printSmallTumblersUsingPrintPreview(paramType, orderData, printLageJars) {
        const _self = this;
        const promise = new Promise((resolve, reject) => {
            if (!printLageJars) {
                orderData = orderData.filter( (value) => {
                    return (value.product_form === 'Decor Small Pillar');
                });
            }

            if (orderData.length > 0) {
                this.addPrintDynamicComponent(paramType, orderData)
                    .then(() => {
                        resolve(true);
                    });
            } else {
                this.loader.next(false);
                resolve(true);
            }
        });
          return promise;
    }

    printPRImagesUsingQZTray(paramType, orderData): Observable<boolean> {
        const _self = this;
        const image_data: any[] = [];
        const callback = new Subject<boolean>();

        for (let i = 0; i < orderData.length; i++) {
            for (let j = 0; j < orderData[i].print_ready_image.length; j++) {
                image_data.push({ 'product_form': orderData[i].product_form, 'url': orderData[i].print_ready_image[j] });
            }
        }

        let data = image_data.filter( (value) => {
            if (value.product_form === 'Large Jar') {
                return value.url;
            }
        });

        if (data.length > 0) {
            // print Large Jars
            this.getLargeJarConfig().subscribe(result => {
                if (typeof result === 'object') {
                    const printerName = result.printerName;
                    const customConfig = result.config;
                    _self.printLabel(printerName, customConfig, data).subscribe(flag => {

                        const printLageJars = !flag;
                        // print Small Tumblers
                        _self.printSmallTumblersUsingPrintPreview(paramType, orderData, printLageJars).then(result2 => {
                            callback.next(true);
                        });
                    });
                } else if (typeof result === 'boolean') {
                    this.loader.next(false);
                    // print Small Tumblers
                    _self.printSmallTumblersUsingPrintPreview(paramType, orderData, true).then(result2 => {
                        callback.next(true);
                    });
                }
            });
        } else {
            // print Small Tumblers
            this.printSmallTumblersUsingPrintPreview(paramType, orderData, false).then(result2 => {
                callback.next(true);
            });
        }

        return callback.asObservable();
    }

    printLabel(printerName, customConfig, data): Observable<boolean> {
        const _self = this;
        const callback = new Subject<boolean>();

        let start = 0;
        const size = 2;

        (function createBatches() {
            const printData = [];
            let end = start + size;
            if (end > data.length) {
                end = data.length;
            }
            for (let i = start; i < end; i++) {
                printData.push({
                    type: 'image',
                    data: data[i].url
                });
            }

            _self.qzService.setPrinter(printerName);
            _self.qzService.setConfig(customConfig);
            _self.qzService.printData(printData).then(result => {
                start += size;
                if (start >= data.length) {
                    callback.next(true);
                } else {
                    createBatches();
                }
            }).catch(function(e) {
                 callback.next(false);
            });
        }());
        return callback.asObservable();
    } */
}
