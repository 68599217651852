

<div class="col-md-1 nPr nPl " *ngIf="authService.isLoggedIn()">
    <div class="headerNav">
    
    <perfect-scrollbar class="container" [config]="config">
  <div class="content">
<header>
        <div>
            <a href="javascript:void(0)" (click)="menuhide=!menuhide" class="hidemenu-arrow">
                <i class="glyphicon glyphicon-chevron-right" [hidden]="!menuhide"></i>
                <i class="glyphicon glyphicon-chevron-left" [hidden]="menuhide"></i>
            </a>
        </div>
        <div [hidden]="menuhide">

            <h4>
                YANKEE CANDLE
            </h4>
            <h5>
                Orchestrator<br />
                Editon
            </h5>

            <ul class="main_menu">

                <li class="unactive">           
                    <a *ngIf="isApproval" href="#" routerLink="/approver" routerLinkActive="active">
                        <span class="icon-Approver"></span>
                        CSC Approver
                    </a>
                    <a *ngIf="!isApproval" href="javascript:void(0)" class="disabled">
                        <span class="icon-Approver"></span>
                        CSC Approver
                    </a>
                </li>
                <li class="unactive">
                    <a href="#" *ngIf="isManger" routerLink="/manager" routerLinkActive="active">
                        <span class="icon-Manager-approval"></span>
                        CSC Manager
                    </a>
                     <a *ngIf="!isManger" href="javascript:void(0)" class="disabled">
                       <span class="icon-Manager-approval"></span>
                        CSC Manager
                    </a>
                    
                </li>
                <li class="unactive">
                    <a href="#" *ngIf="isInventory" routerLink="/inventory" routerLinkActive="active">
                        <span class="icon-Inventory"></span>
                        Inventory
                    </a>
                    <a *ngIf="!isInventory" href="javascript:void(0)" class="disabled">
                        <span class="icon-Inventory"></span>
                        Inventory
                    </a>
                </li>
                <li class="unactive">
                    <a href="#" *ngIf="isPrint" routerLink="/print" routerLinkActive="active">
                        <span class="icon-Print"></span>
                        Print
                    </a>
                    <a *ngIf="!isPrint" href="javascript:void(0)" class="disabled">
                         <span class="icon-Print"></span>
                        Print
                    </a>
                </li>
                <li class="unactive">
                    <a href="#" *ngIf="isQuality" routerLink="/qa" routerLinkActive="active">
                        <span class="icon-Quality-check"></span>
                        QA
                    </a>
                    <a *ngIf="!isQuality" href="javascript:void(0)" class="disabled">
                       <span class="icon-Quality-check"></span>
                        QA
                    </a>
                </li>
                <li class="unactive">
                    <a href="#" *ngIf="isShipping" routerLink="/shipment" routerLinkActive="active">
                        <span class="icon-shipment"></span>
                        Shipment
                    </a>
                    <a *ngIf="!isShipping" href="javascript:void(0)" class="disabled">
                       <span class="icon-shipment"></span>
                        Shipment
                    </a>
                </li>
                <li class="unactive">
                    <a href="#" *ngIf="isOrderManagement" routerLink="/ordermanagement" routerLinkActive="active">
                        <span class="icon-Inventory_management"></span>
                        Order Management
                    </a>
                    <a *ngIf="!isOrderManagement" href="javascript:void(0)" class="disabled">
                         <span class="icon-Inventory_management"></span>
                        Order Management
                    </a>
                </li>
                <li class="unactive">
                    <a href="#" *ngIf="isReporting" routerLink="/reporting" routerLinkActive="active">
                        <span class="iconMng-Reporting"></span>
                        Reporting
                    </a>
                    <a *ngIf="!isReporting" href="javascript:void(0)" class="disabled">
                         <span class="iconMng-Reporting"></span>
                         Reporting
                    </a>
                </li>
                <li class="unactive">
                    <a href="#" *ngIf="isusermanagement" routerLink="/usermanagement" routerLinkActive="active">
                        <span class="iconMng-User_management"></span>
                        User Management
                    </a>
                    <a  *ngIf="!isusermanagement" href="javascript:void(0)" class="disabled">
                        <span class="iconMng-User_management"></span>
                        User Management
                    </a>
                </li>
                <li class="unactive">
                    <a href="#" *ngIf="isgroupmanagement" routerLink="/groupmanagement" routerLinkActive="active">
                        <span class="iconMng-Group_management"></span>
                        Group Management
                    </a>
                    <a *ngIf="!isgroupmanagement" href="javascript:void(0)" class="disabled">
                        <span class="iconMng-Group_management"></span>
                       Group Management
                   </a>
                </li>
                <li class="unactive">
                    <a href="#" *ngIf="isconfig" routerLink="/config" routerLinkActive="active">
                        <span class="iconConf-configuration"></span>
                        Configuration
                    </a>
                    <a *ngIf="!isconfig" href="javascript:void(0)" class="disabled">
                        <span class="iconConf-configuration"></span>
                       Configuration
                   </a>
                </li>
                <li class="unactive">
                    <a href="#" *ngIf="isutility" routerLink="/postalcode" routerLinkActive="active">
                        <span class="iconConf-configuration"></span>
                        Utility
                    </a>
                    <a *ngIf="!isutility" href="javascipt:void(0)" class="="disabled>
                        <span class="iconConf-configuration"></span>
                        Utility
                    </a>
                </li>
            </ul>
        </div>
    </header>


  </div>
</perfect-scrollbar>
    </div>
</div>

<div class="col-md-11 nPr nPl" [ngClass]="{'topwrapper':menuhide}">
    <!-- <section class="topheader">
        <ul>
            <li>
                <a href="javascript:void(0)" class="" (click)="showlogoutBtn()">{{username}} <i class="glyphicon glyphicon-chevron-down"></i></a>
                <ul *ngIf="showlogout">
                    <li><a href="#" (click)="logout">Logout</a></li>
                </ul>
            </li>
        </ul>
    </section>
     -->
</div>


