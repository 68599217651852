<div class="order-search-container container">
    <div class="row">
        <h2 class="title">
            Search
            <button class="pull-right search-view-toggle" [class.searchCollapsed]="!searchViewToggle"
                (click)="searchViewToggle = !searchViewToggle;"></button>
        </h2>
    </div>
    <div class="row search-fields-container" *ngIf="searchViewToggle">
        <form #searchForm="ngForm" (ngSubmit)="callSearch()">
            <div class="sub-search-container row">
                <h3>Order Details</h3>
                <div class="sub-search-wrapper">
                    <div class="col-md-4">
                        <label>
                            <span>Order Id</span>
                        </label>
                        <input type="text" class="user-input-text" [(ngModel)]="searchQuery.orderid" name="orderid" />
                    </div>
                    <div class="col-md-4">
                        <label>
                            <span>Quantity </span>
                        </label>
                        <input type="text" #qtyFieldRef class="user-input-text" [(ngModel)]="searchQuery.quantity"
                            (ngModelChange)="quantityUpdate($event)" name="quantity" />
                    </div>
                    <div class="col-md-4">
                        <label>
                            <span>Order Source</span>
                        </label>
                        <select class="user-input-select" name="ordersource" [(ngModel)]="searchQuery.ordersource">
                            <option *ngFor="let orderSrcTmpl of dropDownList?.datalist.source" [value]="orderSrcTmpl">
                                {{orderSrcTmpl}}</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label>
                            <span>SKU</span>
                        </label>
                        <ss-multiselect-dropdown class="user-input-select" [texts]="multiSelectTexts"
                            [settings]="multiSelectSettings" [options]="dropDownList?.datalist.new_sku"
                            [(ngModel)]="searchQuery.sku" name="sku"></ss-multiselect-dropdown>
                    </div>
                    <div class="col-md-4">
                        <label>
                            <span>Order Status</span>
                        </label>
                        <select class="user-input-select" name="orderstatus" [(ngModel)]="searchQuery.orderstatus">
                            <option *ngFor="let orderstats of dropDownList?.datalist.order_status" [value]="orderstats">
                                {{orderstats}}</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label>
                            <span>Order Size</span>
                        </label>
                        <select class="user-input-select" name="ordersize" [(ngModel)]="searchQuery.ordersize">
                            <option value=""></option>
                            <option *ngFor="let ordersz of dropDownList?.datalist.order_size" [value]="ordersz">
                                {{ordersz}}</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label>
                            <span>Description</span>
                        </label>
                        <ss-multiselect-dropdown class="user-input-select" [texts]="multiSelectTexts"
                            [settings]="multiSelectSettings" [options]="dropDownList?.datalist.new_sku_description"
                            [(ngModel)]="skuDesc" name="skudesc"></ss-multiselect-dropdown>
                    </div>
                    <div class="col-md-8">
                        <label>Order Date:</label>
                        <div class="date-picker-container">
                            <span>From: </span>
                            <div class="search-date-picker">
                                <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeHolder="'MM/DD/YYYY HH:mm'"
                                    [ngModel]="fromDate" (ngModelChange)="dateSelected($event, 'from')" name="fromdate">
                                <owl-date-time #dt1></owl-date-time>
                                <!-- owl-date-time [max]="maxDate" [placeHolder]="'DD/MM/YYYY HH:mm'" [autoClose]="true"
                                    [dateFormat]="'DD/MM/YYYY HH:mm'" [ngModel]="fromDate"
                                    (ngModelChange)="dateSelected($event, 'from')" name="fromdate"></owl-date-time> -->
                            </div>
                        </div>
                        <div class="date-picker-container">
                            <span>To: </span>
                            <div class="search-date-picker">
                                <input [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" placeHolder="'MM/DD/YYYY HH:mm'"
                                    [ngModel]="toDate" (ngModelChange)="dateSelected($event, 'to')" name="toDate">
                                <owl-date-time #dt2></owl-date-time>
                                <!-- <owl-date-time [max]="maxDate" [placeHolder]="'DD/MM/YYYY HH:mm'" [autoClose]="true"
                                    [dateFormat]="'DD/MM/YYYY HH:mm'" [ngModel]="toDate"
                                    (ngModelChange)="dateSelected($event, 'to')" name="toDate"></owl-date-time> -->
                            </div>
                        </div>
                        <span class="date-error" *ngIf="fromToDateMismatch">'From Date' should be earlier than 'To
                            date'</span>
                    </div>
                </div>
            </div>
            <div class="sub-search-container row">
                <h3>Product & Shipping Details</h3>
                <div class="sub-search-wrapper">
                    <div class="col-md-4">
                        <label>
                            <span>Fragrance</span>
                        </label>
                        <select class="user-input-select" name="fragrance" [(ngModel)]="searchQuery.fragrance">
                            <option value=""></option>
                            <option *ngFor="let fragrnce of dropDownList?.datalist.product_fragrance"
                                [value]="fragrnce">{{fragrnce}}</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label>
                            <span>Shipping Method</span>
                        </label>
                        <select class="user-input-select" name="shippingmethod"
                            [(ngModel)]="searchQuery.shippingmethod">
                            <option value=""></option>
                            <option *ngFor="let shipMtd of dropDownList?.datalist.delivery_method" [value]="shipMtd">
                                {{shipMtd}}</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label>
                            <span>Ship to State</span>
                        </label>
                        <ng2-completer name="shiptostate" inputName="shiptostate" inputClass="user-input-text"
                            [(ngModel)]="searchQuery.shiptostate" [datasource]="shiptostateDataService"></ng2-completer>
                    </div>
                    <div class="col-md-4">
                        <label>
                            <span>Product Form</span>
                        </label>
                        <select class="user-input-select" name="productform" [(ngModel)]="searchQuery.productform">
                            <option value=""></option>
                            <option *ngFor="let productFrm of dropDownList?.datalist.product_form" [value]="productFrm">
                                {{productFrm}}</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label>
                            <span>Destination</span>
                        </label>
                        <ss-multiselect-dropdown class="user-input-select" [texts]="singleSelectTexts"
                            [settings]="singleSelectSettings" [options]="dropDownList?.datalist.new_country"
                            [(ngModel)]="searchQuery.destination" name="destination"></ss-multiselect-dropdown>
                    </div>
                    <div class="col-md-4">
                        <label>
                            <span>Age (days)</span>
                        </label>
                        <input type="text" #ageFieldRef class="user-input-text" [(ngModel)]="searchQuery.age"
                            (ngModelChange)="ageUpdate($event)" name="age" />
                    </div>
                </div>
            </div>
            <div class="sub-search-container row">
                <h3 class="adv-search-title" [class.remove-border]="!advanceSearchToggle"
                    (click)="advanceSearchToggle = !advanceSearchToggle">
                    Advanced Search
                </h3>
                <div class="sub-search-wrapper" *ngIf="advanceSearchToggle">
                    <div class="col-md-4">
                        <label>
                            <span>Web Order Id</span>
                        </label>
                        <input type="text" class="user-input-text" [(ngModel)]="searchQuery.sourceorderid"
                            name="sourceorderid" />
                    </div>
                    <div class="col-md-4">
                        <label>
                            <span>Address Line 1</span>
                        </label>
                        <ng2-completer name="custaddressline1" inputName="custaddressline1" inputClass="user-input-text"
                            [(ngModel)]="searchQuery.custaddressline1" [datasource]="custaddressline1DataService">
                        </ng2-completer>
                    </div>
                    <div class="col-md-4">
                        <label>
                            <span>Town</span>
                        </label>
                        <ng2-completer name="custtown" inputName="custtown" inputClass="user-input-text"
                            [(ngModel)]="searchQuery.custtown" [datasource]="custtownDataService"></ng2-completer>
                    </div>
                    <div class="col-md-4">
                        <label>
                            <span>First Name</span>
                        </label>
                        <ng2-completer name="custfname" inputName="custfname" inputClass="user-input-text"
                            [(ngModel)]="searchQuery.custfname" [datasource]="custfnameDataService"></ng2-completer>
                    </div>
                    <div class="col-md-4">
                        <label>
                            <span>Address Line 2</span>
                        </label>
                        <ng2-completer name="custaddressline2" inputName="custaddressline2" inputClass="user-input-text"
                            [(ngModel)]="searchQuery.custaddressline2" [datasource]="custaddressline2DataService">
                        </ng2-completer>
                    </div>
                    <div class="col-md-4">
                        <label>
                            <span>County</span>
                        </label>
                        <ng2-completer name="custcounty" inputName="custcounty" inputClass="user-input-text"
                            [(ngModel)]="searchQuery.custcounty" [datasource]="custcountyDataService"></ng2-completer>
                    </div>
                    <div class="col-md-4">
                        <label>
                            <span>Last Name</span>
                        </label>
                        <ng2-completer name="custsname" inputName="custsname" inputClass="user-input-text"
                            [(ngModel)]="searchQuery.custlname" [datasource]="custlnameDataService"></ng2-completer>
                    </div>
                    <div class="col-md-4">
                        <label>
                            <span>Address Line 3</span>
                        </label>
                        <ng2-completer name="custaddressline3" inputName="custaddressline3" inputClass="user-input-text"
                            [(ngModel)]="searchQuery.custaddressline3" [datasource]="custaddressline3DataService">
                        </ng2-completer>
                    </div>
                    <div class="col-md-4">
                        <label>
                            <span>Post Code</span>
                        </label>
                        <ng2-completer name="custpostalcode" inputName="custpostalcode" inputClass="user-input-text"
                            [(ngModel)]="searchQuery.custpostalcode" [datasource]="custpostalcodeDataService">
                        </ng2-completer>
                    </div>
                    <div class="col-md-4">
                        <label>
                            <span>Telephone</span>
                        </label>
                        <ng2-completer name="custnumber" inputName="custnumber" inputClass="user-input-text"
                            [(ngModel)]="searchQuery.custnumber" [datasource]="custnumberDataService"></ng2-completer>
                    </div>
                    <div class="col-md-4">
                        <label>
                            <span>Email</span>
                        </label>
                        <ng2-completer name="custemail" inputName="custemail" inputClass="user-input-text"
                            [(ngModel)]="searchQuery.custemail" [datasource]="custemailDataService"></ng2-completer>
                    </div>
                </div>
            </div>
            <div class="text-center">
                <button type="button" class="ycc-button button-clear" (click)="searchForm.reset(); callClear();">
                    Clear
                </button>
                <button type="submit" class="ycc-button button-search">
                    Search
                </button>
            </div>
        </form>
    </div>
</div>