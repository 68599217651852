import { Injectable } from '@angular/core';
// import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable, throwError } from  'rxjs';
// import 'rxjs/add/operator/do';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/observable/throw';
// import 'rxjs/add/operator/toPromise';

@Injectable()
export class DataService {

  private results: any;

  constructor(private _http: HttpClient) {
    console.log("Ajax call")
  }
  private commentsUrl = 'https://jsonplaceholder.typicode.com/posts';
  GetPost() {
    return this._http.get(this.commentsUrl).subscribe(data => {
      this.results = data['_body'];
      console.log(this.results)
    });

  }

  GetPostMethod(url, params) {
    return this._http.get("/api/"+url).pipe(map((data: HttpResponse<any>) => data));
  }
  postmethod(url, params) {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    // const options = new RequestOptions({ headers: headers });
    const options = {
      headers: new HttpHeaders().append('key', 'value'),
      params: new HttpParams().append('key', 'value')
    }
    return this._http.post('/api/' + url, JSON.stringify(params), options)
      .pipe(map((data: HttpResponse<any>) => data));
     
  }
  private handleError(error: HttpResponse<any>) {
    console.error(error);
    return throwError(error["error"] || 'Server error');
  }
}