<div class="container pagecontainer">
  <div class="page-header">
    <h1>Change Password</h1>
  </div>

  <form [formGroup]="passwordForm" (ngSubmit)="updatePassword(passwordForm.value)" class="form-horizontal" novalidate
    autocomplete="off">
    <div class="form-group"
      [ngClass]="{'has-error': passwordForm.get('oldpassword').invalid && passwordForm.get('oldpassword').dirty}">
      <label for="oldpassword" class="col-sm-2 control-label">Existing Password</label>
      <div class="input-group col-sm-10">
        <div class="input-group-addon"><i class="glyphicon glyphicon glyphicon-lock"></i></div>
        <input type="password" class="form-control" id="oldpassword" [formControl]="oldpassword"
          placeholder="Existing Password">
      </div>
      <div class="text-danger" *ngIf="passwordForm.get('oldpassword').dirty && passwordForm.get('oldpassword').errors">
        <span class="col-sm-2"></span>
        <span class="col-sm-10" style="padding:4px 0 0" *ngIf="passwordForm.get('oldpassword').errors">
          Please enter existing password
        </span>
      </div>
    </div>

    <div formGroupName="passwordGroup" [ngClass]="{'has-error': passwordForm.get('passwordGroup').errors }">

      <div class="form-group"
        [ngClass]="{'has-error': passwordForm.get('passwordGroup.password').invalid && passwordForm.get('passwordGroup.password').dirty}">
        <label for="password" class="col-sm-2 control-label">New Password</label>
        <div class="input-group col-sm-10">
          <div class="input-group-addon"><i class="glyphicon glyphicon glyphicon-asterisk"></i></div>
          <input type="password" class="form-control" id="password" [formControl]="password" placeholder="New Pasword">
        </div>
        <div class="text-danger"
          *ngIf="passwordForm.get('passwordGroup.password').dirty && passwordForm.get('passwordGroup.password').errors">
          <span class="col-sm-2"></span>
          <span class="col-sm-10" style="padding:4px 0 0"
            *ngIf="passwordForm.get('passwordGroup.password').errors && role == 'Administrator Accounts'">
            Password may not contain your name, be atleast 16~20 characters, with atleast 1 uppercase, lowercase,
            number, special character
          </span>
          <span class="col-sm-10" style="padding:4px 0 0"
            *ngIf="passwordForm.get('passwordGroup.password').errors && role == 'Privileged Accounts'">
            Password may not contain your name, be atleast 10~20 characters, with atleast 1 uppercase, lowercase,
            number, special character
          </span>
          <span class="col-sm-10" style="padding:4px 0 0"
            *ngIf="passwordForm.get('passwordGroup.password').errors && role == 'Non-Privileged Accounts'">
            Password may not contain your name, be atleast 8~20 characters, with atleast 1 uppercase, lowercase,
            number, special character
          </span>
          <span class="col-sm-10" style="padding:4px 0 0"
            *ngIf="passwordForm.get('passwordGroup.password').errors && role == 'Service Accounts'">
            Password may not contain your name, be atleast 18~20 characters, with atleast 1 uppercase, lowercase,
            number, special character
          </span>
        </div>
      </div>
      <div class="form-group"
        [ngClass]="{'has-error': passwordForm.get('passwordGroup.retypepass').invalid && passwordForm.get('passwordGroup.retypepass').dirty}">
        <label for="retypepass" class="col-sm-2 control-label">Retype Password <sup>*</sup></label>
        <div class="input-group col-sm-10">
          <div class="input-group-addon"><i class="glyphicon glyphicon glyphicon-asterisk"></i></div>
          <input type="password" class="form-control" [formControl]="retypepass" id="retypepass"
            placeholder="Retype Password">
        </div>
        <div class="text-danger"
          *ngIf="(passwordForm.get('passwordGroup.retypepass').touched || passwordForm.get('passwordGroup.retypepass').dirty) && (passwordForm.get('passwordGroup.retypepass').errors || passwordForm.get('passwordGroup').errors)">
          <span class="col-sm-2"></span>
          <span class="col-sm-10" style="padding:4px 0 0" *ngIf="passwordForm.get('passwordGroup.retypepass').errors">
            Please confirm your password
          </span>
          <span class="col-sm-10" style="padding:4px 0 0" *ngIf="passwordForm.get('passwordGroup').errors">
            Password do not match
          </span>
        </div>
      </div>

    </div>

    <div class="col-md-4" hidden>
      <label for="roles">Roles</label>
      <!-- <select class="user-input-select"  name="roles" [formControl]="roles"  id="group_name" (onselect)="passsword($event, roles_list)" id="myOptions"> -->
      <select class="user-input-select" name="roles" [formControl]="roles" id="group_name" id="myOptions">
        <option value="" disabled>Select one--</option>
        <option *ngFor="let roles_list of roles_lists; let i = index;" id="roles{{i}}">{{roles_list}}</option>
      </select>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label"></label>
      <div class="col-sm-10">
        <button type="submit" [disabled]="passwordForm.invalid" class="btn btn-primary">Update</button>
        <button type="reset" class="btn btn-default">Cancel</button>
      </div>
    </div>
  </form>
</div>