import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'cstToUtc' })
export class CstToUtcPipe implements PipeTransform {
    transform(dateString: string) {
        var convertedDateString = dateString.replace('T', ' ').replace('Z', ' CST');

        var dateValue = new Date(convertedDateString);

        var UKDateString =
            (dateValue.getUTCDate() < 10 ? '0' : '') + dateValue.getUTCDate() + '-' +
            ((dateValue.getUTCMonth() + 1) < 10 ? '0' : '') + (dateValue.getUTCMonth() + 1) + '-' +
            dateValue.getUTCFullYear() + ' ' +
            (dateValue.getUTCHours() < 10 ? '0' : '') + dateValue.getUTCHours() + ':' +
            (dateValue.getUTCMinutes() < 10 ? '0' : '') + dateValue.getUTCMinutes() + ':' +
            (dateValue.getUTCSeconds() < 10 ? '0' : '') + dateValue.getUTCSeconds();

        return UKDateString;
    }
}