<div class="detail-view-container container">
    <div class="back-to-search clearfix">
        <button class="pull-right back-to-search-button" (click)="closeSection()">
            <img src="assets/images/close-button.png" alt="close button">
            <span>Back to Search</span>
        </button>
    </div>
    <div class="row">
        <div class="col-md-4 label-img-container">
            <img [src]="detailViewData.print_img" class="detailViewLabelImage" alt="Print image">
        </div>
        <div class="col-md-4 customer-details-container">
            <h2>Customer Details</h2>
            <form #detailViewform="ngForm">
                <div class="customer-input-container">
                    <label>Order Id</label>
                    <input class="pull-right detail-view-inputs" type="text" name="orderId" [value]="detailViewData.order_id" disabled>
                </div>
                <div class="customer-input-container">
                    <label>Web Order Id</label>
                    <input class="pull-right detail-view-inputs" type="text" name="webOrderId" [value]="detailViewData.source_order_id" disabled>
                </div>
                <div class="customer-input-container">
                    <label>First Name</label>
                    <input class="pull-right detail-view-inputs" type="text" name="firstName" [value]="detailViewData.cust_firstName" disabled>
                </div>
                <div class="customer-input-container">
                    <label>Last Name</label>
                    <input class="pull-right detail-view-inputs" type="text" name="surname" [value]="detailViewData.cust_lastName" disabled>
                </div>
                <div class="customer-input-container">
                    <label>Email</label>
                    <input class="pull-right detail-view-inputs" type="text" name="email" [value]="detailViewData.cust_email" disabled>
                </div>
                <div class="customer-input-container">
                    <label>Label Status</label>
                    <input class="pull-right detail-view-inputs" type="text" name="status" [value]="detailViewData.order_status" disabled>
                </div>
                <div class="customer-input-container">
                    <label>Telephone</label>
                    <input class="pull-right detail-view-inputs" type="text" name="phone" [value]="detailViewData.cust_phone" disabled>
                </div>
                <div class="customer-input-container clearfix">
                    <label>Text Field 1</label>
                    <span class="pull-right text-fields span-truncating-text" title="{{detailViewData.label_text1}}">
                        {{detailViewData.label_text1}}
                    </span>
                    <!-- <input class="pull-right" type="text" name="text1" [value]="detailViewData.label_text1" disabled> -->
                </div>
                <div class="customer-input-container clearfix">
                    <label>Text Field 2</label>
                    <span class="pull-right text-fields span-truncating-text" title="{{detailViewData.label_text2}}">
                        {{detailViewData.label_text2}}
                    </span>
                    <!-- <input class="pull-right" type="text" name="tetx2" [value]="detailViewData.label_text2" disabled> -->
                </div>
                <div class="customer-input-container">
                    <label>Order Date/Time</label>
                    <input class="pull-right detail-view-inputs" type="text" name="orderDateTime" [value]="detailViewData.order_date" disabled>
                </div>
                <div class="customer-input-container">
                    <label>Order Quantity</label>
                    <input class="pull-right detail-view-inputs" type="text" name="orderQuantity" [value]="detailViewData.quantity" disabled>
                </div>
            </form>
        </div>
        <div class="col-md-4 reject-reason-container">
            <h2>Rejection Reason</h2>
            <form #rejectReasonform="ngForm">
                <div class="reject-code-container">
                    <label for="rejectCode">Reason Code:</label>
                    <div *ngIf = "shipToCountry !== 'FR' && shipToCountry !== 'IT' && shipToCountry !== 'DE'" >
                        <select name="rejectCode" id="rejectCode" [(ngModel)]="detailViewData.reason_code" (change)="rejectCodeChange(detailViewData.reason_code);">
                            <option value="null" selected disabled>Reason Code</option>
                            <option value=""></option>
                            <option *ngFor="let rejectCodetmpl of rejectCodes.reason_code" [value]="rejectCodetmpl">{{rejectCodetmpl}}</option>
                        </select>
                    </div>
                    <div *ngIf = "shipToCountry == 'FR'">
                        <select name="rejectCode" id="rejectCode" [(ngModel)]="detailViewData.reason_code" (change)="rejectCodeChange(detailViewData.reason_code);">
                            <option value="null" selected disabled>Reason Code</option>
                            <option value=""></option>
                            <option *ngFor="let rejectCodetmpl of rejectCodes.reason_code_fr" [value]="rejectCodetmpl">{{rejectCodetmpl}}</option>
                        </select>
                    </div>
                    <div *ngIf = "shipToCountry == 'IT'">
                        <select name="rejectCode" id="rejectCode" [(ngModel)]="detailViewData.reason_code" (change)="rejectCodeChange(detailViewData.reason_code);">
                            <option value="null" selected disabled>Reason Code</option>
                            <option value=""></option>
                            <option *ngFor="let rejectCodetmpl of rejectCodes.reason_code_it" [value]="rejectCodetmpl">{{rejectCodetmpl}}</option>
                        </select>
                    </div>
                    <div *ngIf = "shipToCountry == 'DE'">
                        <select name="rejectCode" id="rejectCode" [(ngModel)]="detailViewData.reason_code" (change)="rejectCodeChange(detailViewData.reason_code);">
                            <option value="null" selected disabled>Reason Code</option>
                            <option value=""></option>
                            <option *ngFor="let rejectCodetmpl of rejectCodes.reason_code_de" [value]="rejectCodetmpl">{{rejectCodetmpl}}</option>
                        </select>
                    </div>
                </div>
                <div class="reject-reason-contianer">
                    <label for="rejectReason">Reason Description:</label>
                    <textarea name="rejectReason" id="rejectReason" class="reject-reason" [(ngModel)]="detailViewData.reason_desc" placeholder="Reason for rejection" maxlength="254"></textarea>
                </div>
                <div class="reject-comments-container">
                    <label for="rejectComment">Internal Comments:</label>
                    <textarea name="rejectComment" id="rejectComment" class="reject-comment" [(ngModel)]="detailViewData.reason_comment" placeholder="Rejection comments" maxlength="254"></textarea>
                </div>
            </form>
        </div>
    </div>
    <div class="col-md-offset-5 text-center action-buttons-container">
        <button [disabled]="!detailViewData.reason_code || detailViewData.reason_code === 'Waiting On Consumer'" *ngIf="isCSCLabelManager" (click)="rejectOrder();">Reject</button>
        <button [disabled]="!detailViewData.reason_code || detailViewData.reason_code === 'Waiting On Consumer'" *ngIf="isCSCLabelManager" (click)="holdOrder();">Hold</button> 
        <button [disabled]="!detailViewData.reason_code" *ngIf="!isCSCLabelManager"(click)="holdOrder();">Hold</button>
        <button [disabled]="!disableApprove" (click)="approveOrder();">Approve</button>
    </div>
</div>