import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationComponent } from './configuration.component';


import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { MdDatepickerModule, MdInputModule, MdNativeDateModule, MdGridListModule } from '@angular/material';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2CompleterModule } from "ng2-completer";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";
import { DataService } from "../../shared/service/data.service"

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MultiselectDropdownModule,
    MatGridListModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    NgxDatatableModule,
    Ng2CompleterModule
  ],
  declarations: [ConfigurationComponent],
  exports: [ConfigurationComponent],
  bootstrap: [],
  providers: [DataService]
})
export class ConfigurationModule { 
  
}
