<div md-dialog-content>
    <div popup-content>
        <div class="panel panel-default">
            <div class="panel-heading" *ngIf="!upsError">
                <h2>EU Orders Manual Consignment</h2>
                <button class="pull-right back-to-page-button" (click)="closePopup(true)"><img
                        src="assets/images/close-button.png" alt="close button"></button>
            </div>
            <div class="panel-heading" *ngIf="upsError">
                <h2>Manual Consignment</h2>
                <button class="pull-right back-to-page-button" (click)="closePopup(true)"><img
                        src="assets/images/close-button.png" alt="close button"></button>
            </div>
        </div>
        <div class="red" *ngIf="upsError">
            <p><strong>Action Failed: <span>{{upsError}}</span></strong></p>
        </div>

        <form class="manualcons" [formGroup]="registerForm" (ngSubmit)="save(registerForm.value)" novalidate
            autocomplete="off">

            <div class="sub-search-container row">
                <div class="sub-search-wrapper">
                    <div class="form-row">
                        <label>Shipping Type</label>
                        <select class="shipping_service_type" name="shipping_service_type"
                            formControlName="shipping_service_type" [(ngModel)]='selectedValue'>
                            <option *ngFor="let service_type of shipped_service_type" [value]="service_type">
                                {{service_type}}</option>
                        </select>
                    </div>
                    <br />
                    <label for="packages">Tracking Number</label>
                    <div *ngFor="let package of t.controls; let i = index">
                        <div [formGroup]="package" class="form-row">
                            <div class="user-input-track">
                                <label for="trackingNumber">Parcel {{i+1}}</label>
                                <input type="text" class="user-input-text" formControlName="trackingNumber"
                                    placeholder="Enter Tracking Number" />
                            </div>
                            <br>
                        </div>
                    </div>
                    <div class="col-md-10 text-center">
                        <button type="reset" class="btn btn-default pull-right leftpadding"
                            (click)="reset()">Reset</button>
                        <button type="submit" [disabled]="registerForm.invalid"
                            class="btn btn-default pull-right leftpadding" (click)="Click()">Save</button>
                    </div>

                </div>
            </div>
        </form>
    </div>
</div>