import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { CompleterService, CompleterData } from 'ng2-completer';

import { SearchQuery } from '../../model/searchQuery.model';
import { SearchDataList } from '../../model/searchDataList.model';

@Component({
    selector: 'order-search',
    templateUrl: './order-search.component.html',
    styleUrls: ['./order-search.component.css'],
    animations: [
        trigger('shrinkInOut', [
            transition(':leave', [
                style({ height: "*" }),
                animate(350, style({ height: 0 }))
            ]),
            transition(':enter', [
                style({ height: 0 }),
                animate(350, style({ height: "*" }))
            ])
        ])
    ]
})
export class OrderSearchComponent implements OnChanges, AfterViewInit {

    @Input() dropDownList: SearchDataList;

    @Input() searchQuery: SearchQuery;
    @Output() searchQueryChange = new EventEmitter();

    @Output() searchClick = new EventEmitter();
    @Output() clearClick = new EventEmitter();

    private multiDropdownElement;

    constructor(private CompleterService: CompleterService, private _elementRef: ElementRef) {
        let autoCompleteData = {
            shiptostateDataService: "shiptostate",
            custfnameDataService: "custfname",
            custlnameDataService: "custlname",
            custnumberDataService: "custnumber",
            custemailDataService: "custemail",
            custaddressline1DataService: "custaddressline1",
            custaddressline2DataService: "custaddressline2",
            custaddressline3DataService: "custaddressline3",
            custtownDataService: "custtown",
            custpostalcodeDataService: "custpostalcode",
            custcountyDataService: "custcounty"
        }

        for (let key in autoCompleteData) {
            this[key] = CompleterService.remote(`api/autoCompleteSearch?${autoCompleteData[key]}=`, null, null);
            this[key].dataField("data");
        }
    }

    shiptostateDataService: CompleterData;
    custfnameDataService: CompleterData;
    custlnameDataService: CompleterData;
    custnumberDataService: CompleterData;
    custemailDataService: CompleterData;
    custaddressline1DataService: CompleterData;
    custaddressline2DataService: CompleterData;
    custaddressline3DataService: CompleterData;
    custtownDataService: CompleterData;
    custpostalcodeService: CompleterData;
    custcountyDataService: CompleterData;
    searchViewToggle: boolean = true;
    advanceSearchToggle: boolean = false;

    @ViewChild('qtyFieldRef')
    private qtyFieldEleRef: ElementRef;

    @ViewChild('ageFieldRef')
    private ageFieldEleRef: ElementRef;

    fromDate: Date;
    toDate:Date;
    skuDesc: string[] = [];

    //max date to be able to select in datepicker
    maxDate = new Date((new Date).setMinutes((new Date).getMinutes() + 1));

    fromToDateMismatch: boolean = false;

    multiSelectTexts: IMultiSelectTexts = {
        checkAll: 'Select all',
        uncheckAll: 'Clear all',
    }
    multiSelectSettings: IMultiSelectSettings = {
        enableSearch: true,
        dynamicTitleMaxItems: 1,
        showCheckAll: true,
        showUncheckAll: true,
        buttonClasses: "btn btn-default multi-select"
    }

    singleSelectTexts: IMultiSelectTexts = {
        uncheckAll: 'Clear',
    }
    singleSelectSettings: IMultiSelectSettings = {
        enableSearch: true,
        selectionLimit: 1,
        autoUnselect: true,
        checkedStyle: "fontawesome",
        closeOnSelect: true,
        showUncheckAll: true,
        buttonClasses: "btn btn-default multi-select"
    }

    ngAfterViewInit(): void {
        let closeButtonElem;
        this.multiDropdownElement = this._elementRef.nativeElement.querySelectorAll('ss-multiselect-dropdown button');
        for (let i = 0; i < this.multiDropdownElement.length; i++) {
            this.multiDropdownElement[i].addEventListener('click', function(e) {
                e.srcElement.nextElementSibling.firstElementChild.querySelector('input').value = '';
                closeButtonElem = e.srcElement.nextElementSibling.firstElementChild.querySelector('button');
                if (closeButtonElem) {
                    closeButtonElem.click();
                }
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.dropDownList.firstChange) {
            this.dropDownList = changes.dropDownList.currentValue;
            this.dropDownList.datalist.new_sku.sort(function (a, b) { return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0); });
            this.dropDownList.datalist.new_sku_description.sort(function (a, b) { return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0); });
            this.dropDownList.datalist.product_fragrance.sort();
            this.dropDownList.datalist.product_form.sort();

        }
    }

    dateSelected(e, field) {
        if(field === "from") {
            this.fromDate = e;
        } else {
            this.toDate = e;
        }
        this.fromToDateMismatch = !this.checkDateValidity(this.fromDate, this.toDate);
    }

    callSearch() {
        //stop service call on error
        if ( this.fromToDateMismatch ) {
            return false;
        }
        //format from & to Dates before searching
        this.searchQuery.fromdate = (this.fromDate) ? (new Date(this.fromDate.setMinutes(this.fromDate.getMinutes() - this.fromDate.getTimezoneOffset()))).toJSON().replace('Z', '') : '';
        this.searchQuery.todate = (this.toDate) ? (new Date(this.toDate.setMinutes(this.toDate.getMinutes() - this.toDate.getTimezoneOffset()))).toJSON().replace('Z', '') : '';
        //sku desc "Decor" conversion
        if(this.skuDesc ) {
            if( this.skuDesc.length) {
                this.searchQuery.skudesc = this.skuDesc.map( (value, index)=> {
                    if ( value.indexOf("Small Decor") > -1) {
                        value = value.replace(/Decor/, "D&eacute;cor")
                    }
                    return value;
                })
            } else {
                this.searchQuery.skudesc = [];
            }
        }
        this.searchClick.emit();
    }

    callClear() {
        this.searchQuery.fromdate = "";
        this.searchQuery.todate = "";
        this.fromToDateMismatch = false;
        this.searchQuery.skudesc = [];
        this.clearClick.emit();

        if(!this.advanceSearchToggle) {
            this.searchQuery.sourceorderid = "";
            this.searchQuery.custaddressline1 = "";
            this.searchQuery.custtown = "";
            this.searchQuery.custfname = "";
            this.searchQuery.custaddressline2 = "";
            this.searchQuery.custcounty = "";
            this.searchQuery.custlname = "";
            this.searchQuery.custaddressline3 = "";
            this.searchQuery.custpostalcode = "";
            this.searchQuery.custnumber = "";
            this.searchQuery.custemail = "";
        }
    }
    checkDateValidity(from, to): boolean {
        if (from && to && from > to) {
            return false;
        }
        //returning true by default
        return true;
    }
    quantityUpdate(e) {
        this.searchQuery.quantity = this.onlyDecimal(e, true);
        this.qtyFieldEleRef.nativeElement.value = this.searchQuery.quantity;
    }
    ageUpdate(e) {
        this.searchQuery.age = this.onlyDecimal(e, true);
        this.ageFieldEleRef.nativeElement.value = this.searchQuery.age;
    }
    onlyDecimal (input: string, zeroInvalid?: boolean) {
        if (input && /\D/g.test(input)) {
          // Filter non-digits from input value.
          input = input.replace(/\D/g, '');
        }
        //check to zero
        if (zeroInvalid && input == "0") {
            input = '';
        }
        return input;
    }
}