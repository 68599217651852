import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SearchSharedModule } from '../../shared/module/searchshared.module';
import { QualityAssuranceComponent, QAFailedMessageModal } from './qa.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SearchSharedModule
    ],
    declarations: [
        QualityAssuranceComponent,
        QAFailedMessageModal
    ],
    entryComponents: [
        QAFailedMessageModal
    ],
    exports: [
        QualityAssuranceComponent
    ]
})

export class QualityAssuranceModule{}