import { Component, OnInit, Input } from '@angular/core';



@Component({
    selector: 'app-stackbar',
    templateUrl: './stackbar.component.html',
    styleUrls: ['./stackbar.component.css']
})
export class StackbarComponent implements OnInit {
    @Input() bargraphData: any;

    
    constructor() { }

    ngOnInit() {
        
    }    

    public barChartOptions: any = {
        scaleShowVerticalLines: false,
        responsive: true,
        scaleShowValues: false,
        scaleValuePaddingX: 10,
        scaleValuePaddingY: 10,
        animation: {
            onComplete: function () {
                var chartInstance = this.chart,
                    ctx = chartInstance.ctx;
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';
                this.data.datasets.forEach(function (dataset, i) {
                    var meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {
                        var data = dataset.data[index];
                        ctx.fillText(data, bar._model.x, bar._model.y - 5);
                    });
                });
            }
        }
    };
    public barChartLabels: string[] = ['New', 'Pending', 'Approved', 'On-Hold', 'Rejected', 'Ready to Print', 'Printed', 'QA Verified', 'QA Review', 'Cancelled', 'Shipped', 'Returned'];
    public barChartType: string = 'bar';
    public barChartLegend: boolean = true;

    public barChartData: any[] = [       
        { data: this.bargraphData}
    ];
     
    // events
    public chartClicked(e: any): void {
        console.log(e);

    }

    public chartHovered(e: any): void {
        console.log(e);
    }


    public randomize(): void {
        // Only Change 3 values
        let data = [Math.round(Math.random() * 100), 59, 80, (Math.random() * 100), 20, 60, (Math.random() * 100), 55, 75, (Math.random() * 100), 29, 10, (Math.random() * 100), 56, (Math.random() * 100), 40];
        let clone = JSON.parse(JSON.stringify(this.barChartData));
        clone[0].data = data;
        this.barChartData = clone;
        /**
         * (My guess), for Angular to recognize the change in the dataset
         * it has to change the dataset variable directly,
         * so one way around it, is to clone the data, change it and then
         * assign it;
         */
    }

}
