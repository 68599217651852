<div class="row pagination-wrapper clearfix">
    <div class="col-md-4">
        <div>
            Total orders: {{ searchResults.totalOrderCount }} <span class="pageDetailsDisplay" *ngIf="hideQuantity == undefined">Selected orders quantity: {{getSelectedOrdersQuantity()}} </span>
        </div>
        <div>
            <span *ngIf="searchResults.orders.length; else Zero_Results_count">
                View orders: 
                <ng-container *ngIf="searchResults.currentPageSize && searchResults.currentPageNo !== searchResults.totalPageCount">
                    {{ ((searchResults.currentPageNo - 1) * searchResults.currentPageSize) + 1}} - {{ searchResults.currentPageNo * searchResults.currentPageSize}}
                </ng-container>
                <ng-container *ngIf="searchResults.currentPageNo === searchResults.totalPageCount">
                    {{ ((searchResults.currentPageNo - 1) * searchResults.currentPageSize) + 1}} - {{searchResults.totalOrderCount}}
                </ng-container> 
                <ng-container *ngIf="!searchResults.currentPageSize">
                    1 - {{searchResults.totalOrderCount}}
                </ng-container>            
            </span>
            <ng-template #Zero_Results_count>Total ( 0 of 0 )</ng-template>
            <span class="pageDetailsDisplay" *ngIf="!noSelectText">                
                Select a row to view details above
            </span>
        </div>
    </div>
    <div class="col-md-4 text-center">
        <button class="prev-button" type="button" (click)="onPrev()" [disabled]="(searchResults && searchResults.currentPageNo === 1) || isLoading"> < Prev</button>
        <input type="text" #pageNumTopFieldRef class="pageText text-right" [class.error]="isPageCountError" [disabled]="isLoading" name="pageno" [ngModel]="pageNum" (ngModelChange)="updatePageNo($event)" (keyup.enter)="getpageNum();" />
        <span> of {{ searchResults.totalPageCount ? searchResults.totalPageCount : 1 }} </span>
        <button class="next-button" type="button" (click)="onNext()" [disabled]="(searchResults && searchResults.totalPageCount && searchResults.currentPageNo === searchResults.totalPageCount) || !searchResults.totalPageCount  || isLoading">Next  ></button>
    </div>
    <div class="col-md-4">
        <div class="pull-right">
            <span>Display: </span>
            <select name="pagesize" [(ngModel)]="pageSize" (change)="pageSizeChanged();" [disabled]="isLoading">
            <!--<option *ngFor="let pageSz of pageSizes" [value]="pageSz.value">{{pageSz.name}}</option>-->
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50" selected>50</option>
                <option value="100">100</option>
                <option value="150">150</option>
                <option value="0">All</option>
            </select>
            <span *ngIf="!noGridView">
                <span>View: </span>
                <img (click)="viewChange.emit( true );" src="assets/images/list-view.png" />
                <span> | </span>
                <img (click)="viewChange.emit( false );" src="assets/images/grid-view.png" />
            </span>
        </div>
    </div>
</div>

<!-- content -->
<ng-content select="[searchdisplay]"></ng-content>
<!-- content -->
<div class="row pagination-wrapper clearfix">
    <div class="col-md-4 col-md-push-4 text-center">
        <button class="prev-button" type="button" (click)="onPrev()" [disabled]="(searchResults && searchResults.currentPageNo === 1) || isLoading"> < Prev</button>
        <input type="text" #pageNumBottomFieldRef class="pageText text-right" [class.error]="isPageCountError" [disabled]="isLoading" name="pageno" [ngModel]="pageNum" (ngModelChange)="updatePageNo($event)" (keyup.enter)="getpageNum();" />
        <span> of {{ searchResults.totalPageCount ? searchResults.totalPageCount : 1 }} </span>
        <button class="next-button" type="button" (click)="onNext()" [disabled]="(searchResults && searchResults.totalPageCount && searchResults.currentPageNo === searchResults.totalPageCount) || !searchResults.totalPageCount  || isLoading">Next  ></button>
        
    </div>
    <div class="col-md-push-4">
        <ng-content select="[actionbuttons]"></ng-content>
    </div>
</div>