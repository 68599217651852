import { Component, Input, Output, EventEmitter, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as moment from 'moment';
import { OrderDetails } from "../../model/orderDetails.model";
import { SearchQuery } from "../../model/searchQuery.model";
import { CstToUtcPipe } from '../../pipe/cst-to-utc.pipe';
import { SharedApplicationService } from '../../service/shared.service';

@Component({
    selector: 'list-view',
    templateUrl: 'data-table.component.html',
    styleUrls: ['data-table.component.css']
})

export class DataTablecomponent implements OnInit {
    cstToUtc: CstToUtcPipe = new CstToUtcPipe();
    constructor(@Inject(DOCUMENT) private document: Document, private sharedApp: SharedApplicationService) { }

    @Input() disableCheckBoxForMissingReadyToPrintImages: boolean = false;
    @Input() searchQuery: SearchQuery;
    @Output() searchQueryChange = new EventEmitter();

    @Output() recordClicked = new EventEmitter();
    @Output() itemsSelected = new EventEmitter();
    @Output() dataListChange = new EventEmitter();

    private _dataList: OrderDetails[];

    @Input()
    set dataList(value: OrderDetails[]) {
        value = value.map((data) => {
            data.product_fragrance = data.product_fragrance.split("&#")[0];
            let order_date = data.order_date.replace('Z', '-0600');
            data.order_date = moment(order_date).format("MM/DD/YYYY HH:mm");
            let eventJSON;
            eventJSON = JSON.stringify(event.srcElement);
            if(data.order_status === "ReadyToPrint" || data.order_status === "Printed" || data.order_status === "QA Review"){
                if (eventJSON.includes("&filter=missingbarcode")) {
                    this.missingBarcode = true;
                    data.disableCheckBoxForMissingReadyToPrintImages = false;
                }
                else {
                    this.missingBarcode = false;
                    data.disableCheckBoxForMissingReadyToPrintImages = this.disableCheckBoxForMissingReadyToPrintImages;
                }
            }
            else {
                data.disableCheckBoxForMissingReadyToPrintImages = this.disableCheckBoxForMissingReadyToPrintImages;
                data.isSelected = (this.disableCheckBoxForMissingReadyToPrintImages==true && data.print_ready_image == "") ? false : true;
            }
            
            return data;
        });
        this._dataList = value;
        this.setSelectedRows(this._dataList);
    }
    get dataList(): OrderDetails[] {
        return this._dataList;
    }

    selectedRows;
    defaultSort;
    loading;
    missingBarcode: boolean = false;
    ngOnInit() {
        this.defaultSort = [{ prop: this.searchQuery.sortby, dir: this.searchQuery.sortorder }]
    }

    setSelectedRows(newDataList: OrderDetails[]) {
        this.selectedRows = newDataList.filter((obj) => {
            if(obj.order_status === "ReadyToPrint" || obj.order_status === "Printed" || obj.order_status === "QA Review"){
                if (this.missingBarcode) {
                    obj.isSelected = true;
                    return true
                }
                else {
                    obj.isSelected =  (!obj.barcode_img && !obj.print_ready_image) ? false : true
                    return obj.isSelected;
                }
            }
            else if(obj.order_status === "OnHold"){
                obj.isSelected =  (obj.reason_code == 'Waiting On Consumer') ? false : true
                return obj.isSelected;
            }
            else{
                return obj.isSelected;
            }
        })
    }

    columns: any[] = [
        { flexGrow: 1, sortable: false, draggable: false, resizeable: false, headerCheckboxable: true, checkboxable: true },
        { name: 'Order#', flexGrow: 4, prop: 'order_id' },
        { name: 'Order Size', flexGrow: 3, prop: 'order_size' },
        { name: 'Date/Time', flexGrow: 5, prop: 'order_date', sortable: true },
        { name: 'SKU', flexGrow: 3, prop: 'sku', sortable: true },
        { name: 'Product Form', flexGrow: 4, prop: 'product_form', sortable: true },
        { name: 'Fragrance', flexGrow: 4, prop: 'product_fragrance', sortable: true },
        { name: 'Qty', flexGrow: 2, prop: 'quantity', sortable: true }
    ];

    onSelect(e) {      
        for (let dataItem of this.dataList) {
            var selectorders = (e.selected.filter((obj) => {
                return dataItem.order_id == obj.order_id;
            }))
            if (selectorders.length == 1) {
                dataItem.isSelected = (!dataItem.print_ready_image && !dataItem.barcode_img) ? false : true;
                if(this.missingBarcode){
                    dataItem.isSelected = true;
                }  
            } else {
                dataItem.isSelected = false;
            }
        }
    }

    onUserEvent(e) {
        if (e.type == "click" && e.column.prop == "order_id") {
            this.recordClicked.emit(e.row);
            this.document.body.scrollTop = 0;
        }
    }

    onSort(e) {
        this.searchQuery.sortby = e.sorts[0].prop;
        this.searchQuery.sortorder = e.sorts[0].dir == "asc" ? "1" : "-1";
        this.searchQueryChange.emit(this.searchQuery);
    }

    displayCheck(row) {
        return (() => {
            if (this.missingBarcode = true) {
                return true;
            }
            else {
                this.missingBarcode = false;
                return ((row.disableCheckBoxForMissingReadyToPrintImages && !row.print_ready_image) ? false : true);
            }
        })();
    }
}
