import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from "@angular/common/http";
import { UserInfoModalService } from '../../../shared/util/modal/user-info.modal';
import { SharedApplicationService } from "../../../shared/service/shared.service"
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-manual-consignment',
  templateUrl: './manual-consignment.component.html',
  styleUrls: ['./manual-consignment.component.css']
})
export class ManualConsignmentComponent implements OnInit {
  data = [];
  orderNumber: string;
  collectionDate: string;
  quantity;
  upsError;
  orderLineItemlist;
  registerForm: FormGroup;
  nextClicked = false;
  trackingNumber = new FormControl();
  shipping_service_type = new FormControl();
  dataCopy = [];
  order_lines = [];
  num_of_pkg;
  radioSelected;
  constructor(private http: HttpClient, private userModal: UserInfoModalService,
    public dialogRef: MatDialogRef<ManualConsignmentComponent>,
    @Inject(MAT_DIALOG_DATA) public popupData: any, private fb: FormBuilder, public sharedAppService: SharedApplicationService) {
    console.log(this.popupData, "pop up data")
    this.data = this.popupData;
    this.dataCopy = this.data;
    this.orderNumber = this.popupData.orderNumber;
    this.collectionDate = this.popupData.collectionDate;
    this.quantity = this.popupData.totalQuantities;
    this.orderLineItemlist = this.popupData.orderLineItemlist;
    this.orderDetails = this.popupData.orderDetails;
    this.order_lines = this.popupData.order_lines;
    this.num_of_pkg = this.popupData.num_of_pkg;
    this.upsError = this.popupData.upsError;
    this.radioSelected = this.popupData.radioSelected;
  }

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      num_of_pkg: new FormArray([]),
      shipping_service_type: this.shipping_service_type,
    })
    console.log(this.registerForm, "register form")
    this.getters();
  }

  get f() { return this.registerForm.controls; }
  get t() { return this.f.num_of_pkg as FormArray; }
  package: any;

  getters() {
    this.package = this.num_of_pkg;
    if (this.t.length < this.package) {
      for (let i = this.t.length; i < this.package; i++) {
        this.t.push(this.fb.group({
          trackingNumber: ['', Validators.required],
        }));
        console.log(this.t, "array inside")
      }
      console.log(this.t, "array after for loop")
    }
    else {
      for (let i = this.t.length; i >= this.num_of_pkg; i--) {
        this.t.removeAt(i);
      }
    }
  }

  shipped_service_type = ["International Delivery", "Next Day Delivery", "Two Day Delivery", "Saturday Delivery", "Sunday Delivery"]
  selectedValue = "International Delivery";

  save(formdata: any): void {
    let status = "Shipped";
    let postParams: any = null;

    console.log(this.orderLineItemlist, "list")
    postParams = {
      orderId: JSON.stringify(this.orderLineItemlist),
      orderStatus: status,
      trackingNumber: formdata.num_of_pkg,
      order_lines: this.order_lines,
      shippingDetails: {
        shippingType: "UPSS",
        shipmentType: this.radioSelected,
        serviceType: formdata.shipping_service_type,
        customerServiceType: this.shippingDetails.customerServiceType,
        invoiceNumber: this.shippingDetails.invoice_number,
        numberOfPackages: this.shippingDetails.numberOfPackages,
        weight: this.shippingDetails.weight,
        collectionDate: moment.parseZone(this.collectionDate).format("YYYY-MM-DDTHH:mm:ss"),
        networkCode: this.shippingDetails.selectedShippingType.networkCode
      },
      deliveryDetails: {
        "firstName": this.orderDetails.shipTo_cust_firstName,
        "lastName": this.orderDetails.shipTo_cust_lastName,
        "email": this.orderDetails.cust_email,
        "number": this.orderDetails.cust_phone,
        "address_line_1": this.orderDetails.shipTo_cust_address_line_1,
        "address_line_2": this.orderDetails.shipTo_cust_address_line_2,
        "address_line_3": this.orderDetails.shipTo_cust_address_line_3,
        "town": this.orderDetails.shipTo_cust_town,
        "county": this.orderDetails.shipTo_cust_county,
        "postcode": this.orderDetails.shipTo_cust_postcode,
        // "postcode": "B66 1BY",//TODO: remove this
        "country": this.orderDetails.shipTo_cust_country
      },
    }
    console.log(postParams, "post")
    this.sharedAppService.httpPostService(`/api/shipping/manualConsignment`, postParams)
      .then(
        // Success
        data => {
          this.userModal.openModal({ status: 'success', title: status, message: `Selected orders successfully ${status}.` });
          this.closePopup(true);
          this.getOrderDetails();
        },
        // Error
        err => {
          console.log(err);
          this.userModal.openModal({ status: 'error', title: 'Action Failed', message: `${status} of order failed. ${err.message}` });
        }
      )
  }
  orderDetails;
  orderLineItemsList = [];
  shippingTypes;
  shippingDetails = {
    shippingType: "Parcel",
    shipmentType: "UPS",
    serviceType: "International Delivery",
    customerServiceType: "Standard Delivery",
    invoice_number: "",
    numberOfPackages: 1,
    weight: "4.9",
    selectedShippingType: {
      networkCode: "",
      serviceType: "",
      type: ""
    }
  }
  orderError: string = "";
  isStatusShipped: boolean = false;

  getOrderDetails() {
    if (!this.orderNumber) {
      return false;
    }
    this.sharedAppService.httpGetService(`/api/orders/${this.orderNumber}/shipping`)
      .then(
        (data) => {
          if (data && data["order_line_items"].length) {
            this.orderDetails = data["order_details"];
            this.orderLineItemsList = data['order_line_items'];
            let orderCancelRejectList = [];
            this.shippingTypes = data["dpd_details"].shippingTypes;
            let shipCountry = data["dpd_details"].countryCode;
            if (shipCountry.indexOf("GB") == -1) {
              this.shippingDetails.weight = "3";
            }
            else {
              this.shippingDetails.weight = "4.9";
            }
            this.shippingDetails.invoice_number = this.orderDetails.invoice_number;
            this.shippingDetails.numberOfPackages = 1;
            this.shippingDetails.shippingType = "Parcel";
            this.shippingDetails.serviceType = "International Delivery";
            let qaVerifiedList = this.orderLineItemsList.filter((order, index) => {
              //order quatity based on order status
              if (order.order_status == "Shipped") {
                this.orderLineItemsList[index].disp_ship_qty = order.shipped_quantity;
                this.isStatusShipped = true;
              } else {
                if (order.quantity_confirmed === null || order.quantity_confirmed === "") {
                  this.orderLineItemsList[index].disp_ship_qty = order.shipped_quantity;
                }
                else {
                  this.orderLineItemsList[index].disp_ship_qty = order.quantity_confirmed;
                }
                this.isStatusShipped = false;
              }
              //seperate cancelled and rejected orders
              if (order.order_status == "Cancelled" || order.order_status == "Rejected") {
                orderCancelRejectList.push(order);
              }
              return (order.order_status == "QA Verified" || order.order_status == "Shipped");
            });

          }
        },
        err => console.log(err)
      );
  }

  public Click(): void {
    this.nextClicked = false;
  }

  public reset(): void {
    this.ngOnInit;
  }

  closePopup(withData: boolean): void {
    if (withData && this.dataCopy.length > 0 && this.dataCopy[0].weight !== '') {
      this.dialogRef.close(this.dataCopy);
    } else {
      this.dialogRef.close();
    }
  }
}
