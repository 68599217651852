<div md-dialog-content class="ordermanagementpopup">
    <div popup-title class="row popup-Header-container">
        <div class="col-md-11 print-ticket-header">
            <h2>Preview Summary</h2>
        </div>
        <div class="col-md-1 back-to-page clearfix">
            <button class="pull-right back-to-page-button" (click)="closePreviewSummaryPopup()"><img src="assets/images/close-button.png" alt="close button"></button>
        </div>
    </div>
    <div popup-content class="row popup-list-container">
        <!-- <app-statuspupup [recordData]="selectedrecodData" (onUpdated)="updatedGride($event)"></app-statuspupup> -->

        <ul class="tabs">
            <li [class.active]="showtabactive">
                <a href="javascript:void(0)" (click)="showtabs(true)">Order Flow</a>
            </li>
            <li [class.active]="!showtabactive">
                <a href="javascript:void(0)" (click)="showtabs(false)">Shipping Info</a>
            </li>
        </ul>
        <form class="statusform" [formGroup]="StatusUpdateForm" novalidate *ngIf="showModelBox">
            <div class="sub-search-wrapper">
                <div class="tabsContent" [class.active]="showtabactive">
                    <div class="col-md-10 col-md-offset-1">
                        <div class="col-md-3">
                            <strong>Order Number:</strong><span> {{Ordernumber}}</span>
                        </div>
                        <div class="col-md-3">
                            <strong>Web Order Id:</strong><span> {{web_order_id}}</span>
                        </div>
                        <div class="col-md-3">
                            <strong>Current Line Item Quantity:</strong><span> {{total_line_items}}</span>
                        </div>
                        <p>Ordered Date: {{updatedate}}</p>
                        <ul class="ordertracking">
                            <li *ngFor="let order of OrderStatusDetails">
                                <div>
                                    <div class="orderStatus active">
                                        <span>{{order.order_status}}</span>
                                        <p class="username">{{order.user}}</p>
                                        <p class="date">{{order.date}}<br>{{order.time}} UTC</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-md-10 col-md-offset-1" style="margin-top:40px;">
                        <h4>{{OrderName}}</h4>
                        <h4 class="orderinfo">Current Order Status: {{orderStatusinfo}}</h4>
                    </div>

                    <div class="clearfix"></div>
                    <fieldset>
                        <legend> K-Shuffle </legend>
                        <!-- Drop Down -->
                        <div class="col-md-4">
                            <div class="formgroup">
                                <label>Order Status</label>
                                <!-- (change)="statuschangevalue(StatusUpdateForm.value.order_status)" -->
                                <select class="user-input-select" (change)="statuschangevalue(StatusUpdateForm)" name="order_status" formControlName="order_status" [disabled]="kshuffleEditmode">
                                    <option *ngFor="let status of statusDropDown" [value]="status"
                                     [selected]="status ==order_statusVal">{{status}}</option>
                                </select>
                            </div>
                        </div>
                        <!-- Drop Down -->
                        <div class="col-md-8 TrackingUrl">
                            <div class="formgroup">
                                <label>Tracking Url</label>
                                <input type="text" class="formcontrol" formControlName="tracking_url" [readonly]="kshuffleEditmode" [disabled]="kshuffleEditmode" />
                            </div>
                            <div class="error" *ngIf="tracking_url_invalid">
                                This field is required
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="formgroup">
                                <label>Tracking Number</label>
                                <input type="text" class="formcontrol" formControlName="tracking_number" [readonly]="kshuffleEditmode" [disabled]="kshuffleEditmode" required />
                            </div>
                            <div class="error" *ngIf="tracking_number_invalid">
                                This field is required
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="formgroup">
                                <label>Shipping Method</label>
                                <select class="user-input-select" name="delivery_method" formControlName="delivery_method" [disabled]="kshuffleEditmode">
                                <option *ngFor="let shipMtd of dropDownDataList.datalist.delivery_method" [value]="shipMtd" [selected]="shipMtd ==_delivery_method">{{shipMtd}}</option>
                            </select>
                            </div>
                            <div class="error" *ngIf="delivery_method_invalid">
                                This field is required
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="formgroup">
                                <label>Shipped Quantity</label>
                                <input type="text" class="formcontrol" formControlName="shippedquantity" [readonly]="!Isnotshippined" [disabled]="!Isnotshippined" required />
                            </div>
                            <div class="error" *ngIf="shippedquantity_invalid">
                                This field is required
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="formgroup">
                                <label>Line Item Quantity</label>
                                <input type="text" class="formcontrol" formControlName="lineitemquantity" [readonly]="kshuffleEditmode" [disabled]="kshuffleEditmode" />
                            </div>
                            <div class="error" *ngIf="lineitemquantity_invalid">
                                This field is required
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="formgroup">
                                <label>Synch</label>
                                <select class="user-input-select" name="order_synch" formControlName="order_synch" [disabled]="kshuffleEditmode">                          
                                <option *ngFor="let syn of synch" [value]="syn" 
                                 [selected]="syn==synchselect">{{syn}}</option>                            
                            </select>
                            </div>
                        </div>
                            <div class="col-md-4 datepickerDiv">
                                    <div class="formgroup">
                                        <label class="datepicker-label">Shipped Date</label>
                                        <!-- <owl-date-time [autoClose]="true" [dateFormat]="'MM/DD/YYYY HH:mm'" [placeHolder]="'MM/DD/YYYY HH:mm'" formControlName="shippeddate" name="shippeddate" [disabled]="kshuffleEditmode">
                                      </owl-date-time> -->
                                      <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeHolder="'MM/DD/YYYY HH:mm'"
                                    formControlName="shippeddate" name="shippeddate" [disabled]="kshuffleEditmode">
                                <owl-date-time #dt1></owl-date-time>
                                    </div>
                                    <div class="error" *ngIf="shippeddate_invalid">
                                        This field is required
                                    </div>
                                </div>
								<div class="col-md-4">
                            <div class="formgroup">
                                <label>Shipping Type</label>
                                <select class="user-input-select" name="shipped_service_type" formControlName="shipped_service_type" [attr.disabled]="kshuffleEditmode? '' : null">                          
                                <option *ngFor="let service_type of shipped_service_type" [value]="service_type" 
                                 [selected]="service_type==service_type_selected">{{service_type}}</option>                            
                            </select>
                            </div>
                            <div class="error" *ngIf="shipped_service_type_invalid">
                                This field is required
                            </div>
                        </div>
                            
                            <div class="col-md-6">
                                    <div class="formgroup checkbox">
                                        <div class="checkboxcontainer">
                                            <label for="shipmentgroupidgroup">Update Shippment Group</label>
                                            <input type="checkbox" class="formcontrol" [checked]="multiorderchecked" (change)="multiorders($event,StatusUpdateForm,'frmorderflow')" formControlName="shipmentgroupid" [disabled]="kshuffleEditmode" name="shipmentgroupidgroup" id="shipmentgroupidgroup"
                                            />
                                        </div>
                                    </div>
                                </div>
                        
                    </fieldset>
                    <div class="clearfix"></div>
                    <div class="col-md-12 btnwrapper text-right">
                        <button (click)="kshuffleditmodeIstrue(StatusUpdateForm)" class="btn btn-default">Edit</button>
                        <input *ngIf="!kshuffleEditmode" type="submit" value="Update" class="btn btn-primary" (click)="updatekshuffleStatus(StatusUpdateForm.value,StatusUpdateForm)" />
                    </div>
                </div>
                <div class="tabsContent" [class.active]="!showtabactive">
                    <fieldset>
                        <legend> Shipping </legend>
                        <input type="hidden" formControlName="OrderId" />
                        <div class="col-md-4">
                            <div class="formgroup">
                                <label>FirstName</label>
                                <input type="text" class="formcontrol" formControlName="firstName" [readonly]="editmode" required />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="formgroup">
                                <label>LastName</label>
                                <input type="text" class="formcontrol" formControlName="lastName" [readonly]="editmode" required />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="formgroup">
                                <label>Address line_1</label>
                                <input type="text" class="formcontrol" formControlName="address_line_1" [readonly]="editmode" required />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="formgroup">
                                <label>Address line_2</label>
                                <input type="text" class="formcontrol" formControlName="address_line_2" [readonly]="editmode" required />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="formgroup">
                                <label>Address line_3</label>
                                <input type="text" class="formcontrol" formControlName="address_line_3" [readonly]="editmode" required />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="formgroup">
                                <label>Company</label>
                                <input type="text" class="formcontrol" formControlName="company" [readonly]="editmode" required />
                            </div>
                        </div>
                        <!-- Drop Down -->
                        <div class="col-md-4">
                            <div class="formgroup">
                                <label>County</label>
                                <input type="text" class="formcontrol" formControlName="county" [readonly]="editmode" required />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="formgroup">
                                <label>State</label>
                                <input type="text" class="formcontrol" formControlName="state" [readonly]="editmode" required />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="formgroup">
                                <label>Country</label>
                                <select class="user-input-select" name="country" formControlName="country" [disabled]="editmode">
                                <option [selected]="countryid==''" value="">--select--</option>
                                <option *ngFor="let countryname of dropDownDataList.datalist.new_country" [value]="countryname.id" [selected]="countryname.id ==countryid">{{countryname.name}}</option>
                        </select>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="col-md-4">
                            <div class="formgroup">
                                <label>Town</label>
                                <input type="text" class="formcontrol" formControlName="town" [readonly]="editmode" [disabled]="editmode" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="formgroup">
                                <label>Postcode</label>
                                <input type="text" class="formcontrol" formControlName="postcode" [readonly]="editmode" [disabled]="editmode" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="formgroup checkbox">
                                <div class="checkboxcontainer">
                                    <label for="shippingshipmentgroupidgroup">Update Shippment Group</label>
                                    <input type="checkbox" class="formcontrol" [checked]="multiorderchecked" (change)="multiorders($event,StatusUpdateForm,'frmshipping')" formControlName="shippingshipmentgroupid" id="shippingshipmentgroupidgroup" [disabled]="editmode">
                                </div>
                            </div>
                        </div>

                    </fieldset>
                    <!-- NewImage URLS Added -->
                    <fieldset class="statusImages">
                        <legend> Images </legend>
                        <div class="col-md-10">
                            <div class="formgroup">
                                <label>Preview Image</label>
                                <input type="text" class="formcontrol" formControlName="preview_img" [readonly]="editmode" [disabled]="editmode" />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="hoverImg">
                                <img [src]="Src_preview_img" class="preview">
                                <div class="hoverpreview-arrow">
                                    <img [src]="Src_preview_img" class="hoverpreview">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="formgroup">
                                <label>Label Image</label>
                                <input type="text" class="formcontrol" formControlName="label_img" [readonly]="editmode" [disabled]="editmode" />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="hoverImg">
                                <img [src]="Src_label_img" class="preview">
                                <div class="hoverpreview-arrow">
                                    <img [src]="Src_label_img" class="hoverpreview">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="formgroup">
                                <label>Printed Image</label>
                                <input type="text" class="formcontrol" formControlName="print_img" [readonly]="editmode" [disabled]="editmode" />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="hoverImg">
                                <img [src]="Src_print_img" class="preview">
                                <div class="hoverpreview-arrow">
                                    <img [src]="Src_print_img" class="hoverpreview">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="formgroup">
                                <label>Barcode Image</label>
                                <input type="text" class="formcontrol" formControlName="barcode_image" [readonly]="editmode" [disabled]="editmode" />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="hoverImg">
                                <img [src]="Src_barcode_image" class="preview">
                                <div class="hoverpreview-arrow">
                                    <img [src]="Src_barcode_image" class="hoverpreview">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="formgroup">
                                <label>Print Ready Image</label>
                                <input type="text" class="formcontrol" formControlName="print_ready_image" [readonly]="editmode" [disabled]="editmode" />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="hoverImg">
                                <img [src]="Src_print_ready_image" class="preview">
                                <div class="hoverpreview-arrow">
                                    <img [src]="Src_print_ready_image" class="hoverpreview">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="formgroup">
                                <label>Thumbnail Image</label>
                                <input type="text" class="formcontrol" formControlName="thumbnail_img" [readonly]="editmode" [disabled]="editmode" />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="hoverImg">
                                <img [src]="Src_thumbnail_img" class="preview">
                                <div class="hoverpreview-arrow">
                                    <img [src]="Src_thumbnail_img" class="hoverpreview">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <div class="clearfix"></div>
                    <div class="col-md-12 btnwrapper text-right">
                        <button (click)="editmodeIstrue(StatusUpdateForm)" class="btn btn-default">Edit</button>
                        <input *ngIf="!editmode" type="submit" value="Update" class="btn btn-primary" (click)="updateStatus(StatusUpdateForm.value,StatusUpdateForm)" />
                    </div>
                </div>
                <!-- Updates end here -->
            </div>
        </form>
    </div>
</div>
