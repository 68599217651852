import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-ordermanagement',
    templateUrl: './ordermanagement.component.html',
    styleUrls: ['./ordermanagement.component.css']
})
export class OrdermanagementComponent implements OnInit {
    public Mydata = [
        { data: [8, 29, 4, 39, 46, 17, 10] }
    ]


    constructor() { }

    ngOnInit() {
    }



}
