import { Injectable } from '@angular/core';
// import { Http, Response, Headers, RequestOptions} from '@angular/http';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
// import 'rxjs/add/operator/do';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/observable/throw';

@Injectable()
export class TagService {

  constructor(private http: HttpClient) { }
  getTag() {
    const headers = new Headers ({ 'Content-Type': 'application/json'});
    // const options = new RequestOptions({headers: headers});
    const options = {
      headers: new HttpHeaders().append('key', 'value')
    }

    return this.http.get('/api/versiontag', options).pipe(
      map((response: HttpResponse<any>) => response),
      catchError(this.handleError));
  }

  private handleError(error: HttpResponse<any>) {
    console.error(error);
    return throwError(error["error"] || 'Server error');
  }


}

