import { Component, ViewChild, Inject, OnInit, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

import { QaDetails } from '../../shared/model/qaDetails.model';
import { QADetailViewComponent } from '../../shared/components/qa-detail-view/qa-detail-view.component';
import { UserInfoModalService } from '../../shared/util/modal/user-info.modal';
import { SharedApplicationService } from '../../shared/service/shared.service';
import { PackagingSlipPrintViewComponent } from '../../shared/components/print-view/packaging-slip-print-view.component';

@Component({
    selector: 'quality-assurance',
    templateUrl: './qa.component.html',
    styleUrls: ['./qa.component.css']
})
export class QualityAssuranceComponent {

    constructor(public dialog: MatDialog,
        private userModal: UserInfoModalService, private sharedAppService: SharedApplicationService) { }

    ngAfterViewInit() {
        this.orderIdEleRef.nativeElement.focus();
    }

    setFocusToOrderIdField() {
        this.orderIdEleRef.nativeElement.select();
    }

    @ViewChild('orderIdRef')
    private orderIdEleRef: ElementRef;

    @ViewChild(QADetailViewComponent)
    private qaDetailViewRef: QADetailViewComponent;

    selectedGridRow: any = [];
    detailViewData = new QaDetails();
    orderId;
    loading: any;
    isInvalidQty: boolean = false;
    isFullFilled: boolean = false;
    isQtyExceeds: boolean = false;
    isOrderInComplete: boolean = false;
    disableAllActionButtons: boolean = true;
    disableResetButtons: boolean = true;
    userEnteredQty: number;
    qaOrderErr: string = "";
    searchResults = [];
    orderstatuses = {
        'single-order': "Single Order",
        'multi-order-complete': "Multi Order (Complete)",
        'multi-order-wait': "Multi Order (Wait)",
        'cancelled': 'Cancelled / Rejected'
    }

    getOrderDetails() {
        if (!this.orderId) {
            return false;
        }
        this.isFullFilled = false;
        this.isQtyExceeds = false;
        this.isInvalidQty = false;
        this.isOrderInComplete = false;
        this.qaOrderErr = "";
        this.qaDetailViewRef.clearScannedSKU();
        if (this.searchResults.length && this.detailViewData &&
            this.detailViewData["order_id"] == this.orderId
        ) {
            this.userEnteredQty = 1;
        } else {
            this.detailViewData = new QaDetails();
            this.searchResults = [];
            this.sharedAppService.httpGetService(`/api/orders/${this.orderId}/qa`)
                .then(
                    (data: any) => {
                        if (data && data.length) {
                            this.searchResults = data;
                            this.userEnteredQty = 1;
                            this.selectedGridRow = this.searchResults.filter((obj) => {
                                return obj["order_status"] == "QA Verified";
                            });
                            this.detailViewData = this.searchResults.filter((obj, index) => {
                                if (this.orderId == obj["order_id"]) {
                                    this.searchResults[index].isCurrentScannedItem = true;
                                }
                                return this.orderId == obj["order_id"];
                            })[0];
                            if (this.detailViewData.product_form.indexOf('Votive') > -1) {
                                this.userEnteredQty = this.detailViewData.quantity;
                            }
                            this.detailViewData.order_date = moment(this.detailViewData.order_date.replace('Z', '-0600')).format("MM/DD/YYYY HH:mm");
                            if (this.detailViewData.order_status == "Cancelled"
                                || this.detailViewData.order_status == "Rejected"
                                || this.detailViewData.order_status == "QA Verified") {
                                this.disableAllActionButtons = true;
                                this.disableResetButtons = (this.detailViewData.order_status != "QA Verified");
                                this.qaOrderErr = this.detailViewData.order_status == "QA Verified" ? "This order has been already QA Verified" : "";
                            } else if (this.detailViewData.order_status != "Printed" && this.detailViewData.order_status != "QA Verified" && this.detailViewData.order_status != "QA Review") {
                                this.qaOrderErr = `${this.orderId} is in status - ${this.detailViewData.order_status}`;
                                this.clearQAScreendata();
                            } else {
                                this.disableResetButtons = (this.detailViewData.order_status != "QA Verified");
                                this.qaOrderErr = this.detailViewData.order_status == "QA Review" ? `Previously in QA Review with rejected reason : ${this.detailViewData.qa_failed_reason}` : '';
                                this.disableAllActionButtons = false;
                                this.qaDetailViewRef.setFocusProductScanSKU();
                            }
                        } else {
                            this.detailViewData['order_id'] = this.orderId;
                            this.qaOrderErr = `No data available for the entered order.`;
                            this.disableAllActionButtons = true;
                            this.disableResetButtons = true;
                        }
                    },
                    err => console.log(err)
                );
        }
    }

    getRowClass(row) {
        return {
            'current-order': row.isCurrentScannedItem
        }
    }

    getTotalConfirmed() {
        if (this.searchResults.length) {
            let otherItemsVerifiedCancelReject = (this.searchResults.filter((obj) => {
                return (
                    obj["order_id"] != this.detailViewData["order_id"] &&
                    (obj["order_status"] == "QA Verified" || obj["order_status"] == "Cancelled" || obj["order_status"] == "Rejected"));
            })).length;
            let currentItem = this.searchResults.filter((obj) => {
                return obj["order_id"] == this.detailViewData["order_id"]
            })[0];
            let currentConfirmed: number = 0;
            if (currentItem["order_status"] == "QA Verified") {
                currentConfirmed = 1;
            } else {
                currentConfirmed = currentItem["quantity"] == Number(currentItem["quantity_confirmed"]) + Number(currentItem["quantity_rejected"]) ? 1 : 0;
            }
            return otherItemsVerifiedCancelReject + currentConfirmed;
        } else {
            return null;
        }
    }

    skuScanComplete(e) {
        this.userEnteredQty = 1;
        this.confirmQty()
    }

    confirmQty() {
        this.updateQuantity("quantity_confirmed");
    }

    rejectQty() {
        //this.updateQuantity("quantity_rejected");
        this.openFailedModal();
    }

    updateQuantity(field) {
        this.isFullFilled = false;
        this.isQtyExceeds = false;
        this.isInvalidQty = false;
        if (this.userEnteredQty && !isNaN(this.userEnteredQty) && (this.userEnteredQty > 0) && parseFloat(this.userEnteredQty.toString()) == parseInt(this.userEnteredQty.toString())) {
            //checking if already fullfilled
            if (this.detailViewData["quantity"] == (Number(this.detailViewData["quantity_confirmed"]) + Number(this.detailViewData["quantity_rejected"]))) {
                this.isFullFilled = true;
                return false;
            }
            //checking if qty exceeds
            if (this.userEnteredQty > this.detailViewData["quantity"] - (Number(this.detailViewData["quantity_confirmed"]) + Number(this.detailViewData["quantity_rejected"]))) {
                this.isQtyExceeds = true;
                return false;
            }
            this.searchResults = this.searchResults.map((obj) => {
                if (obj["order_id"] == this.detailViewData["order_id"]) {
                    obj[field] = Number(obj[field]) + Number(this.userEnteredQty);
                }
                return obj;
            });
            if (this.detailViewData["quantity"] == Number(this.detailViewData["quantity_confirmed"])) {
                if(this.detailViewData["gift_box_sku_id"] && this.detailViewData["gift_box_sku_id"] != ""){
                    window.confirm("This order contains Gift box. Click OK to Confirm.")? this.verifyOrder()  : ""
                }
                else{
                    this.verifyOrder();
                }
            }
        } else {
            this.isInvalidQty = true;
        }
    }

    getOrderstatusLegend() {
        if (this.detailViewData.order_status == "Cancelled" || this.detailViewData.order_status == "Rejected") {
            return 'cancelled';
        }
        let confirmCount = this.getTotalConfirmed();
        if (confirmCount) {
            if (this.searchResults.length == 1) {
                return 'single-order';
            } else if (confirmCount == this.searchResults.length) {
                return 'multi-order-complete';
            } else {
                return 'multi-order-wait'
            }
        }
        return '';
    }

    clearQAScreendata() {
        this.qaDetailViewRef.clearScannedSKU();
        this.isFullFilled = false;
        this.isQtyExceeds = false;
        this.isInvalidQty = false;
        this.isOrderInComplete = false;
        this.disableAllActionButtons = true;
        this.disableResetButtons = true;
        this.detailViewData = new QaDetails();
        this.searchResults = [];
        this.orderId = "";
        this.userEnteredQty = null;
    }

    printPackagingSlip() {
        this.sharedAppService.httpGetService(`/api/orders/${this.detailViewData["order_id"]}/shipping`)
            .then(
                (data) => {
                    if (data && data["order_line_items"].length) {
                        this.sharedAppService.sendPrint(PackagingSlipPrintViewComponent, { order_details: data["order_details"], order_line_items: data['order_line_items'] });
                    }
                },
                err => console.log(err)
            );
    }

    openFailedModal() {
        let dialogRef = this.dialog.open(QAFailedMessageModal,
            { width: '800px', data: this.detailViewData["order_id"] });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.clearQAScreendata();
                this.qaOrderErr = "";

            }
        });
    }

    verifyOrder() {
        let currentItem = this.searchResults.filter((obj) => {
            return obj["order_id"] == this.detailViewData["order_id"]
        })[0];
        if (currentItem.quantity != Number(currentItem.quantity_confirmed) + Number(currentItem.quantity_rejected)) {
            this.isOrderInComplete = true;
            return false;
        }
        let params = {
            "quantityConfirmed": currentItem["quantity_confirmed"],
            "quantityRejected": currentItem["quantity_rejected"]
        }
        this.sharedAppService.httpPostService(`/api/orderstatus/${this.detailViewData["order_id"]}/QA Verified`, params)
            .then(
                data => {
                    let modalref = this.userModal.openModal({ status: 'success', title: 'Order Verified', message: 'Order successfully verified.' });
                    modalref.afterClosed().subscribe(
                        () => { this.orderIdEleRef.nativeElement.focus() }
                    )
                    this.qaOrderErr = "";
                    this.clearQAScreendata();
                },
                err => {
                    console.log(err);
                    this.userModal.openModal({ status: 'error', title: 'Action Failed', message: `Order verfication failed. ${err.message}` });
                }
            )
    }
}

@Component({
    selector: 'qa-failed-message-modal',
    template: `
    <div>
        <h3 class="text-center">Order Failed</h3>
        <div class="reason-container">
            <label class="d-block">Reason:</label>
            <textarea class="modal-textarea" [(ngModel)]="failMessage"></textarea>
            <button type="button" class="approve-button pull-right" (click)="submit()">Submit</button>
            <p *ngIf="isFailMessageError" class="red pull-right fail-error-message">Reason for failure is required</p>
        </div>
    </div>
    `,
    styles: [`
    .modal-textarea{width: 100%;resize: none;height: 125px;}
    .reason-container{ padding: 0 125px;}
    .fail-error-message{ margin-top: 8px; margin-right: 8px;}
    `]
})
export class QAFailedMessageModal {
    constructor(
        public dialogRef: MatDialogRef<QAFailedMessageModal>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private sharedAppService: SharedApplicationService) { }

    failMessage: string;
    isFailMessageError: boolean = false;

    submit() {
        if (!this.failMessage) {
            this.isFailMessageError = true;
            return false;
        }
        let params = { failedReason: this.failMessage }
        this.sharedAppService.httpPostService(`/api/orderstatus/${this.data}/QA Review`, params)
            .then(
                data => this.dialogRef.close(true),
                err => this.dialogRef.close(true)
            )
    }
}