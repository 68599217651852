<div md-dialog-content>
    <div popup-content>
        <div class="panel panel-default">
            <div class="panel-heading">
                <h2>Bulk Reject Orders</h2>
                <button class="pull-right back-to-page-button" (click)="closePopup()"><img
                        src="assets/images/close-button.png" alt="close button"></button>
            </div>
            <div class="panel-body">
                <div *ngIf="errorMsg" class="error-reject-code">{{errorMsg}}</div>
                <div class="reject-reason-container">
                    <h2>Rejection Reason</h2>
                    <div class="row">
                        <div class="reject-code-container">
                            <div class="reason-code-container">
                                <label for="rejectCode">Reason Code:</label>
                                <div *ngIf="shipToCountry !== 'FR' && shipToCountry !== 'IT' && shipToCountry !== 'DE'">
                                    <select name="rejectCode" id="rejectCode" [(ngModel)]="reasonCode"
                                        (change)="rejectCodeChange(reasonCode)">
                                        <option value="null" disabled selected>Reason Code</option>
                                        <option value="null"></option>
                                        <option *ngFor="let rejectCodetmpl of rejectCodes" [value]="rejectCodetmpl">
                                            {{rejectCodetmpl}}</option>
                                    </select>
                                </div>
                                <div *ngIf="shipToCountry == 'FR'">
                                    <select name="rejectCode" id="rejectCode" [(ngModel)]="reasonCode"
                                        (change)="rejectCodeChange(reasonCode)">
                                        <option value="null" disabled selected>Reason Code</option>
                                        <option value="null"></option>
                                        <option *ngFor="let rejectCodetmpl of rejectCodesFR" [value]="rejectCodetmpl">
                                            {{rejectCodetmpl}}</option>
                                    </select>
                                </div>
                                <div *ngIf="shipToCountry == 'DE'">
                                    <select name="rejectCode" id="rejectCode" [(ngModel)]="reasonCode"
                                        (change)="rejectCodeChange(reasonCode)">
                                        <option value="null" disabled selected>Reason Code</option>
                                        <option value="null"></option>
                                        <option *ngFor="let rejectCodetmpl of rejectCodesDE" [value]="rejectCodetmpl">
                                            {{rejectCodetmpl}}</option>
                                    </select>
                                </div>
                                <div *ngIf="shipToCountry == 'IT'">
                                    <select name="rejectCode" id="rejectCode" [(ngModel)]="reasonCode"
                                        (change)="rejectCodeChange(reasonCode)">
                                        <option value="null" disabled selected>Reason Code</option>
                                        <option value="null"></option>
                                        <option *ngFor="let rejectCodetmpl of rejectCodesIT" [value]="rejectCodetmpl">
                                            {{rejectCodetmpl}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="reason-desc-container">
                                <div class="reject-reason-container">
                                    <label for="rejectReason">Reason Description</label>
                                    <textarea name="rejectReason" id="rejectReason" class="reject-reason"
                                        [(ngModel)]="desc"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-offset-5 text-center action-buttons-container">
            <button [disabled]="!rejectCodes || reasonCode === 'Waiting On Consumer'" (click)="rejectOrder();">Reject</button>
        </div>
    </div>
</div>