<div class="user-search-container container">
  <div class="row">
    <h2 class="title">
          Add User
          <button class="pull-right search-view-toggle" [class.searchCollapsed]="!searchViewToggle" (click)="searchViewToggle = !searchViewToggle;"></button>
        </h2>
  </div>

  <div class="row search-fields-container" *ngIf="searchViewToggle">
    <form [formGroup]="registerForm" (ngSubmit)="registerUser(registerForm.value)" novalidate autocomplete="off">
      <div class="sub-search-container row">
        <h3>User Details</h3>
        <div class="sub-search-wrapper">
          <div class="col-md-4" [ngClass]="{'has-error': registerForm.get('firstname').invalid && registerForm.get('firstname').dirty}">
            <label for="fname">
              <span>First Name*</span>
            </label>
            <input type="text" class="user-input-text" [formControl]="firstname" id="fname" placeholder="First Name">
            <div class="text-danger" *ngIf="registerForm.get('firstname').dirty && registerForm.get('firstname').errors">
              <span class="col-sm-10" style="padding:4px 0 0" *ngIf="registerForm.get('firstname').errors">
                    Please enter your first name
                </span>
            </div>
          </div>


          <div class="col-md-4" [ngClass]="{'has-error': registerForm.get('lastname').invalid && registerForm.get('lastname').dirty}">
            <label for="lname">Last Name*</label>
            <input type="text" class="user-input-text" [formControl]="lastname" id="lname" placeholder="Last Name">
            <div class="text-danger" *ngIf="registerForm.get('lastname').dirty && registerForm.get('lastname').errors">
              <span class="col-sm-10" style="padding:4px 0 0" *ngIf="registerForm.get('lastname').errors">
                    Please enter your last name
                </span>
            </div>
          </div>


          <div class="col-md-4" [ngClass]="{'has-error': registerForm.get('email').invalid && registerForm.get('email').dirty}">
            <label for="email">Email*</label>
            <input type="text" class="user-input-text" [formControl]="email" id="email" placeholder="Email">
            <div class="text-danger" *ngIf="registerForm.get('email').dirty && registerForm.get('email').errors">
              <span class="col-sm-10" style="padding:4px 0 0" *ngIf="registerForm.get('email').invalid">
                    Please enter a valid email
                </span>
            </div>
          </div>

          <div class="col-md-4" [ngClass]="{'has-error': registerForm.get('username').invalid && registerForm.get('username').dirty}">
            <label for="username">Username*</label>
            <input type="text" class="user-input-text" [formControl]="username" id="username" placeholder="Username">
            <div class="text-danger" *ngIf="registerForm.get('username').dirty && registerForm.get('username').errors">
              <span class="col-sm-10" style="padding:4px 0 0" *ngIf="registerForm.get('username').errors">
                    Please enter username
                </span>
              <span class="col-sm-10" style="padding:4px 0 0" *ngIf="registerForm.get('username').errors">
                    Username must be longer than 3 characters.
                </span>
              <span class="col-sm-10" style="padding:4px 0 0" *ngIf="registerForm.get('username').errors">
                    Username cannot be longer than 16 characters.
                </span>
            </div>
          </div>

          <div class="col-md-4">
            <label for="group">Group*</label>
            <select class="user-input-select"  name="group_name" [formControl]="group_name" [(ngModel)]="term5" id="group_name">
                  <option *ngFor="let group of groups" [value]="group.group_name">{{group.group_name}}</option>
              </select>
          </div>

          <div class="col-md-4">
            <label for="roles">Roles*</label>
            <select class="user-input-select"  name="roles" [formControl]="roles"  id="group_name" (onselect)="passsword($event, roles_list)" id="myOptions">
              <option value="" disabled>Select one--</option>
                  <option *ngFor="let roles_list of roles_lists; let i = index;" id="roles{{i}}" >{{roles_list}}</option>
              </select>
          </div>

          <div class="col-md-4" hidden>
            <label for="password_minimum_age">password_minimum_age</label>
            <select class="user-input-select"  name="password_minimum_age" [formControl]="password_minimum_age"  id="group_name" (onselect)="passsword($event, roles_list)" id="myOptions">
              <option value="1">1 Day</option>
              </select>
          </div>

          <div class="col-md-4" hidden>
            <label for="password_maximum_age">password_maximum_age</label>
            <select class="user-input-select"  name="password_maximum_age" [formControl]="password_maximum_age"  id="group_name" (onselect)="passsword($event, roles_list)" id="myOptions">
              <option value="30">30 Days</option>
              <option value="90">90 Days</option>
              <option value="365">365 Days</option>
              </select>
          </div>

          <div class="col-md-4" formGroupName="passwordGroup" [ngClass]="{'has-error': registerForm.get('passwordGroup').errors }">

            <div  [ngClass]="{'has-error': registerForm.get('passwordGroup.password').invalid && registerForm.get('passwordGroup.password').dirty}">
              <label for="password">Password* </label>
              <input type="password" class="user-input-text" [formControl]="password" id="password" placeholder="Password">
              <div class="text-danger" *ngIf="registerForm.get('passwordGroup.password').dirty && registerForm.get('passwordGroup.password').errors">

                    <span class="col-sm-10" style="padding:4px 0 0" *ngIf="registerForm.get('passwordGroup.password').errors && registerForm.get('roles').value == 'Administrator Accounts' ">
                            Password may not contain your name, be atleast 16~20 characters, with either uppercase, lowercase, number or special character
                        </span>
                    <span class="col-sm-10" style="padding:4px 0 0" *ngIf="registerForm.get('passwordGroup.password').errors && registerForm.get('roles').value == 'Privileged Accounts' ">
                            Password may not contain your name, be atleast 10~20 characters, with either uppercase, lowercase, number or special character
                        </span>
                    <span class="col-sm-10" style="padding:4px 0 0" *ngIf="registerForm.get('passwordGroup.password').errors && registerForm.get('roles').value == 'Non-Privileged Accounts' ">
                            Password may not contain your name, be atleast 8~20 characters, with either uppercase, lowercase, number or special character
                        </span>
                    <span class="col-sm-10" style="padding:4px 0 0" *ngIf="registerForm.get('passwordGroup.password').errors && registerForm.get('roles').value == 'Service Accounts' ">
                          Password may not contain your name, be atleast 18~20 characters, with either uppercase, lowercase, number or special character
                      </span>
              </div>
            </div>

              <br>

            <div [ngClass]="{'has-error': registerForm.get('passwordGroup.retypepass').invalid && registerForm.get('passwordGroup.retypepass').dirty}">
              <label for="retypepass">Retype*</label>
              <input type="password" class="user-input-text" [formControl]="retypepass" id="retypepass" placeholder="Retype Password">
              <div class="text-danger" *ngIf="(registerForm.get('passwordGroup.retypepass').touched || registerForm.get('passwordGroup.retypepass').dirty) && (registerForm.get('passwordGroup.retypepass').errors || registerForm.get('passwordGroup').errors)">
                <span class="col-sm-10" style="padding:4px 0 0" *ngIf='registerForm.get("passwordGroup.retypepass").errors'>
                        Please confirm your password
                    </span>
                <span class="col-sm-10" style="padding:4px 0 0" *ngIf="registerForm.get('passwordGroup').errors">
                        Password do not match
                    </span>
              </div>
            </div>

          </div>

<br><br><br><br><br><br>


  <div class="text-center">
    <button type="submit" [disabled]="(registerForm.invalid || SendUserButton === true)" class="ycc-button button-search">Submit</button>
            <button type="reset" class="ycc-button button-clear">Reset</button>
            <form [formGroup]="registerForm" class="text-right" (ngSubmit)="sendUser(registerForm.value)">
              <button [disabled]="(SendUserButton === false)" type="submit" class="ycc-button button-search">Send To User</button>
            </form>
  </div>

        </div>
      </div>
    </form>
  </div>
  <div class="required container">
    * Required
  </div>
</div>
