import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from '../../common/toastr.service';
import { UserService } from '../../user/user.service';
import { AuthService } from '../../user/auth.service';
import { IUser } from '../user';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  profileForm: FormGroup
  userObj: any;
  user: IUser;

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private userService: UserService,
              private router: Router,
              private toastr: ToastrService) {
  }

  firstname = new FormControl('', [Validators.required]);
  lastname = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.email]);
  group_name = new FormControl('', [Validators.required]);

  ngOnInit() {
    this.userObj =  this.authService.currentUser;
    console.log(this.userObj)
    this.profileForm = this.fb.group({
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email,
      group_name: this.group_name
    });

    this.userService.getUser(this.userObj.userid).subscribe(data => {
      if (data["success"] === false) {
        if (data["errcode"]) {
          this.authService.logout();
          this.router.navigate(['login']);
        }
        this.toastr.error(data["message"]);
      } else {
        let newData = data["data"]
        this.user = newData[0];
        console.log(this.user)
        this.populateForm(this.user);
      }
    });
  }

  populateForm(data): void {
    this.profileForm.patchValue({
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      group_name: data.group_name
    });
  }

  updateUser(formdata: any): void {
    if (this.profileForm.dirty && this.profileForm.valid) {
      this.userService.updateUser(this.userObj.userid, this.profileForm.value)
        .subscribe(data => {
          if (data["success"] === false) {
            if (data["errcode"]){
              this.authService.logout();
              this.router.navigate(['login']);
            }
            alert(data["message"])
            //this.toastr.error(data.message);
          } else {
            alert(data["message"])
            location.reload();
           // this.toastr.success(data.message);
            const theUser: any = JSON.parse(localStorage.getItem('currentUser'));
            theUser.user.firstname = this.profileForm.value.firstname;
            localStorage.setItem('currentUser', JSON.stringify(theUser));
          }
        });
    }
  }

}

