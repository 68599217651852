import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-groupmanagement',
  templateUrl: './groupmanagement.component.html',
  styleUrls: ['./groupmanagement.component.css']
})
export class GroupmanagementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
