import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchSharedModule } from '../../shared/module/searchshared.module';
import { CSCLabelManagerPageComponent } from './cscLabelManager.component';
import { BulkRejectComponent } from './bulk-reject/bulk-reject.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
    imports: [
        CommonModule,
        SearchSharedModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule
    ],
    declarations: [
        CSCLabelManagerPageComponent,
        BulkRejectComponent
    ],
    exports: [CSCLabelManagerPageComponent]
})

export class CSCLabelManagerModule{}