<div class="row">
  <div class="col-md-11">
    <br>
    <form [formGroup]="myForm" (ngSubmit)="Getdetails(myForm)">
      <div class="col-md-3 formgroup datewrapper">
        <label class="pull-left">From Date:</label>
        <!-- <owl-date-time [autoClose]="true"
         [dateFormat]="'DD/MM/YYYY HH:mm'"
          [placeHolder]="'DD/MM/YYYY HH:mm'"
           formControlName="FromDate"
            [ngModel]="fromDate"
          [inputId]="'FromDate'"
          (ngModelChange)="dateSelecteduserproduction($event, 'from')"
          >
        </owl-date-time> -->
        <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeHolder="'MM/DD/YYYY HH:mm'"
          formControlName="FromDate" [ngModel]="fromDate" name="FromDate" (ngModelChange)="dateSelecteduserproduction($event, 'from')">
        <owl-date-time #dt1></owl-date-time>
      </div>
      <div class="col-md-3 formgroup datewrapper">
        <label class="pull-left">To Date</label>
        <!-- <owl-date-time  [max]="maxDate"
         [placeHolder]="'DD/MM/YYYY HH:mm'" 
         [autoClose]="true" [dateFormat]="'DD/MM/YYYY HH:mm'" 
         formControlName="ToDate"
          [ngModel]="toDate"
          name="ToDate"
          (ngModelChange)="dateSelecteduserproduction($event, 'to')"
          >
        </owl-date-time> -->
        <input [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" placeHolder="'MM/DD/YYYY HH:mm'" formControlName="ToDate"
          [ngModel]="toDate" name="ToDate" (ngModelChange)="dateSelecteduserproduction($event, 'to')">
        <owl-date-time #dt2></owl-date-time>
        <span class="date-error" *ngIf="fromToDateMismatch">'From Date' should be earlier than 'To date'</span>
      </div>     
       <div class="col-md-3">
            <div class="formgroup">
                <label>Order Status</label>                
                <select class="user-input-select"                             
                  name="order_status" 
                  formControlName="order_status">
                    <option *ngFor="let status of OrderstatusGroup" [value]="status"
                      [selected]="status ==order_status">{{status}}</option>
                </select>
            </div>
        </div>
      <div class="col-md-2 formgroup">      
        <button type="submit">Go</button> &nbsp;
        <button type="reset" (click)="resetform()">Clear</button>
      </div>
    </form>
  </div>
  <div class="clearfix"></div>
  <div class="col-md-10">
    <div style="padding-top: 45px;">
      <div *ngIf="showtable">
        <button type="button" class="pull-right exportcsv" (click)="exportcsv(OrdersArray)">Export Excel</button>
        <div>
          <ngx-datatable
           class="material" [columnMode]="'flex'" 
           [columns]="columns" [headerHeight]="50" [selectionType]="'button'"
            [loadingIndicator]="loading" [rowHeight]="50" 
            [scrollbarV]="true" [rows]="rows" 
            [columns]="columns">
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
