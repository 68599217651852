import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from '../../../common/toastr.service';
import { UserService } from '../../../user/user.service';
import { AuthService } from '../../../user/auth.service';
import { IUser } from '../../../user/user';
import { FindUserComponent } from '../find-user/find-user.component';
import { Observable } from 'rxjs/';
// import 'rxjs/add/operator/do';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/observable/throw';


@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {
  @Output() cancelClicked = new EventEmitter();
  @Output() updateUsersubmit = new EventEmitter();

  @Input() userid: string;

  ngOnChanges(change) {
    this.getUserEdit();
  }

  profileForm: FormGroup
  userObj: any;
  user: IUser;
  data: any;
  groups: any;
  // fireEditEvent(e, i: any){
  //   console.log(e.username);
  //   console.log(e._id);
  //   // this.populateForm(e)
  //   // console.log(i);
  // }

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private userService: UserService,
              // private FindUserComponent: FindUserComponent,
              private router: Router,
              private toastr: ToastrService) {
  }

  roles_lists = [ "Administrator Accounts", "Privileged Accounts", "Non-Privileged Accounts", "Service Accounts"]
  firstname = new FormControl('', [Validators.required]);
  lastname = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.email]);
  username = new FormControl('', [Validators.required]);
  group_name = new FormControl('', [Validators.required]);
  roles = new FormControl('', [Validators.required]);
  password_maximum_age = new FormControl('', [Validators.required]);
  password_minimum_age = new FormControl('', [Validators.required]);

  searchViewToggle: boolean = true;

  ngOnInit() {
    window.scrollTo(0, 0);

    this.userObj =  this.authService.currentUser;
    this.profileForm = this.fb.group({
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email,
      username: this.username,
      group_name: this.group_name,
      roles: this.roles,
      password_minimum_age: this.password_minimum_age,
      password_maximum_age: this.password_maximum_age

    });

    this.userService.getGroups().subscribe(data => {
      if (data["success"] === false) {
        if (data["errcode"]) {
          //this.authService.logout();
          this.router.navigate(['/usermanagement']);
        }
       // this.toastr.error(data.message);
      } else {
        this.groups = data["data"];
        //this.populateForm(this.user);
        // console.log(data);
      }
    });
  }

  ngAfterViewChecked() {

this.profileForm.get('roles').valueChanges.subscribe(

    (roles: string) => {
      // debugger

        if (roles === 'Administrator Accounts') {

            this.profileForm.get('password_minimum_age').setValue(1);

            this.profileForm.get('password_maximum_age').setValue(30);

        } else if (roles === 'Privileged Accounts') {

            this.profileForm.get('password_minimum_age').setValue(1);

            this.profileForm.get('password_maximum_age').setValue(90);

        } else if (roles === 'Non-Privileged Accounts') {

            this.profileForm.get('password_minimum_age').setValue(1);

            this.profileForm.get('password_maximum_age').setValue(90);

        } else if (roles === 'Service Accounts') {

            this.profileForm.get('password_minimum_age').setValue(1);

            this.profileForm.get('password_maximum_age').setValue(365);
        }

    }

)
  }


  populateUser(): void{
    this.user = this.userService.transmitData(this.data);
    // this.userService.transmitData(user).then(user => this.user = user)

    console.log(this.user)
  }

populateForm(data): void {
    this.profileForm.patchValue({
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      username: data.username,
      group_name: data.group_name,
      roles: data.roles,
    });
    console.log(data.group_name)
  }

  updateUser(userid): void {
    // console.log(formdata)
    if (this.profileForm.dirty && this.profileForm.valid) {
      this.userService.updateUser(this.userid, this.profileForm.value) //this.userid
        .subscribe(data => {
          if (data["success"] === false) {
            if (data["errcode"]){
              console.log(data["errcode"])
             // this.authService.logout();
              // this.router.navigate(['/usermanagement']);
            }
            // this.toastr.error(data.message);
          } else {
              this.updateUsersubmit.emit();
              alert("User has been Updated")
          }
        });
    }
  }

  getUserEdit(): void {
    this.userService.getUser(this.userid).subscribe(data => {

      if (data["success"] === false) {
        if (data["errcode"]) {
          //this.authService.logout();
          this.router.navigate(['/usermanagement']);
        }
        // this.toastr.error(data.message);
      } else {
        this.user = data["data"];
        this.populateForm(this.user[0]);

      }

    });
    console.log(this.username);

  }

  cancelWasClicked(){
    this.cancelClicked.emit();
  }

}
