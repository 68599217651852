import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormControl, FormArray } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as moment from 'moment';
import { DataService } from "../../shared/service/data.service"
@Component({
    selector: 'app-snap-shot',
    templateUrl: './snap-shot.component.html',
    styleUrls: ['./snap-shot.component.css'],
    animations: [
        trigger('shrinkInOut', [
            transition(':leave', [
                style({ height: "*" }),
                animate(350, style({ height: 0 }))
            ]),
            transition(':enter', [
                style({ height: 0 }),
                animate(350, style({ height: "*" }))
            ])
        ])
    ]
})
export class SnapShotComponent implements OnInit {
    constructor(private dataservice: DataService) { }
    public myForm: FormGroup;
    ngOnInit() {
        this.myForm = new FormGroup({
            'FromDate': new FormControl(),
            'ToDate': new FormControl()
        });
        this._onload();
    }
    public fromDate: any = new Date();
    public toDate: any = new Date();

    _onload() {
        var fromdatenow = new Date()
        fromdatenow.setHours(0)
        fromdatenow.setMinutes(0)
        fromdatenow.setSeconds(0)
        fromdatenow.setMilliseconds(0)

        var todatenow = new Date()
        todatenow.setHours(23)
        todatenow.setMinutes(59)
        todatenow.setSeconds(0)
        todatenow.setMilliseconds(0)

        this.ToDate = todatenow.toString()
        this.FromDate = fromdatenow.toString()

        // this.ToDate=new Date().toString()
        // this.FromDate=new Date().toString()
        this.fromDate = moment.parseZone(this.FromDate).format("YYYY-MM-DDTHH:mm:ss")
        this.toDate = moment.parseZone(this.ToDate).format("YYYY-MM-DDTHH:mm:ss")
        this.dataservice.GetPostMethod("orders/reportdates?fromdate=" +
            this.fromDate + "&todate=" + this.toDate, "&user=" + localStorage.getItem('Username') + "")
            .subscribe(Response => {
                this.snapshotLoadGraph(Response)
            })
    }
    fromToDateMismatch: boolean = false
    checkDateValidity(from, to): boolean {

        if (from && to && from > to) {
            return false;
        }
        //returning true by default
        return true;
    }

    dateSelectedsnapshot(e, field) {
        if (field === "from") {
            this.fromDate = e;
        } else {
            this.toDate = e;
        }
        if (typeof (this.fromDate) == "string" && this.fromDate != "")
            this.fromDate = new Date(this.fromDate)
        this.fromToDateMismatch = !this.checkDateValidity(this.fromDate, this.toDate);
    }
    Getdetails(myForm: any) {
        //console.log(myForm);
        this.fromDate = moment.parseZone(myForm.controls.FromDate.value).format("YYYY-MM-DDTHH:mm:ss")
        this.toDate = moment.parseZone(myForm.controls.ToDate.value).format("YYYY-MM-DDTHH:mm:ss")
        if (this.fromDate == "Invalid date" || this.toDate == "Invalid date") {
            alert("Please fill")
            return false
        }

        this.dataservice.GetPostMethod("orders/reportdates?fromdate=" +
            this.fromDate + "&todate=" + this.toDate, "&user=" + localStorage.getItem('Username') + "")
            .subscribe(Response => {
                this.snapshotLoadGraph(Response)
                // if (Response.success == "true") {

                //     this.LoadGraph(Response.message)
                //   //this.LoadGraph(Response.message)
                // }
                // else {
                //   alert(Response.message)
                //   this.fromDate = ''
                //   this.toDate = ''
                // }
            })
    }
    exportcsv(JsonData): any {
        let today = new Date();
        var summary = 'Order status, Order Count, From Date,To Date, Total Orders count\n';
        summary += 'New' + ',' + JsonData["New"] + ',' + this.fromDate + ',' + this.toDate + ',' + JsonData["TotalCount"] + ',' + '\n',
            summary += "Pending" + ',' + JsonData["Pending"] + ',' + "\n",
            summary += "Approved" + ',' + JsonData["Approved"] + ',' + "\n",
            summary += "OnHold" + ',' + JsonData["OnHold"] + ',' + "\n",
            summary += "Rejected" + ',' + JsonData["Rejected"] + ',' + "\n",
            summary += "Ready to Print" + ',' + JsonData["Ready to Print"] + ',' + "\n",
            summary += "Printed" + ',' + JsonData["Printed"] + ',' + "\n",
            summary += "QA Verified" + ',' + JsonData["QA Verified"] + ',' + "\n",
            summary += "QA Review" + ',' + JsonData["QA Review"] + ',' + "\n",
            summary += "Cancelled" + ',' + JsonData["Cancelled"] + ',' + "\n",
            summary += "Shipped" + ',' + JsonData["Shipped"] + ',' + "\n",
            summary += "Returned" + ',' + JsonData["Returned"]

        // jsonData.forEach(function (row) {
        //     summary += row.Approved + ',' + row.sku_description + ',' + row.skuordercount + ',' + row.line_item_price + ',' + row.currency + ',';
        //     summary += "\n";
        // });

        //console.log(summary);
        var csvElement = document.createElement('a');
        csvElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(summary);
        csvElement.target = '_blank';
        csvElement.download = 'Snapshot_Report_' + today + '.csv';
        csvElement.click();
    }
    private isLoading: boolean = false
    public FromDate: string;
    public ToDate: string;
    public OrdersArray: any;
    private showErrors: boolean;
    public JsonData: any;
    public Jlatters: any = [];
    private isSearchGraphToggle: boolean = false;
    public barChartData: any[] = [
        { data: [] }
    ]
    Newcount: number = 0;
    Pendingcount: number = 0;
    Approvedcount: number = 0;
    OnHoldcount: number = 0;
    Rejectedcount: number = 0;
    ReadytoPrintcount: number = 0;
    Printedcount: number = 0;
    QAVerifiedcount: number = 0;
    QAReviewcount: number = 0;
    Cancelledcount: number = 0;
    Shippedcount: number = 0;
    Returnedcount: number = 0;

    snapshotLoadGraph(data) {
        // data = data["orders"]
        let MyArray = [];
        let Newcount = 0;
        let Pendingcount = 0;
        let Approvedcount = 0;
        let OnHoldcount = 0
        let Rejectedcount = 0
        let ReadytoPrintcount = 0
        let Printedcount = 0
        let QAVerifiedcount = 0
        let QAReviewcount = 0
        let Cancelledcount = 0
        let Shippedcount = 0
        let Returnedcount = 0;
        //data = data[0];
        if (data) {
            data.map(function (val) {
                // console.log(val)
                if (val["_id"] == "New")
                    Newcount = val["count"]
                else if (val["_id"] == "Pending")
                    Pendingcount = val["count"]
                else if (val["_id"] == "Approved")
                    Approvedcount = val["count"]
                else if (val["_id"] == "OnHold")
                    OnHoldcount = val["count"]
                else if (val["_id"] == "Rejected")
                    Rejectedcount = val["count"]
                else if (val["_id"] == "ReadyToPrint")
                    ReadytoPrintcount = val["count"]
                else if (val["_id"] == "Printed")
                    Printedcount = val["count"]
                else if (val["_id"] == "QA Verified")
                    QAVerifiedcount = val["count"]
                else if (val["_id"] == "QA Review")
                    QAReviewcount = val["count"]
                else if (val["_id"] == "Shipped")
                    Shippedcount = val["count"]
		else if (val["_id"] == "Cancelled")
                    Cancelledcount = val["count"]
		else if (val["_id"] == "Returned")
                    Returnedcount = val["count"]
            })
        }

        // var NewData = data[1];
        // for (var j = 0; j < NewData.length; j++) {
        //     this.Newcount = this.Newcount + NewData[j]["order"]["total_line_items"];
        //     TotalCount = TotalCount + NewData[j]["order"]["total_line_items"];
        // }
        let TotalCount = 0;
        if (data) {
            data.map(function (val) {
                // console.log(val)

                if (val["_id"] == "New") {
                    Newcount = val["count"]
                    TotalCount = TotalCount+val["count"];
                }
                else if (val["_id"] == "Pending") {
                    Pendingcount = val["count"]
                    TotalCount = TotalCount+val["count"];
                }
                else if (val["_id"] == "Approved") {
                    Approvedcount = val["count"]
                    TotalCount = TotalCount+val["count"];
                }
                else if (val["_id"] == "OnHold") {
                    OnHoldcount = val["count"]
                    TotalCount = TotalCount+val["count"];
                }
                else if (val["_id"] == "Rejected") {
                    Rejectedcount = val["count"]
                    TotalCount = TotalCount+val["count"];
                }
                else if (val["_id"] == "Ready to Print") {
                    ReadytoPrintcount = val["count"]
                    TotalCount = TotalCount+val["count"];
                }
                else if (val["_id"] == "Printed") {
                    Printedcount = val["count"]
                    TotalCount = TotalCount+val["count"];
                }
                else if (val["_id"] == "QA Verified") {
                    QAVerifiedcount = val["count"]
                    TotalCount+=val["count"];
                }
                else if (val["_id"] == "QA Review") {
                    QAReviewcount = val["count"]
                    TotalCount = TotalCount+val["count"];
                }
                else if (val["_id"] == "Shipped") {
                    Shippedcount = val["count"]
                    TotalCount = TotalCount+val["count"];
                }
		else if (val["_id"] == "Returned") {
                    Returnedcount = val["count"]
                    TotalCount = TotalCount+val["count"];
                }
            })
        }

        let csvdata = {
            "New": Newcount,
            "Pending": Pendingcount,
            "Approved": Approvedcount,
            "OnHold": OnHoldcount,
            "Rejected": Rejectedcount,
            "Ready to Print": ReadytoPrintcount,
            "Printed": Printedcount,
            "QA Verified": QAVerifiedcount,
            "QA Review": QAReviewcount,
            "Cancelled": Cancelledcount,
            "Shipped": Shippedcount,
            "Returned": Returnedcount,
            "TotalCount": TotalCount
        }
        this.OrdersArray = csvdata;
        this.barChartData = [
            {
                data: [Newcount, Pendingcount, Approvedcount, OnHoldcount,
                    Rejectedcount, ReadytoPrintcount, Printedcount, QAVerifiedcount, QAReviewcount,
                    Cancelledcount, Shippedcount, Returnedcount]
            }
        ]
        this.isLoading = false;
    }

    //Graph[]
    public barChartOptions: any = {
        scaleShowVerticalLines: false,
        responsive: true,
        scaleShowValues: false,
        scaleValuePaddingX: 10,
        scaleValuePaddingY: 10,
        scales: {
            xAxes: [{
                gridLines: {
                    display: false
                }
            }],
            yAxes: [{
                gridLines: {
                    display: false
                }
            }]
        },
        tooltips: { enabled: false },
        animation: {
            onComplete: function () {
                var chartInstance = this.chart,
                    ctx = chartInstance.ctx;
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';
                this.data.datasets.forEach(function (dataset, i) {
                    var meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {
                        var data = dataset.data[index];
                        ctx.fillText(data, bar._model.x, bar._model.y - 5);
                    });
                });
            }
        }
    };
    public barChartLabels: string[] = ['New', 'Pending', 'Approved', 'On-Hold', 'Rejected', 'Ready to Print', 'Printed', 'QA Verified', 'QA Review', 'Cancelled', 'Shipped', 'Returned'];
    public barChartType: string = 'bar';
    public barChartLegend: boolean = false;
    public chartClicked(e: any): void {
        console.log(e);

    }
    public chartHovered(e: any): void {
        console.log(e);
    }

}
