// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  qztraykey: `-----BEGIN RSA PRIVATE KEY-----
MIIEowIBAAKCAQEAjQhDm8ooeH0E0zrn/OdMTO4ozScT1GD0MYyx+ei92pM16bxS
Fs5zHL1Sm+lpqU4yfiKHy6Ij322wHNQex0F64uyZt2QpOQ2omIhtoQxciBfg6cgM
DhUT2nebcSdk8z3Oiq6J9BnnIEdwdfv3TnWWY5v+ArjrF7Vlo6m/p9olq7o7Yw6W
Ry6W+CCWZY2Mgj9ULUQOTpZglVyv+fOW35Eh72D+eUXjAFBTo3XxkXiNElTcOlRx
cl+dtSSs8wZH1KQ34AD2a/n6h8bl8VNhQ0H2GNXZOsL+sCZmqNn1eQf2fqesWscs
30N2uzG60rcnkhx0kb7OOE1vFz7LxhYBZ44AeQIDAQABAoIBACCh1OLrN8oJQ2CM
tLjz29fBE3fEnvX5KQd+AoBMtgEpJsRaarQUONZhiAfDTkt6aHx/COS5M4uzGZjS
C/0WzZRMIpUoe9FTs7r3dkZZUd+7hrMRMpjfj6RxIGpiLx2JI407LXhptezmGsNx
9ZsV4iJSKcf/eYYW1tmzoUD5XNYK/VtVcyoIwESS3xgbKSxRMofod5uGTknW7zzM
uW+EeocJ1RzKhy45TFNCaqjfoRGvDgP6HCld2BVMYYsI5LSMP4O4ygd9Esg/RWZ7
76Q17KCIfVp80E+WcGmPa/NFjwU+EE/QiVgO0NqZh5WgEK2C9PiZF9bivoQwM4HS
hDwbr3ECgYEAv9chSaVh98Eq88hCmPW4N/qGp4IYjWjyJSFTv6KrlfhNBXWyhO2I
iYY/YSLMCaKfu9hUFhiOfDaMwIvA+peITOvWFJAlnxysjJaWX2mwQ0yy2qWF3UdP
lCdYQ1Tm6R30Ha4xnS/hq6EQFsuvhHI+i4jWEDS8JSPBcdo0C9o4APsCgYEAvDMU
bHJUC4H/f/1Y+cU2KcCzjPPUByr6OXqgJAN3GnVITfzle4DGqoBdsr+rVShCq8cW
12jGO/rnwvNrHzpBu4ykM37s93RAitK2sPLMXc4kyS9v3VWMEYKFuNk29dShGIkM
y/pV9eQi0cIoJaFTKkZTOiGkPZyI51vyEFEI0hsCgYBHAJiRaWSZDiWXlMcRVeCY
12B/Hud3byAwHeWhR9O57eyT2Lv59vBR9h3/FaHu7wGvxKFajDwwPQBbQ15pYSxR
S/gwJCnIehRi9FkzdyqFvh10T078Vyhc7TL9uK6+GRgA5Df4tYAQxcvuo/SSULZV
bo/L77izBkjSmrAostuHgQKBgQCgW93RMfGPBKvSQOspl/K4zOuw9Kn7mZEwnN4L
EbDQqJJ9cTmQbH/bFBnCjzLMEswMVk2OKkaPIRGNuZ/TMe6Egif/gO0lRLciEr18
NMYSP+50RqlIrPX2jYKnqN39xbmmkFCJFbtdE5DfrlJLDhUw9k9APx4VKWY1zNCL
OqELdQKBgAOny46IQl2fCij1tFOq2pV0eCco4nOviLoVf7197kdf5sC2Y+VzAGQc
kYilvNqZw7rGFJHNHbQCp8DnClcAAQwtssga9g5Goy8dgJKeilm2w4c1I1AIKW5/
G8BADvHQewWXuXeI+aKICe6z15X8DH07W3aIIuVfsPS4oQ59SCfB
-----END RSA PRIVATE KEY-----`,
digitalCertificate: `-----BEGIN CERTIFICATE-----
MIIFNjCCAyCgAwIBAgIENTc3NzALBgkqhkiG9w0BAQUwgZgxCzAJBgNVBAYTAlVT
MQswCQYDVQQIDAJOWTEbMBkGA1UECgwSUVogSW5kdXN0cmllcywgTExDMRswGQYD
VQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMxGTAXBgNVBAMMEHF6aW5kdXN0cmllcy5j
b20xJzAlBgkqhkiG9w0BCQEWGHN1cHBvcnRAcXppbmR1c3RyaWVzLmNvbTAeFw0x
OTAxMTcwNTAwMDBaFw0yMDAyMjAwNTAwMDBaMIIBADELMAkGA1UEBgwCVVMxGDAW
BgNVBAgMD1NvdXRoIERlZXJmaWVsZDELMAkGA1UEBwwCTUExKDAmBgNVBAoMH1Ro
ZSBZYW5rZWUgQ2FuZGxlIENvbXBhbnksIEluYy4xFjAUBgNVBAsMDVlhbmtlZSBD
YW5kbGUxHTAbBgNVBAMMFHFhcy55YW5rZWVjYW5kbGUuY29tMSswKQYJKoZIhvcN
AQkBDBxFY29tbWVyY2VEZXZvcHNAbmV3ZWxsY28uY29tMTwwOgYDVQQNDDNyZW5l
d2FsLW9mLWUyNTg0ZDQwNWU3Mjc1NDdiZTRlM2E3NjdlNTNjYTcwZDNhNGJlYWQw
ggEgMAsGCSqGSIb3DQEBAQOCAQ8AMIIBCgKCAQEAjQhDm8ooeH0E0zrn/OdMTO4o
zScT1GD0MYyx+ei92pM16bxSFs5zHL1Sm+lpqU4yfiKHy6Ij322wHNQex0F64uyZ
t2QpOQ2omIhtoQxciBfg6cgMDhUT2nebcSdk8z3Oiq6J9BnnIEdwdfv3TnWWY5v+
ArjrF7Vlo6m/p9olq7o7Yw6WRy6W+CCWZY2Mgj9ULUQOTpZglVyv+fOW35Eh72D+
eUXjAFBTo3XxkXiNElTcOlRxcl+dtSSs8wZH1KQ34AD2a/n6h8bl8VNhQ0H2GNXZ
OsL+sCZmqNn1eQf2fqesWscs30N2uzG60rcnkhx0kb7OOE1vFz7LxhYBZ44AeQID
AQABoyMwITAfBgNVHSMEGDAWgBSQplC3hNS56l/yBYQTeEXoqXVUXDALBgkqhkiG
9w0BAQUDggIBAJbyDNOo6vnZOkvQG9oPvM5k61GId/12WpnmhcQKkjFW0FGML/45
Uu/dTmDFX70pDRMj/QmGU0+1veujRUprGJiPirjs4nc6R6iHfDzDPCI7tXUGjJWK
GMVUVf+MB3rMCinoXdw84OawNRUfc2lKDUGt50PQqutx+1Qvy4yYsy7uXUXVIzGK
tTdPuoDI27zqLJgbQeLVOMVlDMuCK+Wn46mbCslyd2xjwLI6rl+/EUH8dz9WsuA6
UFKejX0Sav/M7nE9zhFNqkgCypkRRoplxeT/jycqkttq2m67pG10zwkCa2xV8Zdu
NwnB6GPU3gTnN2cfMv7NXC5aNhZ8MKs11Kta2YbHVlfp7GBPW0hpeiaFovI8zwmU
RWADRCiLfJpVvmRGcw5XDwCq/jtMzMDdZK5w4iR1ElTu+l+pZM9jMZLhZsY4QSha
+43Olso5GIGm2J8bEaMTMZF58PfVlGGxI8DzojOMEQBEiOAmnUGHQXcjSFuDxpEw
YdOppBCeqtsNOHp+zxRb/Y2LapCwRmFzAp9czcU3XsyYzGMd3/5cIZ+YJ5UMtV4w
BtYk+qXASdmGkIHuceyfhszJL4lQWNpyQntECj8zt4myjwQsY4QoO0qZS9t0BUKt
qD60B2BO2ivi3LUAyotOGJ7cWWouXZWZ2WgQgJW8433OqPhe/RxyQl6A
-----END CERTIFICATE-----
--START INTERMEDIATE CERT--
-----BEGIN CERTIFICATE-----
MIIFEjCCA/qgAwIBAgICEAAwDQYJKoZIhvcNAQELBQAwgawxCzAJBgNVBAYTAlVT
MQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYDVQQKDBJRWiBJ
bmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcG
A1UEAwwQcXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBx
emluZHVzdHJpZXMuY29tMB4XDTE1MDMwMjAwNTAxOFoXDTM1MDMwMjAwNTAxOFow
gZgxCzAJBgNVBAYTAlVTMQswCQYDVQQIDAJOWTEbMBkGA1UECgwSUVogSW5kdXN0
cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMxGTAXBgNVBAMM
EHF6aW5kdXN0cmllcy5jb20xJzAlBgkqhkiG9w0BCQEWGHN1cHBvcnRAcXppbmR1
c3RyaWVzLmNvbTCCAiIwDQYJKoZIhvcNAQEBBQADggIPADCCAgoCggIBANTDgNLU
iohl/rQoZ2bTMHVEk1mA020LYhgfWjO0+GsLlbg5SvWVFWkv4ZgffuVRXLHrwz1H
YpMyo+Zh8ksJF9ssJWCwQGO5ciM6dmoryyB0VZHGY1blewdMuxieXP7Kr6XD3GRM
GAhEwTxjUzI3ksuRunX4IcnRXKYkg5pjs4nLEhXtIZWDLiXPUsyUAEq1U1qdL1AH
EtdK/L3zLATnhPB6ZiM+HzNG4aAPynSA38fpeeZ4R0tINMpFThwNgGUsxYKsP9kh
0gxGl8YHL6ZzC7BC8FXIB/0Wteng0+XLAVto56Pyxt7BdxtNVuVNNXgkCi9tMqVX
xOk3oIvODDt0UoQUZ/umUuoMuOLekYUpZVk4utCqXXlB4mVfS5/zWB6nVxFX8Io1
9FOiDLTwZVtBmzmeikzb6o1QLp9F2TAvlf8+DIGDOo0DpPQUtOUyLPCh5hBaDGFE
ZhE56qPCBiQIc4T2klWX/80C5NZnd/tJNxjyUyk7bjdDzhzT10CGRAsqxAnsjvMD
2KcMf3oXN4PNgyfpbfq2ipxJ1u777Gpbzyf0xoKwH9FYigmqfRH2N2pEdiYawKrX
6pyXzGM4cvQ5X1Yxf2x/+xdTLdVaLnZgwrdqwFYmDejGAldXlYDl3jbBHVM1v+uY
5ItGTjk+3vLrxmvGy5XFVG+8fF/xaVfo5TW5AgMBAAGjUDBOMB0GA1UdDgQWBBSQ
plC3hNS56l/yBYQTeEXoqXVUXDAfBgNVHSMEGDAWgBQDRcZNwPqOqQvagw9BpW0S
BkOpXjAMBgNVHRMEBTADAQH/MA0GCSqGSIb3DQEBCwUAA4IBAQAJIO8SiNr9jpLQ
eUsFUmbueoxyI5L+P5eV92ceVOJ2tAlBA13vzF1NWlpSlrMmQcVUE/K4D01qtr0k
gDs6LUHvj2XXLpyEogitbBgipkQpwCTJVfC9bWYBwEotC7Y8mVjjEV7uXAT71GKT
x8XlB9maf+BTZGgyoulA5pTYJ++7s/xX9gzSWCa+eXGcjguBtYYXaAjjAqFGRAvu
pz1yrDWcA6H94HeErJKUXBakS0Jm/V33JDuVXY+aZ8EQi2kV82aZbNdXll/R6iGw
2ur4rDErnHsiphBgZB71C5FD4cdfSONTsYxmPmyUb5T+KLUouxZ9B0Wh28ucc1Lp
rbO7BnjW
-----END CERTIFICATE-----`
};
