import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'paginator',
    templateUrl: 'pagination.component.html',
    styleUrls: ['pagination.component.css']
})
export class PaginationComponent implements OnChanges {
    @Input() isInventoryMangage:boolean = false;
    @Input() isLabelPrint:boolean = false;
    @Input() isLoading:boolean;
    @Input() searchResults;
    @Input() pageNumCache;
    @Input() noGridView;
    @Input() noSelectText;
    @Input() hideQuantity: boolean; //Variable to hide quantity field
    
    @Input() pageSize;
    @Output() pageSizeChange = new EventEmitter();

    @Input() pageNum;
    @Output() pageNumChange = new EventEmitter();

    @Output() paginationChange = new EventEmitter();

    @Output() viewChange = new EventEmitter();

    isPageCountError: boolean = false;
    
    @ViewChild('pageNumTopFieldRef')
    private pageNumTopFieldEleRef: ElementRef;
    @ViewChild('pageNumBottomFieldRef')
    private pageNumBottomFieldEleRef: ElementRef;
    
    ngOnChanges(changes: SimpleChanges) {
        if (changes.searchResults && !changes.searchResults.firstChange) {
            this.pageNum = changes.searchResults.currentValue.currentPageNo
        }
    }

    onPrev(){
        this.pageNum = this.pageNumCache - 1; //decrement page number
        this.initiateSearch();
    }

    onNext(){
        this.pageNum = this.pageNumCache + 1; //increment page number
        this.initiateSearch();
    }

    pageSizeChanged () {
        this.pageNum = 1;  //move to page 1 on changing page size
        this.pageSize = Number(this.pageSize);
        this.pageSizeChange.emit(this.pageSize);
        this.initiateSearch();
    }

    updatePageNo ( e ) {
        if (/\D/g.test(e)) {
            // Filter non-digits from input value.
            e = e.replace(/\D/g, '');
        }
        //check to zero
        if( (e != 0 && e <= this.searchResults.totalPageCount) || e === "" ){
            this.pageNum = e;
        }
        this.pageNumTopFieldEleRef.nativeElement.value = this.pageNum;
        this.pageNumBottomFieldEleRef.nativeElement.value = this.pageNum;
    }
    getpageNum () {
        this.isPageCountError = false; // remove any previously set error
        if( this.pageNum == this.searchResults.currentPageNo ) {
            return true; //already in the same page
        } else if (this.pageNum && !isNaN(this.pageNum) && (this.pageNum > 0) && (this.pageNum <= this.searchResults.totalPageCount)) {
            this.initiateSearch(); // get new page details;
        } else {
            this.isPageCountError = true; // show error
        }
    }

    initiateSearch() {
        this.pageNum = Number(this.pageNum);
        this.pageNumChange.emit(this.pageNum);
        this.isPageCountError = false; //remove any previous errors
        this.paginationChange.emit();
    }

    //function to update the selected orders quantity
    getSelectedOrdersQuantity() {
        let selectedOrderQuantity: number = 0;
        let ordersData = this.searchResults.orders;
        for(let i=0;i<ordersData.length;i++){
            if(ordersData[i].isSelected == true) {
                selectedOrderQuantity += ordersData[i].quantity;
            }
        }
        return selectedOrderQuantity;
    }
}