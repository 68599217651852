  <div class="user-search-container container">
    <div class="row">
      <h2 class="title">
            Find Group
            <button class="pull-right search-view-toggle" [class.searchCollapsed]="!searchViewToggle" (click)="searchViewToggle = !searchViewToggle;"></button>
          </h2>
    </div>

    <div class="row search-fields-container" *ngIf="searchViewToggle">
      <form id="filter2" [formGroup]="registerForm" (ngSubmit)="registerGroup(registerForm.value)" novalidate autocomplete="off">
        <div class="sub-search-container row">
          <h3>Group Details</h3>
          <div class="sub-search-wrapper">
            <div class="col-md-4" [ngClass]="{'has-error': registerForm.get('group_name').invalid && registerForm.get('group_name').dirty}">
              <label for="group_name">
                <span>Group Name</span>
              </label>
              <input type="text" class="user-input-text" [formControl]="group_name" [(ngModel)]="term12" id="group_name" placeholder="Group Name">
            </div>


            <div class="col-md-4" [ngClass]="{'has-error': registerForm.get('group_description').invalid && registerForm.get('group_description').dirty}">
              <label for="group_description">Group Description</label>
              <input type="text" class="user-input-text" [formControl]="group_description" [(ngModel)]="term22" id="group_description" placeholder="Group Description">
            </div>




            <label for="access_list" id="checkboxList"><span id="checkboxList3">Access List:</span>
                <div id="checkboxList2" class="sub-search-wrapper">
                  <form  *ngFor="let access of accesses">
                <input (click)="accessCheckbox($event)" class="accessListCss" name="group.access_list" type="checkbox" [(ngModel)]="access.checked" [value]="access.name" [formControl]="access_list" > {{ access.name }}
                </form>

              </div>

              </label>
          </div>
        </div>
      </form>
    </div>
  </div>

  <br>
  <table class="postTable container" id="myTable">
    <thead>
      <tr>
        <th (click)="sortTable(0)" class="postTableCss">Group Name</th>
        <th (click)="sortTable(1)" class="postTableCss">Group Description</th>
        <th class="postTableCss">Access List</th>
        <th class="postTableCss"></th>
        <th class="postTableCss"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let group of groups | filter2: term12:term22:accesses" id='searchContainer'>
        <td>{{ group.group_name}}</td>
        <td>{{ group.group_description}}</td>
        <td><select id="accessListBox" name="accesslist"><option value="accesslist" disabled *ngFor="let access_list of group.access_list">{{ access_list }}</option></select></td>
        <td><img title="Edit Group" type="image" src="assets/images/edit-icon.jpg" class="clickableCell" (click)="fireEditGroupEvent(this.group._id); editGroupWasClicked()"></td>
        <td><img id="trash" title="Delete Group" type="image" src="assets/images/delete_icon.png" class="clickableCell" (click)="delGroup(group._id)" ng-really-message="Are you sure?"></td>
      </tr>
    </tbody>
  </table>
