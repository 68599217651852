<div class="container">
    <div class="order-details-container">
        <div class="col-md-12 fields-container">
            <p *ngIf="orderError" class="red">{{orderError}}</p>
            <form>
                <section class="section-container clearfix">
                    <h3 class="section-title">Order Details</h3>
                    <div class="inputs-container">
                        <div class="user-input-field col-md-4">
                            <label for="orderNumber">Order Number</label>
                            <!--<input type="text" id="orderNumber" #orderNumRef name="orderNumber" (focus)="orderIdFocused();" [(ngModel)]="orderNumber" (keyup.enter)="getOrderDetails();" class="pull-right">-->
                            <input type="text" id="orderNumber" #orderNumRef name="orderNumber" (focus)="orderIdFocused();" (click)="orderIdFocused();" (paste)="orderIdFocused();" [(ngModel)]="orderNumber" (keyup.enter)="getOrderDetails();" class="pull-right">
                        </div>
                        <div class="user-input-field col-md-4">
                            <label for="orderStatus">Order Status</label>
                            <input type="text" id="orderStatus" class="pull-right" [value]="orderDetails?.order_status" disabled>
                        </div>
                    </div>
                </section>
                <section class="section-container clearfix">
                    <h3 class="section-title">Shipping Details</h3>
                    <div class="inputs-container">
                        <div class="row">
                            <div class="user-input-field col-md-4">
                                <label for="shippingType">Shipping Type</label>
                                <select name="shippingType" [disabled]="(!isOrderLoaded || disableActionItems || isStatusShipped)" [(ngModel)]="shippingDetails.selectedShippingType" class="user-input-select pull-right" id="shippingType" (change)="shippingTypeChange()">
                                    <!-- <option value="Expresspak 5" selected>Expresspak 5</option>
                                    <option value="Parcel">Parcel</option> -->
                                    <option *ngFor="let shippingtype of shippingTypes" [ngValue]="shippingtype">{{shippingtype.type}}</option>
                                </select>
                            </div>
                            <div class="user-input-field col-md-4">
                                <label for="numOfPackages"># of Packages</label>
                                <input type="text" id="numOfPackages" name="numOfPackages" class="pull-right" [(ngModel)]="shippingDetails.numberOfPackages" (input)="numOfPackageChange($event)" [disabled]="(!isOrderLoaded || disableActionItems || isStatusShipped || shippingDetails?.shippingType?.toLowerCase().indexOf('express') > -1 )">
                                <p class="red" *ngIf="isNumOfPackValid">Enter a valid quanity</p>
                            </div>
                            <div class="user-input-field col-md-4">
                                <label>Collection  Date:</label>
                                <div class="search-date-picker">
                                    <!-- <owl-date-time [placeHolder]="'DD/MM/YYYY HH:mm'" [autoClose]="true" [dateFormat]="'DD/MM/YYYY HH:mm'" [(ngModel)]="collectionDate" name="collectionDate"></owl-date-time> -->
                                    <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1"
                                        placeHolder="'MM/DD/YYYY HH:mm'" [(ngModel)]="collectionDate"
                                        name="collectionDate">
                                    <owl-date-time #dt1></owl-date-time>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="user-input-field col-md-4"></div> -->
                        <div class="row">
                            <div class="user-input-field col-md-4">
                                <!-- <label for="shipmentType">Shipment Type</label>
                                <input type="text" id="shipmentType" class="pull-right" disabled [value]="shippingDetails.shipmentType"> -->
                                <label for="shipmentType" class="pull-left shipment-type-label">Shipment Type</label>
                                <div class="pull-right">
                                    <div *ngFor="let aShipmentType of UKShipmentType" class="pull-left">
                                        <span>
                                            <input type="radio" name="radiogroup" [id]="aShipmentType.name" [checked]="aShipmentType.name === 'UPS'"
                                            [value]="aShipmentType.name" (change)="onShipmentTypeChange($event)">
                                        </span>
                                        <label for="{{aShipmentType.name}}">{{ aShipmentType.name }}&nbsp;&nbsp;</label>
                                    </div>    
                                </div>
                            </div>
                            <div class="user-input-field col-md-4">
                                <label for="Weight">Weight</label>
                                <input type="text" id="Weight" name="weight" class="pull-right" [(ngModel)]="shippingDetails.weight" disabled>
                            </div>
                            <div class="user-input-field col-md-4" *ngIf="radioSelected=='DPD Local'" [hidden]="!warnCountryInfo">
                                <label>Invoice Number</label>
                                <input type="text" id="invoice_number" name="invoice" [(ngModel)]="shippingDetails.invoice_number" [disabled]="disableActionItems || isStatusShipped">
                            </div>                            
                        </div>
                        <!-- <div class="user-input-field col-md-4"></div> -->
                        <div class="row">
                            <div class="user-input-field col-md-4">
                                <label for="serviceType">Service Type</label>
                                <input type="text" *ngIf="radioSelected=='DPD Local'" id="serviceType" class="pull-right" disabled [value]="shippingDetails.serviceType">
                                <input type="text" *ngIf="radioSelected=='UPS'" id="serviceType" class="pull-right" disabled>
                            </div>
                            <div class="user-input-field col-md-4">
                                <label for="customerServiceType">Customer Service Type</label>
                                <input type="text" *ngIf="radioSelected=='DPD Local'" id="customerServiceType" class="pull-right" disabled [value]="shippingDetails.customerServiceType">
                                <input type="text" *ngIf="radioSelected=='UPS'" id="customerServiceType" class="pull-right" disabled>
                            </div>
                        </div>
                        <!-- <div class="user-input-field col-md-4"></div>                         -->
                    </div>
                </section>
                <section class="section-container clearfix">
                    <div class="inputs-container cusomer-details-container">
                        <div class="user-input-field col-md-4">
                            <label for="customerName">First Name</label>
                            <input type="text" id="customerName" class="pull-right" [value]="orderDetails?.shipTo_cust_firstName" disabled>
                        </div>
                        <div class="user-input-field col-md-4">
                            <label for="address1">Address 1</label>
                            <input type="text" id="address1" class="pull-right" [value]="orderDetails?.shipTo_cust_address_line_1" disabled>
                        </div>
                        <div class="user-input-field col-md-4">
                            <label for="county">County</label>
                            <input type="text" id="county" class="pull-right" [value]="orderDetails?.shipTo_cust_county" disabled>
                        </div>
                        <div class="user-input-field col-md-4">
                            <label for="custsname">Last Name</label>
                            <input type="text" id="custsname" class="pull-right" [value]="orderDetails?.shipTo_cust_lastName" disabled>
                        </div>
                        <div class="user-input-field col-md-4">
                            <label for="address2">Address 2</label>
                            <input type="text" id="address2" class="pull-right" [value]="orderDetails?.shipTo_cust_address_line_2" disabled>
                        </div>
                        <div class="user-input-field col-md-4">
                            <label for="postalCode">Post Code</label>
                            <input type="text" id="postalCode" class="pull-right" [value]="orderDetails?.shipTo_cust_postcode" disabled>
                        </div>
                        <div class="user-input-field col-md-4">
                            <label for="email">Email</label>
                            <input type="text" id="email" class="pull-right" [value]="orderDetails?.cust_email" disabled>
                        </div>
                        <div class="user-input-field col-md-4">
                            <label for="address3">Address 3</label>
                            <input type="text" id="address3" class="pull-right" [value]="orderDetails?.shipTo_cust_address_line_3" disabled>
                        </div>
                        <div class="user-input-field col-md-4">
                            <label for="country">Country</label>
                            <input type="text" id="address3" class="pull-right" [value]="orderDetails?.shipTo_cust_country" disabled>
                        </div>
                        <div class="user-input-field col-md-4">
                            <label for="custNum">Telephone</label>
                            <input type="text" id="custNum" class="pull-right" [value]="orderDetails?.cust_phone" disabled>
                        </div>
                        <div class="user-input-field col-md-4">
                            <label for="town">Town</label>
                            <input type="text" id="town" class="pull-right" [value]="orderDetails?.shipTo_cust_town" disabled>
                        </div>
                        <div class="col-md-10 col-md-offset-1 text-center" *ngIf="warnCountryInfo">
                            <div class="alert alert-danger" role="alert" >
                                <p>
                                    <strong> <span class="glyphicon glyphicon-info-sign" aria-hidden="true"></span> An invoice is required for the selected country</strong>
                                </p>
                                <p>
                                    Remember to <strong>print 5 copies</strong> of your invoice for customs clearance. 
                                    <strong>PLEASE ENSURE ALL COPIES HAVE AN ORIGINAL SIGNATURE</strong>
                                </p>
                            </div>
                        </div>
                        <div class="col-md-10 col-md-offset-1 text-center" *ngIf="serviceInfoType">
                            <div class="alert alert-danger" role="alert" >
                                
                                <p>
                                    
                                    <strong>Please remember to place all packages in a green bag or container</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </div>
        <div *ngIf="searchResults.orders.length">
            <paginator [isLoading]="isLoading" [searchResults]="searchResults" [pageNumCache]="searchparams.pageno" [(pageNum)]="searchparams.pageno" [(pageSize)]="searchparams.pagesize" (paginationChange)="searchOnPaginationchange($event);" [noGridView]="true" [noSelectText]="true" [hideQuantity]="true">
                <div searchdisplay>
                    <div class="order-grid-containter">
                        <ngx-datatable class="material" [rows]="searchResults.orders" [columnMode]="'flex'" [columns]="columns" [headerHeight]="50" [loadingIndicator]="loading" [rowHeight]="50" [scrollbarV]="true">
                        </ngx-datatable>
                    </div>
                </div>
                <div actionbuttons>
                    <button type="button" class="approve-button pull-right" *ngIf="radioSelected=='DPD Local'"(click)="printPackagingSlip()" [disabled]="disableActionItems || (shippingDetails.invoice_number==='' && warnCountryInfo)">
                        Print Packaging Slip
                    </button>
                    <button type="button" class="approve-button pull-right" *ngIf="radioSelected=='UPS'" (click)="printPackagingSlip()" [disabled]="disableActionItems">
                        Print Packaging Slip
                    </button>
                    <button type="button" class="approve-button pull-right" *ngIf="radioSelected=='DPD Local'" (click)="printShippingLabel()" [disabled]="disableActionItems || isStatusShipped || (shippingDetails.invoice_number==='' && warnCountryInfo)|| EUCountry">
                        Print Shipping Label
                    </button>
                    <button type="button" class="approve-button pull-right" *ngIf="radioSelected=='UPS'" (click)="printShippingLabel()" [disabled]="disableActionItems || isStatusShipped || EUCountry">
                        Print Shipping Label
                    </button> 
                    <button type="button" class="manual-consignment" *ngIf="EUCountry" (click)="manualConsignment()" [disabled]="disableActionItems || isStatusShipped">
                        Manual Consignment
                    </button>
                </div>
            </paginator>
        </div>
    </div>
    <span class="fa fa-facebook-square" style="visibility:hidden"></span>
</div>