import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UserInfoModalService } from '../../util/modal/user-info.modal';
import { SharedApplicationService } from '../../service/shared.service';
import * as moment from 'moment';
import { OrderDetails } from "../../model/orderDetails.model";

@Component({
    selector: 'detail-view',
    templateUrl: './detail-view.component.html',
    styleUrls: ['./detail-view.component.css']
})
export class DetailViewComponent implements OnInit {
    @Input() detailViewData: OrderDetails;
    @Input() rejectCodes;
    @Input() disableApprove: boolean = false;
    @Input() isCSCLabelManager: boolean = false;
    @Output() closeDetails = new EventEmitter();

    constructor(private userModal: UserInfoModalService,
        private sharedAppService: SharedApplicationService) { }

    labelImageurl: string;
    rejectionProps = {
        rejectCode: "",
        rejectReason: "",
        rejectComment: ""
    };
     shipToCountry;
    reason_code_original: string;
    reason_desc_original: string;
    ngOnInit() {
        this.reason_code_original = this.detailViewData.reason_code;
        this.reason_desc_original = this.detailViewData.reason_desc;
        this.labelImageurl = "assets/images/label_image.jpg";
        this.detailViewData.order_date = moment(this.detailViewData.order_date).format("MM/DD/YYYY HH:mm");
        this.detailViewData.reason_code = this.detailViewData.reason_code === "" ? null : this.detailViewData.reason_code;
        this.detailViewData.reason_desc = this.detailViewData.reason_desc === "" ? null : this.detailViewData.reason_desc;
        this.shipToCountry = this.detailViewData.shipTo_cust_country;
    }

    closeSection() {
        this.closeDetails.emit();
        if (this.detailViewData.order_status == "Approved" || this.detailViewData.order_status == "Pending") {
            this.detailViewData.reason_code = "";
            this.detailViewData.reason_desc = "";
        }
        else {
            this.detailViewData.reason_code = this.reason_code_original;
            this.detailViewData.reason_desc = this.reason_desc_original;
        }
    }

    rejectCodeChange(code) {
        // this.detailViewData.reason_desc = this.rejectCodes.reason_description[this.rejectCodes.reason_code.indexOf(code)];
        let shippingCountry = this.detailViewData.shipTo_cust_country;
        let desc;
        let reasonCode;
        if(shippingCountry == "FR"){
            desc = this.rejectCodes.reason_description_fr[this.rejectCodes.reason_code_fr.indexOf(code)];
            reasonCode = this.rejectCodes.reason_code_fr.indexOf(code);
        }
        else if(shippingCountry == "IT"){
            desc = this.rejectCodes.reason_description_it[this.rejectCodes.reason_code_it.indexOf(code)];
            reasonCode = this.rejectCodes.reason_code_it.indexOf(code);
        }
        else if(shippingCountry == "DE"){
            desc = this.rejectCodes.reason_description_de[this.rejectCodes.reason_code_de.indexOf(code)];
            reasonCode = this.rejectCodes.reason_code_de.indexOf(code);
        }
        else{
            desc = this.rejectCodes.reason_description[this.rejectCodes.reason_code.indexOf(code)];
            reasonCode = this.rejectCodes.reason_code.indexOf(code);
        }
        var text1 = this.detailViewData.label_text1;
        var text2 = this.detailViewData.label_text2;
        if(text1 == null){
            text1="";
        }
        if(text2 == null){
            text2="";
        }
        
        if (reasonCode >= 0) {
            if (text1.trim() != "" && text2.trim() != "") {
                desc = desc.replace("XXX", '"' + text1 + ' / ' + text2 + '"');
            } else if (text1.trim() != "" && text2.trim() == "") {
                desc = desc.replace("XXX", text1);
            } else if (text1.trim() == "" && text2.trim() != "") {
                desc = desc.replace("XXX", text2);
            } else {
                desc = desc.replace("XXX", "");
            }
            this.detailViewData.reason_desc = desc;
        }
        else {
            console.log("inside else")
            this.detailViewData.reason_desc = desc;
        }
    }

    rejectOrder() {
        let status = "Rejected";
        this.postOrderStatus(status)
    }

    holdOrder() {
        let status = "OnHold";
        this.postOrderStatus(status)
    }

    approveOrder() {
        let status = "Approved";
        this.postOrderStatus(status)
    }
    postOrderStatus(status: string) {
        let postParams: any = null; //dummy data for post to work
        if (status != "Approved") {
            postParams = {
                rejectionCode: this.detailViewData.reason_code,
                rejectionDesc: this.detailViewData.reason_desc,
                rejectionComment: this.detailViewData.reason_comment
            }
        }
        this.sharedAppService.httpPostService(`/api/orderstatus/${this.detailViewData.order_id}/${status}`, postParams)
            .then(
                // Success
                data => {
                    this.userModal.openModal({ status: 'success', title: status, message: `Selected orders successfully ${status}.` });
                    this.closeDetails.emit(true); //close the detail view
                },
                // Error
                err => {
                    console.log(err);
                    this.userModal.openModal({ status: 'error', title: 'Action Failed', message: `${status} of order failed. ${err.message}` });
                }
            )
    }
}