import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'modal',
    templateUrl: './base.modal.html',
    styles: [ `.popup-style { background: #ffffff; }`]
})
export class BaseModal implements OnInit {
    @Input('show-modal') showModal: boolean;

    @Output('closed') closeEmitter: EventEmitter < BaseModalResult > = new EventEmitter < BaseModalResult > ();
    @Output('loaded') loadedEmitter: EventEmitter < BaseModal > = new EventEmitter < BaseModal > ();
    @Output() positiveLabelAction = new EventEmitter();

    constructor() {}

    ngOnInit() {
        this.loadedEmitter.next(this);
      }
    
      show() {
        this.showModal = true;
      }
    
      hide() {
        this.showModal = false;
        this.closeEmitter.next({
          action: BaseModalAction.POSITIVE
        });
      }

      positiveAction() {
        this.positiveLabelAction.next(this);
        return false;
      }
    
      cancelAction() {
        this.showModal = false;
        this.closeEmitter.next({
          action: BaseModalAction.CANCEL
        });
        return false;
      }
}

export enum BaseModalAction { POSITIVE, CANCEL }

export interface BaseModalResult {
  action: BaseModalAction;
}