import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from '../../../common/toastr.service';
import { UserService } from '../../../user/user.service';
import { AuthService } from '../../../user/auth.service';
import { IUser } from '../../../user/user';
import { IGroup } from '../../../user/group';
// import { FindGroupComponent } from '../find-group/find-group.component';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/do';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/observable/throw';

function comparePassword(c: AbstractControl): {[key: string]: boolean} | null {
  const passwordControl = c.get('password');
  const confirmControl = c.get('retypepass');

  if (passwordControl.pristine || confirmControl.pristine) {
    return null;
  }

  if (passwordControl.value === confirmControl.value) {
    return null;
  }
  return { 'mismatchedPassword': true };
}

@Component({
  selector: 'app-update-lost-password',
  templateUrl: './update-lost-password.component.html',
  styleUrls: ['./update-lost-password.component.css']
})
export class UpdateLostPasswordComponent implements OnInit {
  @Output() cancelPassClicked = new EventEmitter();
  @Output() resetPasswordSubmit = new EventEmitter();
  @Input() userid: string;

  registerForm: FormGroup;
  passwordForm: FormGroup;
  user: any;

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private userService: UserService,
              private router: Router,
              private toastr: ToastrService) {
  }

  roles_lists = [ 'Administrator Accounts', 'Privileged Accounts', 'Non-Privileged Accounts', 'Service Accounts'];

  password = new FormControl('', [Validators.required]);
  retypepass = new FormControl('', [Validators.required]);
  roles = new FormControl('');

  searchViewToggle = true;

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.registerForm = this.fb.group({
      // password: this.password,
      passwordGroup: this.fb.group({
        password: this.password,
        retypepass: this.retypepass,


      }, {validator: comparePassword}),
      roles: this.roles
    });

    this.userService.getUser(this.userid).subscribe(data => {
      // console.log(this.user[0].lockUntil);
      if (data["success"] === false) {
        if (data["errcode"]) {
          // this.authService.logout();
          this.router.navigate(['/usermanagement']);
        }
        // this.toastr.error(data.message);
      } else {
        this.user = data["data"];
        if (this.user[0].roles === 'Administrator Accounts') {
          this.registerForm.get('passwordGroup.password').setValidators(
            [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9_]).{16,20}$')]);

        } else if (this.user[0].roles === 'Privileged Accounts') {
          this.registerForm.get('passwordGroup.password').setValidators(
            [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9_]).{10,20}$')]);

        } else if (this.user[0].roles === 'Non-Privileged Accounts') {

          this.registerForm.get('passwordGroup.password').setValidators(
            [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9_]).{8,20}$')]);

        } else if (this.user[0].roles === 'Service Accounts') {
          this.registerForm.get('passwordGroup.password').setValidators(
            [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9_]).{18,20}$')]);

        }
        // this.populateForm(this.user);
        // console.log(this.user);
      }
    });

    this.getUserEdit(this.userid);

  }
  updateLostPassword(userid): void {
    if (userid.roles == null || userid.roles == "") {
      this.userService.getUser(this.userid).subscribe(data => {
        if (data["success"] === false) {
          if (data["errcode"]) {
            // this.authService.logout();
            this.router.navigate(['/usermanagement']);
          }
          // this.toastr.error(data.message);
        } else {
          let newData = data["data"];
          userid.roles = newData[0].roles;

          if (this.registerForm.dirty && this.registerForm.valid) {
            const theForm = this.registerForm.value;
            const thePass = this.registerForm.value.passwordGroup.password;
            theForm.password = thePass;
            console.log(theForm.value, "theForm");
            delete theForm.passwordGroup;

            this.userService.updateLostPassword(this.userid, this.registerForm.value)
              .subscribe(data => {
                if (data["success"] === false) {
                  if (data["errcode"]) {
                    console.log(data["errcode"]);
                  }
                  alert(data["message"]);
                  // this.toastr.error(data.message);
                } else {
                  this.resetPasswordSubmit.emit();
                  alert(data["message"]);
                  
                  // this.toastr.success(data.message);
                }
                this.registerForm.reset();
              });
          }
        }
      });
      //console.log(formdata.roles, "roles in update");
    }
    else {
      if (this.registerForm.dirty && this.registerForm.valid) {
        const theForm = this.registerForm.value;
        const thePass = this.registerForm.value.passwordGroup.password;
        theForm.password = thePass;
        console.log(theForm)
        this.userService.updateLostPassword(this.userid, this.registerForm.value)
          .subscribe(data => {
            if (data["success"] === false) {
              if (data["errcode"]) {
                console.log(data["errcode"]);
              }
              alert(data["message"])
              //this.toastr.error(data.message);
            } else {
              this.resetPasswordSubmit.emit();
              alert(data["message"])

              // this.toastr.success(data.message);
            }
            this.registerForm.reset();
          });
      }
    }
  }
  /*
    // console.log(formdata)
    if (this.registerForm.dirty && this.registerForm.valid) {
      const theForm = this.registerForm.value;
      const thePass = this.registerForm.value.passwordGroup.password;
      theForm.password = thePass;
      console.log(theForm);
      this.userService.updateLostPassword(this.userid, this.registerForm.value)
      // this.userid
        .subscribe(data => {
          console.log(this.registerForm.value);
          if (data.success === false) {
            if (data.errcode) {
              console.log(data.errcode);
            } alert(data.message);
            // this.toastr.error(data.message);
          } else {
            this.resetPasswordSubmit.emit();
            alert(data.message);

          }
          this.registerForm.reset();
        });
    }
  }

*/
  populateForm(data): void {
    this.registerForm.patchValue({
      roles: data.roles
    });
    // console.log(data.group_name)
  }


  getUserEdit(userid): void {
    this.userService.getUser(this.userid).subscribe(data => {

      if (data["success"] === false) {
        if (data["errcode"]) {
          // this.authService.logout();
          this.router.navigate(['/usermanagement']);
        }
        // this.toastr.error(data.message);
      } else {
        this.user = data["data"];
        this.populateForm(this.user[0]);

      }

    });
    // console.log(this.username);

  }


  cancelWasClicked() {
    this.cancelPassClicked.emit();
  }
}
