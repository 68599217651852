import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-approver',
  templateUrl: './approver.component.html',
  styleUrls: ['./approver.component.css']
})
export class ApproverComponent implements OnInit {
  private _showDetailscomp:boolean=false;
  constructor() { }

  ngOnInit() {
  }

}
