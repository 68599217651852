import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
// import { Http, Response, Headers, RequestOptions } from '@angular/http';
// import { MdDialogModule, MdDialog, MdDialogRef, MD_DIALOG_DATA } from '@angular/material';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../user/auth.service';
import { ToastrService } from '../../common/toastr.service';
import { TagService } from '../../user/tag.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  tag = {
    version: '',
    env: '',
    region: ''
  }

  constructor(private fb: FormBuilder,
    private authService: AuthService,
    public tagService: TagService,
    private router: Router,
    public toastr: ToastrService,
    private http: HttpClient, public dialog: MatDialog) {
  }


  username = new FormControl('', [Validators.required]);
  password = new FormControl('', [Validators.required]);
  useremail = new FormControl('', [Validators.required]);
  newpassword = new FormControl('', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9_]).{16,20}$')]);
  forgotpasswordShow: boolean = false;

  Rememberme: boolean;
  Username: string;
  Userpassword: any;
  desablebtn: boolean;
  passwordExpired: boolean;

  loginForm: FormGroup = this.fb.group({
    username: this.username,
    password: this.password,
    newpassword: this.newpassword,
  });
  forgotForm: FormGroup = this.fb.group({
    useremail: this.useremail
  });





  public onSaveUsernameChanged(value: any) {
    this.Rememberme = value;
    localStorage.setItem("RememberUser", value)
  }

  ngOnInit() {
    this.Username = ""
    if (localStorage.getItem("RememberUser") == "false") {
      this.desablebtn = true;
      this.Rememberme = false;
      this.username.setValue("")
      this.password.setValue("")

    }
    else {
      this.desablebtn = false;
      this.Rememberme = true;
      this.username.setValue(localStorage.getItem("Username"))
      this.password.setValue(localStorage.getItem("userpwd"))

    }
    if (this.authService.isLoggedIn())
      this.router.navigate(['/report']);

    this.tagService.getTag().subscribe(tag =>
      this.tag = tag["data"]
    );
  }


  //authService.isLoggedIn()
  loginUser(formdata: any): void {
    if (this.loginForm.dirty && this.loginForm.valid || !this.desablebtn) {
      this.authService.login(this.loginForm.value)
        .subscribe(data => {
          // data = JSON.parse(data["_body"])
          if (data["success"] === false) {
            alert(data["message"]);

            if (data["data"]) {
              let newData = data["data"];
              let passwordLastUpdated = new Date(newData.password_created),
                today = new Date(),
                expiredDaysCount = Math.ceil(Math.abs(passwordLastUpdated.getTime() + (newData.password_maximum_age * 1000 * 3600 * 24) - today.getTime()) / (1000 * 3600 * 24));
              console.log(expiredDaysCount, "expired count in days");
              // condition to validate password expiry
              if (expiredDaysCount > 0) {
                this.passwordExpired = true;
              }
            }
          }
          else {
            //this.toastr.success('Login successful.');
            this.router.navigate(['report']);
          }
        });
    }
  }

  LoginPassword(formdata: any): void {
    console.log(this.loginForm.value, "loginform details");
    if (this.loginForm.dirty && this.loginForm.valid) {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      // const options = new RequestOptions({ headers: headers });
      const options = {
        headers: new HttpHeaders().append('key', 'value'),
        params: new HttpParams().append('key', 'value')
      }
      const params = {};
      params["username"] = formdata.username;
      params["password"] = formdata.password;
      params["newpassword"] = formdata.newpassword;
      this.http.post("/api/resetLoginPassword", params, options).toPromise()
        .then(
          response => {
            // var data = response["_body"]
            // data = JSON.parse(data)
            if (response["success"]) {
              alert(response["message"])
              this.passwordExpired = false;
              this.loginForm.reset();
            }
            else {
              alert(response["message"])
            }
          })
    }
  }
  forgotpassword() {
    this.forgotpasswordShow = true;
  }
  lostPassword = "";
  userName = "";
  ResetButton: boolean;
  ResetPassword(formdata: any): void {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    // const options = new RequestOptions({ headers: headers });
    const options = {
      headers: new HttpHeaders().append('key', 'value'),
      params: new HttpParams().append('key', 'value')
    }
    const params = {};
    params["useremail"] = formdata.useremail;
    this.http.post("/api/forgotpassword", params, options).toPromise()
      .then(
        response => {
          // response = JSON.parse(response["_body"]);
          if (response["success"]) {
            //alert("Your password is: " + response["password"]);
            this.userName = response["username"];
            alert("please check your email...we sent you the password")
            this.ResetButton = true;
          }
          else {
            alert("invalid email id");
            this.ResetButton = false;
          }
        })



  }
  cancelWasClicked(){
    this.passwordExpired = false;
    this.loginForm.reset();
  }


}
