import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from '../../../common/toastr.service';
import { UserService } from '../../../user/user.service';
// import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FilterPipe } from "../../../../temp/adimn/filter.pipe";


function comparePassword(c: AbstractControl): {[key: string]: boolean} | null {
  const passwordControl = c.get('password');
  const confirmControl = c.get('retypepass');

  if (passwordControl.pristine || confirmControl.pristine) {
    return null;
  }

  if (passwordControl.value === confirmControl.value) {
    return null;
  }
  return { 'mismatchedPassword': true };
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {
  @Output() registersubmit = new EventEmitter();


  registerForm: FormGroup;
  passwordGroup: FormGroup;
  groups: any;
  $: any;
  SendUserButton: boolean = false;
  term5: FilterPipe;

  constructor(private fb: FormBuilder,
              private userService: UserService,
              private router: Router,
              private http: HttpClient,
              private toastr: ToastrService) {4

  }
  roles_lists = [ "Administrator Accounts", "Privileged Accounts", "Non-Privileged Accounts", "Service Accounts"]
  password_maximum_age_lists = [ 30, 90, 365 ]
  // password_minimum_age_lists = [ "Administrator Accounts", "Privileged Accounts", "Non-Priviliged Accounts", "Service Accounts"]
  firstname = new FormControl('', [Validators.required]);
  lastname = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.email]);
  username = new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(16)]);
  roles = new FormControl('', [Validators.required]);
  group_name = new FormControl('', [Validators.required]);
  password_maximum_age = new FormControl('', [Validators.required]);
  password_minimum_age = new FormControl('', [Validators.required]);


  password = new FormControl('', [Validators.required]);
  retypepass = new FormControl('', [Validators.required]);

  public passsword(event, roles_list){
    console.log(this.roles)
    console.log(roles_list)
    console.log(event)
  }
  searchViewToggle: boolean = true;

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.registerForm = this.fb.group({
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email,
      username: this.username,
      group_name: this.group_name,
      roles: this.roles,
      password_minimum_age: this.password_minimum_age,
      password_maximum_age: this.password_maximum_age,
      // password: this.password,
      passwordGroup: this.fb.group({
        password: this.password,
        retypepass: this.retypepass,


      }, {validator: comparePassword})
    });
    this.userService.getGroups().subscribe(data => {
      if (data["success"] === false) {
        if (data["errcode"]) {
          //this.authService.logout();
          this.router.navigate(['/usermanagement']);
        }
        // this.toastr.error(data.message);
      } else {
        this.groups = data["data"];
        //this.populateForm(this.user);
        // console.log(data);
      }
    });
  }

  ngAfterViewChecked() {

    this.registerForm.get('roles').valueChanges.subscribe(

      (roles: string) => {
        // debugger

        if (roles === 'Administrator Accounts') {

          this.registerForm.get('passwordGroup.password').setValidators([Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9_]).{16,20}$')]);

          this.registerForm.get('password_minimum_age').setValue(1);

          this.registerForm.get('password_maximum_age').setValue(30);

        } else if (roles === 'Privileged Accounts') {

          this.registerForm.get('passwordGroup.password').setValidators([Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9_]).{10,20}$')]);

          this.registerForm.get('password_minimum_age').setValue(1);

          this.registerForm.get('password_maximum_age').setValue(90);

        } else if (roles === 'Non-Privileged Accounts') {

          this.registerForm.get('passwordGroup.password').setValidators([Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9_]).{8,20}$')]);

          this.registerForm.get('password_minimum_age').setValue(1);

          this.registerForm.get('password_maximum_age').setValue(90);

        } else if (roles === 'Service Accounts') {

          this.registerForm.get('passwordGroup.password').setValidators([Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9_]).{18,20}$')]);

          this.registerForm.get('password_minimum_age').setValue(1);

          this.registerForm.get('password_maximum_age').setValue(365);
        }

        this.registerForm.get('passwordGroup.password').updateValueAndValidity();

      }

    )
  }


  registerUser(formdata: any): void {
    if (this.registerForm.dirty && this.registerForm.valid) {
      const theForm = this.registerForm.value;
      const thePass = this.registerForm.value.passwordGroup.password;
      theForm.password = thePass;
      console.log(theForm)
      delete theForm.passwordGroup;

      this.userService.register(theForm)
        .subscribe(data => {
          if (data["success"] === false) {
            alert("Oops..! Trying to add existing user details or something else");
            this.registersubmit.emit(); 
          } else {
            // console.log(data.username)
            //this.registersubmit.emit();
            alert("User has been created")
            this.SendUserButton = true;
            // this.router.navigate(['/usermanagement']);
            // location.reload();
          }
          //this.registerForm.reset();
        });
      // console.log(this.roles)
    }
  }
  sendUser(formdata: any): void {
    if (this.registerForm.dirty && this.registerForm.valid) {
      const theSendForm = this.registerForm.value;
      // console.log(theSendForm);
    }
    const headers = new Headers({ 'Content-Type': 'application/json' });
    // const options = new RequestOptions({ headers: headers });
    const options = {
      headers: new HttpHeaders().append('key', 'value'),
      params: new HttpParams().append('key', 'value')
    }
    const params = {};
    params["firstname"] = formdata.firstname;
    params["useremail"] = formdata.email;
    params["username"] = formdata.username;
    params["password"] = formdata.password;
    this.http.post("/api/senduser", params, options).toPromise()
      .then(
      response => {
        // response = JSON.parse(response["_body"]);
        if (response["success"]) {
          alert("Email Successfully Sent")
          this.registersubmit.emit();
        }
        else {
          alert("Error while sending Email");

        }
      })
      this.registerForm.reset();
  }

}
