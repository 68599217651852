<div class="row">
  <div class="col-md-11">
    <br>
    <form [formGroup]="myForm" (ngSubmit)="Getdetails(myForm)">
      <div class="col-md-3 formgroup">
        <label>From Date:</label>
        <!-- <owl-date-time
         [placeHolder]="'DD/MM/YYYY HH:mm'"
         [autoClose]="true"          
         [dateFormat]="'DD/MM/YYYY HH:mm'"
          formControlName="FromDate"
           [ngModel]="FromDate"
          name="FromDate"
          (ngModelChange)="dateSelectedsnapshot($event, 'from')"
        >          
        </owl-date-time> -->
        <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="'DD/MM/YYYY HH:mm'"
          formControlName="FromDate" [ngModel]="FromDate" name="FromDate"
          (ngModelChange)="dateSelectedsnapshot($event, 'from')">
        <owl-date-time #dt1></owl-date-time>
      </div>
      <div class="col-md-3 formgroup">
        <label>To Date</label>
        <!-- <owl-date-time
         [placeHolder]="'DD/MM/YYYY HH:mm'"
         [autoClose]="true"          
         [dateFormat]="'DD/MM/YYYY HH:mm'"
          formControlName="ToDate"
           [ngModel]="ToDate"
          name="ToDate"
          (ngModelChange)="dateSelectedsnapshot($event, 'to')"
          >
        </owl-date-time> -->
        <input [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" placeholder="'DD/MM/YYYY HH:mm'" formControlName="ToDate"
          [ngModel]="ToDate" name="ToDate" (ngModelChange)="dateSelectedsnapshot($event, 'to')">
        <owl-date-time #dt2></owl-date-time>
        <span class="date-error" *ngIf="fromToDateMismatch">'From Date' should be earlier than 'To date'</span>
      </div>
      <div class="col-md-3 formgroup">
        <label>&nbsp;</label>
        <br>
        <button type="submit">Go</button> &nbsp;
        <button type="reset">Clear</button>
      </div>
    </form>
  </div>
  <div class="clearfix"></div>
  <div class="col-md-10 col-lg-offset-1">
    <button type="button" class="pull-right" (click)="exportcsv(OrdersArray)">Export Excel</button>
    <div style="padding-top: 100px;">
      <div style="display: block">
        <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
          [legend]="barChartLegend" [chartType]="barChartType"></canvas>
      </div>
    </div>
  </div>
</div>