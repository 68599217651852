<div class="tile-container">
    <div class="tile-select-container">
        <label>
            <input type="checkbox" (click)="selectClicked.emit();" [(ngModel)]="orderDetails.isSelected" [disabled]="disableCheckBoxForMissingReadyToPrintImages && orderDetails.print_ready_image=='' || orderDetails.reason_code ==='Waiting On Consumer'" name="orderSelect">
            <span>Select</span>
        </label>
    </div>
    <div class="tile-image-contianer">
        <!-- <img [src]="orderDetails.label_img" *ngIf="!isInventoryMang && isCSCLabel" [class.gridViewLabelImage]="sharedAppService.checkValidImageURL(orderDetails.label_img)" alt="Label Image" (click)="imageClick();">
        <img [src]="orderDetails.preview_img" *ngIf="isInventoryMang && !isCSCLabel" [class.gridViewLabelImage]="sharedAppService.checkValidImageURL(orderDetails.preview_img)" alt="Label Image" (click)="imageClick();">
        <img [src]="orderDetails.print_img" *ngIf="!isInventoryMang && !isCSCLabel" [class.gridViewLabelImage]="sharedAppService.checkValidImageURL(orderDetails.print_img)" alt="Label Image" (click)="imageClick();">  -->
        <img [src]="orderDetails.print_img" [class.gridViewLabelImage]="sharedAppService.checkValidImageURL(orderDetails.print_img)" alt="Label Image" (click)="imageClick();">
    </div>
    <div>
        <form>
            <div class="tile-label-details">
                <label for="tileOrderId">Order#</label>
                <input class="pull-right" type="text" id="tileOrderId" [value]="orderDetails.order_id" disabled name="orderId">
            </div>
            <div class="tile-label-details">
                <label for="textField1">Text Field 1</label>
                <input class="pull-right" type="text" id="textField1" [value]="orderDetails.label_text1" disabled name="textField1">
            </div>
            <div class="tile-label-details">
                <label for="textField2">Text Field 2</label>
                <input class="pull-right" type="text" id="textField2" [value]="orderDetails.label_text2" disabled name="textField2">
            </div>
            <div class="action-elem-container text-center">
                <span *ngIf = "shipToCountry !== 'FR' && shipToCountry !== 'IT' && shipToCountry !== 'DE'" >
                    <select name="rejectCode" class="rejectCode" [(ngModel)]="reasonCode">
                        <option value="null" selected disabled >Reason Code</option>
                        <option *ngFor="let rejectCodetmpl of rejectCodes.reason_code" [value]="rejectCodetmpl">{{rejectCodetmpl}}</option>
                    </select>
                </span>
                <span *ngIf = "shipToCountry == 'FR'">
                    <select name="rejectCode" class="rejectCode" [(ngModel)]="reasonCode">
                        <option value="null" selected disabled >Reason Code</option>
                        <option *ngFor="let rejectCodetmpl of rejectCodes.reason_code_fr" [value]="rejectCodetmpl">{{rejectCodetmpl}}</option>
                    </select>
                </span>
                <span *ngIf = "shipToCountry == 'IT'">
                    <select name="rejectCode" class="rejectCode" [(ngModel)]="reasonCode">
                        <option value="null" selected disabled >Reason Code</option>
                        <option *ngFor="let rejectCodetmpl of rejectCodes.reason_code_it" [value]="rejectCodetmpl">{{rejectCodetmpl}}</option>
                    </select>
                </span>
                <span *ngIf = "shipToCountry == 'DE'">
                    <select name="rejectCode" class="rejectCode" [(ngModel)]="reasonCode">
                        <option value="null" selected disabled >Reason Code</option>
                        <option *ngFor="let rejectCodetmpl of rejectCodes.reason_code_de" [value]="rejectCodetmpl">{{rejectCodetmpl}}</option>
                    </select>
                </span>
                
                <button class="oh-hold-button" [disabled]="!reasonCode || reasonCode === 'Waiting On Consumer'" (click)="labelHold();">
                    {{canRejectOrder ? 'Reject' : 'On Hold'}}
                </button>
            </div>
        </form>
    </div>
</div>