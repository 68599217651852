import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormControl, FormArray } from '@angular/forms';
import * as moment from 'moment';
import { DataService } from "../../shared/service/data.service"

@Component({
  selector: 'app-crs-report',
  templateUrl: './crs-report.component.html',
  styleUrls: ['./crs-report.component.css']
})
export class CrsReportComponent implements OnInit {

  constructor(private dataservice: DataService) {
    
      }
      public myForm: FormGroup;
    
      ngOnInit() {
        this.myForm = new FormGroup({
          'FromDate': new FormControl(),
          'ToDate': new FormControl(),
          'order_status': new FormControl()
        });
        this._onload()
      }
      crstoDate
      crsfromDate
      _onload() {
        var fromdatenow = new Date()
        fromdatenow.setHours(0)
        fromdatenow.setMinutes(0)
        fromdatenow.setSeconds(0)
        fromdatenow.setMilliseconds(0)
    
        var todatenow = new Date()
        todatenow.setHours(23)
        todatenow.setMinutes(59)
        todatenow.setSeconds(0)
        todatenow.setMilliseconds(0)
        this.crstoDate = todatenow.toString()
        this.crsfromDate = fromdatenow.toString()
    
    
        this.myForm.setValue({
          order_status: "All",
          FromDate: '',
          ToDate: ''
    
        })
        this.getcrsreportdata(this.crsfromDate, this.crstoDate);
      }
      fromToDateMismatch: boolean = false;
      checkDateValidity(from, to): boolean {
        if (from && to && from > to) {
            return false;
        }
        //returning true by default
        return true;
    }
    
    dateSelectedcrs(e, field) {
        if(field === "from") {
            this.fromDate = e;
        } else {
            this.toDate = e;
        }
        if(typeof(this.fromDate)=="string"&&this.fromDate!="")
          this.fromDate=new Date(this.fromDate)
        else if(typeof(this.toDate)=="string"&&this.toDate!="")
          this.toDate=new Date(this.toDate)
        this.fromToDateMismatch = !this.checkDateValidity(this.fromDate, this.toDate);
    }
    getcrsreportdata(fromdate, todate) {
        this.dataservice.GetPostMethod("inventorystatus?startDate=" + fromdate + "&endDate=" + todate + "&user=" + localStorage.getItem('Username') + "", "")
          .subscribe(Response => {
            if (Response["success"] == "true") {
              this.rows=Response["message"]
              this.showtable=true
              this.OrdersArray=Response["message"]
            }
            else {
              
            }
          })
      }
    
    
      public fromDate: any;
      public toDate: any;
      public OrdersArray: any;
      public showtable = false;
      public Orderstatus: any;
      rows = [];
      columns = [
        { name: 'Shipment Group ID', flexGrow: 2, prop: 'shipment_group_id' },
        { name: 'Sku', flexGrow: 2, prop: 'sku' },                
        { name: 'Web ID', flexGrow: 2, prop: 'web_order_id' },              
        { name: 'Status Updated Date', flexGrow: 2, prop: 'entry_date_time' },
        { name: 'Order Status', flexGrow: 2, prop: 'order_status' },
        { name: 'Order ID', flexGrow: 2, prop: 'order_id' },
        { name: 'Total Quantity', flexGrow: 2, prop: 'line_item_quantity' },
       // { name: 'Cancelled Quantity', flexGrow: 1, prop: 'cancelled_quantity' },
        { name: 'Ordered Date', flexGrow: 2, prop: 'order_submit_date' },
      ];
    
      Getdetails(myForm: any) {        
        var frmdate = ""
        this.fromDate = moment.parseZone(myForm.controls.FromDate.value).format("YYYY-MM-DDTHH:mm:ss")
        this.toDate = moment.parseZone(myForm.controls.ToDate.value).format("YYYY-MM-DDTHH:mm:ss")
        //this.Orderstatus = myForm.controls.order_status.value;
        if (this.fromDate == "Invalid date" || this.toDate == "Invalid date") {
          alert("Please fill")
          return false
        }
        this.getcrsreportdata(this.fromDate, this.toDate)
    
      }
      resetform() {
        this.showtable = false;
      }
      exportcsv(JsonData): any {
        let today = new Date();
        var summary = 'Shipment Group ID, Sku , Status Updated Date,Order Status,Order ID,Total Quantity, Ordered Date\n';
        JsonData.forEach(function (row) {
          summary += row.shipment_group_id + ',' + row.sku + ',' + row.entry_date_time + ','+ row.order_status + ','
          + row.order_id + ','+ row.line_item_quantity + ',' + row.order_submit_date + ',';
          summary += "\n";
        });
        var csvElement = document.createElement('a');
        csvElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(summary);
        csvElement.target = '_blank';
        csvElement.download = 'CRS_Report_' + today + '.csv';
        csvElement.click();
      }
    }